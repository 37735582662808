import useDefinedContext from "hooks/useDefinedContext";
import AthleteStoreContext from "../contexts/AthleteStoreContext";
import isArray from "lodash/isArray";
import { useMemo } from "react";

/**
 * Provide a memoized array of athleteIds to filter the athletes returned by the hook
 * @param {string[]} [athleteIds]
 */
function useAthleteStore(athleteIds?: string[]) {
  const athletes = useDefinedContext(AthleteStoreContext);

  return useMemo(() => {
    if (isArray(athleteIds)) {
      return athletes.filter((athlete) => athleteIds.includes(athlete.id));
    }

    return athletes;
  }, [athleteIds, athletes]);
}

export default useAthleteStore;
