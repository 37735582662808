import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { grey } from "@mui/material/colors";

interface RPEIndicatorProps {
  score?: number | null;
}

function RPEIndicator(props: RPEIndicatorProps) {
  const { score } = props;

  const scoreColor = [
    "#04B86C",
    "#04B86C",
    "#83C530",
    "#83C530",
    "#EBD721",
    "#EBD721",
    "#F5A62E",
    "#F5A62E",
    "#DA2A69",
    "#DA2A69",
  ];

  const redDotPositions = [10, 20, 30, 40, 50, 60, 70, 80, 90];
  const lineHeight = 6;

  return (
    <Box position="relative" title={score ? `RPE ${score}/10` : undefined}>
      <svg height={lineHeight} width="100%">
        <rect rx={lineHeight / 2} height={lineHeight} width="100%" fill="#EDE8E8" />
        <circle r={lineHeight / 2} cx={lineHeight / 2} cy={lineHeight / 2} fill={grey[400]} />
        {redDotPositions.map((pos, posIndex) => (
          <circle key={pos} r={lineHeight / 2} cx={`${pos}%`} cy={lineHeight / 2} fill={scoreColor[posIndex]} />
        ))}
        <circle
          r={lineHeight / 2}
          cx={"100%"}
          cy={lineHeight / 2}
          fill="#DA2A69"
          transform={`translate (-${lineHeight / 2},0)`}
        />
        {score && (
          <rect rx={lineHeight / 2} height={lineHeight} width={`${score * 10}%`} fill={scoreColor[score - 1]}>
            <animate attributeName="width" from={`0%`} to={`${score * 10}%`} dur="800ms" />
          </rect>
        )}
      </svg>
      <Grid container wrap="nowrap" justifyContent="space-between" spacing="0px" p={0}>
        <Grid item>
          <Box position="absolute">
            <Typography variant="h4" component="span">
              0
            </Typography>
          </Box>
          &nbsp;
        </Grid>
        {redDotPositions.map((pos) => (
          <Grid key={pos} item>
            <Typography variant="h4" component="span">
              {pos / 10}
            </Typography>
          </Grid>
        ))}
        <Grid item>
          <Box position="absolute" right={0}>
            <Typography variant="h4" component="span">
              10
            </Typography>
          </Box>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  );
}

export default RPEIndicator;
