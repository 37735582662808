import uniq from "lodash/uniq";
import { useMemo } from "react";
import { EntityType } from "types/entity";
import useChartSelections from "components/charting/hooks/useChartSelections";
import intersection from "lodash/intersection";
import { AthleteWithType, GroupWithType } from "types/AthleteOrGroup";

function useChartSourceIds() {
  const { sources } = useChartSelections();

  return useMemo(() => {
    const athleteSources: AthleteWithType[] = [];
    const groupSources: GroupWithType[] = [];

    sources.forEach((source) => {
      if (source.type === EntityType.GROUP) {
        groupSources.push(source);
      } else {
        athleteSources.push(source);
      }
    });

    const groupAthleteIntersection = intersection(...groupSources.map((source) => source.athleteIds));

    return uniq([...athleteSources.map((athlete) => athlete.id), ...groupAthleteIntersection]);
  }, [sources]);
}
export default useChartSourceIds;
