import { useMemo, useEffect, useState } from "react";
import DeprecatedGroupsContext from "../../contexts/GroupsContext";
import GroupService from "services/GroupService";
import GroupsStoreContext from "./GroupsStoreContext";
import GroupActionsContext from "./GroupActionsContext/GroupActionsContext";
import { firstBy } from "thenby";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import type { CreateGroupRequest, UpdateGroupRequest } from "services/GroupService/GroupService.types";
import type { Group, GroupActions } from "types/groups";

type GroupContextValue = [Group[], GroupActions];

function GroupsProvider(props: { children: React.ReactNode }) {
  const [groups, setGroups] = useState<Array<Group>>([]);
  const organisation = useOrganisation();

  const groupActions = useMemo<GroupActions>(
    () => ({
      fetchAll: async () => {
        if (!organisation.featureConfig.groupsEnabled) return;

        const result = await GroupService.getAll();

        setGroups(result);
      },
      fetchById: async (id: string) => {
        const result = await GroupService.fetchById(id);

        setGroups((prevGroups) => [...prevGroups.filter((group) => group.id !== id), result]);

        return result;
      },
      delete: async (id: string) => {
        const result = await GroupService.delete(id);

        setGroups((prevGroups) => prevGroups.filter((group) => group.id !== id));

        return result;
      },
      create: async (group: CreateGroupRequest) => {
        const result = await GroupService.create(group);
        const newGroup = { ...result, ...group };

        setGroups((prevGroups) => [...prevGroups, newGroup]);

        return newGroup;
      },
      update: async (id: string, group: UpdateGroupRequest) => {
        const result = await GroupService.update(id, group);
        const updatedGroup = { ...group, ...result, id };

        setGroups((prevGroups) => [...prevGroups.filter((group) => group.id !== id), updatedGroup]);

        return updatedGroup;
      },
    }),
    [organisation.featureConfig.groupsEnabled]
  );

  useEffect(() => {
    groupActions.fetchAll();
  }, [groupActions]);

  const groupsContext = useMemo<GroupContextValue>(
    () => [groups.slice(0).sort(firstBy("name", { direction: "asc", ignoreCase: true }).thenBy("id")), groupActions],
    [groupActions, groups]
  );

  const store = useMemo(() => {
    return groupsContext[0];
  }, [groupsContext]);

  return (
    <DeprecatedGroupsContext.Provider value={groupsContext}>
      <GroupActionsContext.Provider value={groupActions}>
        <GroupsStoreContext.Provider value={store}>{props.children}</GroupsStoreContext.Provider>
      </GroupActionsContext.Provider>
    </DeprecatedGroupsContext.Provider>
  );
}

export default GroupsProvider;
