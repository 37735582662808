import RemoteConfigService from "services/RemoteConfigService";
import { useEffect, useState } from "react";
import PageWarning from "components/PageWarning";

export interface RemoteAnnouncementBannerProps {}

function RemoteAnnouncementBanner(props: RemoteAnnouncementBannerProps) {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    (async () => {
      const msg = await RemoteConfigService.getStringValue("HUB_ANNOUNCEMENT_TEXT");

      setMessage(msg);
    })();
  }, []);

  return <PageWarning show={Boolean(message)} message={message} />;
}

export default RemoteAnnouncementBanner;
