import MessageCard from "components/MessageCard";
import * as Components from "./OneRepMaxChartNoData.components";
import useChartSelections from "components/charting/hooks/useChartSelections";
import ChartHelpers from "pages/Charting/helpers/ChartHelpers";

interface OneRepMaxChartNoDataProps {}

function OneRepMaxChartNoData(props: OneRepMaxChartNoDataProps) {
  const selections = useChartSelections();

  return (
    <MessageCard title="1RM">
      {`No data to display${
        selections.period.value ? ChartHelpers.generateTimePhrase(selections.period.value) : ""
      }. Please check you have selected a `}
      <Components.ImportantText>VBT exercise</Components.ImportantText>.
    </MessageCard>
  );
}

export default OneRepMaxChartNoData;
