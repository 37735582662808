import Grid from "@mui/material/Grid";
import Tables from "components/Tables";
import { tableCells as athleteTableCells } from "components/AthleteHub/AthleteTable/AthleteTable.config";
import AthleteTableIconButtonControls from "components/AthleteHub/AthleteTable/AthleteTableControls/AthleteTableIconButtonControls";

interface GroupAthleteTableControlsProps {}

function GroupAthleteTableControls(props: GroupAthleteTableControlsProps) {
  return (
    <>
      <Grid {...Tables.controlsContainerProps}>
        <Grid {...athleteTableCells.checkbox} width="42px" pl={1} textAlign="center" lineHeight={0}>
          <Tables.Icons.Search color="inherit" />
        </Grid>
        <Grid {...athleteTableCells.name} pr={1} minWidth={320} maxWidth={480} xs={6} lg={4}>
          <Tables.Components.SearchTextInput />
        </Grid>
        <AthleteTableIconButtonControls />
      </Grid>
    </>
  );
}

export default GroupAthleteTableControls;
