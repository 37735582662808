import { styled, alpha } from "@mui/material";
import { DataGridPro as MuiDataGrid } from "@mui/x-data-grid-pro";

const DataGrid = styled(MuiDataGrid)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    borderWidth: 0,
    fontSize: theme.typography.h4.fontSize,
    minHeight: theme.spacing(20),
    "& .MuiDataGrid-row": {
      cursor: "pointer",
      "& .MuiDataGrid-cell:first-of-type": {
        paddingLeft: theme.spacing(3),
      },
      "& .MuiDataGrid-cell:last-of-type": {
        paddingRight: theme.spacing(3),
      },
    },
    "& .MuiDataGrid-cell": {
      borderBottomColor: theme.palette.grey[300],
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottomColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      "& .MuiDataGrid-columnHeader:first-of-type": {
        paddingLeft: theme.spacing(3),
      },
      "& .MuiDataGrid-columnHeader:last-of-type": {
        paddingRight: theme.spacing(3),
      },
    },
    "& .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows": {
      color: theme.palette.primary.main,
    },
    "& .MuiDataGrid-footerContainer": {
      borderTopColor: theme.palette.grey[300],
    },
    "& .MuiDataGrid-sortIcon": {
      color: alpha(theme.palette.getContrastText(theme.palette.primary.main), 1),
    },
    "& .MuiDataGrid-menuIconButton": {
      color: alpha(theme.palette.getContrastText(theme.palette.primary.main), 1),
    },
  };
});
export default DataGrid;
