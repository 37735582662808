import DataVisualizationTypeProvider from "./DataVisualizationTypeProvider";
import ChartOptionsProvider from "components/charting/providers/ChartOptionsProvider";
import ChartSelectionsProvider from "components/charting/providers/ChartSelectionsProvider";
import LoadingProvider from "providers/LoadingProvider";
import ChartSetSelectProvider from "./ChartSetSelectProvider";
import ChartCompareSetSelectProvider from "./ChartCompareSetSelectProvider";

function ChartingProvidersV3(props: { children: React.ReactNode }) {
  return (
    <LoadingProvider>
      <ChartOptionsProvider>
        <ChartSelectionsProvider>
          <DataVisualizationTypeProvider>
            <ChartSetSelectProvider>
              <ChartCompareSetSelectProvider>
                <>{props.children}</>
              </ChartCompareSetSelectProvider>
            </ChartSetSelectProvider>
          </DataVisualizationTypeProvider>
        </ChartSelectionsProvider>
      </ChartOptionsProvider>
    </LoadingProvider>
  );
}
export default ChartingProvidersV3;
