import PropTypes from "prop-types";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Backdrop from "../Backdrop";
import * as Components from "./SpeedDialMenu.components";
import { useCallback } from "react";
import logEvent from "utils/logEvent";

function SpeedDialMenu(props) {
  const { handleClose } = props;

  const handlePrimaryClick = useCallback(() => {
    logEvent("HUB_FAB_OPEN");
  }, []);

  const curriedHandleMenuActionClick = useCallback(
    (actionName) => {
      return () => {
        handleClose();
        logEvent("HUB_FAB_ACTION", { action: actionName });
      };
    },
    [handleClose]
  );

  return (
    <>
      <Backdrop open={props.open} />
      <Components.PrimaryFabSpeedDial
        ariaLabel="CreateShortcuts"
        icon={<SpeedDialIcon />}
        onClick={handlePrimaryClick}
        onClose={handleClose}
        onOpen={props.handleOpen}
        open={props.open}
        direction="up"
        FabProps={{
          color: "primary",
          size: "large",
        }}
      >
        {props.actions.map((action) => {
          const handleMenuActionClick = curriedHandleMenuActionClick(action.name);

          return (
            <Components.OptionFabSpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement="left"
              onClick={handleMenuActionClick}
              FabProps={{ size: "large" }}
            />
          );
        })}
      </Components.PrimaryFabSpeedDial>
    </>
  );
}

SpeedDialMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SpeedDialMenu;
