import { saveAs } from "file-saver";

class FileService {
  static getFileName(contentDispositionHeader, defaultFileName) {
    let fileName = defaultFileName;

    if (contentDispositionHeader) {
      const matches = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

      if (matches && matches[1] !== undefined) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    return fileName;
  }

  static downloadResponseAsZip(response) {
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], { type });

    saveAs(blob, FileService.getFileName(response.headers["content-disposition"], "Output Sports Data Export.zip"));

    return;
  }
}
export default FileService;
