import { styled } from "@mui/material";
import Button from "@mui/material/Button";

export const GroupedButton = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
  borderRadius: 0,
  textTransform: "none",
  fontWeight: 400,
  "&:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "&:last-of-type": {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  "&:hover": {
    backgroundColor: selected ? undefined : theme.palette.grey[300],
  },
}));
