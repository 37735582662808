import { LinePath } from "@visx/shape";
import { useUnitFormatter } from "hooks";
import useChartingColors from "pages/Charting/hooks/useChartingColors";
import useXScale from "pages/Charting/hooks/useXScale";
import useYScaleById from "pages/Charting/hooks/useYScaleById";
import { SelectedChartMeasure } from "components/charting/types";
import { Fragment, useCallback } from "react";
import { Variants } from "types/exercise";
import useChartAnnotationLineLabelPosition from "../../../annotations/hooks/useChartAnnotationLineLabelPosition";

interface AverageLineProps {
  average: number;
  scaleId: string;
  variant: Variants | null;
  selectedMetric: SelectedChartMeasure["metric"];
}

function AverageLine(props: AverageLineProps) {
  const { average, variant, scaleId, selectedMetric } = props;
  const xScale = useXScale();
  const yScale = useYScaleById(scaleId);
  const unitFormatter = useUnitFormatter();
  const { axis, lines } = useChartingColors();
  const getX = useCallback((_: unknown, index: number) => xScale.range()[index], [xScale]);
  const setLineLabelPosition = useChartAnnotationLineLabelPosition();

  const formattedValue = unitFormatter(average, selectedMetric);

  return (
    <Fragment>
      {[average, average].map((yValue, index) => (
        <text
          key={["AVERAGE", variant, index].filter(Boolean).join("_")}
          y={yScale(yValue) + setLineLabelPosition(variant, Boolean(index))}
          x={getX(undefined, index)}
          textAnchor={index ? "end" : undefined}
          fontSize={12}
          fill={axis.line}
        >
          {["Average", formattedValue, variant && `(${variant.charAt(0)})`].filter(Boolean).join(" ")}
        </text>
      ))}
      <LinePath
        data={[yScale(average), yScale(average)]}
        x={getX}
        y={(yPoint) => yPoint}
        stroke={lines.secondary}
        strokeDasharray={9}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Fragment>
  );
}

export default AverageLine;
