import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6.66669L8 10.6667L12 6.66669"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 10L12 16L18 10"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 13.3333L16 21.3333L24 13.3333"
        stroke="currentColor"
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function CaretIcon(props: SvgIconProps) {
  const item = size[(props.fontSize as "small" | "large") || "default"];

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default CaretIcon;
