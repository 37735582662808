import { useMemo } from "react";
import useGroupsContext from "hooks/useGroupsContext";
import { CombinedAthletesAndGroups } from "types";
import { EntityType } from "types/entity";
import difference from "lodash/difference";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";

function useCombinedAthletesAndGroups(): CombinedAthletesAndGroups {
  const athletes = useAthleteStore();
  const [groups] = useGroupsContext();
  const archivedAthleteIds = useArchivedAthletes(true);

  const athletesWithType = useMemo(
    () => athletes.map((athlete) => ({ ...athlete, type: EntityType.ATHLETE as const })),
    [athletes]
  );
  const groupsWithType = useMemo(
    () =>
      groups.map((group) => ({
        ...group,
        athleteIds: difference(group.athleteIds, archivedAthleteIds),
        type: EntityType.GROUP as const,
      })),
    [archivedAthleteIds, groups]
  );

  return useMemo(() => {
    return [...athletesWithType, ...groupsWithType];
  }, [athletesWithType, groupsWithType]);
}
export default useCombinedAthletesAndGroups;
