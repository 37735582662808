import React from "react";
import PropTypes from "prop-types";
import "./Loader.scss";

const Loader = (props) => {
  return (
    <div className={`wrapper-loader ${props.isSmall ? "small" : props.withDarkBackground ? "has-dark-bg" : ""}`}>
      <div className="loader" />
    </div>
  );
};

Loader.propTypes = {
  isSmall: PropTypes.bool,
  withDarkBackground: PropTypes.bool,
};

export default Loader;
