import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { TooltipColorStrip, TooltipDataRow, TooltipPaper } from "components/TooltipCard/TooltipCard.components";
import TooltipCardHeader from "components/TooltipCard/TooltipCardHeader";
import useColorRegistry from "../../../../pages/Charting/hooks/useColorRegistry";
import useChartSelections from "../../hooks/useChartSelections";
import useChartMeasureData from "../../../../pages/Charting/hooks/useChartMeasureData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MetadataType } from "types/metadata";
import { alpha } from "@mui/material";
import { LinePointData } from "components/charting/types/Chart.types";
import TooltipCardActions from "./TooltipCardActions";
import { Measurement, Wellness } from "types";
import { useCallback } from "react";
import TooltipCardNote from "components/TooltipCardNote";
import { useTranslation } from "react-i18next";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";

function TooltipCard(props: LinePointData) {
  const tooltipData = props;
  const entityColor = useColorRegistry()(tooltipData.entityId);
  const wellnesses = useWellnessMetadata();
  const selections = useChartSelections();
  const letter = props.variant?.charAt(0);
  const { t } = useTranslation();

  const measure = useChartMeasureData(tooltipData);

  const tooltipCardHeaderProps =
    selections.measures.length <= 1
      ? {
          athleteId: tooltipData.source.id,
          color: entityColor,
          completedDate: measure.completedDate,
          variant: props.variant,
        }
      : measure;

  const computedAggregationPeriodMessage = t("Charting.tooltipAggregationPeriodMessage", {
    label: selections.aggregationPeriod.label,
  });

  const isSelectedMeasure = useCallback(
    (id: string, metricField: string) => {
      if (measure.type === "Wellness") {
        return tooltipData.measure.id === id;
      }
      return tooltipData.measure.metricField === metricField;
    },
    [measure.type, tooltipData.measure.id, tooltipData.measure.metricField]
  );

  const wellnessComments =
    props.metadataType === "Wellness"
      ? wellnesses
          .map(({ name, commentField }) => ({
            label: name,
            comment: props.measurement[commentField as keyof Wellness] as string | null,
          }))
          .filter(({ comment }) => comment)
      : undefined;

  return (
    <TooltipPaper>
      <TooltipColorStrip entityColor={entityColor} letter={letter} />
      <TooltipDataRow>
        <TooltipCardHeader {...tooltipCardHeaderProps} />
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Grid container alignItems="center" columns={12}>
          {measure.metrics.map((metric) => (
            <Grid
              item
              xs={measure.type === MetadataType.EXERCISE ? 4 : 6}
              key={metric.id}
              sx={{
                backgroundColor: isSelectedMeasure(metric.id, metric.field) ? alpha(entityColor, 0.1) : undefined,
                borderRadius: 1,
              }}
            >
              <Box p={0.5}>
                <Typography align="center" style={{ lineHeight: 1.2 }}>
                  <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                    {[tooltipData.computed && "*", metric.label].filter(Boolean).join("")}
                  </Box>
                </Typography>
                <Typography variant="h4" title={`${metric.value}`} align="center">
                  {metric.value || "-"}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </TooltipDataRow>
      {Boolean(wellnessComments?.length) && (
        <>
          <Divider />
          <TooltipDataRow>
            <Grid container spacing="8px">
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={500}>
                  {t("Charting.tooltipCommentsHeader")}
                </Typography>
              </Grid>
              {wellnessComments?.map(({ label, comment }) => (
                <Grid container xs={6}>
                  <Grid item xs={12}>
                    <Typography title={`${label}: ${comment}`}>
                      <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                        {label}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography title={`${label}: ${comment}`} sx={{ fontStyle: "italic" }}>
                      {comment}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </TooltipDataRow>
        </>
      )}
      <TooltipCardNote {...tooltipData} />
      {tooltipData.computed && (
        <>
          <Divider />
          <TooltipDataRow>
            <Typography variant="caption" title={computedAggregationPeriodMessage} align="center">
              {computedAggregationPeriodMessage}
            </Typography>
          </TooltipDataRow>
        </>
      )}
      {Boolean(!tooltipData.computed && props.metadataType === "Exercise") && (
        <>
          <Divider />
          <TooltipDataRow>
            <TooltipCardActions measurementId={(props.measurement as Measurement).id} />
          </TooltipDataRow>
        </>
      )}
    </TooltipPaper>
  );
}

export default TooltipCard;
