import { firstBy } from "thenby";
import type { Exercise } from "types";

class ExerciseHelpers {
  static canConfigureReps(exercise?: Exercise) {
    const is10_5_TEST = exercise?.id?.includes("10_5");

    return Boolean(!is10_5_TEST && ExerciseHelpers.isRepExercise(exercise));
  }

  static isRepExercise(exercise?: Exercise) {
    return Boolean(exercise?.repetitions?.length) || ExerciseHelpers.hasRepCountMetric(exercise);
  }

  static isVBTExercise(exercise?: Exercise) {
    return ["Barbell Velocity", "Slams, Swings & Throws"].includes(`${exercise?.category}`);
  }

  static isOneRepMaxExercise(exercise?: Exercise) {
    return Boolean(exercise?.isOneRepMaxEnabled);
  }

  static getWeightMetric(exercise?: Exercise) {
    return exercise?.metrics.find((metric) => metric.field === "weight");
  }

  static hasWeightMetric(exercise?: Exercise) {
    return Boolean(ExerciseHelpers.getWeightMetric(exercise));
  }

  static hasRepCountMetric(exercise?: Exercise) {
    return Boolean(exercise?.metrics.find((metric) => metric.field === "repCount"));
  }

  static hasVariants(exercise?: Exercise) {
    return Boolean(exercise?.variants.length);
  }

  static isPowerMetric(field?: string): boolean {
    return Boolean(field?.toUpperCase().includes("POWER"));
  }

  static velocityTargetsCalculatedByPeakOnly(exercise: Exercise) {
    return Boolean(
      [
        "BARBELL_HANG_CLEAN",
        "BARBELL_HANG_SNATCH",
        "BARBELL_POWER_CLEAN",
        "BARBELL_POWER_SNATCH",
        "FREE_VBT_OLYMPICS",
      ].includes(exercise.id) || ["Slams, Swings & Throws"].includes(exercise.category)
    );
  }

  static generateInstructions(exercise: Exercise) {
    const rowDelimiter = exercise.instructions?.indexOf("\r\n") !== -1 ? "\r\n" : "\n";

    return (exercise.instructions || "")
      .split(rowDelimiter)
      .map((line, lineIndex, baseArray) => `${baseArray.length > 1 ? `${lineIndex + 1}. ` : ""}${line}`);
  }

  static sort(exercises: Exercise[]) {
    return [...exercises].sort(firstBy("category", { ignoreCase: true }).thenBy("name"));
  }

  /**
   * @description Method gets the metric by field name or the default metric if there is no default metric it will return the first metric
   *
   */
  static getMetricByFieldNameOrDefault(exercise: Exercise, metricField: string | undefined | null) {
    const metric =
      exercise.metrics?.find((metric) => metric.field === metricField) ||
      exercise.metrics?.find((metric) => metric.isDefault) ||
      exercise.metrics[0];

    return metric;
  }

  /**
   * @description Method filters exercises by id
   */
  static filterExercisesById(exercises: Exercise[], id: Exercise["id"]) {
    return exercises.filter((exercise) => exercise.id === id);
  }

  /**
   * @description Method filters exercises by ids
   */
  static filterExercisesByIds(exercises: Exercise[], id: Exercise["id"][]) {
    return exercises.filter((exercise) => id.includes(exercise.id));
  }
}

export default ExerciseHelpers;
