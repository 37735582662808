import { ButtonProps } from "@mui/material/Button";
import { ButtonGroupProps as MuiButtonGroupProps } from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import React, { useCallback } from "react";
import * as Components from "./ButtonGroup.components";

interface ButtonGroupProps<T = any, K = T & { label: React.ReactNode; value: any }> {
  options: K[];
  value: K;
  setOption(value: K): void;
  size?: MuiButtonGroupProps["size"];
  disabled?: boolean;
}

const ButtonGroup = React.forwardRef(function ButtonGroup<T extends { label: React.ReactNode; value: any }>(
  props: ButtonGroupProps<T>,
  ref: React.Ref<HTMLDivElement>
) {
  const { options, setOption, value, size = "large" } = props;

  const getButtonProps = useCallback(
    (option: T): ButtonProps => {
      const onClick = () => {
        setOption(option);
      };

      if (option !== value) {
        return { onClick };
      }

      return {
        color: "primary",
        variant: "contained",
        onClick,
      };
    },
    [setOption, value]
  );

  return (
    <Grid container ref={ref} spacing="0px" p={0}>
      <Components.ButtonGridItem item xs={12} width="100%" borderRadius={2}>
        <Components.ButtonGroup size={size} disableElevation disabled={props.disabled}>
          {options.map((option, optionIndex) => (
            <Components.GroupedButton
              key={optionIndex}
              sx={{ height: "37px" /* Height of sibling inputs */ }}
              {...getButtonProps(option)}
              aria-label={`select ${option.label?.toString()}`}
            >
              {option.label}
            </Components.GroupedButton>
          ))}
        </Components.ButtonGroup>
      </Components.ButtonGridItem>
    </Grid>
  );
});

export default ButtonGroup;
