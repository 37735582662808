import Autocomplete from "./Autocomplete";
import Avatar from "./Avatar";
import Button from "./Button";
import ButtonBase from "./ButtonBase";
import Checkbox from "./Checkbox";
import Chip from "./Chip";
import Paper from "./Paper";
import Switch from "./Switch";
import TextField from "./TextField";
import ToggleButton from "./ToggleButton";
import ToggleButtonGroup from "./ToggleButtonGroup";

const Mui5 = {
  Autocomplete,
  Avatar,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  Paper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
};
export default Mui5;
