import SvgIcon from "@mui/material/SvgIcon";

function CameraIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" />
      <path d="M10.2 6L9.102 7.22222H7.2C6.54 7.22222 6 7.77222 6 8.44444V15.7778C6 16.45 6.54 17 7.2 17H16.8C17.46 17 18 16.45 18 15.7778V8.44444C18 7.77222 17.46 7.22222 16.8 7.22222H14.898L13.8 6H10.2ZM12 15.1667C10.344 15.1667 9 13.7978 9 12.1111C9 10.4244 10.344 9.05556 12 9.05556C13.656 9.05556 15 10.4244 15 12.1111C15 13.7978 13.656 15.1667 12 15.1667Z" />
    </SvgIcon>
  );
}
export default CameraIcon;
