import Mui5 from "library/mui5";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridBorderBottomGrey } from "../Settings.components";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import CoachesTable from "./CoachesTable";

interface SettingsCoachManagementProps {}

function SettingsCoachManagement(props: SettingsCoachManagementProps) {
  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <Box p={6} pb={2}>
                <Typography variant="h1">
                  <Box component="span" fontWeight="fontWeightMedium">
                    Coaches
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box pt={4.5} pr={6} width={240}>
                <Mui5.Button href="create">Create a Coach</Mui5.Button>
              </Box>
            </Grid>
          </Grid>
          <Box pt={0} p={6}>
            <Mui5.Paper>
              <Box p={0}>
                <CoachesTable />
              </Box>
            </Mui5.Paper>
          </Box>
        </GridBorderBottomGrey>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsCoachManagement;
