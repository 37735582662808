import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import useAthlete from "hooks/useAthlete";
import useUnitFormatter from "hooks/useUnitFormatter";
import AthleteName from "components/AthleteName";
import { useNavigate } from "react-router-dom";
import AnalyticsService from "services/AnalyticsService";
import { ExerciseMetric, FireStoreMeasurement } from "types";
import logEvent from "utils/logEvent";
import * as LeaderboardsComponents from "../Leaderboards.components";

export const LeaderboardTableAbsoluteRow = (props: {
  position: number | null;
  measurement: FireStoreMeasurement;
  metric: ExerciseMetric;
}) => {
  const { position, measurement, metric } = props;
  const athlete = useAthlete(measurement.athleteId, true);
  const formatUnit = useUnitFormatter();
  const navigate = useNavigate();

  const clickableProps = athlete && {
    component: ButtonBase,
    onClick: () => {
      logEvent("HUB_LIVE_ATHLETE_PROFILE_CHART_EXERCISE", {
        [AnalyticsService.PROPERTIES.EXERCISE_ID]: measurement.exerciseId,
        [AnalyticsService.PROPERTIES.EVENT_CLICK_LOCATION]: "liveLeaderboard",
      });
      navigate(`/athlete-hub/${athlete.id}?chartType=LINE&metadataId=${measurement.exerciseId}`);
    },
  };

  return (
    <LeaderboardsComponents.TableRowStyledBox {...clickableProps}>
      <Grid container alignItems="center" p={0} spacing="0px">
        <LeaderboardsComponents.PositionCellGrid item>{position}</LeaderboardsComponents.PositionCellGrid>
        <Grid item xs>
          <Grid item>
            <AthleteName {...athlete} id={measurement.athleteId} />
          </Grid>
        </Grid>
        <LeaderboardsComponents.MetricCellGrid item>
          {formatUnit(measurement[metric.field], metric)}
        </LeaderboardsComponents.MetricCellGrid>
      </Grid>
    </LeaderboardsComponents.TableRowStyledBox>
  );
};
