import { useMemo } from "react";
import ChartOptionsContext from "components/charting/contexts/ChartOptionsContext";
import useCombinedAthletesAndGroups from "hooks/useCombinedAthletesAndGroups";
import { getInitialOptions } from "pages/Charting/Charting.config";
import useMetadataStore from "providers/MetadataProvider/useMetadataStore";

interface ChartOptionsContextProps {
  children: React.ReactNode;
}

function ChartOptionsProvider(props: ChartOptionsContextProps) {
  const metadata = useMetadataStore();
  const athletesAndGroups = useCombinedAthletesAndGroups();

  const chartOptions = useMemo(
    () =>
      getInitialOptions({
        sources: athletesAndGroups,
        measures: metadata,
      }),
    [athletesAndGroups, metadata]
  );

  return <ChartOptionsContext.Provider value={chartOptions}>{props.children}</ChartOptionsContext.Provider>;
}

export default ChartOptionsProvider;
