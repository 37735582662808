import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import moment from "moment";
import { ChartingSelections, SelectedChartMeasure, SelectedChartMeasureEmpty } from "components/charting/types";
import MeasureService from "pages/Charting/services/MeasureService";
import chartDataApiReducer, { chartDataApiReducerInitialState } from "./useChartDataApi.reducer";
import MeasureServiceHelper from "../../helpers/MeasureServiceHelper";
import { ChartDataApiReducerActions, ChartDataApiRequestStatus } from "./useChartDataApi.types";
import useSetLoading from "hooks/useSetLoading";
import AnalyticsService from "services/AnalyticsService";
import useLogEventContext from "hooks/useLogEventContext";
import { MeasureData } from "components/charting/types/MeasureData.types";

export interface UseChartDataApiProps {
  sourceIds: string[];
  measures: Exclude<SelectedChartMeasure, SelectedChartMeasureEmpty>[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  period: ChartingSelections["period"];
}

function useChartDataApi(props: UseChartDataApiProps) {
  const { sourceIds, measures, startDate, endDate } = props;
  const [measureStore, dispatchToMeasureStore] = useReducer(chartDataApiReducer, chartDataApiReducerInitialState);
  const setLoading = useSetLoading();
  const timePeriodRef = useRef(props.period);
  timePeriodRef.current = props.period;
  const logEvent = useLogEventContext();

  const startDateString = startDate.toISOString();
  const endDateString = endDate.toISOString();

  const measureStoreStatusRef = useRef(measureStore.status);
  measureStoreStatusRef.current = measureStore.status;

  useEffect(() => {
    sourceIds.forEach((sourceId) => {
      measures.forEach(async (measure) => {
        const requestParams = { sourceId, measure, startDate, endDate };
        const requestId = MeasureServiceHelper.getRequestId(requestParams);

        if (measureStoreStatusRef.current[requestId]) return;

        logEvent({
          [AnalyticsService.PROPERTIES.PERIOD]: timePeriodRef.current.value || "CUSTOM",
          [AnalyticsService.PROPERTIES.CHARTING_SOURCE_COUNT]: sourceIds.length,
          [AnalyticsService.PROPERTIES.CHARTING_MEASURE_COUNT]: measures.length,
          [AnalyticsService.PROPERTIES.CHARTING_MEASURE_ID]: measure.id,
          [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: measure.type,
          [AnalyticsService.PROPERTIES.EXERCISE_CATEGORY]:
            measure.type === "Exercise" ? measure.metadata.category : undefined,
        });

        try {
          setLoading(true);
          dispatchToMeasureStore({ type: ChartDataApiReducerActions.SET_STATUS_PENDING, requestId });

          const result = await MeasureService.get(requestParams);

          dispatchToMeasureStore({ type: ChartDataApiReducerActions.ADD_MEASURES, requestId, payload: result });
        } catch (error) {
          dispatchToMeasureStore({ type: ChartDataApiReducerActions.CLEAR_STATUS, requestId });
        }
      });
    });
  }, [endDate, logEvent, measures, setLoading, sourceIds, startDate]);

  useEffect(() => {
    const requestInProgress = Boolean(
      Object.values(measureStore.status).find((status) => status === ChartDataApiRequestStatus.PENDING)
    );

    if (!requestInProgress) {
      setLoading(false);
    }
  }, [measureStore.status, setLoading]);

  useEffect(() => {
    return () => {
      dispatchToMeasureStore({ type: ChartDataApiReducerActions.RESET });
    };
  }, [startDateString, endDateString]);

  const measureIds = useMemo(() => measures.map((measure) => measure.id), [measures]);

  const deleteMeasureById = useCallback((payload: { id: string }) => {
    dispatchToMeasureStore({ type: ChartDataApiReducerActions.DELETE_MEASURE, payload });
  }, []);

  /**
   * @param measureSets filter data except for currently selected sourceIds and measures */
  const measureSets: [MeasureData[], { deleteMeasureById: typeof deleteMeasureById }] = useMemo(
    () => [
      measureStore.measureSets.filter(
        (measureSet) => sourceIds.includes(measureSet.sourceId) && measureIds.includes(measureSet.metadataId)
      ),
      { deleteMeasureById },
    ],
    [deleteMeasureById, measureIds, measureStore.measureSets, sourceIds]
  );

  return measureSets;
}
export default useChartDataApi;
