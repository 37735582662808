import ParentSize from "@visx/responsive/lib/components/ParentSize";
import ParentDimensionsContext from "components/charting/contexts/ParentDimensionsContext";

interface ParentDimensionsProviderProps {
  children?: React.ReactChild;
}

function ParentDimensionsProvider(props: ParentDimensionsProviderProps) {
  return (
    <ParentSize style={{ top: 0, right: 0, bottom: 0, left: 0, position: "absolute" }} debounceTime={0}>
      {(dimensions) => (
        <ParentDimensionsContext.Provider value={dimensions}>{props.children}</ParentDimensionsContext.Provider>
      )}
    </ParentSize>
  );
}

export default ParentDimensionsProvider;
