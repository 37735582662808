import ExerciseHelpers from "helpers/ExerciseHelpers";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions";
import useOneRepMaxStore from "providers/OneRepMaxProvider/useOneRepMaxStore";
import { useEffect, useMemo } from "react";
import { Exercise } from "types/exercise";
import { FormOneRepMax } from "./useOneRepMaxFormInitialValues.types";
import OneRepMaxBulkEditFormHelpers from "helpers/OneRepMaxBulkEditFormHelpers";
import usePreferences from "hooks/usePreferences";

interface Use1rmFormInitialValuesProps {
  exercise: Exercise | null;
  athleteIds: string[];
}

function useOneRepMaxFormInitialValues(props: Use1rmFormInitialValuesProps): FormOneRepMax[] {
  const oneRepMaxStore = useOneRepMaxStore();
  const oneRepMaxActions = useOneRepMaxActions();
  const isVariantExercise = ExerciseHelpers.hasVariants(props.exercise || undefined);
  const [{ imperialSystemEnabled }] = usePreferences();

  useEffect(() => {
    if (!props.exercise?.id) return;

    oneRepMaxActions.getByAthleteIds(props.exercise.id, props.athleteIds);
  }, [props.exercise?.id, props.athleteIds, oneRepMaxActions]);

  const exerciseId = props.exercise?.id;

  const initialValues = useMemo(() => {
    if (!exerciseId) {
      return [];
    }

    const athletesOneRepMaxes: FormOneRepMax[] = props.athleteIds.map((athleteId) =>
      OneRepMaxBulkEditFormHelpers.toFormRow(oneRepMaxStore[athleteId]?.[exerciseId], {
        athleteId,
        exerciseId,
        isVariantExercise,
        imperialSystemEnabled,
      })
    );

    return athletesOneRepMaxes;
  }, [exerciseId, props.athleteIds, oneRepMaxStore, isVariantExercise, imperialSystemEnabled]);

  return initialValues;
}

export default useOneRepMaxFormInitialValues;
