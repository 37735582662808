import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useFormikContext } from "formik";
import { LeaderboardFormValues } from "../LeaderboardEditable.types";
import FullScreenIcon from "library/Icons/FullScreenIcon";
import CloseIcon from "@mui/icons-material/Close";
import * as Components from "./LeaderboardEditableLabel.components";
import LeaderboardHelpers from "pages/Leaderboards/helpers/LeaderboardHelpers";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "components/Tooltip";
import { LeaderboardRankType } from "types";
import { useMemo } from "react";
import { leaderboardComparePeriodCustom } from "../LeaderboardEditable.config";
import moment from "moment";
import { Transition } from "react-transition-group";
import { useTranslation } from "react-i18next";

interface LeaderboardEditableLabelProps {
  onDelete(): void;
  toggleFullscreen(): void;
  isFullscreen: boolean;
}

function LeaderboardEditableLabel(props: LeaderboardEditableLabelProps) {
  const { t } = useTranslation();
  const { values, isSubmitting } = useFormikContext<LeaderboardFormValues>();

  const defaultLeaderboardName = LeaderboardHelpers.getDefaultName(values);
  const placeholder = defaultLeaderboardName || t("Leaderboard.addNameText");

  const selectedGroupCount = values.groups.length;

  const subtitle = useMemo(() => {
    if (values.rankType === LeaderboardRankType.PercentageOfBest) {
      return `${values.type.label} - ${
        values.comparePeriodType !== leaderboardComparePeriodCustom
          ? values.comparePeriodType.label
          : `${moment(values.startDateComparePeriod).format("MMM D, YYYY")} (${t("customLabel")})`
      }`;
    }

    return values.type.label;
  }, [t, values.comparePeriodType, values.rankType, values.startDateComparePeriod, values.type.label]);

  const name = values.exercise ? values.name : t("selectAnExercise");

  return (
    <Box pl={3} py={1.25} pt={1.25} pr={2.5}>
      <Grid container alignItems="center" spacing={0}>
        <Grid item xs>
          <Tooltip title={values.name || placeholder} placement="top" arrow>
            <Components.LeaderboardHeading
              color={!values.name || !values.exercise ? "grey.400" : undefined}
              gutterBottom={false}
              noWrap
            >
              {name || placeholder}
            </Components.LeaderboardHeading>
          </Tooltip>
          <Components.LeaderboardSubtitle variant="subtitle1">
            {subtitle}
            {t("Leaderboard.selectedGroupCountLabel", { count: selectedGroupCount })}
          </Components.LeaderboardSubtitle>
        </Grid>
        <Grid item position="relative">
          <Transition in={isSubmitting} timeout={1500} unmountOnExit>
            {(state) => (
              <CircularProgress
                size={32}
                color="secondary"
                sx={{
                  mr: 1,
                  ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                    exited: { opacity: 0 },
                    exiting: { opacity: 1 },
                    unmounted: { opacity: 0 },
                  }[state],
                }}
              />
            )}
          </Transition>
        </Grid>
        <Tooltip title={t(!props.isFullscreen ? "fullscreenLabel" : "closeLabel")} placement="top" arrow>
          <Grid item>
            <Components.LowPaddingIconButton
              aria-label={`fullscreen-leaderboard-${values.name}`}
              title={t("Leaderboard.viewFullScreenTitle")}
              size="small"
              color="primary"
              onClick={props.toggleFullscreen}
            >
              {!props.isFullscreen ? <FullScreenIcon /> : <CloseIcon fontSize="large" />}
            </Components.LowPaddingIconButton>
          </Grid>
        </Tooltip>
      </Grid>
    </Box>
  );
}

export default LeaderboardEditableLabel;
