import { useCallback, useMemo } from "react";
import Tables from "../../../../../components/Tables";
import useMultiSelectIds from "providers/MultiSelectProvider/useMultiSelectIds";
import useRequest from "hooks/useRequest";
import useWorkoutsActions from "pages/Workouts/providers/WorkoutsProvider/useWorkoutsActions";
import useIntersectingAthleteIds from "hooks/useIntersectingAthleteIds";
import useEntityFilterById from "hooks/useEntityFilterById";
import IconPopperMenu from "library/IconPopperMenu";
import TableAthleteAssignPopperContent from "components/Tables/TableAthleteAssignPopperContent";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useFilteredWorkouts from "pages/Workouts/providers/FilteredWorkoutsProvider/useFilteredWorkouts";

interface AthleteAssignmentControlProps {
  tooltipTitle: string;
}

function AllWorkoutsBulkAthleteAssignmentControl(props: AthleteAssignmentControlProps) {
  const selectedWorkoutIds = useMultiSelectIds();
  const filteredWorkouts = useFilteredWorkouts();
  const workoutActions = useWorkoutsActions();
  const athletes = useAthleteStore();
  const bulkUpdateAthleteId = useRequest(workoutActions.bulkUpdateAthleteId);

  const visibleSelectedWorkouts = useEntityFilterById(selectedWorkoutIds, filteredWorkouts);
  const visibleSelectedWorkoutIds = useMemo(() => visibleSelectedWorkouts.map((a) => a.id), [visibleSelectedWorkouts]);
  const intersectingAthleteIds = useIntersectingAthleteIds(visibleSelectedWorkouts);
  const intersectingAthletes = useEntityFilterById(intersectingAthleteIds, athletes);

  const handleAthleteAssignment = useCallback(
    async (athleteId: string, isAssignment: boolean) => {
      await bulkUpdateAthleteId(visibleSelectedWorkoutIds, athleteId, isAssignment);
    },
    [visibleSelectedWorkoutIds, bulkUpdateAthleteId]
  );

  return (
    <IconPopperMenu
      tooltipTitle={props.tooltipTitle}
      icon={<Tables.Icons.AssignAthletes />}
      disabled={!Boolean(visibleSelectedWorkoutIds.length)}
      popperContent={
        <TableAthleteAssignPopperContent commonAthletes={intersectingAthletes} onChange={handleAthleteAssignment} />
      }
    />
  );
}

export default AllWorkoutsBulkAthleteAssignmentControl;
