import {
  getFirestore,
  collection,
  where,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import { Leaderboard, LeaderboardBase } from "types";
import http from "axiosSetup";
import { firebaseApp } from "config/firebase";

class LeaderboardService {
  static subscribe(
    {
      userId,
      exerciseId,
      startDate,
      endDate,
    }: {
      userId: string;
      exerciseId: string;
      metricField?: string;
      startDate?: string;
      endDate?: string;
      sortOrder: 0 | 1;
    },
    onNext: (snapshot: QuerySnapshot<DocumentData>) => void
  ) {
    const db = getFirestore(firebaseApp);

    const queryArgs: QueryConstraint[] = [];

    queryArgs.push(where("userId", "==", userId));
    queryArgs.push(where("exerciseId", "==", exerciseId));

    if (typeof startDate !== "undefined") {
      queryArgs.push(where("completedDate", ">=", new Date(startDate)));
    }

    if (typeof endDate !== "undefined") {
      queryArgs.push(where("completedDate", "<=", new Date(endDate)));
    }

    const q = query(collection(db, "measurement"), ...queryArgs);

    return onSnapshot(q, onNext);
  }

  static getAll() {
    return http.get<Leaderboard[]>("/leaderboards").then((response) => response.data);
  }

  // static getById(id: string) {
  //   return http.get<Leaderboard>(`/leaderboards/${id}`).then((response) => response.data);
  // }

  static create(leaderboard: LeaderboardBase) {
    return http
      .post<Leaderboard>("/leaderboards", {
        ...leaderboard,
        groups: undefined,
        groupIds: leaderboard.groups.map((group) => group.id),
      })
      .then((response) => response.data);
  }

  static update(id: string, leaderboard: LeaderboardBase) {
    return http
      .put<Leaderboard>(`/leaderboards/${id}`, {
        ...leaderboard,
        groups: undefined,
        groupIds: leaderboard.groups.map((group) => group.id),
      })
      .then((response) => response.data);
  }

  static delete(id: string) {
    return http.delete<{ id: string }>(`/leaderboards/${id}`).then((response) => response.data);
  }
}
export default LeaderboardService;
