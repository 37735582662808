import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import isNumber from "lodash/isNumber";
import { CirclePath } from "./WellnessScore.helpers";

interface WellnessScoreProps {
  label: string;
  value?: number | null;
}

const wellnessScoreColors = ["", "#DA2A69", "#F5A62E", "#EBD721", "#83C530", "#04B86C"];

function WellnessScore(props: WellnessScoreProps) {
  const { label } = props;
  const value = isNumber(props.value) ? props.value : 0;

  return (
    <Box display="inline-block">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="caption" align="center">
          <Box component="span" fontWeight="fontWeightRegular">
            {label}
          </Box>
        </Typography>
      </Box>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="48px"
        maxHeight="48px"
      >
        <svg width="72" height="48" viewBox="0 0 24 24">
          <CirclePath cx={12} cy={12} r={10} start={-1.5} end={4.7} color="#0D1724" opacity={0.05} />
          <CirclePath cx={12} cy={12} r={10} start={-1.5} end={1.24 * value - 1.5} color={wellnessScoreColors[value]} />
        </svg>
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          top={0}
          bottom={0}
          left={0}
          right={0}
        >
          <Typography variant="h3">
            <Box component="span" fontWeight="fontWeightBold">
              {value || "-"}
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default WellnessScore;
