import Box from "@mui/material/Box";
import SmsCodeInput from "./SmsCodeInput/SmsCodeInput";
import { Collapse } from "@mui/material";
import LoginForm from "./LoginForm/LoginForm";
import useLoginHandlers from "./hooks/useLoginHandlers";

/**
 * @param {{ setError(error: null | Error): void; error: null | Error }} props
 */
function Login(props) {
  const { clearError, showMfa, onSignIn, onMfaCancel, onVerifyMfaCode, requestVerificationCode, mfaHint } =
    useLoginHandlers({
      setError: props.setError,
    });

  return (
    <Box bgcolor="primary.main">
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Box>
          <Collapse in={!showMfa} unmountOnExit={false}>
            <LoginForm onSignIn={onSignIn} errorMessage={props.error?.message} clearErrors={clearError} />
          </Collapse>
          <Collapse in={showMfa} unmountOnExit>
            <SmsCodeInput
              verifyCode={onVerifyMfaCode}
              resendCode={requestVerificationCode}
              onCancel={onMfaCancel}
              hint={mfaHint}
              errorMessage={props.error?.message}
            />
          </Collapse>
          <div id="recaptcha-mfa-signin-container" />
        </Box>
      </Box>
    </Box>
  );
}
export default Login;
