import { DataVisualizationType } from "components/charting/config/chartTypes";
import DataVisualizationTypeContext from "components/charting/contexts/DataVisualizationTypeContext";
import SetDataVisualizationTypeContext from "components/charting/contexts/SetDataVisualizationTypeContext";
import { SelectedChartMeasure } from "components/charting/types";
import { emptyMeasure } from "pages/Charting/Charting.config";
import ChartHelpers from "pages/Charting/helpers/ChartHelpers";
import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import { useCallback, useState } from "react";

function DataVisualizationTypeProvider(props: { children: React.ReactNode }) {
  const [dataVisualizationType, setDataVisualizationType] = useState(DataVisualizationType.LINE_CHART);
  const setSelections = useSetChartSelections();

  const onSetDataVisualizationType = useCallback(
    (type: DataVisualizationType) => {
      const repExerciseChartsOnly = [
        DataVisualizationType.SET_COMPARISON_CHART,
        DataVisualizationType.PROFILING_CHART,
      ].includes(type);

      if (repExerciseChartsOnly) {
        setSelections((prev) => {
          const filteredExercises = prev.measures.filter(ChartHelpers.filterRepExercisesOnly);
          const measure = filteredExercises.concat(emptyMeasure).shift() as SelectedChartMeasure;

          if (measure.repMetric) {
            const repetitionsMetadata = ChartHelpers.filterProfilingRepMetrics(measure.metadata.repetitions, {
              visType: type,
            });

            return {
              measures: [
                {
                  ...measure,
                  repMetric:
                    repetitionsMetadata.find((rep) => rep.field === measure.repMetric?.field) ||
                    repetitionsMetadata[0] ||
                    null,
                },
              ],
            };
          }

          return { measures: [measure] };
        });
      }

      setDataVisualizationType(type);
    },
    [setDataVisualizationType, setSelections]
  );

  return (
    <SetDataVisualizationTypeContext.Provider value={onSetDataVisualizationType}>
      <DataVisualizationTypeContext.Provider value={dataVisualizationType}>
        {props.children}
      </DataVisualizationTypeContext.Provider>
    </SetDataVisualizationTypeContext.Provider>
  );
}

export default DataVisualizationTypeProvider;
