import { styled } from "@mui/material";
import Divider from "@mui/material/Divider";

export const PositionedDivider = styled(Divider)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(2),
  right: theme.spacing(4),
}));

export const GroupContentDiv = styled("div")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2, 3, 3, 2),
}));
