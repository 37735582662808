import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import * as Yup from "yup";

export const errorMessages = {
  exercise: "Select an exercise",
  customDate: "Required when using custom date range",
};

function isCustomDateRequired([rangeType]: any, schema: Yup.ObjectSchema<{}, Yup.AnyObject, {}, "">) {
  if (rangeType === RangeType.Other) {
    return schema.required(errorMessages.customDate);
  }
  return schema.nullable();
}

export const schema = Yup.object()
  .shape({
    rangeType: Yup.number().required(),
    exerciseId: Yup.object().required(errorMessages.exercise),
    startDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
    endDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
  })
  .required();
