import { LoadingContext } from "components/LocalisedLoadingProvider";
import SetLoadingContext from "contexts/SetLoadingContext";
import React, { useState } from "react";

interface LoadingProviderProps {
  children?: React.ReactNode;
}

function LoadingProvider(props: LoadingProviderProps) {
  const loadingState = useState(false);

  return (
    <LoadingContext.Provider value={loadingState}>
      <SetLoadingContext.Provider value={loadingState[1]}>{props.children}</SetLoadingContext.Provider>
    </LoadingContext.Provider>
  );
}

export default LoadingProvider;
