import UnitHelper from "helpers/UnitHelper";

class WeightHelpers {
  static toReadable(weight: number | null | undefined, imperialSystemEnabled: boolean): string {
    return UnitHelper.process(weight, {
      from: UnitHelper.units.Kilogram,
      to: imperialSystemEnabled && UnitHelper.units.Pound,
      decimals: imperialSystemEnabled ? 0 : 2,
    });
  }
}

export default WeightHelpers;
