import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import * as Components from "./MuiCheckbox.components";

function MuiCheckbox(props) {
  const { color = "primary", onBlur = null, name = null } = props;
  return (
    <FormGroup>
      <Components.MuiCheckboxFormControlLabel
        control={
          <Checkbox
            onChange={props.onChange}
            onBlur={onBlur}
            name={name}
            checked={props.value}
            value={props.value}
            disabled={props.disabled}
            color={color}
          />
        }
        label={props.label}
        labelPlacement="end"
      />
    </FormGroup>
  );
}

MuiCheckbox.propTypes = {
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "secondary", "default"]),
};

export default MuiCheckbox;
