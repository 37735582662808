import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SettingsAccountProfilePhotoEdit from "./SettingsAccountProfilePhotoEdit";
import SettingsAccountPersonalEdit from "./SettingsAccountPersonalEdit";
import SettingsAccountPasswordChange from "./SettingsAccountPasswordChange";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import { GridBorderBottomGrey } from "../Settings.components";
import SettingsOrganisationEdit from "./SettingsOrganisationEdit";
import CanDoAction from "components/CanDoAction";

interface SettingsAccountEditProps {}

function SettingsAccountEdit(props: SettingsAccountEditProps) {
  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <Box pb={4}>
              <Typography variant="h1">
                <Box component="span" fontWeight="fontWeightMedium">
                  Account
                </Box>
              </Typography>
            </Box>
            <SettingsAccountProfilePhotoEdit />
          </Box>
        </GridBorderBottomGrey>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <SettingsAccountPersonalEdit />
          </Box>
        </GridBorderBottomGrey>
        <CanDoAction action="organisation.any">
          <GridBorderBottomGrey item xs={12}>
            <Box p={6}>
              <SettingsOrganisationEdit />
            </Box>
          </GridBorderBottomGrey>
        </CanDoAction>
        <Grid item xs={12}>
          <Box p={6}>
            <SettingsAccountPasswordChange />
          </Box>
        </Grid>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsAccountEdit;
