import Grid from "@mui/material/Grid";
import { alpha, styled } from "@mui/material";
import MuiButton from "@mui/material/Button";
import MuiButtonGroup from "@mui/material/ButtonGroup";

export const ButtonGridItem = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export const ButtonGroup = styled(MuiButtonGroup)({
  display: "flex",
  justifyContent: "stretch",
});
export const GroupedButton = styled(MuiButton)(({ theme }) => ({
  textTransform: "none",
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  flex: 1,
  borderWidth: 2,
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.grey[300],

  "&.MuiButtonGroup-grouped:not(:last-of-type):hover": {
    borderRightColor: "transparent",
  },
  "&.MuiButton-root:hover": {
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
  },
  "&.MuiButton-contained, &.MuiButton-contained:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
    zIndex: 1,
  },
  "&.MuiButton-outlined.Mui-disabled": {
    borderWidth: 2,
  },
  "&.MuiButton-contained.Mui-disabled": {
    border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    zIndex: 1,
  },
}));
