import { useMemo } from "react";
import { scaleTime, TimeInput } from "@visx/scale";
import { extent } from "d3-array";
import { LinePointData } from "components/charting/types/Chart.types";
import useChartDimensions from "../../../pages/Charting/hooks/useChartDimensions";

function useTimeScale(data: LinePointData[]) {
  const dimensions = useChartDimensions();

  const scale = useMemo(
    () =>
      scaleTime({
        range: dimensions.ranges.x,
        domain: extent(data, (p: LinePointData) =>
          typeof p.x === "number" ? p.x : p.x.valueOf()
        ) as unknown as TimeInput[],
        nice: true,
      }),
    [data, dimensions.ranges.x]
  );

  scale.range([0, dimensions.inner.width]);

  return scale;
}
export default useTimeScale;
