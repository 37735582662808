import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 19.75V5.75003C20 4.64546 19.1046 3.75003 18 3.75003H15.5556H8.44444H6C4.89543 3.75003 4 4.64546 4 5.75003V19.75C4 20.8546 4.89543 21.75 6 21.75H18C19.1046 21.75 20 20.8546 20 19.75Z"
        stroke="currentColor"
        strokeWidth="1.6"
      />
      <path d="M11 10.25H16" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <path d="M11 15.25H16" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
      <circle cx="8.05263" cy="10.3027" r="0.552632" fill="currentColor" stroke="currentColor" />
      <circle cx="8.05263" cy="15.3027" r="0.552632" fill="currentColor" stroke="currentColor" />
      <rect x="8.25" y="2.25" width="7.5" height="3" rx="1" fill="currentColor" />
    </svg>
  ),
};

function WorkoutsIcon(props: SvgIconProps) {
  return <SvgIcon {...props}>{size.default}</SvgIcon>;
}
export default WorkoutsIcon;
