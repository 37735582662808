import ChartingScaffolding from "./components/ChartingScaffolding";
import LineChart from "./Charts/LineChart";
import ChartingProviders from "./providers/ChartingProviders";
import ChartControls from "./components/ChartControls";
import ChartLegend from "./components/ChartLegend";
import { useEffect } from "react";
import logEvent from "utils/logEvent";
import ParentDimensionsProvider from "components/charting/providers/ParentDimensionsProvider";

interface ChartingProps {}

function Charting(props: ChartingProps) {
  useEffect(() => {
    logEvent("CHARTING_V3_VIEW_PAGE");
  }, []);

  return (
    <ChartingProviders>
      <ChartingScaffolding
        legend={<ChartLegend />}
        chart={
          <ParentDimensionsProvider>
            <LineChart />
          </ParentDimensionsProvider>
        }
        controls={<ChartControls />}
      />
    </ChartingProviders>
  );
}

export default Charting;
