import Box from "@mui/material/Box";
import AthleteProfilingChart from "components/charting/AthleteProfilingChart";
import AthleteProfilingChartEntityColorProvider from "components/charting/AthleteProfilingChart/AthleteProfilingChartEntityColorProvider";
import { DataVisualizationType } from "components/charting/config/chartTypes";
import LogEventContext from "components/charting/contexts/LogEventContext";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import LineChart from "components/charting/LineChart";
import ChartDataProvider from "components/charting/providers/ChartDataProvider";
import ParentDimensionsProvider from "components/charting/providers/ParentDimensionsProvider";
import SetComparisonChart from "components/charting/SetComparisonChart";
import SetComparisonChartLegend from "components/charting/SetComparisonChart/SetComparisonChartLegend";
import SetComparisonChartEntityColorProvider from "components/charting/SetComparisonChart/SetComparisonChartEntityColorProvider";
import { memo, useCallback } from "react";
import AnalyticsService from "services/AnalyticsService";
import logEvent from "utils/logEvent";
import AthleteProfileMeasurementsTable from "./AthleteProfileMeasurementsTable";
import * as Components from "./AthleteProfileDataVisualizationSelector.components";
import OneRepMaxProvider from "providers/OneRepMaxProvider";
import OneRepMaxChart from "components/charting/OneRepMaxChart";
import OneRepMaxChartDataProvider from "components/charting/providers/OneRepMaxChartDataProvider";
import AthleteProfilingChartLegend from "components/charting/AthleteProfilingChart/AthleteProfilingChartLegend";

interface AthleteProfileDataVisualizationSelectorProps {}

function AthleteProfileDataVisualizationSelector(props: AthleteProfileDataVisualizationSelectorProps) {
  const visType = useDataVisualizationType();

  const renderLineChart = useCallback(
    () => (
      <LogEventContext.Provider
        value={({ sourceCount, measureCount, ...properties }) => {
          logEvent("ATHLETE_PROFILE_VIEW_DATA", {
            ...properties,
            [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.LINE_CHART,
            [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.LINE_CHART,
            [AnalyticsService.PROPERTIES.EXERCISE_ID]: properties.measureId,
            [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: properties.measureType,
          });
        }}
      >
        <Box flex={1} mb={3} mr={3} position="relative">
          <OneRepMaxProvider>
            <ChartDataProvider>
              <ParentDimensionsProvider>
                <LineChart />
              </ParentDimensionsProvider>
            </ChartDataProvider>
          </OneRepMaxProvider>
        </Box>
      </LogEventContext.Provider>
    ),
    []
  );

  const renderMeasurementsTable = useCallback(
    () => (
      <LogEventContext.Provider
        value={(properties) => {
          logEvent("ATHLETE_PROFILE_VIEW_DATA", {
            ...properties,
            [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.MEASUREMENTS_TABLE,
            [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.MEASUREMENTS_TABLE,
            [AnalyticsService.PROPERTIES.EXERCISE_ID]: properties.measureId,
            [AnalyticsService.PROPERTIES.CHARTING_MEASURE_ID]: properties.measureId,
            [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: properties.measureType,
          });
        }}
      >
        <Components.OverflowBox flex={1} position="relative" p={1}>
          <AthleteProfileMeasurementsTable />
        </Components.OverflowBox>
      </LogEventContext.Provider>
    ),
    []
  );

  const renderSetComparisonChart = useCallback(
    () => (
      <LogEventContext.Provider
        value={(properties) => {
          logEvent("ATHLETE_PROFILE_VIEW_DATA", {
            ...properties,
            [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.SET_COMPARISON_CHART,
            [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.SET_COMPARISON_CHART,
            [AnalyticsService.PROPERTIES.EXERCISE_ID]: properties.measureId,
            [AnalyticsService.PROPERTIES.CHARTING_MEASURE_ID]: properties.measureId,
            // measure category
          });
        }}
      >
        <SetComparisonChartEntityColorProvider>
          <Box position="relative" p={1} pb={0} mr={3}>
            <SetComparisonChartLegend />
          </Box>
          <Box flex={1} position="relative" p={1} mb={3} mr={3}>
            <ParentDimensionsProvider>
              <SetComparisonChart />
            </ParentDimensionsProvider>
          </Box>
        </SetComparisonChartEntityColorProvider>
      </LogEventContext.Provider>
    ),
    []
  );

  const renderAthleteProfilingChart = useCallback(
    () => (
      <LogEventContext.Provider
        value={(properties) => {
          logEvent("ATHLETE_PROFILE_VIEW_DATA", {
            ...properties,
            [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.PROFILING_CHART,
            [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.PROFILING_CHART,
            [AnalyticsService.PROPERTIES.EXERCISE_ID]: properties.measureId,
            // measure id
            // measure category
          });
        }}
      >
        <OneRepMaxProvider>
          <AthleteProfilingChartEntityColorProvider>
            <Box position="relative" p={1} pb={0} mr={3}>
              <AthleteProfilingChartLegend />
            </Box>
            <Box flex={1} position="relative" p={1} mb={3} mr={3}>
              <ParentDimensionsProvider>
                <AthleteProfilingChart />
              </ParentDimensionsProvider>
            </Box>
          </AthleteProfilingChartEntityColorProvider>
        </OneRepMaxProvider>
      </LogEventContext.Provider>
    ),
    []
  );

  const renderOneRepMaxChart = useCallback(
    () => (
      <LogEventContext.Provider
        value={({ sourceCount, measureCount, ...properties }) => {
          logEvent("ATHLETE_PROFILE_VIEW_DATA", {
            ...properties,
            [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.ONE_REP_MAX_CHART,
            [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.ONE_REP_MAX_CHART,
            [AnalyticsService.PROPERTIES.EXERCISE_ID]: properties.measureId,
            [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: properties.measureType,
          });
        }}
      >
        <Box flex={1} mb={3} mr={3} position="relative">
          <OneRepMaxChartDataProvider>
            <ParentDimensionsProvider>
              <OneRepMaxChart />
            </ParentDimensionsProvider>
          </OneRepMaxChartDataProvider>
        </Box>
      </LogEventContext.Provider>
    ),
    []
  );

  const visualizations = {
    [DataVisualizationType.LINE_CHART]: renderLineChart,
    [DataVisualizationType.MEASUREMENTS_TABLE]: renderMeasurementsTable,
    [DataVisualizationType.SET_COMPARISON_CHART]: renderSetComparisonChart,
    [DataVisualizationType.PROFILING_CHART]: renderAthleteProfilingChart,
    [DataVisualizationType.ONE_REP_MAX_CHART]: renderOneRepMaxChart,
  };

  return visualizations[visType]();
}

export default memo(AthleteProfileDataVisualizationSelector);
