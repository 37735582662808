import { AutocompleteProps, AutocompleteRenderInputParams, AutocompleteValue, ChipTypeMap } from "@mui/material";
import { useField } from "formik";
import Mui5 from "library/mui5";
import { useCallback } from "react";

export interface FormAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> {
  name: string;
  renderInput(
    params: AutocompleteRenderInputParams & { error?: boolean; helperText?: React.ReactNode; placeholder?: string }
  ): React.ReactNode;
}

function FormAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = false,
>(props: FormAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { name, renderInput, ...otherProps } = props;
  const [{ multiple, onChange, checked, ...field }, meta, helpers] = useField<
    AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  >(props.name);

  const error = !!Boolean(meta.touched && meta.error);
  const helperText = meta.touched && meta.error ? meta.error : null;

  const curriedRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return renderInput({ ...params, error, helperText });
    },
    [error, helperText, renderInput]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) =>
      helpers.setValue(newValue),
    [helpers]
  );

  return <Mui5.Autocomplete onChange={handleChange} renderInput={curriedRenderInput} {...field} {...otherProps} />;
}

export default FormAutocomplete;
