import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const fullWidthMinus4px = { height: "calc(100% - 4px)" };

export const ContainerMainContentBox = styled(Box)(() => ({
  height: `calc(100% - 36px)`,
}));

export const FullWidthMinus4pxBox = styled(Box)(() => ({
  ...fullWidthMinus4px,
}));
