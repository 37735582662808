import { useCallback } from "react";
import { Variants } from "types/exercise";

function useChartAnnotationLineLabelPosition() {
  return useCallback((variant: Variants | null, end: boolean) => {
    const above = -5;
    const below = 15;

    if (variant === "Left") {
      return end ? below : above;
    }

    return !end ? below : above;
  }, []);
}
export default useChartAnnotationLineLabelPosition;
