import useDefinedContext from "hooks/useDefinedContext";
import GroupsStoreContext from "../GroupsStoreContext";
import isArray from "lodash/isArray";
import { useMemo } from "react";

/**
 * @param {string[]} [groupIds]
 * Provide a memoized array of groupIds to filter the groups returned by the hook
 */
function useGroups(groupIds?: string[]) {
  const groups = useDefinedContext(GroupsStoreContext);

  return useMemo(() => {
    if (isArray(groupIds)) {
      return groups.filter((group) => groupIds.includes(group.id));
    }

    return groups;
  }, [groupIds, groups]);
}

export default useGroups;
