import { styled } from "@mui/material";
import { IconButton } from "@mui/material";

export const RootDiv = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  display: "flex",
}));

export const ColourStripDiv = styled("div", {
  shouldForwardProp: (propName: string) => propName !== "borderColour",
})<{ borderColour: string | undefined }>(({ theme, borderColour }) => ({
  width: theme.spacing(0.5),
  alignItems: "stretch",
  backgroundColor: borderColour ? borderColour : theme.palette.grey[400],
}));

export const CardDiv = styled("div")(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderLeftWidth: theme.spacing(0),
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
  alignItems: "stretch",
}));

export const MeasureSelectorOpenButton = styled(IconButton, {
  shouldForwardProp: (propName: string) => propName !== "isOpen",
})<{ open: boolean }>(({ theme, open }) => ({
  transform: open ? "rotate(-180deg)" : "rotate(0deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
