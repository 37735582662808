import moment from "moment";
import { LineData, LinePointData } from "components/charting/types/Chart.types";
import { useMemo } from "react";
import { firstBy } from "thenby";
import AggregationHelper from "../helpers/AggregationHelpers";
import { AggregationType } from "../../../components/charting/types/AggregateBy";
import useChartSelections from "../../../components/charting/hooks/useChartSelections";

function useChartLineAggregator(lines: LineData[]) {
  const { aggregationPeriod, aggregationType } = useChartSelections();

  const aggregatedChartData = useMemo(() => {
    const aggregateBy = aggregationPeriod.value;
    const formats = {
      day: "YYYY-MM-DD",
      week: "YYYY-WW",
      month: "YYYY-MM",
    };

    if (aggregateBy == null) {
      return lines;
    }

    return lines.map((lineData) => {
      const aggregatedData = lineData.line.reduce<{ [label: string]: LinePointData[] }>((acc, chartPointData) => {
        const date = moment(chartPointData.x);
        const label = date.format(formats[aggregateBy]);

        const existingData = acc[label] || [];

        return {
          ...acc,
          [label]: [...existingData, { ...chartPointData, x: new Date(date.startOf(aggregateBy).toISOString()) }],
        };
      }, {});

      return {
        ...lineData,
        line: Object.entries(aggregatedData).map(([dateLabel, aggregates]): LinePointData => {
          if (aggregates.length === 1) {
            return aggregates[0];
          }

          if (aggregationType.value === AggregationType.AVERAGE) {
            return AggregationHelper.computeAverageLinePointData(aggregates, dateLabel, formats[aggregateBy]);
          }

          const sortedByPeriod = aggregates.sort(firstBy("y", lineData.sortOrder));

          if (aggregationType.value === AggregationType.WORST) {
            return sortedByPeriod.pop() as LinePointData;
          }

          return sortedByPeriod.shift() as LinePointData;
        }),
      };
    });
  }, [lines, aggregationPeriod, aggregationType]);

  return aggregatedChartData;
}
export default useChartLineAggregator;
