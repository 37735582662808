import useDefinedContext from "hooks/useDefinedContext";
import useRequest from "hooks/useRequest";
import moment from "moment";
import { useEffect, useMemo } from "react";
import CompletedWorkoutsContext from "../../contexts/CompletedWorkoutsContext";
import { CompletedWorkout } from "../../Workouts.types";

function useGetCompletedWorkoutsByWorkoutId(workoutId: string) {
  const [store, actions] = useDefinedContext(CompletedWorkoutsContext);

  const getAll = useRequest(actions.getAll);

  useEffect(() => {
    const startDate = moment("2019-01-01", "YYYY-MM-DD").startOf("day").toISOString();
    const endDate = moment().endOf("day").toISOString();

    (async () => {
      await getAll({ workoutId: workoutId, startDate, endDate });
    })();
  }, [workoutId, getAll]);

  return useMemo(
    () =>
      (Object.values(store) as CompletedWorkout[]).filter(
        (completedWorkout) => completedWorkout.workoutId === workoutId
      ),
    [store, workoutId]
  );
}
export default useGetCompletedWorkoutsByWorkoutId;
