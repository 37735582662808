import { createContext } from "react";
import { MeasurementSet } from "types";

export interface ChartCompareSetSelectContextType {
  value: MeasurementSet[];
  onChange(sets: MeasurementSet[]): void;
  options: MeasurementSet[];
}

const ChartCompareSetSelectContext = createContext<ChartCompareSetSelectContextType | undefined>(undefined);

ChartCompareSetSelectContext.displayName = "ChartCompareSetSelectContext";

export default ChartCompareSetSelectContext;
