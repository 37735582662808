import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";

export const GridChartLegendContainer = styled(Grid)((props) => ({
  padding: props.theme.spacing(0.5, 0),
}));
export const GridChartLegendItem = styled(Grid)<{ disableBorders?: boolean }>((props) => ({
  border: props.disableBorders ? undefined : `1px solid ${props.theme.palette.grey[300]}`,
  borderRadius: props.theme.spacing(1),
}));
export const ChartLegendColor = styled("div")((props) => ({
  background: props.theme.palette.primary.main,
  height: 16,
  width: 16,
  borderRadius: 2,
  margin: props.theme.spacing(1),
}));
export const GridChartLegendRemoveX = styled(Grid)((props) => ({
  padding: props.theme.spacing(0, 0.5, 0, 0),
}));
