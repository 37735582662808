export function CirclePath({
  cx,
  cy,
  r,
  start,
  end,
  color,
  opacity,
}: {
  cx: number;
  cy: number;
  r: number;
  start: number;
  end: number;
  color: string;
  opacity?: number;
}) {
  const length = end - start;

  const fromX = r * Math.cos(start) + cx;
  const fromY = r * Math.sin(start) + cy;
  const toX = r * Math.cos(end) + cx;
  const toY = r * Math.sin(end) + cy;
  const large = Math.abs(length) <= Math.PI ? "0" : "1";
  const sweep = "1";

  return (
    <path
      d={`${["M", fromX, fromY].join(" ")} ${["A", r, r, 0, large, sweep, toX, toY].join(" ")}`}
      fill="none"
      stroke={color}
      strokeWidth="2"
      opacity={opacity}
      strokeLinecap="round"
    />
  );
}
