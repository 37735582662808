import ChartHelpers from "pages/Charting/helpers/ChartHelpers";
import useChartOptions from "pages/Charting/hooks/useChartOptions";
import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { EntityType } from "types/entity";
import useChartMeasures from "./useChartMeasures";
import useDataVisualizationType from "./useDataVisualizationType";

function useChartQueryParams({ sourceEnabled }: { sourceEnabled?: boolean } = {}) {
  const options = useChartOptions();
  const query = new URLSearchParams(useLocation().search);
  const chartMeasures = useChartMeasures();
  const visType = useDataVisualizationType();
  const setSelections = useSetChartSelections();

  const queryParams: {
    metadataId?: string;
    chartType?: string;
    interval?: string;
    sourceId?: string;
  } & { [id: string]: string } = {};

  type ValidKeys = keyof typeof queryParams;

  query.forEach((value, key: ValidKeys) => {
    queryParams[key] = value;
  });

  const changeMeasureAtIndex = chartMeasures.onChangeAtIndex;

  const measure = useMemo(() => {
    if (!queryParams.metadataId) {
      return undefined;
    }
    return ChartHelpers.filterMeasuresByDataVisType(options.measures, { visType }).find(
      (measure) => measure.id === queryParams.metadataId
    );
  }, [options.measures, queryParams.metadataId, visType]);

  const source = useMemo(() => {
    /**
     * @densk1
     * Need to confirm that athletes have loaded, because
     * if not a rerender breaks references equality on selected dropdown */
    const hasSomeAthleteSources = !!options.sources.find((source) => source.type === EntityType.ATHLETE);

    if (!(sourceEnabled && queryParams.sourceId && hasSomeAthleteSources)) {
      return;
    }

    return options.sources.find((source) => source.id === queryParams.sourceId);
  }, [options.sources, queryParams.sourceId, sourceEnabled]);

  useEffect(() => {
    setTimeout(() => {
      source && setSelections({ sources: [source] });
      measure && changeMeasureAtIndex(0)(undefined, measure);
    }, 100);
  }, [changeMeasureAtIndex, measure, setSelections, source]);
}
export default useChartQueryParams;
