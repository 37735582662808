export enum ACTIONS {
  SET_STATUS_PENDING = "SET_STATUS_PENDING",
  SET_STATUS_CALLED = "SET_STATUS_CALLED",
  UPDATE_ITEMS_IN_STATE = "UPDATE_ITEMS_IN_STATE",
  REMOVE_ITEM_FROM_STATE = "REMOVE_ITEM_FROM_STATE",
}

export enum STATUS {
  PENDING = "PENDING",
  CALLED = "CALLED",
}

export type SelectedExercisesStateType = {
  exerciseId: string;
  status: STATUS;
};

export type SelectedExercisesActionsTypes =
  | { type: ACTIONS.SET_STATUS_PENDING; payload: string }
  | { type: ACTIONS.SET_STATUS_CALLED; payload: string }
  | { type: ACTIONS.UPDATE_ITEMS_IN_STATE; payload: string[] }
  | { type: ACTIONS.REMOVE_ITEM_FROM_STATE; payload: string };

export const initialState: SelectedExercisesStateType[] = [];

export function reducer(state: SelectedExercisesStateType[], action: SelectedExercisesActionsTypes) {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_STATUS_PENDING:
      return [
        ...state,
        {
          exerciseId: payload,
          status: STATUS.PENDING,
        },
      ];
    case ACTIONS.SET_STATUS_CALLED:
      return state.map((item) => {
        if (item.exerciseId === payload) {
          return { ...item, status: STATUS.CALLED };
        }
        return item;
      });
    case ACTIONS.UPDATE_ITEMS_IN_STATE:
      return state.filter((item) => payload.includes(item.exerciseId));
    case ACTIONS.REMOVE_ITEM_FROM_STATE:
      return state.filter((item) => item.exerciseId !== payload);

    default:
      return state;
  }
}
