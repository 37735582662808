import Grid from "@mui/material/Grid";
import RagStatusIndicator from "library/RagStatusIndicator";
import { RagStatus } from "contexts/AthleteMonitoringStatusesContext/AthleteMonitoringStatusesContext.types";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as Components from "./RagStatusSummaryCard.components";
import { useUnitFormatter } from "hooks";
import useExercises from "providers/ExercisesProvider/useExercises";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import { ExerciseMetric } from "pages/Workouts/WorkoutForm/types/Exercise.types";
import isNumber from "lodash/isNumber";
import { useTranslation } from "react-i18next";

type RagStatusSummaryCardProps = RagStatus;

function RagStatusSummaryCard(props: RagStatusSummaryCardProps) {
  const exercises = useExercises();
  const unitFormatter = useUnitFormatter();
  const { t } = useTranslation();

  const metadataMetric: ExerciseMetric | undefined = ExerciseMetadataService.metric(
    exercises.find((exercise) => exercise.id === props.metadataId),
    props.metricField
  );

  const formattedValues = props.values.map((ragValue) => ({
    ...ragValue,
    average: isNumber(ragValue.average) ? unitFormatter(ragValue.average, metadataMetric) : null,
    best: isNumber(ragValue.best) ? unitFormatter(ragValue.best, metadataMetric) : null,
    latest: isNumber(ragValue.latest) ? unitFormatter(ragValue.latest, metadataMetric) : null,
    lastTested: ragValue.lastTested && moment(ragValue.lastTested).fromNow(),
    standardDeviation: isNumber(ragValue.standardDeviation)
      ? unitFormatter(ragValue.standardDeviation, metadataMetric)
      : null,
  }));

  const exerciseNameComponent = (
    <Typography variant="h4" color="primary" gutterBottom={false}>
      {props.metadataName}
    </Typography>
  );

  const metricFieldComponent = props.metadataType === 0 && (
    <Typography variant="body2" color="textSecondary" gutterBottom={false}>
      {metadataMetric?.name}
    </Typography>
  );

  const gridDataComponent = (
    <>
      <Components.RagStatusSummaryCardGridVariantHeaderRow display={props.values.length === 2} />
      <Components.RagStatusSummaryCardGridDataRow
        label={t("RagStatusSummaryCard.averageLabel")}
        property="average"
        formattedValues={formattedValues}
      />
      <Components.RagStatusSummaryCardGridDataRow
        label={t("RagStatusSummaryCard.latestLabel")}
        property="latest"
        formattedValues={formattedValues}
      />
      <Components.RagStatusSummaryCardGridDataRow
        label={t("RagStatusSummaryCard.bestLabel")}
        property="best"
        formattedValues={formattedValues}
      />
      <Components.RagStatusSummaryCardGridDataRow
        label={t("RagStatusSummaryCard.standardDeviationLabel")}
        property="standardDeviation"
        formattedValues={formattedValues}
      />
      <Components.RagStatusSummaryCardGridDataRow
        label={t("RagStatusSummaryCard.lastTestedLabel")}
        property="lastTested"
        formattedValues={formattedValues}
      />
    </>
  );

  return (
    <Components.RagStatusSummaryCardPopoverStyledBox
      p={2}
      display="inline-block"
      isVariantExercise={props.values.length}
    >
      <Grid container alignItems="center" columns={12}>
        <Grid item xs={10}>
          {exerciseNameComponent}
          {metricFieldComponent}
        </Grid>
        <Grid item xs={2}>
          <RagStatusIndicator values={props.values} />
        </Grid>
        <Components.RagStatusSummaryCardGridRowDivider />
        {gridDataComponent}
        <Components.RagStatusSummaryCardGridRowDivider />
        <Grid item xs={12} lineHeight={1.0}>
          <Typography
            variant="caption"
            color="textSecondary"
            lineHeight={0.8}
            component="span"
            fontWeight="fontWeightRegular"
          >
            {t("RagStatusSummaryCard.infoText")}
          </Typography>
        </Grid>
      </Grid>
    </Components.RagStatusSummaryCardPopoverStyledBox>
  );
}

export default RagStatusSummaryCard;
