import useDefinedContext from "hooks/useDefinedContext";
import AnthropometricMetadataStoreContext from "../contexts/AnthropometricMetadataContext";

interface UseAnthropometricMetadataProps {}

function useAnthropometricMetadata(props?: UseAnthropometricMetadataProps) {
  return useDefinedContext(AnthropometricMetadataStoreContext);
}

export default useAnthropometricMetadata;
