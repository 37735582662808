import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import UserService from "services/UserService";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

function GoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    const currentUser = UserService.currentUser;

    if (currentUser?.email?.includes("outputsports.com")) {
      return;
    }

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return null;
}
export default GoogleAnalytics;
