import useSelectedEntities from "providers/SelectedEntitiesProvider/useSelectedEntities";
import useSetSelectedEntities from "providers/SelectedEntitiesProvider/useSetSelectedEntities";
import TableFiltersPopperContentUI from "../TableFiltersPopperContentUI";

interface TableFiltersPopperContentProps<T> {
  getColor?(option: T): string | undefined;
  SearchInputProps: {
    placeholder: string;
  };
  options: T[];
  getOptionLabel: (option: T) => string;
  groupBy?: (option: T) => string;
}

/**
 * TODO: [OS-5267] Refactor to wrapper and generic pattern (see <TableWorkoutLabelFilterPopperMenu />)
 * @deprecated - use wrapper and generic pattern around <TableFiltersPopperContentUI /> instead */
function TableFiltersPopperContent<T extends { id: string }>(props: TableFiltersPopperContentProps<T>) {
  const selectedEntities = useSelectedEntities<T>() as T[];
  const setSelectedEntities = useSetSelectedEntities<T>();

  return (
    <TableFiltersPopperContentUI
      {...props}
      selectedEntities={selectedEntities}
      setSelectedEntities={setSelectedEntities}
    />
  );
}

export default TableFiltersPopperContent;
