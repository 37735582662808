import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";

export const ChartLegendItemColorBlock = styled("div")((props) => ({
  background: props.color,
  height: 16,
  width: 16,
  borderRadius: 2,
  margin: props.theme.spacing(1),
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
}));

export const RemoveButtonGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0.5, 0, 0),
}));
