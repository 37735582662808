import { styled } from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import MuiPaper, { paperClasses } from "@mui/material/Paper";

export { paperClasses };

const Paper = styled(MuiPaper)({
  [`&.${paperClasses.rounded}`]: {
    borderRadius: 8,
  },
  [`&.${paperClasses.elevation1}`]: {
    boxShadow: "2px 2px 20px 0 rgba(0,0,0,0.12)",
  },
}) as typeof MuiPaper;
export default Paper;
