import AthleteMonitoringStatusesContext from "contexts/AthleteMonitoringStatusesContext";
import { useContext } from "react";

function useAthleteMonitoringStatusesContext() {
  const context = useContext(AthleteMonitoringStatusesContext);

  if (typeof context === "undefined") throw new Error("<AthleteMonitoringStatusesContext.Provider /> doesn't exist");

  return context;
}
export default useAthleteMonitoringStatusesContext;
