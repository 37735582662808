import {
  getDocs,
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  QueryConstraint,
  limit,
  DocumentData,
  Query,
} from "firebase/firestore";
import moment from "moment";
import { FireStoreMeasurement } from "types";
import MeasurementService from "services/MeasurementService";
import { firebaseApp } from "config/firebase";

class ActivityFeedService {
  private static makeQuery(opts: {
    organisationId: string;
    limit?: number;
    startDate?: string;
    endDate?: string;
    athleteIds?: string[];
  }): Query<DocumentData> {
    const db = getFirestore(firebaseApp);

    const queryArgs: QueryConstraint[] = [];

    queryArgs.push(where("userId", "==", opts.organisationId));
    queryArgs.push(orderBy("completedDate", "desc"));

    if (typeof opts.startDate !== "undefined") {
      queryArgs.push(where("completedDate", ">=", new Date(opts.startDate)));
    }

    if (typeof opts.endDate !== "undefined") {
      queryArgs.push(where("completedDate", "<=", new Date(moment(opts.endDate).toISOString())));
    }

    if (typeof opts.limit !== "undefined") {
      queryArgs.push(limit(opts.limit));
    }

    if (typeof opts.athleteIds !== "undefined") {
      queryArgs.push(where("athleteId", "in", opts.athleteIds));
    }

    return query(collection(db, "measurement"), ...queryArgs);
  }

  static subscribe(
    opts: {
      organisationId: string;
      limit?: number;
      startDate?: string;
      endDate?: string;
    },
    onUpdate: (measurements: FireStoreMeasurement[]) => void
  ) {
    const query = ActivityFeedService.makeQuery(opts);

    return onSnapshot(query, (snapshot) => {
      onUpdate(snapshot.docs.map(MeasurementService.deserializeFirestoreDoc));
    });
  }

  static async get(opts: {
    organisationId: string;
    limit?: number;
    startDate?: string;
    endDate?: string;
    athleteIds?: string[];
  }) {
    const query = ActivityFeedService.makeQuery(opts);

    const snapshot = await getDocs(query);

    return snapshot.docs.map(MeasurementService.deserializeFirestoreDoc);
  }
}
export default ActivityFeedService;
