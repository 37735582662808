import IconButton from "@mui/material/IconButton";
import DustbinIcon from "library/IconsStandardized/DustbinIcon";
import { Component } from "react";
import "./ModuleItemMenu.scss";

class ModuleItemMenu extends Component {
  handleDeleteClick = (event) => {
    event?.stopPropagation();
    this.props.deleteHandler(this.props.template);
  };

  render() {
    return (
      <div className="module-menu">
        <IconButton size="small" color="primary" onClick={this.handleDeleteClick} aria-label="Delete Template">
          <DustbinIcon />
        </IconButton>
      </div>
    );
  }
}

export default ModuleItemMenu;
