import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfoCard from "components/InfoCard";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import CsvExportIcon from "library/Icons/CsvExportIcon";
import FullScreenIcon from "library/Icons/FullScreenIcon";
import ImageExportIcon from "library/Icons/ImageExportIcon";
import ChartingIcon from "library/Icons/LineChartIcon";
import { useTranslation } from "react-i18next";

export interface ChartingInfoCardProps {
  onClose?(): void;
}

function ChartingInfoCard(props: ChartingInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("Charting.infoCardTitle")} icon={<ChartingIcon htmlColor={"black"} />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("Charting.infoCardDescriptionP1")} {t("Charting.infoCardDescriptionP2")}{" "}
            {t("Charting.infoCardDescriptionP3")} {t("Charting.infoCardDescriptionP4")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("Charting.infoCardIconTitle1")}
              control={<CsvExportIcon fontSize="large" />}
              label={t("Charting.infoCardIconLabel1")}
            />
          </Grid>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("Charting.infoCardIconTitle2")}
              control={<ImageExportIcon fontSize="large" />}
              label={t("Charting.infoCardIconLabel2")}
            />
          </Grid>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("Charting.infoCardIconTitle3")}
              control={<FullScreenIcon fontSize="large" />}
              label={t("Charting.infoCardIconLabel3")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={1}>
          <Typography variant="h2">{t("Charting.infoCardAdvancedOptionsSectionTitle")}</Typography>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsShowPersonalBestHeader")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel1")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsShow1RMLineHeader")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel2")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsShowAverageValue")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel3")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsShowStandardDeviation")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel4")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsPlotFromZero")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel5")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("Charting.advancedOptionsAggregationHeader")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("Charting.infoCardIconAdvancedOptionsLabel6")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default ChartingInfoCard;
