import useImmutableSetState from "components/Chart/hooks/useImmutableSetState";
import { getInitialSelections } from "pages/Charting/Charting.config";
import { ChartingSelections } from "components/charting/types";
import { useState } from "react";
import ChartSelectionsContext from "components/charting/contexts/ChartSelectionsContext";
import SetChartSelectionsContext from "components/charting/contexts/SetChartSelectionsContext";

interface ChartSelectionsContextProps {
  children: React.ReactNode;
}

function ChartSelectionsProvider(props: ChartSelectionsContextProps) {
  const [chartSelections, setChartSelections] = useState<ChartingSelections>(() => getInitialSelections());

  const immutableSetChartSelections = useImmutableSetState(setChartSelections);

  return (
    <ChartSelectionsContext.Provider value={chartSelections}>
      <SetChartSelectionsContext.Provider value={immutableSetChartSelections}>
        {props.children}
      </SetChartSelectionsContext.Provider>
    </ChartSelectionsContext.Provider>
  );
}

export default ChartSelectionsProvider;
