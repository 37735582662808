import { styled } from "@mui/material";
import Mui5 from "library/mui5";

export const TableRowButton = styled(Mui5.Button)(({ theme }) => ({
  "&.MuiButton-sizeLarge": {
    padding: theme.spacing(0),
    borderRadius: 0,
  },
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
})) as typeof Mui5.Button;

export const ColorBlocksContainer = styled("div")({
  width: "40px",
  height: "100%",
  display: "block",
  position: "absolute",
  top: 0,
  bottom: 5,
});
