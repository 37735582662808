import RAGSettingsContext, {
  RAGSettings,
  RAGSettingsContextType,
} from "contexts/RAGSettingsContext/RAGSettingsContext";
import { useCallback, useState, useMemo, useEffect } from "react";
import RAGSettingsService from "services/RAGSettingsService";

function RAGSettingsProvider(props: { children?: React.ReactNode }) {
  const [ragTemplates, setRagTemplates] = useState<RAGSettings[]>([]);

  const getAll = useCallback(async () => {
    return RAGSettingsService.getAll().then((result) => setRagTemplates(result));
  }, []);

  const create = useCallback(async (ragTemplate: RAGSettings) => {
    return RAGSettingsService.create(ragTemplate).then((result) => {
      const newTemplate = {
        ...ragTemplate,
        id: result.id,
      };
      setRagTemplates((prev) => [...prev, newTemplate]);
      return newTemplate;
    });
  }, []);

  const update = useCallback(async (id: string, ragTemplate: RAGSettings) => {
    return RAGSettingsService.update(id, ragTemplate).then(() => {
      setRagTemplates((prev) =>
        prev.map((template) => {
          if (id === template.id) {
            return { ...template, ...ragTemplate };
          }
          return template;
        })
      );
    });
  }, []);

  const deleteById = useCallback(async (id: string) => {
    return RAGSettingsService.delete(id).then(() => {
      setRagTemplates((prev) => prev.filter((template) => template.id !== id));
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await getAll();
      } catch (_) {}
    })();
  }, [getAll]);

  const contextValue = useMemo<RAGSettingsContextType>(
    () => [ragTemplates, { getAll, create, delete: deleteById, update }],
    [create, update, getAll, deleteById, ragTemplates]
  );

  return <RAGSettingsContext.Provider value={contextValue}>{props.children}</RAGSettingsContext.Provider>;
}

export default RAGSettingsProvider;
