import Grid from "@mui/material/Grid";
import DependantFormField from "library/Form/DependantFormField";
import FormAutoComplete from "library/Form/FormAutocomplete";
import { Formik, Form, FormikContextType, FormikHelpers } from "formik";
import { Exercise, ExerciseMetric } from "types";
import RadioGroup from "library/Form/RadioGroup";
import { useFilteredExercises } from "hooks";
import useExercises from "providers/ExercisesProvider/useExercises";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useCallback, useMemo } from "react";
import CustomDateRange from "library/Form/CustomDateRange";
import CustomDateRangeFullWidthFix from "library/Forms/CustomDateRangeFullWidthFix";
import Button from "library/mui5/Button";
import moment from "moment";
import SetDateRangeHelper from "helpers/SetDateRangeHelper";
import { schema } from "./AthleteLineChartModuleForm.schema";
import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import Mui5 from "library/mui5";

interface AthleteLineChartModuleFormInitialValues<T = string, U = string> {
  exerciseId: null | T;
  metricField: null | (T extends string ? string : ExerciseMetric);
  rangeType: RangeType;
  startDate: undefined | U;
  endDate: undefined | U;
  moduleType: 1;
}

interface AthleteLineChartModuleFormProps {
  onSubmit(values: AthleteLineChartModuleFormInitialValues): void;
  onCancel(): void;
  initialValues?: AthleteLineChartModuleFormInitialValues;
}

function AthleteLineChartModuleForm(props: AthleteLineChartModuleFormProps) {
  const { onSubmit, initialValues, onCancel } = props;

  const exercises = useExercises();
  const { exerciseMetrics } = useFilteredExercises(exercises);

  const onMetricDependancyChange = useCallback(
    ({ setFieldValue, dependancyValue }: FormikHelpers<typeof initialValues> & { dependancyValue: Exercise }) => {
      const exercise = dependancyValue;
      const defaultMetric = exercise?.metrics.find((metric) => metric.isDefault) || null;
      return setFieldValue("metricField", defaultMetric);
    },
    []
  );

  const onCustomDateDependancyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("rangeType", RangeType.Other),
    []
  );

  const memoizedInitialValues = useMemo(() => {
    const { startDate, endDate, rangeType, exerciseId, metricField } = initialValues || {};
    const exercise = exercises.find((exercise) => exercise.id === exerciseId);

    return {
      exerciseId: exercise || null,
      metricField: exercise?.metrics.find((metric) => metric.field === metricField) || null,
      rangeType: rangeType ?? RangeType.Month,
      startDate: startDate ? moment(startDate).startOf("day") : undefined,
      endDate: endDate ? moment(endDate).endOf("day") : undefined,
      moduleType: 1 as const,
    };
  }, [exercises, initialValues]);

  function handleSubmit(values: AthleteLineChartModuleFormInitialValues<Exercise, moment.Moment>) {
    const rangeType = parseInt(`${values.rangeType}`);

    return onSubmit({
      ...values,
      ...SetDateRangeHelper.calculateDateRange({
        rangeType,
        startDate: values.startDate,
        endDate: values.endDate,
      }),
      exerciseId: values.exerciseId?.id || null,
      metricField: values.metricField?.field || null,
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={memoizedInitialValues} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormAutoComplete
              name="exerciseId"
              options={exercises}
              groupBy={(option: Exercise) => option.category}
              getOptionLabel={(option: Exercise) => option.name || ""}
              renderInput={(params: any) => (
                <Mui5.TextField {...params} label="Exercise" placeholder="Select Exercise" variant="standard" />
              )}
              isOptionEqualToValue={(option: Exercise, value: Exercise | undefined) => option.id === value?.id}
              filterOptions={exerciseAutocompleteFilterOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <DependantFormField dependancyName="exerciseId" onDependancyChange={onMetricDependancyChange}>
              {({ values }: FormikContextType<AthleteLineChartModuleFormInitialValues<Exercise>>) => {
                const options = exerciseMetrics(values.exerciseId);

                return (
                  <FormAutoComplete
                    name="metricField"
                    noOptionsText="No Options (Select an Exercise)"
                    options={options}
                    disabled={!options.length}
                    defaultValue={null}
                    getOptionLabel={(option: ExerciseMetric) => option.name || ""}
                    renderInput={(params: any) => (
                      <Mui5.TextField {...params} label="Metric" placeholder="Select Metric" variant="standard" />
                    )}
                    isOptionEqualToValue={(option: ExerciseMetric, value: ExerciseMetric) =>
                      option.field === value?.field
                    }
                  />
                );
              }}
            </DependantFormField>
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              label="Date Range"
              name="rangeType"
              color="primary"
              options={[
                { label: "Today", value: RangeType.Day },
                { label: "Week", value: RangeType.Week },
                { label: "Month", value: RangeType.Month },
                { label: "Year", value: RangeType.Year },
                {
                  label: "Custom",
                  value: RangeType.Other,
                  component: (
                    <CustomDateRangeFullWidthFix>
                      <DependantFormField
                        dependancyName="startDate"
                        onDependancyChange={onCustomDateDependancyChange}
                      />
                      <DependantFormField dependancyName="endDate" onDependancyChange={onCustomDateDependancyChange} />
                      <CustomDateRange startInputName="startDate" endInputName="endDate" />
                    </CustomDateRangeFullWidthFix>
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default AthleteLineChartModuleForm;
