import { useMemo, useRef, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AthleteService from "services/AthleteService";
import BulkUploadService from "services/BulkUploadService";
import ArchivedAthletesProvider from "providers/ArchivedAthletesProvider";
import AthleteStoreContext from "./contexts/AthleteStoreContext";
import { ArchivedAthlete, Athlete } from "types/athlete";
import AthleteActionsContext from "./contexts/AthleteActionsContext";
import { AthleteActions } from "./AthletesProvider.types";

function AthletesProvider(props: { children?: React.ReactNode }) {
  const apiCallRef = useRef(false);
  const [athletes, setAthletes] = useState<Athlete[]>([]);

  const handleFetchAll = useCallback(async () => {
    if (apiCallRef.current) return;

    apiCallRef.current = true;

    try {
      const result = await AthleteService.getAll();
      setAthletes(result);
      apiCallRef.current = false;
    } catch (error) {
      apiCallRef.current = false;
      throw error;
    }
  }, []);

  const handleFetchById = useCallback(async (athleteId: string) => {
    const fetchedAthlete = await AthleteService.getById(athleteId);
    setAthletes((prevAthletes) => [...prevAthletes.filter((athlete) => athlete.id !== athleteId), fetchedAthlete]);
  }, []);

  const handleCreateAthlete = useCallback(
    async (athlete: Athlete) => {
      const result = await AthleteService.create(athlete);

      await handleFetchById(result.id);

      return result.id;
    },
    [handleFetchById]
  );

  const handleUpdateAthlete = useCallback(
    async (updatedAthlete: Athlete) => {
      const result = await AthleteService.update(updatedAthlete);
      await handleFetchById(result.id);
      return result.id;
    },
    [handleFetchById]
  );

  const handleDeleteAthlete = useCallback(async (athleteId: string) => {
    await AthleteService.delete(athleteId);
    setAthletes((prevAthletes) => prevAthletes.filter((athlete) => athlete.id !== athleteId));
  }, []);

  const bulkAssignGroups = useCallback(async (bulkData: { id: string; groupIds: string[] }[]) => {
    let backupPrevAthletes: Athlete[] = [];

    try {
      setAthletes((prevAthletes) => {
        // create a backup of prevAthletes to be set only if the API call errors.
        backupPrevAthletes = prevAthletes.slice(0);

        return prevAthletes.map((athlete) => {
          const updatedGroupIds = bulkData.find(({ id }) => id === athlete.id)?.groupIds;

          if (updatedGroupIds) return { ...athlete, groupIds: updatedGroupIds };

          return athlete;
        });
      });
      await AthleteService.bulkAssignGroups(bulkData);
    } catch (error) {
      // revert athletes on API error
      setAthletes(backupPrevAthletes);
      throw error;
    }
  }, []);

  const handleToggleArchiveAthlete = useCallback(async (athleteId: string, isArchived: boolean) => {
    await AthleteService.archive(athleteId, isArchived);

    setAthletes((prevAthletes) =>
      prevAthletes.map((athlete) => {
        if (athlete.id !== athleteId) {
          return athlete;
        }

        return { ...athlete, isArchived };
      })
    );
  }, []);

  const bulkUpload = useMemo(
    () => ({
      create: async (newAthletes: Athlete[]) => {
        await BulkUploadService.bulkCreate(newAthletes);
        await handleFetchAll();
      },
    }),
    [handleFetchAll]
  );

  useEffect(() => {
    handleFetchAll();
  }, [handleFetchAll]);

  const athleteActions: AthleteActions = useMemo(
    () => ({
      fetchAll: handleFetchAll,
      fetchById: handleFetchById,
      create: handleCreateAthlete,
      update: handleUpdateAthlete,
      delete: handleDeleteAthlete,
      toggleArchived: handleToggleArchiveAthlete,
      bulkUpload,
      bulkAssignGroups,
    }),
    [
      bulkAssignGroups,
      bulkUpload,
      handleCreateAthlete,
      handleDeleteAthlete,
      handleFetchAll,
      handleFetchById,
      handleToggleArchiveAthlete,
      handleUpdateAthlete,
    ]
  );

  const unarchivedAthletes = useMemo(() => athletes.filter((athlete) => !athlete.isArchived), [athletes]);

  const archivedAthletes = useMemo(
    () => athletes.filter((athlete) => athlete.isArchived) as ArchivedAthlete[],
    [athletes]
  );

  return (
    <AthleteActionsContext.Provider value={athleteActions}>
      <AthleteStoreContext.Provider value={unarchivedAthletes}>
        <ArchivedAthletesProvider athletes={archivedAthletes}>{props.children}</ArchivedAthletesProvider>
      </AthleteStoreContext.Provider>
    </AthleteActionsContext.Provider>
  );
}

AthletesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AthletesProvider;
