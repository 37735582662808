import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

export const Container = styled("div")({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});
export const MessageContainer = styled("div")((props) => ({
  textAlign: "center",
  paddingBottom: props.theme.spacing(2),
  maxWidth: 400,
}));
export const TitleText = styled(Typography)((props) => ({
  fontSize: props.theme.spacing(4),
  fontWeight: props.theme.typography.fontWeightLight,
}));
export const MessageText = styled(Typography)((props) => ({
  fontWeight: props.theme.typography.fontWeightLight,
}));
export const LogoContainer = styled("div")((props) => ({
  paddingTop: props.theme.spacing(2),
}));
export const LogoImg = styled("img")({
  height: 48,
});
