import Grid from "@mui/material/Grid";
import usePreferences from "hooks/usePreferences";
import { FieldArray, FieldArrayRenderProps } from "formik";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import { useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import useExercise from "hooks/useExercise";
import Checkbox from "@mui/material/Checkbox";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ACTIONS, SelectedExercisesActionsTypes } from "../reducer";
import NumberFormat from "react-number-format";
import Mui5 from "library/mui5";

interface AthleteProfile1RMEditRowProps {
  exerciseIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  exercise1rmValue: Omit<FormOneRepMax, "athleteId">;
  dispatchToSelectedExercises: React.Dispatch<SelectedExercisesActionsTypes>;
}

function AthleteProfile1RMEditRow(props: AthleteProfile1RMEditRowProps) {
  const { exerciseIndex, arrayHelpers, exercise1rmValue, dispatchToSelectedExercises } = props;
  const [{ imperialSystemEnabled }] = usePreferences();
  const exercise = useExercise(exercise1rmValue.exerciseId);

  return (
    <Grid container justifyContent="space-between" alignItems="center" columns={12} pt={1} width="100%">
      <Grid item xs={1}>
        <Checkbox
          checkedIcon={<CloseOutlinedIcon />}
          inputProps={{ "aria-label": `Delete ${exercise?.name}` }}
          checked
          onClick={useCallback(
            () =>
              dispatchToSelectedExercises({
                type: ACTIONS.REMOVE_ITEM_FROM_STATE,
                payload: exercise1rmValue.exerciseId,
              }),
            [dispatchToSelectedExercises, exercise1rmValue]
          )}
          sx={{ ml: 1 }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography color="textPrimary" variant="h4" noWrap title={exercise?.name}>
          {exercise?.name}
        </Typography>
      </Grid>
      <Grid item xs mr={4}>
        <FieldArray
          name={`${arrayHelpers.name}.${exerciseIndex}.oneRepMaxes`}
          render={(weightValueArrayHelpers) => (
            <Grid container flexDirection="row" justifyContent="flex-end" key={`${exercise1rmValue.exerciseId}`}>
              {exercise1rmValue.oneRepMaxes.map((oneRepMax, oneRepMaxIndex) => {
                const weightFieldProps = weightValueArrayHelpers.form.getFieldProps<number>(
                  `${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight`
                );

                const weightFieldMetaProps = weightValueArrayHelpers.form.getFieldMeta<number>(
                  `${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight`
                );
                return (
                  <Grid
                    item
                    xs={5}
                    ml={2}
                    key={`${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight${oneRepMax.variant}`}
                  >
                    <NumberFormat
                      maxLength={5}
                      decimalScale={2}
                      customInput={Mui5.TextField}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      label={oneRepMax.variant as string | undefined}
                      {...weightFieldProps}
                      value={
                        typeof weightFieldProps.value == "number"
                          ? Math.round(weightFieldProps.value * 10) / 10
                          : weightFieldProps.value
                      }
                      fullWidth={false}
                      error={Boolean(weightFieldMetaProps.touched && weightFieldMetaProps.error)}
                      helperText={weightFieldMetaProps.touched && weightFieldMetaProps.error}
                      InputProps={{
                        sx: { width: "120px" },
                        endAdornment: (
                          <InputAdornment position="end">{imperialSystemEnabled ? "lbs" : "kg"}</InputAdornment>
                        ),
                        inputProps: {
                          "aria-label": `${exercise?.name} ${oneRepMax.variant ? oneRepMax.variant : ""}`,
                          // maxLength: 5,
                        },
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} pt={1}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default AthleteProfile1RMEditRow;
