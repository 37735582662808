import useChartOptions from "../../../hooks/useChartOptions";
import useChartSelections from "../../../../../components/charting/hooks/useChartSelections";
import useSetChartSelections from "../../../hooks/useSetChartSelections";
import { EntityType } from "types/entity";
import { useCallback, useMemo } from "react";
import { AthleteOrGroup, GroupWithType } from "types/AthleteOrGroup";
import intersection from "lodash/intersection";
import AthleteHelpers from "helpers/AthleteHelpers";

export function useSourceSelectHandlers() {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();

  const getFilterOptionLabel = useCallback(
    (option: AthleteOrGroup) => (option.type === EntityType.ATHLETE ? AthleteHelpers.getFullName(option) : option.name),
    []
  );

  const getSourceOptionDisabled = useCallback(
    (option: AthleteOrGroup) => {
      const isGroupAndMoreThanOneMeasureSelected = selections.measures.length > 1 && option.type === EntityType.GROUP;

      return isGroupAndMoreThanOneMeasureSelected;
    },
    [selections.measures.length]
  );

  const handleSourcesChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, sources: AthleteOrGroup[]) => {
      /**
       * @densk1
       * Only one source type (Group / Athlete) can be selected at any time.
       * In the multiple selection case when a new selection of differing
       * type is added the last item selected will become the only selected item.
       */
      const sourcesAreSameTypes = !sources.find((source) => source.type !== sources[0].type);
      const singleSelection = (!sourcesAreSameTypes || selections.measures.length > 1) && sources.pop();

      setSelections({ sources: singleSelection ? [singleSelection] : sources });
    },
    [selections.measures.length, setSelections]
  );

  const groupSources = useMemo(() => {
    const groups: GroupWithType[] = [];

    selections.sources.forEach((source) => {
      source.type === EntityType.GROUP && groups.push(source);
    });

    return groups;
  }, [selections.sources]);

  const handleExpandGroups = useCallback(() => {
    const groupAthleteIds = intersection(...groupSources.map((group) => group.athleteIds));
    const expandedGroupAthletes = options.sources.filter((source) => groupAthleteIds.includes(source.id));

    setSelections({ sources: expandedGroupAthletes });
  }, [groupSources, options.sources, setSelections]);

  return {
    getFilterOptionLabel,
    getSourceOptionDisabled,
    handleSourcesChange,
    handleExpandGroups,
    showGroupExpand: !!groupSources.length,
  };
}
