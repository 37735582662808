import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import MeasurementService from "services/MeasurementService";
import { MeasurementActivityActions, MeasurementActivityDates } from "./useActivityDateFinder.types";

function useActivityDateFinder(
  athleteId: string,
  currentSelectedDate: moment.Moment
): [MeasurementActivityDates, MeasurementActivityActions] {
  const [activityDates, setActivityDates] = useState<MeasurementActivityDates>({
    previous: null,
    next: null,
  });

  const date = currentSelectedDate.clone().startOf("day").toISOString();

  const findPreviousAndNext = useCallback(async (athleteId: string, selectedDate: string) => {
    const [previous, next] = await Promise.all([
      MeasurementService.getPreviousActivityDate(athleteId, { date: selectedDate }).catch(() => null),
      MeasurementService.getNextActivityDate(athleteId, { date: selectedDate }).catch(() => null),
    ]);
    setActivityDates({ previous, next });
  }, []);

  const actions = useMemo(
    () => ({
      findPreviousAndNext,
      findPrevious: async () => {
        const currentDate = moment(date);

        let newPreviousDate = activityDates.previous;

        if (activityDates.previous !== null) {
          const searchBefore = moment(activityDates.previous).startOf("day").toISOString();

          newPreviousDate = await MeasurementService.getPreviousActivityDate(athleteId, {
            date: searchBefore,
          }).catch(() => null);
        }

        setActivityDates({ next: currentDate.toISOString(), previous: newPreviousDate });
      },
      findNext: async () => {
        const currentDate = moment(date);

        let newNextDate = activityDates.next;

        if (activityDates.next !== null) {
          const searchAfter = moment(activityDates.next).endOf("day").toISOString();

          newNextDate = await MeasurementService.getNextActivityDate(athleteId, {
            date: searchAfter,
          }).catch(() => null);
        }

        setActivityDates({ next: newNextDate, previous: currentDate.toISOString() });
      },
    }),
    [activityDates, athleteId, date, findPreviousAndNext]
  );

  /**
   * @description Will only run on initial render and when athleteId changes
   */
  useEffect(() => {
    findPreviousAndNext(athleteId, date);

    return () => {
      setActivityDates({ previous: null, next: null });
    };
  }, [athleteId]); // eslint-disable-line react-hooks/exhaustive-deps

  return [activityDates, actions];
}

export default useActivityDateFinder;
