import useTickerId from "../TickerProvider/useTickerId";
import { createPortal } from "react-dom";

interface TickerPortalProps {
  children?: React.ReactNode;
}

let errorShown = false;

function TickerPortal(props: TickerPortalProps) {
  const tickerId = useTickerId();

  if (typeof tickerId === "undefined") {
    if (process.env.NODE_ENV === "development" && errorShown === false) {
      console.warn("TickerPortal must be a descendant of TickerProvider to render");
      errorShown = true;
    }

    return null;
  }

  return createPortal(props.children, document.getElementById(tickerId)!, tickerId);
}

export default TickerPortal;
