import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.55556 21.8889H16.4444M12 21.8889V17.4444M12 17.4444C8.93176 17.4444 6.44444 14.9571 6.44444 11.8889V3H17.5556V11.8889C17.5556 14.9571 15.0682 17.4444 12 17.4444ZM17.5556 5.22222H19.2222C20.7563 5.22222 22 6.46588 22 8C22 9.53412 20.7563 10.7778 19.2222 10.7778H17.5556M6.44444 10.7778H4.77778C3.24366 10.7778 2 9.53412 2 8C2 6.46588 3.24366 5.22222 4.77778 5.22222H6.44444"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7292 6.15566C11.8501 5.94811 12.1499 5.94811 12.2708 6.15566L13.0449 7.48476C13.0892 7.56079 13.1634 7.6147 13.2494 7.63332L14.7527 7.95885C14.9874 8.00968 15.0801 8.29484 14.92 8.47395L13.8952 9.62091C13.8366 9.68652 13.8082 9.77375 13.8171 9.86128L13.9721 11.3916C13.9962 11.6305 13.7537 11.8068 13.5339 11.7099L12.1264 11.0897C12.0459 11.0542 11.9541 11.0542 11.8736 11.0897L10.4661 11.7099C10.2463 11.8068 10.0038 11.6305 10.0279 11.3916L10.1829 9.86128C10.1917 9.77375 10.1634 9.68652 10.1048 9.62091L9.07995 8.47395C8.91992 8.29484 9.01257 8.00968 9.24732 7.95885L10.7506 7.63332C10.8366 7.6147 10.9108 7.56079 10.9551 7.48476L11.7292 6.15566Z"
        fill="black"
      />
    </svg>
  ),
};

function LeaderboardsIcon(props: SvgIconProps) {
  return <SvgIcon {...props}>{size.default}</SvgIcon>;
}
export default LeaderboardsIcon;
