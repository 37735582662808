import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import { useLoadingContext } from "hooks";
import Grid from "@mui/material/Grid";
import { useMemo } from "react";
import RAGTemplate from "./RAGTemplate";
import Tables from "components/Tables";

interface RAGTemplatesListProps {}

function RAGTemplatesList(props: RAGTemplatesListProps) {
  const [ragTemplates] = useMonitoringTemplates();
  const [loading] = useLoadingContext();

  const templatesComponent = useMemo(
    () =>
      ragTemplates.map((template) => (
        <Grid key={template.id} item xs={12}>
          <RAGTemplate {...template} />
        </Grid>
      )),
    [ragTemplates]
  );

  if (!ragTemplates.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Tables.Components.Skeleton
            parentHeight={800}
            animation={loading && "wave"}
            text="Click 'New Template' to create an Athlete Monitoring template"
          />
        </Grid>
      </Grid>
    );
  }

  return <Grid container>{templatesComponent}</Grid>;
}

export default RAGTemplatesList;
