import useDefinedContext from "hooks/useDefinedContext";
import WorkoutLabelOptionsContext from "../WorkoutLabelOptionsContext";

interface UseWorkoutLabelOptionsProps {}

function useWorkoutLabelOptions(props?: UseWorkoutLabelOptionsProps) {
  return useDefinedContext(WorkoutLabelOptionsContext);
}

export default useWorkoutLabelOptions;
