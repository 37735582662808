import { useCallback } from "react";
import usePreferences from "hooks/usePreferences";
import UnitHelper from "../../helpers/UnitHelper";

/**
 * TODO: Talk to Carden about deprecating this hook in favor of useUnitParser
 * reason: useUnitParser returns the value and unit separately and a formatted string
 */
function useMetricFormatter() {
  const [preferences] = usePreferences();

  return useCallback(
    /**
     * @returns {string | undefined} */
    (value, metricMetadata) => {
      const displayMetadata = preferences.imperialSystemEnabled
        ? metricMetadata?.displayMetadata?.imperial
        : metricMetadata?.displayMetadata?.metric;

      const toUnit = displayMetadata?.unit;
      const displayDecimals = displayMetadata?.decimals;

      return UnitHelper.process(value, {
        from: metricMetadata?.unitOfMeasure,
        to: toUnit,
        decimals: displayDecimals,
      });
    },
    [preferences.imperialSystemEnabled]
  );
}
export default useMetricFormatter;
