import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === "development" && false,
  resources: { en },
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
