const allWorkoutsConfig = {
  publish: {
    warnings: {
      isShared: "Changing this will affect Workout visibility for Groups or Athletes you cannot access.",
    },
    errors: {
      orgOwnerOnly: "Only the Organisation Owner can Publish or Unpublish this Workout.",
    },
  },
  delete: {
    isShared: "This delete action will affect assigned Groups or Athletes you cannot access.",
  },
  edit: {
    isShared: "Editing this Workout will affect assigned Groups or Athletes you cannot access.",
  },
  assignment: {
    groups: {
      accessRemoved: "You will lose access to some of the selected Workouts when the last Group is unassigned.",
    },
  },
};
export default allWorkoutsConfig;
