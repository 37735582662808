import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

function DustbinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C9.44772 2.5 9 2.94772 9 3.5V4.5H5.5C4.94772 4.5 4.5 4.94772 4.5 5.5C4.5 6.05228 4.94772 6.5 5.5 6.5H18.5C19.0523 6.5 19.5 6.05228 19.5 5.5C19.5 4.94772 19.0523 4.5 18.5 4.5H15V3.5C15 2.94772 14.5523 2.5 14 2.5H10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.5C18.0523 7.5 18.5 7.94772 18.5 8.5V17C18.5 19.2092 16.7091 21 14.5 21H9.5C7.29086 21 5.5 19.2092 5.5 17V8.50002C5.5 7.94773 5.94772 7.50002 6.5 7.50002C7.05228 7.50002 7.5 7.94773 7.5 8.50002V17C7.5 18.1046 8.39543 19 9.5 19H14.5C15.6046 19 16.5 18.1046 16.5 17V8.5C16.5 7.94772 16.9477 7.5 17.5 7.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 8.75C9.91421 8.75 10.25 9.08579 10.25 9.5V15.5C10.25 15.9142 9.91421 16.25 9.5 16.25C9.08579 16.25 8.75 15.9142 8.75 15.5V9.5C8.75 9.08579 9.08579 8.75 9.5 8.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.75C12.4142 8.75 12.75 9.08579 12.75 9.5V15.5C12.75 15.9142 12.4142 16.25 12 16.25C11.5858 16.25 11.25 15.9142 11.25 15.5V9.5C11.25 9.08579 11.5858 8.75 12 8.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8.75C14.9142 8.75 15.25 9.08579 15.25 9.5V15.5C15.25 15.9142 14.9142 16.25 14.5 16.25C14.0858 16.25 13.75 15.9142 13.75 15.5V9.5C13.75 9.08579 14.0858 8.75 14.5 8.75Z"
      />
    </SvgIcon>
  );
}
export default DustbinIcon;
