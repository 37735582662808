import { useEffect, useMemo } from "react";
import useAllAthletes from "./useAllAthletes";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";

function useAthlete(id?: string, getByIdWhenNotInStore?: boolean) {
  const athletes = useAllAthletes();
  const { fetchById } = useAthleteActions();

  const athlete = useMemo(() => athletes.find((athlete) => athlete.id === id), [athletes, id]);

  useEffect(() => {
    if (!(id && getByIdWhenNotInStore && !athlete)) return;

    fetchById(id);
  }, [id, getByIdWhenNotInStore, fetchById, athlete]);

  return useMemo(() => (athlete?.isArchived ? undefined : athlete), [athlete]);
}
export default useAthlete;
