import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Exercise, ExerciseMetric, FireStoreMeasurement, Variants } from "types";
import LeaderboardService from "services/LeaderboardService";
import * as Components from "./LeaderboardTableAbsolute.components";
import uniqBy from "lodash/uniqBy";
import Loader from "shared/components/Loader/Loader";
import { useLoadingContext } from "components/LocalisedLoadingProvider";
import MessageWithLogo from "components/MessageWithLogo";
import useMeasurementsFilter from "pages/Live/hooks/useMeasurementsFilter";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useAllowedAthleteMeasurementFilter from "hooks/useAllowedAthleteMeasurementFilter";
import { firstBy } from "thenby";
import useTickerQueue from "components/Ticker/TickerProvider/useTickerQueue";
import LeaderboardHelpers from "pages/Leaderboards/helpers/LeaderboardHelpers";
import LeaderboardTickerPortal from "../LeaderboardTickerPortal";
import useUpdateLeaderboardStore from "../hooks/useUpdateLeaderboardStore";
import { useTranslation } from "react-i18next";

interface LeaderboardTableAbsoluteProps {
  exercise: Exercise;
  metric: ExerciseMetric;
  variant: Variants | null;
  startDate?: string;
  endDate?: string;
}

const LeaderboardTableAbsolute = memo(function LeaderboardTableAbsolute(props: LeaderboardTableAbsoluteProps) {
  const { t } = useTranslation();
  const organisation = useOrganisation();
  const { exercise, metric, startDate, endDate, variant } = props;
  const [loading, setLoading] = useLoadingContext();
  const [measurements, setMeasurements] = useState<{ [id: string]: FireStoreMeasurement | undefined }>({});
  const [queue, setQueue] = useTickerQueue<FireStoreMeasurement>();
  const metricField = metric.field;
  const sortOrder = metric.sortType;
  const exerciseId = exercise.id;

  const initialMeasurementsRef = useRef<boolean>(true);
  const tickerMeasurement: FireStoreMeasurement | undefined = queue[0];

  const updateMeasurementStore = useUpdateLeaderboardStore({
    initialMeasurementsRef,
    setMeasurements,
    setQueue,
  });

  useEffect(() => {
    setLoading(true);
    const unsubscribe = LeaderboardService.subscribe(
      {
        userId: organisation.id,
        exerciseId,
        metricField,
        startDate,
        endDate,
        sortOrder,
      },
      (snapshot) => {
        LeaderboardHelpers.onSnapshot(snapshot, updateMeasurementStore);

        if (!snapshot.metadata.fromCache) {
          initialMeasurementsRef.current = false;
        }

        setLoading(false);
      }
    );

    return () => {
      initialMeasurementsRef.current = true;
      setMeasurements({});
      setQueue([]);
      unsubscribe();
      setLoading(false);
    };
  }, [
    endDate,
    exerciseId,
    metricField,
    organisation.id,
    setLoading,
    setQueue,
    sortOrder,
    startDate,
    updateMeasurementStore,
  ]);

  const measurementsArray = useMemo(() => {
    const measurementsArray: FireStoreMeasurement[] = [];

    Object.values(measurements)
      .filter((measurement) => measurement && typeof measurement[metricField] === "number")
      .forEach((measurement) => measurement && measurementsArray.push(measurement));

    return measurementsArray.sort(firstBy(metricField, sortOrder === 0 ? "asc" : "desc"));
  }, [measurements, metricField, sortOrder]);

  const uniqueMeasurements = useMemo(
    () => uniqBy(!variant ? measurementsArray : measurementsArray.filter((m) => m.variant === variant), "athleteId"),
    [measurementsArray, variant]
  );
  const allowedAthleteMeasurements = useAllowedAthleteMeasurementFilter(uniqueMeasurements);
  const filteredMeasurements = useMeasurementsFilter(allowedAthleteMeasurements);

  const allowedTickerMeasurements = useAllowedAthleteMeasurementFilter(
    useMemo(() => [tickerMeasurement].filter(Boolean), [tickerMeasurement])
  );
  const filteredTickerMeasurements = useMeasurementsFilter(allowedTickerMeasurements);
  const filteredTickerMeasurement = useMemo(() => filteredTickerMeasurements.shift(), [filteredTickerMeasurements]);

  useEffect(() => {
    // if a ticker measurement exists but should not be shown bump the queue up 1
    if (tickerMeasurement && typeof filteredTickerMeasurement === "undefined") {
      setQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [filteredTickerMeasurement, setQueue, tickerMeasurement]);

  if (loading && filteredMeasurements.length === 0) {
    return <Loader isSmall />;
  }

  if (filteredMeasurements.length === 0) {
    return <MessageWithLogo>{t("Leaderboard.noMeasurementsError")}</MessageWithLogo>;
  }

  return (
    <>
      {filteredMeasurements.map((measurement, index) => (
        <Components.LeaderboardTableAbsoluteRow
          key={measurement.id}
          position={index + 1}
          measurement={measurement}
          metric={metric}
        />
      ))}
      <LeaderboardTickerPortal measurementId={filteredTickerMeasurement?.id}>
        {filteredTickerMeasurement && (
          <Components.LeaderboardTableAbsoluteRow
            key={filteredTickerMeasurement.id}
            position={filteredMeasurements.indexOf(filteredTickerMeasurement) + 1 || null}
            measurement={filteredTickerMeasurement}
            metric={metric}
          />
        )}
      </LeaderboardTickerPortal>
    </>
  );
});

export default LeaderboardTableAbsolute;
