import UnitHelper from "../../../../helpers/UnitHelper";

class AnthropometricsHelper {
  static metadata = [
    {
      label: "Height",
      value: "height",
      unitOfMeasure: UnitHelper.units.Metre,
      displayMetadata: {
        metric: {
          unit: UnitHelper.units.Centimetre,
        },
        imperial: {
          unit: "FeetInches",
        },
      },
    },
    {
      label: "Weight",
      value: "weight",
      unitOfMeasure: "Kilogram",
      displayMetadata: {
        metric: {
          unit: UnitHelper.units.Kilogram,
        },
        imperial: {
          unit: UnitHelper.units.Pound,
        },
      },
    },
  ];
}
export default AnthropometricsHelper;
