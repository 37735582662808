import http from "axiosSetup";
import { Organisation } from "types/organisation";

class OrganisationService {
  static get(): Promise<Organisation> {
    return http.get("/users/me/organisation").then((response) => response.data);
  }

  static update(organisation: Pick<Organisation, "name">): Promise<Pick<Organisation, "id">> {
    return http.put("/users/me/organisation", organisation).then((response) => response.data);
  }
}

export default OrganisationService;
