import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import outputLogoStripes from "library/DrawerMenu/outputLogoStripes.svg";

interface MessageWithLogoProps {
  children: string;
}

function MessageWithLogo(props: MessageWithLogoProps) {
  return (
    <Box pt={4} textAlign="center">
      <Typography variant="h2">{props.children}</Typography>
      <Box pt={2}>
        <img src={outputLogoStripes} alt="Output Sports Stripes Logo" />
      </Box>
    </Box>
  );
}

export default MessageWithLogo;
