import { TimePeriod } from "types/period";
import { AggregateByPeriod, AggregationType } from "../../components/charting/types/AggregateBy";
import { ChartingOptions } from "../../components/charting/types/ChartingOptions.types";
import moment from "moment";
import { ChartingSelections } from "../../components/charting/types/ChartingSelections.types";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import i18n from "config/i18n";

export const aggregationPeriod = [
  { label: i18n.t("Charting.chartSelectionsAggregationPeriodLabelDay"), value: AggregateByPeriod.DAY },
  { label: i18n.t("Charting.chartSelectionsAggregationPeriodLabelWeek"), value: AggregateByPeriod.WEEK },
  { label: i18n.t("Charting.chartSelectionsAggregationPeriodLabelMonth"), value: AggregateByPeriod.MONTH },
  { label: i18n.t("Charting.chartSelectionsAggregationPeriodLabelShowAll"), value: null },
];
export const aggregationType = [
  { label: i18n.t("Charting.chartSelectionsAggregationTypeLabelBest"), value: AggregationType.BEST },
  { label: i18n.t("Charting.chartSelectionsAggregationTypeLabelWorst"), value: AggregationType.WORST },
  { label: i18n.t("Charting.chartSelectionsAggregationTypeLabelAverage"), value: AggregationType.AVERAGE },
];
export const period = [
  { label: i18n.t("Charting.chartSelectionsPeriodLabelToday"), value: TimePeriod.TODAY },
  { label: i18n.t("Charting.chartSelectionsPeriodLabelWeek"), value: TimePeriod.WEEK },
  { label: i18n.t("Charting.chartSelectionsPeriodLabelMonth"), value: TimePeriod.MONTH },
  { label: i18n.t("Charting.chartSelectionsPeriodLabelYear"), value: TimePeriod.YEAR },
];

export const getInitialOptions = ({ sources = [], measures = [] }: Partial<ChartingOptions>): ChartingOptions => ({
  // type: [],
  sources: [...sources],
  measures: [...measures],
  aggregationPeriod,
  aggregationType,
  period,
  showPersonalBest: false,
  showOneRepMax: false,
  showAverage: false,
  showStandardDeviation: false,
  showPlotFromZero: true,
});

export const emptyMeasure = { type: null, id: null, metadata: null, metric: null, repMetric: null };

export const getInitialSelections = (opts?: Partial<ChartingSelections>): ChartingSelections => ({
  sources: [],
  aggregationPeriod: aggregationPeriod[0],
  aggregationType: aggregationType[0],
  period: period[2],
  measures: [emptyMeasure],
  startDate: moment().endOf("day").subtract(1, period[2].value),
  endDate: moment().endOf("day"),
  showPersonalBest: LocalStorageHelper.get(LocalStorageHelper.keys.showPersonalBest, false),
  showOneRepMax: LocalStorageHelper.get(LocalStorageHelper.keys.showOneRepMax, false),
  showAverage: LocalStorageHelper.get(LocalStorageHelper.keys.showAverageValue, false),
  showStandardDeviation: LocalStorageHelper.get(LocalStorageHelper.keys.showStandardDeviation, false),
  showPlotFromZero: LocalStorageHelper.get(LocalStorageHelper.keys.beginYAxesAtZero, true),
});
