import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import CogIcon from "library/Icons/CogIcon";
import useMuiMenu from "hooks/useMuiMenu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ChartingAdvancedOptionsButtons from "components/charting/ChartingAdvancedOptionsButtons";
import useChartPersistAdvancedOptions from "components/charting/hooks/useChartPersistAdvancedOptions";
import { useTranslation } from "react-i18next";

interface AthleteProfileChartAdvancedOptionsProps {}

function AthleteProfileChartAdvancedOptions(props: AthleteProfileChartAdvancedOptionsProps) {
  const { t } = useTranslation();
  const [anchorEl, { handleOpen, handleClose }] = useMuiMenu();

  const open = Boolean(anchorEl);
  const id = open ? "chart-context-menu-popover" : undefined;

  useChartPersistAdvancedOptions();

  return (
    <>
      <IconButton color="primary" onClick={handleOpen} aria-label="advanced options menu">
        <CogIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} pt={1} pb={1} bgcolor="primary.main" color="common.white">
          <Typography variant="h4" color="inherit">
            {t("AthleteProfileChartAdvancedOptions.title")}
          </Typography>
        </Box>
        <Box mt={1} mb={1}>
          <ChartingAdvancedOptionsButtons />
        </Box>
      </Popover>
    </>
  );
}

export default AthleteProfileChartAdvancedOptions;
