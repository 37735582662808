import { useCallback } from "react";
import { firstBy } from "thenby";
import { FireStoreMeasurement } from "types";

interface UseUpdateLeaderboardMeasurementsStoreProps {
  initialMeasurementsRef: React.MutableRefObject<boolean>;
  setQueue: React.Dispatch<React.SetStateAction<FireStoreMeasurement[]>>;
  setMeasurements: React.Dispatch<
    React.SetStateAction<{
      [id: string]: FireStoreMeasurement | undefined;
    }>
  >;
}

function useUpdateLeaderboardMeasurementsStore(props: UseUpdateLeaderboardMeasurementsStoreProps) {
  const { initialMeasurementsRef, setMeasurements, setQueue } = props;

  return useCallback(
    (newAndUpdatedMeasurements: { [id: string]: FireStoreMeasurement | undefined }) => {
      if (!initialMeasurementsRef.current) {
        const newTickerMeasurements: FireStoreMeasurement[] = [];

        Object.values(newAndUpdatedMeasurements).forEach(
          (measurement) => measurement && newTickerMeasurements.push(measurement)
        );

        setQueue((prevQueue) => [...prevQueue, ...newTickerMeasurements.sort(firstBy("completedDate"))]);
      }

      setMeasurements((prevMeasurements) => ({ ...prevMeasurements, ...newAndUpdatedMeasurements }));
    },
    [initialMeasurementsRef, setMeasurements, setQueue]
  );
}

export default useUpdateLeaderboardMeasurementsStore;
