export enum AggregateByPeriod {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}
export type AggregateBy = AggregateByPeriod | null;

export enum AggregationType {
  BEST = "BEST",
  WORST = "WORST",
  AVERAGE = "AVERAGE",
}
