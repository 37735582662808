import { styled } from "@mui/material";
import MuiChip, { chipClasses } from "@mui/material/Chip";

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<{
  customColor?: string;
}>((props) => ({
  backgroundColor: (props.variant !== "outlined" && props.customColor) || undefined,
  color:
    props.variant !== "outlined" && props.customColor
      ? props.theme.palette.getContrastText(props.customColor)
      : undefined,
  cursor: "inherit",
  paddingLeft: !!props.icon ? 4 : undefined,
  [`.${props.className}.${chipClasses.label}`]: {
    fontSize: props.theme.typography.body2.fontSize,
  },
  [`.${props.className}.${chipClasses.outlined}`]: {
    borderColor: props.customColor,
    color: props.customColor,
    borderWidth: 2,
  },
  [`.${chipClasses.deleteIcon}`]: {
    "& > *": {
      fill: props.theme.palette.getContrastText(props.customColor || props.theme.palette.primary.main),
    },
  },
}));

export default Chip;
