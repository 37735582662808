import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useAthleteMonitoringStatusesContext } from "hooks";
import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import useMuiMenu from "hooks/useMuiMenu";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface RagMonitoringTemplateSelectorMenuProps {
  id: string;
  children(props: {
    anchorProps: {
      onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
      "aria-haspopup": "true";
      "aria-controls": string;
    };
    open: boolean;
    disabled: boolean;
    currentTemplate: RAGSettings | undefined;
  }): JSX.Element;
}

function RagMonitoringTemplateSelectorMenu(props: RagMonitoringTemplateSelectorMenuProps) {
  const [anchorEl, { handleOpen, handleClose }] = useMuiMenu();
  const [ragTemplates] = useMonitoringTemplates();
  const [, { currentRagTemplateId, setTemporaryRagTemplateId }] = useAthleteMonitoringStatusesContext();
  const navigate = useNavigate();
  const currentTemplate = ragTemplates.find((template) => template.id === currentRagTemplateId);
  const { t } = useTranslation();

  function handleItemClick(selectedTemplateId: string): void {
    handleClose();
    setTemporaryRagTemplateId(selectedTemplateId);
  }

  function goToEditTemplates() {
    handleClose();
    navigate("/settings/athlete-monitoring");
  }

  return (
    <>
      {props.children({
        anchorProps: { onClick: handleOpen, "aria-haspopup": "true", "aria-controls": props.id },
        open: Boolean(anchorEl),
        disabled: Boolean(ragTemplates.length > 2),
        currentTemplate,
      })}
      <Menu
        id={props.id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ marginTop: "8px" }}
      >
        {ragTemplates.map((template) => (
          <MenuItem
            onClick={() => handleItemClick(template.id)}
            key={template.id}
            disabled={template.id === currentRagTemplateId}
          >
            {template.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={goToEditTemplates}>
          <Typography variant="subtitle2">{t("Athlete.ragMonitoringTemplateSelectorEditTemplatesText")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default RagMonitoringTemplateSelectorMenu;
