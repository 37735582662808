import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 2.66666L8.90907 8.42424L5.87877 5.39394L1.33331 9.93939"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0303 2.66666H14.6666V6.30303"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.33331 13.3333H14.6666" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 4L13.3636 12.6364L8.81818 8.09091L2 14.9091"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5455 4H22V9.45455"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 20H22" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.3334 5.33334L17.8182 16.8485L11.7576 10.7879L2.66669 19.8788"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0606 5.33334H29.3333V12.6061"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.66669 26.6667H29.3334" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
};

function LineChartIcon(props: SvgIconProps) {
  const item = props.fontSize === "large" || props.fontSize === "small" ? size[props.fontSize] : size.default;

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default LineChartIcon;
