import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import usePreferences from "hooks/usePreferences";
import GroupedOptionButtons from "components/GroupedOptionButtons";

function SettingsMeasurementSystem() {
  const [preferences, handlers] = usePreferences();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightMedium">
            Measurement System
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} width="100%" paddingTop={2}>
        <Box fontWeight="fontWeightLight">
          <Typography variant="subtitle2" gutterBottom fontWeight={300}>
            Measure all units using
          </Typography>
        </Box>
        <GroupedOptionButtons
          value={preferences?.imperialSystemEnabled}
          onClick={handlers.toggleImperialSystem}
          ButtonProps={{ size: "large" }}
          options={[
            {
              label: "Metric System",
              "aria-label": "select metric system",
              value: false,
            },
            {
              label: "Imperial System",
              "aria-label": "select imperial system",
              value: true,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default SettingsMeasurementSystem;
