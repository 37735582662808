import useDefinedContext from "hooks/useDefinedContext";
import MetadataActionsContext from "../contexts/MetadataActionsContext";

interface UseMetadataActionsProps {}

function useMetadataActions(props?: UseMetadataActionsProps) {
  return useDefinedContext(MetadataActionsContext);
}

export default useMetadataActions;
