import { useCallback, useMemo, useState } from "react";
import MultiSelectContext from "./MultiSelectContext";
import SetMultiSelectContext, { SetMultiSelectContextType } from "./SetMultiSelectContext";

interface MultiSelectProviderProps {
  children?: React.ReactNode;
}

function MultiSelectProvider(props: MultiSelectProviderProps) {
  const [selectedIds, setSelectedIds] = useState<{ [athleteId: string]: boolean | undefined }>({});

  const onSetSelectedIds = useCallback<SetMultiSelectContextType>(
    (selectedIds, overwrite) => {
      const selectedIdsUpdate = overwrite
        ? selectedIds
        : (prev: { [athleteId: string]: boolean | undefined }) => ({ ...prev, ...selectedIds });
      setSelectedIds(selectedIdsUpdate);
    },
    [setSelectedIds]
  );

  const selectedIdsList = useMemo(
    () =>
      Object.entries(selectedIds)
        .filter(([, value]) => value)
        .map(([key]) => key),
    [selectedIds]
  );

  return (
    <SetMultiSelectContext.Provider value={onSetSelectedIds}>
      <MultiSelectContext.Provider value={selectedIdsList}>{props.children}</MultiSelectContext.Provider>
    </SetMultiSelectContext.Provider>
  );
}

export default MultiSelectProvider;
