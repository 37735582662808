import OneRepMaxChartEntityColorProvider from "components/charting/OneRepMaxChart/OneRepMaxChartEntityColorProvider";
import useOneRepMaxChartDataApi from "components/charting/OneRepMaxChart/useOneRepMaxChartDataApi";
import OneRepMaxChartDataActionsContext from "components/charting/contexts/OneRepMaxChartDataActionsContext";
import OneRepMaxChartDataContext from "components/charting/contexts/OneRepMaxChartDataContext";

interface OneRepMaxChartDataProviderProps {
  children?: React.ReactNode;
}

function OneRepMaxChartDataProvider(props: OneRepMaxChartDataProviderProps) {
  const [oneRepMaxChartData, oneRepMaxChartDataActions] = useOneRepMaxChartDataApi();

  return (
    <OneRepMaxChartDataContext.Provider value={oneRepMaxChartData}>
      <OneRepMaxChartDataActionsContext.Provider value={oneRepMaxChartDataActions}>
        <OneRepMaxChartEntityColorProvider>{props.children}</OneRepMaxChartEntityColorProvider>
      </OneRepMaxChartDataActionsContext.Provider>
    </OneRepMaxChartDataContext.Provider>
  );
}

export default OneRepMaxChartDataProvider;
