import { createContext } from "react";

export type ChartColorRegistryStoreContextType = {
  [chartEntityId: string]: string;
};

export const ChartColorRegistryStoreContext = createContext<ChartColorRegistryStoreContextType>({});

ChartColorRegistryStoreContext.displayName = "ChartColorRegistryStoreContext";

export default ChartColorRegistryStoreContext;
