import { alpha, styled, Theme } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

export const autocompleteStyleOverrides = {
  [`& .${autocompleteClasses.clearIndicator}`]: {
    visibility: "visible",
  },
};
const StyledAutocomplete = styled(Autocomplete)(autocompleteStyleOverrides);
export default StyledAutocomplete as typeof Autocomplete;

/**
 * Popper and Paper Overrides can be found at:
 * The Popper component renders in a portal, so cannot be overwritten via styled()
 * {@link src/library/MuiThemeProvider/muiTheme.js}
 */
export const autocompletePaperStyleOverrides = {
  borderRadius: 12,
  boxShadow: "2px 2px 20px 0 rgba(0,0,0,0.12)",
};

export const autocompletePopperStyleOverrides = ({ theme }: { theme: Theme }) => ({
  [`& .${autocompleteClasses.groupLabel}`]: { color: theme.palette.primary.main },
  [`& .${autocompleteClasses.listbox}`]: {
    [`& .${autocompleteClasses.option}`]: {
      color: "#4D4D4D",
      "&.Mui-focused": {
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        color: "#4D4D4D",
      },
      '&[aria-selected="true"]': {
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
        color: theme.palette.primary.main,
      },
      '&[aria-selected="true"].Mui-focused': {
        backgroundColor: alpha(theme.palette.primary.light, 0.3),
        color: theme.palette.primary.main,
      },
    },
  },
});
