import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export const MinHeightBox = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(6),
}));

export const OverflowHiddenGrid = styled(Grid)(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(2),
}));
