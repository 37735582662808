import { useCallback, useState } from "react";
import Popover from "@mui/material/Popover";
import { sxPointerEvents } from "./useMuiPopover.styles";

function useMuiPopover(popoverId: string) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const PopoverComponent = useCallback(
    (props: { children: React.ReactNode }) => {
      return (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          sx={sxPointerEvents}
        >
          {props.children}
        </Popover>
      );
    },
    [anchorEl, handlePopoverClose, open, popoverId]
  );

  return {
    anchorProps: {
      "aria-owns": open ? popoverId : undefined,
      "aria-haspopup": "true" as const,
      onMouseEnter: handlePopoverOpen,
      onMouseLeave: handlePopoverClose,
    },
    Popover: PopoverComponent,
  };
}

export default useMuiPopover;
