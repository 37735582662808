import { createContext } from "react";
import AnalyticsService from "services/AnalyticsService";

const propertyValues = Object.values(AnalyticsService.PROPERTIES);
type LogEventContextType = (properties: Partial<{ [key in (typeof propertyValues)[number]]: number | string }>) => void;

const LogEventContext = createContext<LogEventContextType | undefined>(undefined);

LogEventContext.displayName = "LogEventContext";

export default LogEventContext;
