import { useMemo } from "react";
import { Group } from "types/groups";
import { Athlete } from "types/athlete";
import every from "lodash/every";

type FilterProperties =
  | {
      athleteIds: string[];
      groupIds?: string[];
    }
  | {
      athleteIds?: string[];
      groupIds: string[];
    };

function useFilterByAthletesOrGroups<T extends FilterProperties>(
  selectedAthletesOrGroups: (Athlete | Group)[],
  filterableItems: T[]
) {
  return useMemo(() => {
    if (!selectedAthletesOrGroups.length) return filterableItems;

    const filteredGroupIds: string[] = [];
    const filteredAthleteIds: string[] = [];

    selectedAthletesOrGroups.forEach((item) => {
      "firstName" in item ? filteredAthleteIds.push(item.id) : filteredGroupIds.push(item.id);
    });

    return filterableItems.filter((item) => {
      const satisfiesAllGroupIds = every(filteredGroupIds, (id) => item.groupIds?.includes(id));

      if (filteredGroupIds.length > 0 && satisfiesAllGroupIds) return true;

      const satisfiesAllAthleteIds = item.athleteIds?.find((id) => filteredAthleteIds.includes(id));

      return filteredAthleteIds.length > 0 && satisfiesAllAthleteIds;
    });
  }, [filterableItems, selectedAthletesOrGroups]);
}

export default useFilterByAthletesOrGroups;
