import { styled } from "@mui/material";
import Alert, { alertClasses } from "@mui/material/Alert";

export const StyledAlert = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(1, 0, 0, 0),
  [`.${alertClasses.icon}`]: {
    color: theme.palette.getContrastText(theme.palette.warning.main),
  },
  [`.${alertClasses.message}`]: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.getContrastText(theme.palette.warning.main),
  },
}));
