import { ScaleLinear } from "d3-scale";
import { useEffect, useMemo } from "react";
import useChartSelections from "../../../components/charting/hooks/useChartSelections";
import { scaleLinear } from "@visx/scale";
import useChartDimensions from "./useChartDimensions";
import { LineData } from "components/charting/types/Chart.types";
import ChartHelpers from "../helpers/ChartHelpers";
import { MetadataType } from "types/metadata";

function useChartYScales(data: LineData[], options: { altMaxValue: number | null }) {
  const dimensions = useChartDimensions();
  const selections = useChartSelections();

  const scales = useMemo<{ [scaleId: string]: ScaleLinear<number, number, never> }>(() => {
    return selections.measures
      .filter((m) => m.id)
      .reduce((scaleMap, measure) => {
        const scaleId = ChartHelpers.getScaleId(measure);

        const { min, max } = ChartHelpers.getScaleMinMax(data, measure);

        const domainMin = selections.showPlotFromZero || min === max ? 0 : min;
        const domainMax =
          selections.showOneRepMax && options.altMaxValue
            ? options.altMaxValue > max
              ? options.altMaxValue
              : max
            : measure.type === MetadataType.WELLNESS
              ? 5
              : max;

        return {
          ...scaleMap,
          [scaleId]: scaleLinear({
            range: dimensions.ranges.y,
            domain: [domainMin, domainMax],
            nice: true,
          }),
        };
      }, {});
  }, [
    data,
    dimensions.ranges.y,
    selections.measures,
    selections.showPlotFromZero,
    options.altMaxValue,
    selections.showOneRepMax,
  ]);

  useEffect(() => {
    for (const scale in scales) {
      scales[scale].range([dimensions.inner.height, 0] as [number, number]);
    }
  }, [dimensions.inner.height, dimensions.margin.top, scales]);

  return scales;
}
export default useChartYScales;
