class LineHelpers {
  static getXIntersect(line: [number, number][], y: number) {
    if (line.length < 2) {
      return null;
    }

    const [[x1, y1], [x2, y2]] = line;

    const m = (y2 - y1) / (x2 - x1);

    const c = y1 - m * x1;

    const x = (y - c) / m;

    return x;
  }
}
export default LineHelpers;
