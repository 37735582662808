import { Group } from "contexts/GroupsContext/GroupsContext";
import { useCallback, useMemo } from "react";
import CoachService from "services/CoachService";
import { getDefaultCoachFormValues } from "./CoachForm.config";
import { CoachFormProps, CoachFormValues } from "./CoachForm.types";

export function useCoachFormValues(
  initialValues: CoachFormProps["initialValues"],
  { groups }: { groups: Group[] }
): CoachFormValues {
  return useMemo(() => {
    if (typeof initialValues === "undefined") {
      return getDefaultCoachFormValues();
    }

    return CoachService.deserialize(initialValues, { groups });
  }, [groups, initialValues]);
}

export function useHandleCoachFormSubmit(onSubmit: CoachFormProps["onSubmit"]) {
  return useCallback(
    (coachFormValues: CoachFormValues /* , helpers: FormikHelpers<CoachFormValues> */) =>
      onSubmit(CoachService.serialize(coachFormValues)),
    [onSubmit]
  );
}
