import { createContext } from "react";
import { RagStatus } from "./AthleteMonitoringStatusesContext.types";

export type AthleteMonitoringStatusesContextValue = {
  [athleteId: string]: {
    [metadataId: string]: RagStatus;
  };
};

export type AthleteMonitoringStatusesContextActions = {
  getTemplateByAthleteIds(athleteIds: string[], templateId?: string | null): Promise<void>;
  setTemporaryRagTemplateId(id: string | null): void;
  currentRagTemplateId: string | null;
};

export type AthleteMonitoringStatusesContextType = [
  AthleteMonitoringStatusesContextValue,
  AthleteMonitoringStatusesContextActions,
];

export const AthleteMonitoringStatusesContext = createContext<AthleteMonitoringStatusesContextType | undefined>(
  undefined
);

export default AthleteMonitoringStatusesContext;
