import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";

interface LineEquationProps {
  yLabel: string;
  xLabel: string;
  equation?: "linear" | "quadratic";
}

function LineEquation(props: LineEquationProps) {
  const { xLabel, yLabel, equation = "linear" } = props;

  const equations = useMemo(
    () => ({
      linear: `{${yLabel}} = m * {${xLabel}} + b`,
      quadratic: `{${yLabel}} = a * {${xLabel}}² + b * {${xLabel}} + c`,
    }),
    [xLabel, yLabel]
  );

  return (
    <>
      <Typography color="textSecondary" variant="caption">
        Line Equation:&nbsp;
      </Typography>
      <Typography display="inline" variant="caption">
        <Box component="span" color="textPrimary.main">
          {equations[equation]}
        </Box>
      </Typography>
    </>
  );
}

export default LineEquation;
