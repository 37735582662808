import { useMemo } from "react";
import { SkeletonProps } from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { v4 as uuid } from "uuid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CardPaper } from "./TableEmpty.components";
import * as Components from "./TableEmpty.components";
import { useLoadingContext } from "hooks";
import useGetContainerDimensions from "pages/Workouts/hooks/useGetContainerDimensions";

interface TableEmptyProps {
  animation?: SkeletonProps["animation"];
  parentHeight?: number | undefined;
  text: React.ReactNode;
}

const widths = ["60%", "75%", "60%", "50%"];

function TableEmpty(props: TableEmptyProps) {
  const { ref: containerRef, dimensions } = useGetContainerDimensions();
  const [loading] = useLoadingContext();
  const animation = props.animation || (loading && "wave");
  const parentHeight = props.parentHeight || dimensions?.height || 0;

  const rows = Math.floor(parentHeight / 56) || 1;

  const message = (
    <Box
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={3}
      pb={4}
      top={0}
      right={0}
      bottom={0}
      left={0}
      minWidth={0}
    >
      <Box>
        <Typography component="span" variant="h3">
          {props.text}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Grid
      container
      spacing="0px"
      position="relative"
      height="100%"
      overflow="hidden"
      ref={containerRef}
      textAlign="center"
    >
      <Grid item xs={12}>
        {useMemo(
          () =>
            new Array(rows).fill(null).map((_, index) => (
              <CardPaper elevation={0} key={uuid()} square>
                <Grid container spacing="0px" width="100%" alignItems="center" columns={12}>
                  <Grid item xs={2}>
                    <Components.EmptySkeleton animation={animation} variant="rectangular" height={12} width="80%" />
                  </Grid>
                  <Grid item xs={7}>
                    <Components.EmptySkeleton
                      animation={animation}
                      variant="rectangular"
                      height={12}
                      width={widths[index % widths.length]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Components.EmptySkeleton animation={animation} variant="rectangular" height={12} width="75%" />
                  </Grid>
                </Grid>
              </CardPaper>
            )),
          [animation, rows]
        )}
      </Grid>
      {Boolean(!animation) && message}
    </Grid>
  );
}

export default TableEmpty;
