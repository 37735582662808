import { DataVisualizationType } from "../config/chartTypes";

class DataVisualizationShowInputHelper {
  static showMenuWith = {
    exercise: [
      DataVisualizationType.LINE_CHART,
      DataVisualizationType.MEASUREMENTS_TABLE,
      DataVisualizationType.PROFILING_CHART,
      DataVisualizationType.SET_COMPARISON_CHART,
      DataVisualizationType.ONE_REP_MAX_CHART,
    ],
    metric: [DataVisualizationType.LINE_CHART],
    repMetric: [DataVisualizationType.SET_COMPARISON_CHART, DataVisualizationType.PROFILING_CHART],
    period: [DataVisualizationType.LINE_CHART, DataVisualizationType.ONE_REP_MAX_CHART],
    aggregation: [DataVisualizationType.LINE_CHART],
    sets: [DataVisualizationType.SET_COMPARISON_CHART, DataVisualizationType.PROFILING_CHART],
    comparableSets: [DataVisualizationType.PROFILING_CHART],
  };

  static show(visType: DataVisualizationType) {
    const menuOptions = Object.entries(DataVisualizationShowInputHelper.showMenuWith);

    return menuOptions.reduce(
      (acc, [optionName, optionAllowedArray]) => ({
        ...acc,
        [optionName]: optionAllowedArray.includes(visType),
      }),
      {} as { [K in keyof typeof DataVisualizationShowInputHelper.showMenuWith]: boolean }
    );
  }

  static generateTooltipI18nKeyContext(visType: DataVisualizationType) {
    if ([DataVisualizationType.ONE_REP_MAX_CHART].includes(visType)) {
      return "vbtOnly";
    }
    if ([DataVisualizationType.SET_COMPARISON_CHART, DataVisualizationType.PROFILING_CHART].includes(visType)) {
      return "exerciseOnly";
    } else {
      return;
    }
  }
}
export default DataVisualizationShowInputHelper;
