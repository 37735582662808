import useDefinedContext from "hooks/useDefinedContext";
import OneRepMaxActionsContext from "../OneRepMaxActionsContext";

interface UseOneRepMaxActionsProps {}

function useOneRepMaxActions(props?: UseOneRepMaxActionsProps) {
  return useDefinedContext(OneRepMaxActionsContext);
}

export default useOneRepMaxActions;
