import { createContext } from "react";

export type LivePbCheckContextType = [
  {
    [athleteId: string]: { [exerciseId: string]: { [measurementId: string]: string } | undefined } | undefined;
  },
  (athleteId: string, exerciseId: string, measurementId: string) => void,
];

const LivePbCheckContext = createContext<LivePbCheckContextType | undefined>(undefined);
export default LivePbCheckContext;
