import { WellnessMetadata } from "./wellness-metadata";
import { AnthropometricMetadata } from "./anthropometric-metadata";
import { Exercise } from "./exercise";

export enum MetadataType {
  EXERCISE = "Exercise",
  WELLNESS = "Wellness",
  ANTHROPOMETRIC = "Anthropometric",
  ONE_REP_MAX = "OneRepMax",
}

export const MetadataTypeValues = Object.values(MetadataType);

export interface MetadataExercise {
  id: string;
  type: MetadataType.EXERCISE;
  metadata: Exercise;
}
export interface MetadataAnthropometric {
  id: string;
  type: MetadataType.ANTHROPOMETRIC;
  metadata: AnthropometricMetadata;
}
export interface MetadataWellness {
  id: string;
  type: MetadataType.WELLNESS;
  metadata: WellnessMetadata;
}

export type Metadata = MetadataExercise | MetadataAnthropometric | MetadataWellness;
