import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";
import { useMemo } from "react";

function useExcludeArchivedExerciseItems<T extends { exerciseId: string }>(items: T[]) {
  const archivedCustomExerciseIds = useArchivedCustomExercises(true);

  return useMemo(
    () => items.filter((measurement) => !archivedCustomExerciseIds.includes(measurement.exerciseId)),
    [items, archivedCustomExerciseIds]
  );
}

export default useExcludeArchivedExerciseItems;
