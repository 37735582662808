import { ButtonProps } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useCallback, useState } from "react";
import type { SvgIconComponent } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EventHelpers from "helpers/EventHelpers";
import Mui5 from "library/mui5";

interface ConfirmDeleteIconButtonProps {
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
  icon: ReturnType<SvgIconComponent>;
  confirmText?: string;
  cancelText?: string;
  title?: string;
  body?: string;
  confirmColor?: ButtonProps["color"];
  slotProps?: {
    iconButtonProps?: IconButtonProps;
  };
}

function ConfirmActionIconButton(props: ConfirmDeleteIconButtonProps) {
  const {
    onConfirm,
    title = "Confirm",
    body = "Are you sure you wish to confirm this action?",
    confirmText = "Confirm",
    cancelText = "Cancel",
    icon,
    confirmColor,
    slotProps,
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onConfirm(event);
      handleClose();
    },
    [handleClose, onConfirm]
  );

  return (
    <>
      <IconButton {...slotProps?.iconButtonProps} onClick={handleOpen}>
        {icon}
      </IconButton>
      <Dialog open={open} onClose={handleClose} onClick={EventHelpers.stopPropagation}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Mui5.Button fullWidth={false} onClick={handleClose} color="primary" variant="outlined" square size="medium">
            {cancelText}
          </Mui5.Button>
          <Mui5.Button
            fullWidth={false}
            onClick={handleConfirm}
            color={confirmColor}
            variant="contained"
            square
            size="medium"
          >
            {confirmText}
          </Mui5.Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmActionIconButton;
