import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { useTranslation } from "react-i18next";
import useAthleteLimits from "hooks/useAthleteLimits";

export interface AthleteArchiveDisabledAlertProps {}

function AthleteArchiveDisabledAlert(props: AthleteArchiveDisabledAlertProps) {
  const { t } = useTranslation();
  const athleteLimits = useAthleteLimits();

  return (
    <Alert icon={<ArchiveOutlinedIcon />} variant="filled" color="warning" sx={{ maxWidth: 480, textAlign: "left" }}>
      <AlertTitle>{t("AthleteArchiveDisabledAlert.alertTitle")}</AlertTitle>
      {t("AthleteArchiveDisabledAlert.alertBody", {
        activeUsage: `${athleteLimits.active.count}/${athleteLimits.active.limit}`,
        archivedUsage: `${athleteLimits.archived.count}/${athleteLimits.archived.limit}`,
      })}
    </Alert>
  );
}

export default AthleteArchiveDisabledAlert;
