import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import { LineData } from "components/charting/types/Chart.types";
import useDefinedContext from "hooks/useDefinedContext";
import { useMemo } from "react";
import useGetMeasurementsById from "hooks/useGetMeasurementsById";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { SelectedChartMeasureExercise } from "components/charting/types";
import { MetadataType } from "types/metadata";
import { Measurement } from "types";

function useSetComparisonChartData(): LineData[] {
  const { value } = useDefinedContext(ChartSetSelectContext);
  const selections = useChartSelections();
  const measure = selections.measures[0] as SelectedChartMeasureExercise;
  const source = selections.sources[0];
  const measurementIds = useMemo(() => value.map((set) => set.measurementId), [value]);
  const { measurements } = useGetMeasurementsById({
    measurementIds: measurementIds,
  });

  const data: LineData[] = useMemo(() => {
    const getCommon = (measurement: Measurement) => ({
      entityId: measurement.id,
      source,
      measure: { id: measure.id, metricField: measure.metric.field, repMetricField: measure.repMetric?.field },
      metadataType: MetadataType.EXERCISE,
      sortOrder: measure.repMetric?.sortType === 0 ? ("asc" as const) : ("desc" as const),
      variant: measurement.variant || undefined,
    });

    return measurements
      .filter((measurement) => measurementIds.includes(measurement.id))
      .map((measurement): LineData => {
        const common = getCommon(measurement);
        return {
          ...common,
          line: measurement.repetitions.map((rep, repIndex) => ({
            ...common,
            x: repIndex + 1,
            y: rep.find((repMetric) => repMetric.field === common.measure.repMetricField)?.value || 0,
            metadataType: MetadataType.EXERCISE,
            measurement,
            rep,
            repIndex,
          })),
        };
      });
  }, [measure.id, measure.metric, measure.repMetric, measurementIds, measurements, source]);

  return data;
}

export default useSetComparisonChartData;
