import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import moment from "moment";

type ReportModuleDateRange<T = string | moment.Moment | undefined> = {
  rangeType: RangeType;
  startDate: T;
  endDate: T;
};

class SetDateRangeHelper {
  static calculateDateRange({ rangeType, startDate, endDate }: ReportModuleDateRange): ReportModuleDateRange<string> {
    return {
      rangeType,
      startDate: SetDateRangeHelper.calculateStartDate({ rangeType, startDate }).toISOString(),
      endDate: SetDateRangeHelper.calculateEndDate({ rangeType, endDate }).toISOString(),
    };
  }

  private static calculateStartDate({ rangeType, startDate }: Omit<ReportModuleDateRange, "endDate">): moment.Moment {
    const timePeriod = ["day" as const, "week" as const, "month" as const, "year" as const][rangeType];

    if (!timePeriod) {
      return moment(startDate).startOf("day");
    }

    return moment().subtract(1, timePeriod).endOf("day");
  }

  private static calculateEndDate({ rangeType, endDate }: Omit<ReportModuleDateRange, "startDate">): moment.Moment {
    if (rangeType !== RangeType.Other) {
      return moment().endOf("day");
    }

    return moment(endDate).endOf("day");
  }
}
export default SetDateRangeHelper;
