import GroupsContext from "contexts/GroupsContext/GroupsContext";
import { useEffect, useMemo } from "react";
import useDefinedContext from "../useDefinedContext";
import useRequest from "../useRequest";

function useGetGroupById(id: string | undefined) {
  const [groups, { fetchById }] = useDefinedContext(GroupsContext);
  const getGroupById = useRequest(fetchById, true);

  useEffect(() => {
    if (!id) return;

    getGroupById(id);
  }, [id, getGroupById]);

  return useMemo(() => groups.find((group) => group.id === id), [groups, id]);
}
export default useGetGroupById;
