import { styled } from "@mui/material";
import Paper, { paperClasses } from "library/mui5/Paper";

export const TableCardPaper = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.rounded}`]: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(0, 1, 1),
  },
}));
