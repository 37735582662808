import { ISODate, RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import { Exercise, ExerciseMetric } from "types";

export enum KpiType {
  Latest = 0,
  Average = 1,
  Best = 2,
}

export interface AthleteKpiModule<T = string> {
  exercises: {
    exerciseId: T extends Exercise ? Exercise : string;
    metricField: T extends Exercise ? ExerciseMetric : string;
  }[];
  rangeType: RangeType | string;
  kpiType: KpiType | string;
  startDate: ISODate;
  endDate: ISODate;
  moduleType: 0;
}

export type AthleteKpiModuleFormInitialValues = AthleteKpiModule<Exercise> & {
  exercise: Exercise | null;
  metricField: ExerciseMetric | null;
};

export interface AthleteKpiModuleFormProps {
  onSubmit(values: AthleteKpiModule): Promise<void> | void;
  initialValues?: AthleteKpiModule;
  onCancel(): void;
}
