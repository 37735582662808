import Tables from "components/Tables";
import Grid from "@mui/material/Grid";

interface CompletedWorkoutsTableControlsProps {}

function CompletedWorkoutsTableControls(props: CompletedWorkoutsTableControlsProps) {
  return (
    <Grid {...Tables.controlsContainerProps} flexWrap="nowrap" borderBottom={`1px solid`} borderColor="grey.400">
      <Grid {...Tables.controlCells.checkbox} textAlign="center" lineHeight={0}>
        <Tables.Icons.Search color="inherit" />
      </Grid>
      <Grid item xs maxWidth={240} pr={1}>
        <Tables.Components.SearchTextInput />
      </Grid>
    </Grid>
  );
}

export default CompletedWorkoutsTableControls;
