import ChartOptionsProvider from "components/charting/providers/ChartOptionsProvider";
import ChartSelectionsProvider from "components/charting/providers/ChartSelectionsProvider";
import LoadingProvider from "providers/LoadingProvider";
import ChartDataProvider from "components/charting/providers/ChartDataProvider";
import LogEventContext from "components/charting/contexts/LogEventContext";
import { useCallback } from "react";
import logEvent from "utils/logEvent";
import OneRepMaxProvider from "providers/OneRepMaxProvider";

interface ChartingProvidersProps {
  children?: React.ReactNode;
}

function ChartingProviders(props: ChartingProvidersProps) {
  const logChartEvent = useCallback(
    (properties: Record<string, string | number>) => logEvent("CHARTING_V3_VIEW_DATA", properties),
    []
  );
  return (
    <OneRepMaxProvider>
      <LoadingProvider>
        <ChartOptionsProvider>
          <ChartSelectionsProvider>
            <LogEventContext.Provider value={logChartEvent}>
              <ChartDataProvider>{props.children}</ChartDataProvider>
            </LogEventContext.Provider>
          </ChartSelectionsProvider>
        </ChartOptionsProvider>
      </LoadingProvider>
    </OneRepMaxProvider>
  );
}

export default ChartingProviders;
