import http from "../../axiosSetup";
import { firstBy } from "thenby";
import moment from "moment";

class WellnessChartService {
  /**
   * @deprecated TBC in favour of an endpoint that returns data only without metadata */
  static get(athleteId, { startDate, endDate, inAscendingOrder = true }) {
    // console.log("TODO: add function types" /* Potentially created second method that just gets measurements */);
    return http
      .post(`/measurements/athlete-wellness`, {
        athleteId,
        startDate,
        endDate,
        inAscendingOrder,
      })
      .then((response) => response.data);
  }

  static getBookends(points) {
    if (!points?.length) return {};
    const sorted = points.slice().sort(firstBy("measurementDate"));

    return {
      min: moment(sorted.slice().shift().measurementDate).startOf("day"),
      max: moment(sorted.slice().pop().measurementDate).add(1, "day").endOf("day"),
    };
  }
}
export default WellnessChartService;
