import Grid from "@mui/material/Grid";
import Tables from "components/Tables";
import { columnLabels, tableCells } from "../CompletedWorkoutsTable.config";

interface CompletedWorkoutsTableHeaderProps {}

function CompletedWorkoutsTableHeader(props: CompletedWorkoutsTableHeaderProps) {
  return (
    <Grid {...Tables.headerContainerProps} flexWrap="nowrap">
      <Grid item {...tableCells.name}>
        {columnLabels.name}
      </Grid>
      <Grid item {...tableCells.startTime} minWidth={0}>
        {columnLabels.startTime}
      </Grid>
      <Grid item {...tableCells.rpe}>
        {columnLabels.rpe}
      </Grid>
    </Grid>
  );
}

export default CompletedWorkoutsTableHeader;
