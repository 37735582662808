import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Mui5 from "library/mui5";
import { useNavigate, useLocation } from "react-router-dom";
import WorkoutHelpers from "helpers/WorkoutHelpers";
import { Workout } from "pages/Workouts/Workouts.types";
import useRole from "providers/RoleProvider/useRole";
import Tables from "components/Tables";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import allWorkoutsConfig from "pages/Workouts/AllWorkouts/AllWorkouts.config";
import { useCallback } from "react";
import useRequest from "hooks/useRequest";
import useWorkoutsActions from "pages/Workouts/providers/WorkoutsProvider/useWorkoutsActions";
import WorkoutPublishSwitch from "pages/Workouts/AllWorkouts/AllWorkoutsTable/AllWorkoutsTableRow/WorkoutPublishSwitch";

function WorkoutsOverviewHeader(props: Workout) {
  const navigate = useNavigate();
  const location = useLocation();
  const role = useRole();

  const workoutActions = useWorkoutsActions();
  const deleteWorkout = useRequest(workoutActions.delete);

  const goToEdit = useCallback(() => {
    navigate(`${location.pathname}/edit`);
  }, [location.pathname, navigate]);

  const isChangeAffectingUnkownAthletesOrGroups = props.isShared;

  const sharedWorkoutWarning = isChangeAffectingUnkownAthletesOrGroups ? (
    <Alert variant="filled" icon={false} severity="warning" sx={{ py: 0, px: 1, mx: 2, my: 0 }}>
      <AlertTitle>Warning</AlertTitle>
      {allWorkoutsConfig.delete.isShared}
    </Alert>
  ) : undefined;

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteWorkout(id);
      navigate("/workouts");
    },
    [deleteWorkout, navigate]
  );

  return (
    <Grid container spacing="0" py={0} px={0} alignItems="center">
      <Grid item xs pr={2}>
        <Typography variant="h1" gutterBottom>
          <Box component="span" fontWeight="fontWeightBold">
            {props.name}
          </Box>
        </Typography>
        <Typography variant="h4">
          {Boolean(props.description) && (
            <Box component="span" fontWeight="fontWeightLight">
              {props.description}
              {Boolean(props.duration) && (
                <Box component="span" fontSize={16} color="primary.main" px={1}>
                  &bull;
                </Box>
              )}
            </Box>
          )}
          {Boolean(props.duration) && (
            <Box component="span" fontWeight="fontWeightRegular">
              Duration {props.duration} mins
            </Box>
          )}
        </Typography>
      </Grid>
      {WorkoutHelpers.canTogglePublished(props, { role }) && (
        <Grid item pr={2} lineHeight={0}>
          <WorkoutPublishSwitch {...props} />
        </Grid>
      )}
      {WorkoutHelpers.canDelete(props, { role }) && (
        <Grid item pr={2} lineHeight={0}>
          <Tables.Components.BulkDeleteControl
            onDelete={handleDelete}
            ids={[props.id]}
            alert={sharedWorkoutWarning}
            tooltipTitle="Delete Workout"
          />
        </Grid>
      )}
      <Grid item>
        <Mui5.Button
          disabled={!WorkoutHelpers.canEdit(props, { role })}
          color="primary"
          disableElevation
          variant="contained"
          size="medium"
          onClick={goToEdit}
        >
          Edit Workout
        </Mui5.Button>
      </Grid>
    </Grid>
  );
}

export default WorkoutsOverviewHeader;
