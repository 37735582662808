import { ExerciseMeasurementData, MeasureData } from "components/charting/types/MeasureData.types";
import AthleteService from "services/AthleteService";
import MeasurementService from "services/MeasurementService";
import WellnessChartService from "services/WellnessChartService";
import { MetadataType, Wellness } from "types/index";
import { MeasureRequestParams } from "./MeasureService.types";

class MeasureService {
  static async get(options: MeasureRequestParams): Promise<MeasureData> {
    const { sourceId, measure, startDate, endDate } = options;

    if (measure.type === MetadataType.ANTHROPOMETRIC) {
      const anthropometricMeasurements: { measurementDate: string; value: number }[] = [];

      if (measure.id === "height") {
        const heights = await AthleteService.getHeights(sourceId, {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
        heights.forEach((height: { measurementDate: string; value: number }) => {
          anthropometricMeasurements.push({
            measurementDate: height.measurementDate,
            value: height.value,
          });
        });
      } else if (measure.id === "weight") {
        const weights = await AthleteService.getWeights(sourceId, {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
        weights.forEach((height: { measurementDate: string; value: number }) => {
          anthropometricMeasurements.push({
            measurementDate: height.measurementDate,
            value: height.value,
          });
        });
      }

      const anthropometricMeasureData = {
        sourceId,
        metadataType: MetadataType.ANTHROPOMETRIC as const,
        metadataId: measure.id,
        metricField: null,
        measurements: anthropometricMeasurements,
      };

      return anthropometricMeasureData;
    }

    if (measure.type === MetadataType.WELLNESS) {
      // @ts-ignore
      const wellnesses: Wellness[] = (await WellnessChartService.get(sourceId, options)).athleteMeasurements
        .measurements;

      return {
        sourceId,
        metadataType: MetadataType.WELLNESS as const,
        metadataId: measure.id,
        metricField: null,
        measurements: wellnesses,
      };
    }

    const exerciseMeasurements = await MeasurementService.getByAthleteId(sourceId, {
      exerciseId: measure.id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });

    const exerciseMeasureData: ExerciseMeasurementData = {
      sourceId,
      metadataType: MetadataType.EXERCISE as const,
      metadataId: measure.id,
      metricField: measure.metric.field,
      measurements: exerciseMeasurements,
    };

    return exerciseMeasureData;
  }
}
export default MeasureService;
