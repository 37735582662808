import Typography from "@mui/material/Typography";

interface WorkoutOverviewStepPreviewNoteSubTitleProps {
  note: string | null;
}

function WorkoutOverviewStepPreviewNoteSubTitle(props: WorkoutOverviewStepPreviewNoteSubTitleProps) {
  if (!props.note) {
    return null;
  }

  return (
    <Typography variant="body1" color="textSecondary" whiteSpace="pre-line">
      {props.note}
    </Typography>
  );
}

export default WorkoutOverviewStepPreviewNoteSubTitle;
