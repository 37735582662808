import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { alpha, styled } from "@mui/material";

export const MenuLabel = styled(ListItemText)({});

export const DrawerMenuListItem = styled(ListItem, {
  shouldForwardProp: (props: string) => props !== "hasSecondary",
})<{
  hasSecondary: boolean;
}>(({ theme, hasSecondary }) => ({
  padding: hasSecondary ? theme.spacing(0.5, 0, 0.5, 2) : theme.spacing(1.2, 0, 1.2, 2),
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: alpha(theme.palette.secondary1[60], 0.1),
    color: theme.palette.secondary1[60],
  },
}));
