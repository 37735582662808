import { styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import type { AvatarProps } from "@mui/material/Avatar";

const StyledReviewAthleteAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  fontSize: 24,
  height: theme.spacing(7),
  width: theme.spacing(7),
  marginRight: theme.spacing(2),
}));

export function ReviewAthleteAvatar(props: AvatarProps) {
  return <StyledReviewAthleteAvatar className="ph-no-capture" {...props} />;
}
