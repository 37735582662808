import React from "react";

class EventHelpers {
  static preventDefault(event: React.MouseEvent<any, MouseEvent>) {
    event.preventDefault();
  }
  static stopPropagation(
    event: React.MouseEvent<any, MouseEvent> | React.FocusEvent<any> | React.KeyboardEvent<any> | React.TouchEvent<any>
  ) {
    event.stopPropagation();
  }

  static onKeyDownDisableBackspaceDelete(event: React.KeyboardEvent<any>) {
    if (event.key === "Backspace" || event.key === "Delete") {
      event.stopPropagation();
    }
  }
}
export default EventHelpers;
