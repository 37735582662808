import { Fragment } from "react";
import { Measurement } from "types";
import MeasurementsTableRow from "./MeasurementsTableRow";

interface MeasurementsTableProps {
  measurements: Measurement[];
}

function MeasurementsTable(props: MeasurementsTableProps) {
  const { measurements } = props;

  return (
    <Fragment>
      {measurements.map((measurement) => (
        <MeasurementsTableRow key={measurement.id} measurement={measurement} />
      ))}
    </Fragment>
  );
}

export default MeasurementsTable;
