import { Athlete } from "types/athlete";
import { Group } from "contexts/GroupsContext/GroupsContext";
import intersection from "lodash/intersection";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import { useCallback, useMemo } from "react";
import { FireStoreMeasurement } from "types";

function useFilterMeasurementsByAthleteIds(athletesOrGroups: (Athlete | Group)[]) {
  const archivedAthletes = useArchivedAthletes();
  const commonAthleteMemberIds = useMemo(() => {
    const groups: Group[] = [];
    const athleteIds: string[] = [];

    athletesOrGroups.forEach((item) => {
      if ("athleteIds" in item) {
        groups.push(item);
      } else {
        athleteIds.push(item.id);
      }
    }, []);

    const groupIntersectionAthleteIds = intersection(...groups.map((group) => group.athleteIds));

    return Array.from(new Set([...athleteIds, ...groupIntersectionAthleteIds]));
  }, [athletesOrGroups]);

  const archivedAthleteIds = useMemo(() => archivedAthletes.map((athlete) => athlete.id), [archivedAthletes]);

  const excludeMeasurementsForArchivedAthleteIds = useCallback(
    (measurements: FireStoreMeasurement[]) =>
      measurements.filter(({ athleteId }) => !archivedAthleteIds.includes(athleteId)),
    [archivedAthleteIds]
  );

  const filterMeasurementsByAthleteIdsExcludingArchivedAthletes = useCallback(
    (measurements: FireStoreMeasurement[]) => {
      const showableAthleteIds = commonAthleteMemberIds.filter((athleteId) => !archivedAthleteIds.includes(athleteId));

      return measurements.filter(({ athleteId }) => showableAthleteIds.includes(athleteId));
    },
    [archivedAthleteIds, commonAthleteMemberIds]
  );

  if (archivedAthletes.length === 0 && athletesOrGroups.length === 0) {
    return undefined;
  }

  if (!athletesOrGroups.length) {
    return excludeMeasurementsForArchivedAthleteIds;
  }

  return filterMeasurementsByAthleteIdsExcludingArchivedAthletes;
}
export default useFilterMeasurementsByAthleteIds;
