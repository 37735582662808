import React from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

function DependantFormField(props) {
  const { onDependancyChange = undefined, children = null } = props;
  const initialMount = React.useRef(true);
  const formikContext = useFormikContext({ name: props.dependancyName });
  const { values, setFieldValue } = formikContext;
  const dependancyValue = values[props.dependancyName];

  React.useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }

    onDependancyChange && onDependancyChange({ setFieldValue, dependancyValue });
  }, [dependancyValue, onDependancyChange, setFieldValue]);

  if (typeof props.children === "function") {
    return props.children(formikContext);
  }

  return children;
}

DependantFormField.propTypes = {
  dependancyName: PropTypes.string.isRequired,
  onDependancyChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
};

export default DependantFormField;
