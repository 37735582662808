import { useState } from "react";
import SearchTextContext from "./SearchTextContext";
import SetSearchTextContext from "./SetSearchTextContext";

interface SearchTextProviderProps {
  children?: React.ReactNode;
}

function SearchTextProvider(props: SearchTextProviderProps) {
  const [searchText, setSearchText] = useState("");

  return (
    <SearchTextContext.Provider value={searchText}>
      <SetSearchTextContext.Provider value={setSearchText}>{props.children}</SetSearchTextContext.Provider>
    </SearchTextContext.Provider>
  );
}

export default SearchTextProvider;
