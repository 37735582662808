import { styled } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";

const ToggleButton = styled(MuiToggleButton)(({ theme, color }) => ({
  textTransform: "capitalize",
  fontSize: theme.typography.h4.fontSize,
  boxSizing: "border-box",
  lineHeight: 1.1,
  "&.Mui-selected": {
    backgroundColor: color && color !== "standard" && theme.palette[color].main,
    color: color && color !== "standard" && theme.palette[color].contrastText,
    "&:hover": {
      backgroundColor: color && color !== "standard" && theme.palette[color].dark,
    },
  },
}));
export default ToggleButton;
