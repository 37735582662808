import { forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";

function useLink<El extends HTMLAnchorElement>() {
  const renderLink = useCallback(
    (to: string) => forwardRef<El>((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    []
  );

  return renderLink;
}
export default useLink;
