import { CanDoActions, CanDoRemoteConfig } from "types/CanDoActions";
import { Organisation, OrganisationRole } from "types/organisation";

const Role = OrganisationRole;

class CanDoService {
  static rolesForActions = Object.freeze({
    // Athletes
    "athlete.bulkUpload": [Role.OWNER, Role.COACH],
    "athlete.delete": [Role.OWNER],
    "athlete.archive": [Role.OWNER, Role.COACH],

    // Coaches
    "coach.any": [Role.OWNER],

    // Dashboards
    "dashboard.any": [Role.OWNER, Role.COACH],

    // Export Data
    "export.any": [Role.OWNER, Role.COACH],

    // Groups
    "group.any": [Role.OWNER],
    "group.create": [Role.OWNER],
    "group.assign": [Role.OWNER, Role.COACH],
    "group.view": [Role.OWNER, Role.COACH],

    // Leaderboards
    "leaderboard.create": [Role.OWNER, Role.COACH],
    "leaderboard.view": [Role.OWNER, Role.COACH],
    "leaderboard.update": [Role.OWNER, Role.COACH],
    "leaderboard.delete": [Role.OWNER, Role.COACH],

    // Exercise Library
    "library.any": [Role.OWNER, Role.COACH],
    "library.archive": [Role.OWNER],

    // Organisation
    "organisation.any": [Role.OWNER],

    // Reports
    "report.any": [Role.OWNER],

    // Subscriptions
    "subscription.any": [Role.OWNER],

    // Workouts
    "workout.any": [Role.OWNER, Role.COACH],
  });

  static check(
    action: CanDoActions,
    options: {
      role: OrganisationRole | undefined;
      organisation?: Organisation;
      remoteConfig?: CanDoRemoteConfig;
    }
  ) {
    const { role, organisation, remoteConfig } = options;
    return (
      CanDoService.checkRole(action, role) && CanDoService.checkOrganisation(action, { organisation, remoteConfig })
    );
  }

  private static checkRole(action: CanDoActions, role: OrganisationRole | undefined) {
    if (typeof role === "undefined") return false;

    const allowedRolesForAction = CanDoService.rolesForActions[action];

    if (typeof allowedRolesForAction === "undefined") {
      throw new Error(`The action you are checking permission for does not exist: ${action}`);
    }

    return allowedRolesForAction.includes(role);
  }

  private static checkOrganisation(
    action: CanDoActions,
    options: { organisation?: Organisation; remoteConfig?: CanDoRemoteConfig }
  ) {
    const { organisation } = options;

    switch (action) {
      case "dashboard.any":
        return options.remoteConfig?.HUB_DASHBOARDS_ENABLED || organisation?.featureConfig?.isDashboardsEnabled;
      case "library.any":
      case "library.archive":
        return Boolean(organisation?.featureConfig?.customExercisesEnabled);
      case "group.any":
      case "group.assign":
      case "group.create":
      case "group.view":
        return Boolean(organisation?.featureConfig.groupsEnabled);
      case "workout.any":
        return Boolean(organisation?.featureConfig.isWorkoutsEnabled);
      default:
        return true;
    }
  }
}

export default CanDoService;
