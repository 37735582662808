import { lighten, styled } from "@mui/material";

export const Container = styled("div")((props) => ({
  position: "absolute",
  backgroundColor: lighten(props.theme.palette.secondary.main, 0.9),
  zIndex: props.theme.zIndex.modal,
  padding: props.theme.spacing(2),
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  "& .MuiInputBase-input": {
    fontSize: props.theme.spacing(2),
  },
  "& .MuiAvatar-root": {
    fontSize: props.theme.spacing(2),
    height: props.theme.spacing(5),
    width: props.theme.spacing(5),
  },
}));
