var NUMBER_GROUPS = /(-?\d*\.?\d+)/g;

export default function naturalCompare(a: string, b: string, descending?: boolean) {
  var aa = String(a).split(NUMBER_GROUPS),
    bb = String(b).split(NUMBER_GROUPS),
    min = Math.min(aa.length, bb.length);

  for (var i = 0; i < min; i++) {
    var x = aa[i].toLowerCase(),
      y = bb[i].toLowerCase();
    if (x < y) return -1;
    if (x > y) return 1;
    i++;
    if (i >= min) break;
    var z = parseFloat(aa[i]) - parseFloat(bb[i]);
    if (z !== 0) return z;
  }

  return aa.length - bb.length;
}
