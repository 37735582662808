import AthleteTable from "components/AthleteHub/AthleteTable";
import { LocalisedLoadingContext } from "components/LocalisedLoadingProvider";
import { Athlete } from "types/athlete";
import AthleteStoreContext from "providers/AthletesProvider/contexts/AthleteStoreContext";
import AthleteMonitoringFetchWrapper from "library/AthleteMonitoringFetchWrapper";
import { useState } from "react";
import GroupAthleteTableControls from "./GroupAthleteTableControls";

interface GroupAthleteTableProps {
  athletes: Athlete[];
}

function GroupAthleteTable(props: GroupAthleteTableProps) {
  const loadingState = useState(false);

  return (
    <LocalisedLoadingContext.Provider value={loadingState}>
      <AthleteMonitoringFetchWrapper athletes={props.athletes}>
        <AthleteStoreContext.Provider value={props.athletes}>
          <AthleteTable TableControlsComponent={GroupAthleteTableControls} />
        </AthleteStoreContext.Provider>
      </AthleteMonitoringFetchWrapper>
    </LocalisedLoadingContext.Provider>
  );
}

export default GroupAthleteTable;
