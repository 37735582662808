import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import SettingsAccountUsageInfo from "./SettingsAccountUsageInfo";
import { GridBorderBottomGrey } from "../Settings.components";
import SettingsSubscriptionManagement from "./SettingsSubscriptionManagement";
import CanDoAction from "components/CanDoAction";

interface SettingsAccountUsageProps {}

function SettingsAccountUsage(props: SettingsAccountUsageProps) {
  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <Box pb={4}>
              <Typography variant="h1">
                <Box component="span" fontWeight="fontWeightMedium">
                  Subscription
                </Box>
              </Typography>
            </Box>
            <SettingsAccountUsageInfo />
          </Box>
        </GridBorderBottomGrey>
        <CanDoAction action="subscription.any">
          <SettingsSubscriptionManagement />
        </CanDoAction>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsAccountUsage;
