import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.93331" y="1.93333" width="12.1333" height="12.1333" rx="2.4" stroke="currentColor" strokeWidth="1.2" />
      <path
        d="M11.4433 6L8.22917 9.21417L6.5375 7.5225L4 10.06"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.41333 6H11.4433V8.03"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.8" y="2.8" width="18.4" height="18.4" rx="3.2" stroke="currentColor" strokeWidth="1.6" />
      <path
        d="M17.165 9L12.3437 13.8212L9.80625 11.2837L6 15.09"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1199 9H17.1649V12.045"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.66669" y="3.66667" width="24.6667" height="24.6667" rx="3" stroke="currentColor" strokeWidth="2" />
      <path
        d="M22.8867 12L16.4584 18.4283L13.0751 15.045L8.00006 20.12"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8267 12H22.8867V16.06"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function DashboardIcon(props: SvgIconProps) {
  const item = props.fontSize === "large" || props.fontSize === "small" ? size[props.fontSize] : size.default;

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default DashboardIcon;
