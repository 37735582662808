import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import useAllAthletes from "hooks/useAllAthletes";
import { useMemo, useRef } from "react";
import { FireStoreMeasurement } from "types";

/**
 * @description
 * Filters out measurements of athletes that don't exist in the athlete store
 * It makes a get request for the athlete (by athleteId) once only.
 *
 * The use case is for direct to Firestore measurement queries which
 * return all measurements.
 */
function useAllowedAthleteMeasurementFilter(measurements: FireStoreMeasurement[]) {
  const athletes = useAllAthletes();
  const { fetchById } = useAthleteActions();
  const athleteRequestRef = useRef<{ [athleteId: string]: boolean | undefined }>({});
  const allowedAthleteIds = useMemo(() => athletes.map((athlete) => athlete.id), [athletes]);

  return useMemo(
    () =>
      measurements.filter((measurement) => {
        const isAthleteAllowed: boolean = allowedAthleteIds.includes(measurement.athleteId);

        const athleteIdAlreadyFetched = athleteRequestRef.current[measurement.athleteId];

        if (!isAthleteAllowed && !athleteIdAlreadyFetched) {
          try {
            athleteRequestRef.current[measurement.athleteId] = true;

            fetchById(measurement.athleteId);
          } catch (_) {
            // Ignore 404 Athlete Not Found Error
            // as Coach has no access
          }
        }

        return isAthleteAllowed;
      }),
    [allowedAthleteIds, fetchById, measurements]
  );
}

export default useAllowedAthleteMeasurementFilter;
