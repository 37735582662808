import { ButtonGroupProps, ButtonProps } from "@mui/material";
import * as Components from "./GroupedOptionButtons.components";
import ButtonGroup from "@mui/material/ButtonGroup";

interface GroupedOptionButtonsProps<V = string> {
  options: {
    label: string;
    "aria-label"?: string;
    value: V;
  }[];
  value: V;
  ButtonGroupProps?: ButtonGroupProps;
  ButtonProps?: ButtonProps;
  onClick(value: V): void;
}

function GroupedOptionButtons<V>(props: GroupedOptionButtonsProps<V>) {
  const getSelectedProps = (selected: boolean) =>
    selected
      ? {
          color: "primary" as const,
          variant: "contained" as const,
        }
      : undefined;

  return (
    <ButtonGroup disableElevation variant="outlined" {...props.ButtonGroupProps}>
      {props.options.map(({ value, label, ...restOfOption }) => (
        <Components.GroupedButton
          key={label}
          selected={props.value === value}
          onClick={() => props.onClick(value)}
          {...getSelectedProps(props.value === value)}
          {...props.ButtonProps}
          {...restOfOption}
        >
          {label}
        </Components.GroupedButton>
      ))}
    </ButtonGroup>
  );
}

export default GroupedOptionButtons;
