import React from "react";
import SpeedDialMenu from "../../library/SpeedDialMenu";
import useQuickCreateActions from "./useQuickCreateActions";

function FloatingActionMenu(props) {
  const [open, setOpen] = React.useState(false);
  const quickCreateActions = useQuickCreateActions();

  return (
    <SpeedDialMenu
      handleClose={React.useCallback(() => setOpen(false), [])}
      handleOpen={React.useCallback(() => setOpen(true), [])}
      open={open}
      actions={quickCreateActions}
    />
  );
}
export default FloatingActionMenu;
