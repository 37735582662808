import * as Yup from "yup";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import i18n from "config/i18n";

type AthleteProfile1RMEditFormValues = {
  selectedOneRepMaxes: FormOneRepMax[];
  imperialSystemEnabled: boolean;
};

const errorMessages = {
  weight: {
    imperial: {
      positive: i18n.t("AthleteProfile1RMEdit.formWeightErrorPositive", { context: "imperial" }),
      max: i18n.t("AthleteProfile1RMEdit.formWeightErrorMax", { context: "imperial" }),
    },
    metric: {
      positive: i18n.t("AthleteProfile1RMEdit.formWeightErrorPositive", { context: "metric" }),
      max: i18n.t("AthleteProfile1RMEdit.formWeightErrorMax", { context: "metric" }),
    },
  },
};

export const generateAthleteProfile1RMEditSchema = (imperialSystemEnabled: boolean) => {
  const yupWWeightNumber = imperialSystemEnabled
    ? Yup.number()
        .transform((v) => (Number.isNaN(v) ? null : v))
        .positive(errorMessages.weight.imperial.positive)
        .max(1350, errorMessages.weight.imperial.max)
        .nullable()
    : Yup.number()
        .transform((v) => (Number.isNaN(v) ? null : v))
        .positive(errorMessages.weight.metric.positive)
        .max(600, errorMessages.weight.metric.max)
        .nullable();

  return Yup.object<AthleteProfile1RMEditFormValues>().shape({
    selectedOneRepMaxes: Yup.array().of(
      Yup.object().shape({
        oneRepMaxes: Yup.array().of(
          Yup.object().shape({
            weight: yupWWeightNumber,
          })
        ),
      })
    ),
  });
};
