import i18n from "config/i18n";
import http from "../../axiosSetup";
import moment from "moment";
import WeightHelpers from "helpers/WeightHelpers";

class SetComparisonChartService {
  /**
   * @param {string} athleteId
   * @param {{ exerciseId: string }} options
   * @returns {Promise<{
   *   completedDate: string;
   *   measurementId: string;
   *   repetitionCount: number;
   *   setNumber: number;
   *   variant: null | "Left" | "Right";
   *   weightValue: number;
   * }[]>}
   */
  static getSets(athleteId, options) {
    return http
      .post(`/measurements/athlete-exercise/series`, {
        athleteId,
        exerciseId: options.exerciseId,
      })
      .then((response) => response.data);
  }

  static makeLabel(set, isImperial) {
    const weight = WeightHelpers.toReadable(set.weightValue, isImperial);

    return [
      i18n.t("SetSelector.setLegendSet", { set: set.setNumber }),
      i18n.t("SetSelector.setLegendReps", { count: set.repetitionCount, variant: set.variant }),
      i18n.t("SetSelector.setLegendWeight", { weight }),
      moment(set.completedDate).format(i18n.t("Charting.setLegendDateFormat")),
    ]
      .filter(Boolean)
      .join(" / ");
  }
}
export default SetComparisonChartService;
