import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "hooks";
import useLink from "hooks/useLink";
import * as Components from "./SettingsMenu.components";
import useCanDo from "hooks/useCanDo";
import { useEffect, useMemo } from "react";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";

interface SettingsMenuProps {
  children: React.ReactNode;
}

function SettingsMenu(props: SettingsMenuProps) {
  const [, { onSignOut }] = useUserContext();
  const navigate = useNavigate();
  const renderLink = useLink();
  const canDo = useCanDo();
  // TODO: (0S-4710) Replace with AccountInfoProvider
  const organisation = useOrganisation();
  const organisationActions = useOrganisationActions();

  useEffect(() => {
    organisationActions.get();
  }, [organisationActions]);

  const coachesEnabled = Boolean(organisation.accountInfo.coachLimit);
  // END TODO

  const items: {
    label: string;
    component?: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>>;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
  }[] = useMemo(
    () =>
      [
        { label: "Account", component: renderLink("") },
        canDo("athlete.archive") && { label: "Archived Athletes", component: renderLink("athlete-archive") },
        { label: "Athlete Monitoring", component: renderLink("athlete-monitoring") },
        canDo("group.any") &&
          canDo("coach.any") &&
          coachesEnabled && { label: "Coaches", component: renderLink("coaches") },
        { label: "Preferences", component: renderLink("preferences") },
        canDo("subscription.any") && { label: "Subscription", component: renderLink("subscription") },
        { label: "", disabled: true },
        {
          label: "Sign Out",
          onClick: async function handleSignout() {
            await onSignOut();
            navigate("/", { replace: true });
          },
        },
      ].filter(Boolean) as {
        label: string;
        component?: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>>;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
      }[],
    [canDo, navigate, renderLink, onSignOut, coachesEnabled]
  );

  return (
    <Grid container minHeight="100%">
      <Components.VerticalDividerGrid item xs={3} minHeight="100%">
        <Box p={3} pb={0}>
          <Typography variant="h1" gutterBottom>
            Settings
          </Typography>
        </Box>
        <List>
          {items.map(({ label, ...rest }) => (
            <Components.SettingsListItemButton key={label} {...rest}>
              <ListItemText
                primary={
                  <Typography variant="h3" gutterBottom={false}>
                    <Box component="span" fontWeight="fontWeightLight">
                      {label}
                    </Box>
                  </Typography>
                }
              />
            </Components.SettingsListItemButton>
          ))}
        </List>
      </Components.VerticalDividerGrid>
      <Grid item xs={9}>
        {props.children}
      </Grid>
    </Grid>
  );
}

export default SettingsMenu;
