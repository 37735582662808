import { styled, tooltipClasses } from "@mui/material";
import Tooltip from "components/Tooltip";

export const ComparativeProfilingChartTooltip = styled(Tooltip)({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    minWidth: 0,
    maxWidth: 320,
  },
});

export const ColorBlock = styled("div")<{ color: string }>(({ color, theme }) => ({
  width: theme.spacing(2),
  backgroundColor: color,
  height: theme.spacing(2),
}));

export const ColorTab = styled("div")<{ color: string }>(({ color, theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  width: theme.spacing(1),
  backgroundColor: color,
}));
