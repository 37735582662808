import { useMemo } from "react";

interface IntersectingGroupIdsEntity {
  groupIds: string[];
}

function useIntersectingGroupIds<T extends IntersectingGroupIdsEntity>(items: T[]) {
  return useMemo(() => {
    const groupIds = items.map((workout) => workout.groupIds);
    const commonGroupIds = groupIds.reduce((acc, curr) => acc.filter((id) => curr.includes(id)), groupIds[0] || []);

    return commonGroupIds;
  }, [items]);
}

export default useIntersectingGroupIds;
