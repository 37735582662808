import { styled } from "@mui/material";
import Paper, { paperClasses } from "library/mui5/Paper";
import InputBase from "@mui/material/InputBase";

export const InputContainerPaper = styled(Paper)(({ theme }) => ({
  width: 320,
  height: theme.spacing(6),
  marginRight: theme.spacing(2),
  [`&.${paperClasses.rounded}`]: {
    borderRadius: theme.spacing(4),
  },
}));

export const PaddedInputBase = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1.25, 2, 1, 3),
}));
