import * as Components from "./Tables.components";
import config from "./Table.config";
import SearchTextInput from "./TablesSearchTextInput";
import TableEmpty from "./TableEmpty";
import BulkDeleteControl from "./BulkDeleteControl";
import BulkArchiveControl from "./BulkArchiveControl";
import TableFiltersPopperContent from "./TableFiltersPopperContent";
import TableGroupAssignPopperContent from "./TableGroupAssignPopperContent";
import TableHeaderSortButton from "./TableHeaderSortButton";

const Tables = {
  Components: {
    ...Components,
    BulkDeleteControl,
    BulkArchiveControl,
    SearchTextInput,
    Skeleton: TableEmpty,
    TableFiltersPopperContent,
    SortButton: TableHeaderSortButton,
    GroupAssignPopperContent: TableGroupAssignPopperContent,
  },
  ...config,
};
export default Tables;
