import { useMemo } from "react";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";

interface UseAllAthletesProps {}

function useAllAthletes(props?: UseAllAthletesProps) {
  const activeAthletes = useAthleteStore();
  const archivedAthletes = useArchivedAthletes();

  return useMemo(() => [...activeAthletes, ...archivedAthletes], [activeAthletes, archivedAthletes]);
}

export default useAllAthletes;
