import MessageCard from "components/MessageCard";
import { ImportantText } from "./SetComparisonChartNoData.components";

interface SetComparisonChartNoDataProps {}

function SetComparisonChartNoData(props: SetComparisonChartNoDataProps) {
  return (
    <MessageCard title="Set Comparison">
      No data to display. Please check you have selected a <ImportantText>Rep Metric</ImportantText> and the{" "}
      <ImportantText>Sets</ImportantText> you want to plot.
    </MessageCard>
  );
}

export default SetComparisonChartNoData;
