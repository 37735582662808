import IconPopperMenuSearchInput from "library/IconPopperMenu/IconPopperMenuSearchInput";
import Grid from "@mui/material/Grid";
import useGroups from "providers/GroupsProvider/useGroups";
import { useCallback, useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useAutocomplete, { AutocompleteChangeReason } from "@mui/material/useAutocomplete";
import { Group } from "types/groups";
import IconPopperMenuListbox from "library/IconPopperMenu/IconPopperMenuListbox";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";

interface TableGroupAssignPopperContentProps {
  commonGroups: Group[];
  onChange(newGroups: Group[], reason?: AutocompleteChangeReason): void;
}

function TableGroupAssignPopperContent(props: TableGroupAssignPopperContentProps) {
  const { onChange } = props;
  const [inputValue, setInputValue] = useState("");
  const groups = useGroups();

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: Group[], reason: AutocompleteChangeReason) => {
      onChange(value, reason);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent<Element>, value: string) => {
      setInputValue(value);
    },
    [setInputValue]
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: "group-assign-autocomplete",
    options: groups,
    value: props.commonGroups,
    getOptionLabel: (option) => option.name,
    open: true,
    onChange: handleChange,
    multiple: true,
    disableClearable: true,
    inputValue,
    onInputChange: handleInputChange,
    clearOnBlur: false,
  });

  const inputProps = getInputProps();
  const listboxProps = getListboxProps();
  const { t } = useTranslation();

  return (
    <Grid container {...getRootProps()} flexDirection="column" flexWrap="nowrap" height="100%">
      <Grid item width="100%" height="62px">
        <IconPopperMenuSearchInput inputProps={inputProps} placeholder={t("Athlete.bulkGroupAssignPlaceholderText")} />
        <Divider />
      </Grid>
      <Grid
        item
        width="100%"
        position="relative"
        height={groupedOptions.length * 37} // 37 === AutocompleteOptionColourable height
        minHeight={43} // 43 === "No results found" height
        maxHeight={320}
      >
        <IconPopperMenuListbox {...listboxProps}>
          {groupedOptions.length > 0 ? (
            (groupedOptions as Group[]).map((option, index) => {
              const selected = props.commonGroups.includes(option);

              return (
                <AutocompleteOptionColourable
                  {...getOptionProps({ option, index })}
                  key={option.id}
                  colour={option.colour}
                >
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    {selected ? (
                      <CheckBoxIcon htmlColor={option.colour} />
                    ) : (
                      <CheckBoxOutlineBlankIcon htmlColor={option.colour} />
                    )}
                  </ListItemIcon>
                  {option.name}
                </AutocompleteOptionColourable>
              );
            })
          ) : (
            <Box p={1.25} pl={3} pb={0} color="grey.600">
              {t("noResultsFoundText")}
            </Box>
          )}
        </IconPopperMenuListbox>
      </Grid>
    </Grid>
  );
}

export default TableGroupAssignPopperContent;
