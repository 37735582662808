import useDefinedContext from "hooks/useDefinedContext";
import SetTableSortContext from "../SetTableSortContext";

interface useSetTableSortProps {}

function useSetTableSort(props?: useSetTableSortProps) {
  return useDefinedContext(SetTableSortContext);
}

export default useSetTableSort;
