import AthleteKpiModuleForm from "./Forms/AthleteKpiModuleForm";
import moment from "moment";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function AthleteMetrics(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add a Athlete KPI module" close={closeModal}>
      <AthleteKpiModuleForm
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={{
          ...(moduleToEdit || {}),
          startDate: moduleToEdit?.startDate ? moment(moduleToEdit?.startDate).toISOString() : null,
          endDate: moduleToEdit?.endDate ? moment(moduleToEdit?.endDate).toISOString() : null,
        }}
      />
    </ModuleModalWrapper>
  );
}
export default AthleteMetrics;
