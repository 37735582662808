import useDefinedContext from "hooks/useDefinedContext";
import ArchivedCustomExercisesContext from "../ArchivedCustomExercisesContext";
import { useMemo } from "react";
import { CustomExercise } from "types/custom-exercise";

function useArchivedCustomExercises(): CustomExercise[];
function useArchivedCustomExercises(onlyIds: true): string[];
function useArchivedCustomExercises(onlyIds?: boolean): CustomExercise[] | string[] {
  const archivedCustomExercises = useDefinedContext(ArchivedCustomExercisesContext);

  return useMemo(() => {
    if (onlyIds) {
      return archivedCustomExercises.map((exercise) => exercise.id);
    }

    return archivedCustomExercises;
  }, [archivedCustomExercises, onlyIds]);
}

export default useArchivedCustomExercises;
