import * as Yup from "yup";

export const errors = {
  email: "Please use a valid email address",
  password: "Please enter your password",
};

export const schema = Yup.object().shape({
  email: Yup.string().email(errors.email).required(errors.email),
  password: Yup.string().max(50, "Too Long").min(6, "Too Short").required(errors.password),
});
