import { styled } from "@mui/material";
import Button from "@mui/material/Button";

export const ScrollableTable = styled("div")({
  position: "relative",
});

export const ShowMoreButton = styled(Button)({
  textTransform: "none",
  fontWeight: "400",
});
