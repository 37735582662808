import MuiModal from "@mui/material/Modal";
import * as Components from "./Modal.components";

interface ModalProps {
  open: boolean;
  toggleOpen(): void;
  children?: React.ReactNode;
}

function Modal(props: ModalProps) {
  return (
    <MuiModal open={props.open} onClose={props.toggleOpen} disableRestoreFocus>
      <Components.Container>{props.children}</Components.Container>
    </MuiModal>
  );
}

export default Modal;
