import { Athlete } from "types/athlete";
import CsvHelpers from "helpers/CsvHelpers/CsvHelpers";
import { CsvColumn } from "helpers/CsvHelpers/CsvHelpers.types";
import uniqueId from "lodash/uniqueId";
import moment from "moment";
import { AthleteBase } from "types/athlete";

class AthleteHelpers {
  static getFullName(athlete: Pick<Athlete, "firstName" | "lastName">) {
    return [athlete.firstName, athlete.lastName].filter(Boolean).join(" ");
  }

  static getProfileImagePath(athlete?: Pick<Athlete, "id" | "profilePhotoName">) {
    return athlete?.profilePhotoName && `athletes/${athlete.id}/${athlete.profilePhotoName}`;
  }

  static getInitials(athlete: Pick<Athlete, "firstName" | "lastName">) {
    return AthleteHelpers.getFullName(athlete)
      .split(" ")
      .slice(0, 2)
      .map((name) => name[0])
      .join("");
  }

  static getColumns(options?: {
    imperialSystemEnabled?: boolean;
    canAccessGroups?: boolean;
  }): CsvColumn<Partial<Record<keyof AthleteBase, string>>>[] {
    const { imperialSystemEnabled = false, canAccessGroups } = options || {};

    const csvColumns: CsvColumn<Partial<Record<keyof AthleteBase, string>>>[] = [
      {
        label: "First Name",
        key: "firstName",
      },
      {
        label: "Last Name",
        key: "lastName",
      },
      {
        label: "External ID",
        key: "externalId",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Sex (Male/Female/Prefer Not Say/[or leave field blank])",
        key: "sex",
      },
      {
        label: "Date of Birth (DD/MM/YYYY)",
        key: "dateOfBirth",
      },
      {
        label: `Height ${imperialSystemEnabled ? `(ft'in")` : "(cm)"}`,
        key: "height",
      },
      {
        label: `Weight ${imperialSystemEnabled ? "(lbs)" : "(kg)"}`,
        key: "weight",
      },
    ];

    if (canAccessGroups) {
      const groupColumn: CsvColumn<Partial<Record<keyof AthleteBase, string>>> = {
        label: "Groups",
        key: "groupIds",
      };
      csvColumns.push(groupColumn);
    }

    return csvColumns;
  }

  static generateBulkUploadCsvURI(options?: { imperialSystemEnabled?: boolean; canAccessGroups?: boolean }) {
    const { imperialSystemEnabled = false, canAccessGroups } = options || {};

    const columns = AthleteHelpers.getColumns({ imperialSystemEnabled, canAccessGroups });

    const exampleAthlete: Partial<Record<keyof AthleteBase, string>> = {
      firstName: "John",
      lastName: "Doe",
      externalId: "optional_external_id_1000",
      email: "john.doe@example.com",
      sex: "",
      dateOfBirth: "31/12/1990",
      height: `${imperialSystemEnabled ? `5'11"` : "182"}`,
      weight: `${imperialSystemEnabled ? "190" : "86"}`,
    };

    if (canAccessGroups) {
      exampleAthlete.groupIds = "Group Name 1;Group Name 2 ; Group Name 3 ; Group Name 4";
    }

    return CsvHelpers.buildURI(columns, [exampleAthlete]);
  }

  static async csvFileToJson(csvData: File, options?: { imperialSystemEnabled?: boolean; canAccessGroups?: boolean }) {
    const columns = AthleteHelpers.getColumns(options);

    const arrayOfArrays: string[][] = await CsvHelpers.readFileAsText(csvData);

    const csvHeaders = arrayOfArrays.shift();

    // validateHeadings
    csvHeaders!.forEach((header, index) => {
      if (header.replace(/"/g, "") !== columns[index].label.replace(/"/g, "")) {
        throw new Error("column-heading-mismatch");
      }
    });

    const propertyKeys = columns.map((column) => column.key);

    const baseAthletes = arrayOfArrays
      .filter((row) => row.filter(Boolean).length > 0) // filters out rows with all empty cells
      .map((row) => {
        const stringAthlete: Record<keyof AthleteBase, string> = propertyKeys.reduce(
          (acc, key, index) => ({ ...acc, [key]: row[index] }),
          {
            id: "",
            firstName: "",
            lastName: "",
            externalId: "",
            email: "",
            sex: "",
            dateOfBirth: "",
            height: "",
            weight: "",
            groupIds: "",
          }
        );

        const baseAthlete: AthleteBase = {
          id: uniqueId(),
          firstName: stringAthlete.firstName?.trim(),
          lastName: stringAthlete.lastName?.trim(),
          externalId: stringAthlete.externalId?.trim() || null,
          email: stringAthlete.email?.trim().toLowerCase() || null,
          sex:
            (stringAthlete.sex &&
              { male: "Male", female: "Female" }[stringAthlete.sex?.trim().toLowerCase() as "male" | "female"]) ||
            null,
          dateOfBirth: stringAthlete.dateOfBirth?.trim()
            ? moment(stringAthlete.dateOfBirth?.trim(), "DD/MM/YYYY").toISOString()
            : null,
          height:
            Number(stringAthlete.height) ||
            (stringAthlete.height.trim()
              ? stringAthlete.height?.replace(/\s/g, "").replace(/"/g, "") + `"` // "5'11""" => 5'11"
              : null),
          weight: Number(stringAthlete.weight) || null,
          groupIds: stringAthlete.groupIds
            .split(";")
            .map((groupName) => groupName.trim())
            .filter(Boolean),
        };

        return baseAthlete;
      });

    return baseAthletes;
  }
}
export default AthleteHelpers;
