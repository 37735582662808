import CompletedWorkoutsContext from "pages/Workouts/contexts/CompletedWorkoutsContext";
import CompletedWorkoutService from "pages/Workouts/services/CompletedWorkoutService";
import {
  CompletedWorkoutGetAllQueryParams,
  CompletedWorkoutsContextType,
  CompletedWorkoutsStore,
} from "pages/Workouts/Workouts.types";
import { useState, useMemo } from "react";

interface CompletedWorkoutsProviderProps {
  children?: React.ReactNode;
}

function CompletedWorkoutsProvider(props: CompletedWorkoutsProviderProps) {
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkoutsStore>({});

  const completedWorkoutActions = useMemo(
    () => ({
      getAll: async (params: CompletedWorkoutGetAllQueryParams) => {
        const result = await CompletedWorkoutService.getAll(params);

        if (typeof params.workoutId === "string") {
          setCompletedWorkouts((prev) =>
            result.reduce(
              (prevCompletedWorkouts, completedWorkout) => ({
                ...prevCompletedWorkouts,
                [completedWorkout.id]: completedWorkout,
              }),
              prev
            )
          );
        }

        return result;
      },
      getById: async (id: string) => {
        const completedWorkout = await CompletedWorkoutService.getById(id);

        setCompletedWorkouts((prev) => {
          return { ...prev, [completedWorkout.id]: completedWorkout };
        });

        return completedWorkout;
      },
    }),
    []
  );

  const contextValue = useMemo<CompletedWorkoutsContextType>(
    () => [completedWorkouts, completedWorkoutActions],
    [completedWorkoutActions, completedWorkouts]
  );
  return <CompletedWorkoutsContext.Provider value={contextValue}>{props.children}</CompletedWorkoutsContext.Provider>;
}

export default CompletedWorkoutsProvider;
