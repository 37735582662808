import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router";
import useGetWorkoutById from "../hooks/useGetWorkoutById";
import WorkoutOverviewHeader from "./WorkoutOverviewHeader";
import PageContent from "shared/components/PageContent";
import CompletedWorkoutsTable from "./CompletedWorkoutsTable";
import WorkoutOverviewControls from "./WorkoutOverviewAssignments";
import SearchTextProvider from "providers/SearchTextProvider/SearchTextProvider";
import { useEffect, useMemo, useRef } from "react";
import { MultiSelectContext } from "providers/MultiSelectProvider";
import WorkoutOverviewPreview from "./WorkoutOverviewPreview";
import logEvent from "utils/logEvent";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";

interface WorkoutOverviewProps {}

function WorkoutOverview(props: WorkoutOverviewProps) {
  const routeMatch = useParams<"workoutId">();
  const workout = useGetWorkoutById(`${routeMatch.workoutId}`);
  const didExistRef = useRef(false);
  const navigate = useNavigate();

  /**
   * @property {workoutIdInSelectedArray}
   * An array with only the current workout ID as a member
   * Forces this workout to always be selected so controls work
   * for this Workout only.
   */
  const workoutIdInSelectedArray = useMemo(
    () => [routeMatch.workoutId].filter(Boolean),
    [routeMatch.workoutId]
  ) as string[];

  useEffect(() => {
    /**
     * @denski
     * Handles workout removal from the store
     * when last group is unassigned */
    if (workout) {
      // Workout existed in store
      didExistRef.current = true;
    } else if (didExistRef.current && !workout) {
      // Workout was removed from store
      navigate("/workouts");
    }
  }, [navigate, workout]);

  useEffect(() => {
    logEvent("WORKOUTS_OVERVIEW_VIEW");
  }, []);

  if (!workout) return null;

  return (
    <MultiSelectContext.Provider value={workoutIdInSelectedArray}>
      <Stack spacing={2} height="100%">
        <Box p={4} pr={2} pb={0}>
          <PageContent pageHeading="Workouts" pageHeadingLink="/workouts" enableFAB={false} />
        </Box>
        <Box p={4} pt={0} pb={0}>
          <WorkoutOverviewHeader {...workout} />
          <Box pt={2} />
          <WorkoutOverviewControls {...workout} />
        </Box>
        <Grid
          container
          flex={1}
          justifyContent="space-between"
          alignItems="stretch"
          flexWrap="nowrap"
          pb={3}
          px={4}
          overflow="hidden"
        >
          <Grid item xs height="100%">
            <Stack height="100%">
              <SearchTextProvider>
                <CompletedWorkoutsTable workoutId={workout.id} />
              </SearchTextProvider>
            </Stack>
          </Grid>
          <Grid item width="16px" minHeight={0} />
          <Grid item xs minHeight={0}>
            <Stack height="100%">
              <WorkoutOverviewPreview workout={workout} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </MultiSelectContext.Provider>
  );
}

export default WorkoutOverview;
