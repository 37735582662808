import ChartTooltipCardContext from "components/charting/contexts/ChartTooltipCardContext";

interface ChartTooltipProviderProps<T> {
  children?: React.ReactNode;
  TooltipCard(props: T): JSX.Element;
}

function ChartTooltipCardProvider<T>(props: ChartTooltipProviderProps<T>) {
  return (
    <ChartTooltipCardContext.Provider value={props.TooltipCard}>{props.children}</ChartTooltipCardContext.Provider>
  );
}

export default ChartTooltipCardProvider;
