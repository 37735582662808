import AthleteMonitoringStatusesContext from "contexts/AthleteMonitoringStatusesContext";
import {
  AthleteMonitoringStatusesContextType,
  AthleteMonitoringStatusesContextValue,
} from "contexts/AthleteMonitoringStatusesContext/AthleteMonitoringStatusesContext";
import usePreferences from "hooks/usePreferences";
import { useCallback, useEffect, useMemo, useState } from "react";
import AthletesService from "services/AthleteService";
import * as Helpers from "./AthleteMonitoringStatusesProvider.helpers";

interface AthleteMonitoringStatusesProviderProps {
  children?: React.ReactNode;
}

function AthleteMonitoringStatusesProvider(props: AthleteMonitoringStatusesProviderProps) {
  const [ragStatuses, setRagStatuses] = useState<AthleteMonitoringStatusesContextValue>({});
  const [preferences] = usePreferences();
  const [ragTemplateOverrideId, setRagTemplateOverrideId] = useState<string | null>(null);

  useEffect(() => {
    /**
     * @author Carden
     * When default template id is updated
     * reset override to null.
     *
     * The `setState` call doesn't cause a
     * re-render on first render because
     * initial value of `ragTemplateOverrideId`
     * remains the same `null` value.
     */
    setRagTemplateOverrideId(null);
  }, [preferences.defaultRagStatusTemplateId]);

  const getTemplateByAthleteIds = useCallback(
    async (
      athleteIds: string[],
      templateId: string | null = ragTemplateOverrideId || preferences.defaultRagStatusTemplateId
    ) => {
      if (templateId === null) {
        return;
      }

      const result = await AthletesService.getTemplateByAthleteIds(templateId, athleteIds);

      setRagStatuses((prevRagStatuses) => Helpers.updateRagStatusStore(prevRagStatuses, result));
    },
    [preferences.defaultRagStatusTemplateId, ragTemplateOverrideId]
  );

  const contextValue = useMemo<AthleteMonitoringStatusesContextType>(
    () => [
      ragStatuses,
      {
        getTemplateByAthleteIds,
        currentRagTemplateId: ragTemplateOverrideId || preferences.defaultRagStatusTemplateId,
        setTemporaryRagTemplateId: setRagTemplateOverrideId,
      },
    ],
    [getTemplateByAthleteIds, preferences.defaultRagStatusTemplateId, ragStatuses, ragTemplateOverrideId]
  );

  return (
    <AthleteMonitoringStatusesContext.Provider value={contextValue}>
      {props.children}
    </AthleteMonitoringStatusesContext.Provider>
  );
}

export default AthleteMonitoringStatusesProvider;
