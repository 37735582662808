import { WorkoutStep, WorkoutTaskType } from "pages/Workouts/Workouts.types";
import Mui5 from "library/mui5";
import WorkoutOverviewStepPreview from "./WorkoutOverviewStepPreview";
import WorkoutStepPreviewExerciseTitle from "pages/Workouts/WorkoutForm/WorkoutStepsList/WorkoutStepPreviewExerciseTitle";
import WorkoutStepPreviewExerciseSubTitle from "pages/Workouts/WorkoutForm/WorkoutStepsList/WorkoutStepPreviewExerciseSubTitle";
import WorkoutOverviewStepPreviewNoteSubTitle from "./WorkoutOverviewStepPreview/WorkoutOveviewStepPreviewNoteSubTitle/WorkoutOverviewStepPreviewNoteSubTitle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArchivedExerciseErrorIndicator from "pages/Workouts/components/ArchivedExerciseErrorIndicator";

type WorkoutOverviewPreviewStepFactoryProps = WorkoutStep & {
  stepIndex: number;
  superset: boolean;
};

function WorkoutOverviewPreviewStepFactory(props: WorkoutOverviewPreviewStepFactoryProps) {
  const { tasks, stepIndex, superset } = props;

  const taskList = tasks.map((task, taskIndex) => {
    if (task.type === WorkoutTaskType.EXERCISE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={<WorkoutStepPreviewExerciseTitle exerciseId={task.exerciseId} />}
          subTitle={<WorkoutStepPreviewExerciseSubTitle setMetadata={task.setMetadata} exerciseId={task.exerciseId} />}
          type={task.type}
          icon={<ArchivedExerciseErrorIndicator exerciseId={task.exerciseId} />}
        />
      );
    } else if (task.type === WorkoutTaskType.CUSTOM_EXERCISE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || "Custom Exercise"}
          subTitle={<WorkoutStepPreviewExerciseSubTitle setMetadata={task.setMetadata} />}
          type={task.type}
        />
      );
    } else if (task.type === WorkoutTaskType.VIDEO) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || "Video"}
          subTitle={<WorkoutOverviewStepPreviewNoteSubTitle note={task.note} />}
          type={task.type}
          icon={<PlayCircleOutlineIcon />}
        />
      );
    } else if (task.type === WorkoutTaskType.NOTE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || "Note"}
          subTitle={<WorkoutOverviewStepPreviewNoteSubTitle note={task.note} />}
          type={task.type}
          icon={<DescriptionOutlinedIcon />}
        />
      );
    }
    return null;
  });

  return <Mui5.Paper elevation={0}>{taskList}</Mui5.Paper>;
}

export default WorkoutOverviewPreviewStepFactory;
