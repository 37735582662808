import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <rect x="4" y="5" width="16" height="16" rx="2" stroke="currentcolor" strokeWidth="2" fill="transparent" />
      <rect x="7" y="2" width="2" height="6" rx="1" />
      <rect x="21" y="9" width="2" height="18" rx="1" transform="rotate(90 21 9)" />
      <rect x="15" y="2" width="2" height="6" rx="1" />
    </SvgIcon>
  );
}
export default CalendarIcon;
