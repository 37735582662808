import { ChartPoint } from "components/charting/types/Chart.types";
import LineDot from "./LineDot";

interface LineDotsProps {
  line: ChartPoint[];
}

function LineDots(props: LineDotsProps) {
  return (
    <>
      {props.line.map((data, dataIndex) => (
        <LineDot key={dataIndex} {...data} />
      ))}
    </>
  );
}

export default LineDots;
