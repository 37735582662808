import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DateTime, Measurement } from "types";
import Grid from "@mui/material/Grid";
import ActivityCard from "components/ActivityCard";
import * as Skeletons from "../AthleteProfileActivityFeedSkeleton/AthleteProfileActivityFeedSkeleton.components";
import { useTranslation } from "react-i18next";

interface AthleteProfileAllActivityProps {
  id: string;
  date: DateTime;
  measurements: { exerciseId: string; measurements: Measurement[] }[];
}

function AthleteProfileAllActivity(props: AthleteProfileAllActivityProps) {
  const { t } = useTranslation();

  function renderContent() {
    if (!props.measurements.length) {
      const activityCardComponent = <Skeletons.ActivityCard />;
      return (
        <Skeletons.HiddenOverflowBox position="fixed" width="260px">
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
          {activityCardComponent}
        </Skeletons.HiddenOverflowBox>
      );
    }

    return (
      <Grid container spacing="8px">
        {measurements.map(({ exerciseId, measurements: exerciseMeasurements }) => (
          <Grid item xs={12} key={exerciseId}>
            <ActivityCard athleteId={id} exerciseId={exerciseId} measurements={exerciseMeasurements} date={date} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const { id, date, measurements } = props;

  return (
    <Box pb={13}>
      <Box pt={1} pb={2}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightBold">
            {t("AthleteProfileAllActivity.title")}
          </Box>
        </Typography>
      </Box>
      {renderContent()}
    </Box>
  );
}

export default AthleteProfileAllActivity;
