import { ScaleLinear } from "d3-scale";
import { LinePath } from "@visx/shape";
import { Fragment, useCallback, useMemo } from "react";
import useChartingColors from "pages/Charting/hooks/useChartingColors";
import useChartTooltipManager from "components/charting/hooks/useChartTooltipManager";
import { alpha } from "@mui/material/styles";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { Exercise } from "types";
import OneRepMaxEstimateTooltip from "./OneRepMaxEstimateTooltip";

interface AthleteProfilingChartOneRepMaxEstimateLineProps {
  estimatedOneRepMax: number | null;
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleLinear<number, number, never>;
  minimumVelocityThreshold?: number | null;
  entityId: string;
}

function AthleteProfilingChartOneRepMaxEstimateLine(props: AthleteProfilingChartOneRepMaxEstimateLineProps) {
  const { estimatedOneRepMax, xScale, yScale, minimumVelocityThreshold, entityId } = props;
  const { theme, axis } = useChartingColors();
  const selections = useChartSelections();
  const exerciseId = selections.measures[0]?.id;
  const variants = (selections.measures[0]?.metadata as Exercise).variants;
  const athleteId = selections.sources[0].id;

  const x = useMemo(() => (estimatedOneRepMax ? xScale(estimatedOneRepMax) : 0), [estimatedOneRepMax, xScale]);
  const y = useMemo(
    () => (minimumVelocityThreshold ? yScale(minimumVelocityThreshold) : 0),
    [minimumVelocityThreshold, yScale]
  );
  const { open, onMouseEnter, onMouseLeave, onClick, ChartTooltipPortal, onClose } = useChartTooltipManager(
    useMemo(() => ({ x, y }), [x, y])
  );
  const getX = useCallback((_: unknown, index: number) => xScale.range()[index], [xScale]);

  if (!estimatedOneRepMax || !minimumVelocityThreshold || !exerciseId) {
    return null;
  }

  return (
    <Fragment>
      <ChartTooltipPortal>
        <OneRepMaxEstimateTooltip
          entityId={entityId}
          athleteId={athleteId}
          exerciseId={exerciseId}
          variants={variants}
          estimatedOneRepMax={estimatedOneRepMax}
          minimumVelocityThreshold={minimumVelocityThreshold}
          onSuccess={onClose}
        />
      </ChartTooltipPortal>
      {[null, null].map(getX).map((xPoint, index) => (
        <text
          key={`MVTLine-${index}`}
          y={y + (index ? 12 : -4)}
          x={xPoint}
          textAnchor={index ? "end" : undefined}
          fontSize={12}
          fill={axis.line}
        >
          Minimum Velocity Threshold {minimumVelocityThreshold}m/s
        </text>
      ))}
      <LinePath
        data={[y, y]}
        x={getX}
        y={(yPoint) => yPoint}
        stroke={theme.palette.secondary.main}
        strokeDasharray={9}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <g transform={`translate(${x},${y})`}>
        <circle
          key="MVTLine-cicle-outer"
          cursor="pointer"
          r={16}
          fill="transparent"
          strokeWidth={2}
          strokeDasharray={4}
          stroke={alpha(theme.palette.secondary.main, open ? 1 : 0)}
        />
        <circle cursor="pointer" r={8} strokeWidth={2} fill={theme.palette.secondary.main} />
        <circle
          cursor="pointer"
          r={8}
          fill="transparent"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </g>
    </Fragment>
  );
}

export default AthleteProfilingChartOneRepMaxEstimateLine;
