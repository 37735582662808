import UserService from "../UserService";

class ClaimCheckService {
  static roles = {
    OWNER: "OWNER",
    COACH: "COACH",
    ATHLETE: "ATHLETE",
  };

  static async canDo(allowedRoles) {
    const osRole = await UserService.getRole();
    debugger;
    return allowedRoles.includes(osRole);
  }

  static async getClaims() {
    const result = await UserService.currentUser.getIdTokenResult();

    return result.claims;
  }
}
export default ClaimCheckService;
