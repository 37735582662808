import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 11V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H13"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15.5483 4.95146L9.2937 11.1178C8.72179 11.6817 8.39984 12.4513 8.39991 13.2544L8.40008 15.3988C8.40009 15.5093 8.48963 15.5988 8.60008 15.5988L10.754 15.5988C11.5517 15.5988 12.3165 15.2811 12.8795 14.7159L19.0838 8.48699M15.5483 4.95146L17.5207 2.97901C17.9113 2.58849 18.5444 2.58849 18.9349 2.97901L21.0563 5.10033C21.4468 5.49086 21.4468 6.12402 21.0563 6.51455L19.0838 8.48699M15.5483 4.95146L19.0838 8.48699"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
export default EditIcon;
