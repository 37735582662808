import { WorkoutTaskType } from "pages/Workouts/Workouts.types";
import WorkoutOverviewStepPreviewContent from "./WorkoutOverviewStepPreviewContent/WorkoutOverviewStepPreviewContent";
import Grid from "@mui/material/Grid";
import * as Components from "./WorkoutOverviewStepPreview.components";

interface WorkoutOverviewStepPreviewProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  stepIndex: number;
  taskIndex: number;
  superset: boolean;
  type: WorkoutTaskType;
  icon?: React.ReactElement;
}

function WorkoutOverviewStepPreview(props: WorkoutOverviewStepPreviewProps) {
  return (
    <Grid
      width="100%"
      container
      component={Components.StepPreviewTableRow}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={12}>
        <WorkoutOverviewStepPreviewContent {...props} />
      </Grid>
    </Grid>
  );
}

export default WorkoutOverviewStepPreview;
