import useDefinedContext from "hooks/useDefinedContext";
import MetadataStoreContext from "../contexts/MetadataStoreContext";

interface UseMetadataProps {}

function useMetadata(props?: UseMetadataProps) {
  return useDefinedContext(MetadataStoreContext);
}

export default useMetadata;
