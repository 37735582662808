import useDefinedContext from "hooks/useDefinedContext";
import useRequest from "hooks/useRequest";
import { useEffect } from "react";
import CompletedWorkoutsContext from "../contexts/CompletedWorkoutsContext";

function useGetCompletedWorkoutById(workoutId: string, completedWorkoutId: string) {
  const [store, actions] = useDefinedContext(CompletedWorkoutsContext);

  const getById = useRequest(actions.getById);

  useEffect(() => {
    (async () => await getById(completedWorkoutId))();
  }, [getById, completedWorkoutId]);

  return store[completedWorkoutId];
}
export default useGetCompletedWorkoutById;
