import { Athlete } from "types/athlete";
import Grid from "@mui/material/Grid";
import Mui5 from "library/mui5";
import AthleteHelpers from "helpers/AthleteHelpers";
import Box from "@mui/material/Box";
import { useCallback, useMemo, useState } from "react";
import Popover from "@mui/material/Popover";

interface WorkoutOverviewAssignmentsAthletesProps {
  athletes: Athlete[];
  maxDisplayed: number;
}

function WorkoutOverviewAssignmentsAthletes(props: WorkoutOverviewAssignmentsAthletesProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const visibleAthletes = useMemo(() => props.athletes.slice(0, props.maxDisplayed), [props]);
  const otherAthletes = useMemo(() => props.athletes.slice(props.maxDisplayed), [props]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderAthlete = useCallback((athlete: Athlete) => {
    return (
      <Grid key={athlete.id} item>
        <Mui5.Chip color="primary" label={AthleteHelpers.getFullName(athlete)} />
      </Grid>
    );
  }, []);

  return (
    <Grid container alignItems="center" spacing={0.5}>
      {visibleAthletes.map(renderAthlete)}
      {Boolean(otherAthletes.length) && (
        <Grid item pl={1}>
          <Mui5.Button size="medium" variant="text" onClick={handleClick}>
            <Box component="span" color="grey.500" fontWeight="fontWeightRegular">
              Show More (+{otherAthletes.length})
            </Box>
          </Mui5.Button>
          <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box minWidth={(theme) => theme.spacing(24)} maxWidth={(theme) => theme.spacing(36)} p={2}>
              <Grid container spacing="8px">
                {otherAthletes.map(renderAthlete)}
              </Grid>
            </Box>
          </Popover>
        </Grid>
      )}
    </Grid>
  );
}

export default WorkoutOverviewAssignmentsAthletes;
