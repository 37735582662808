import { useMuiPopover } from "hooks";

interface PopoverWrapperProps {
  id: string;
  children(props: {
    anchorProps: ReturnType<typeof useMuiPopover>["anchorProps"];
    Popover: ReturnType<typeof useMuiPopover>["Popover"];
  }): JSX.Element;
}

function PopoverWrapper(props: PopoverWrapperProps) {
  const { anchorProps, Popover } = useMuiPopover(props.id);

  return props.children({ anchorProps, Popover });
}

export default PopoverWrapper;
