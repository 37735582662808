import { useField } from "formik";
import useInfoMessage from "../../../hooks/useInfoMessage";
import UserService from "../../../services/UserService";
import { messages } from "./ForgotPasswordButton.config";
import * as Components from "./ForgotPasswordButton.components";
import { useState } from "react";

function ForgotPasswordButton() {
  const [field] = useField("email");
  const [disabled, setDisabled] = useState(false);

  const setInfo = useInfoMessage({ type: "info" });
  const setError = useInfoMessage({ type: "error" });

  function onSuccess() {
    setInfo({ message: messages.success });
  }

  function onError() {
    setError({ message: messages.error });
  }

  function handleSendPasswordResetEmail() {
    setDisabled(true);

    setTimeout(() => {
      setDisabled(false);
    }, 60000 * 5);

    UserService.sendPasswordResetEmail(field.value).then(onSuccess).catch(onError);
  }

  return (
    <Components.ForgotPasswordButton
      disabled={disabled}
      type="button"
      tabIndex="-1"
      onClick={handleSendPasswordResetEmail}
    >
      Forgot password?
    </Components.ForgotPasswordButton>
  );
}
export default ForgotPasswordButton;
