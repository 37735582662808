import { Component } from "react";
import PropTypes from "prop-types";
import style from "config/style";
import "./CustomColorPicker.scss";

// "#C0392B", // INJURED GROUP COLOR IS EXCLUDED
const [, ...otherColours] = style.colors.options;

class OhCustomColorPicker extends Component {
  static colors = otherColours;

  handleSelectColor = (color) => {
    this.props.field.onChange({ target: { name: "colour", value: color } });
  };

  getOptionClasses = (color) =>
    ["oh-color-clickable", this.props.field.value === color && "selected"].filter(Boolean).join(" ");

  render() {
    return (
      <div className="oh-custom-color-picker">
        {OhCustomColorPicker.colors.map((color) => (
          <div
            className={this.getOptionClasses(color)}
            onClick={() => this.handleSelectColor(color)}
            key={color}
            style={{ backgroundColor: color }}
          />
        ))}
      </div>
    );
  }
}

OhCustomColorPicker.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default OhCustomColorPicker;
