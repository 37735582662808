import { styled } from "@mui/material";

export const ForgotPasswordButton = styled("button")<{ disabled?: boolean }>(({ disabled, theme }) => ({
  position: "absolute",
  top: 1,
  right: 0,
  paddingRight: 0,
  fontSize: 11,
  display: "inline-block",
  textDecoration: "none",
  textAlign: "right",
  color: disabled ? theme.palette.grey[500] : "#39D0FF",
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? "default" : "pointer",
  border: "none",
  background: "transparent",
  outline: "none",
}));
