export enum ReportModuleType {
  ATHLETE_KPI = 0,
  // LINE_CHART = 1, // check this!
  MEASUREMENTS_TABLE = 2,
  RAG_STATUS = 3,
  SET_COMPARISON = 4,
  ATHLETE_COMPARISON = 5,
  GROUP_RAG_STATUS = 6,
  LEADERBOARD = 7,
}

export interface SetComparisonFilter {
  exerciseMeasurementId: string;
  completedDate: string;
  setNumber: number;
  name: string;
}

export interface GroupComparisonFilter {
  athleteId: string;
  athleteName: string;
}

export enum RangeType {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
  Other = 4,
}

export type ISODate = string | moment.Moment | null;
