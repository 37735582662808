import OrganisationActionsContext, { OrganisationActionsContextType } from "contexts/OrganisationActionsContext";
import OrganisationContext from "contexts/OrganisationContext";
import { useMemo } from "react";
import OrganisationService from "services/OrganisationService";
import { Organisation } from "types/organisation";

interface OrganisationProviderProps {
  children?: React.ReactNode;
  organisation: Organisation | null;
  setOrganisation(organisation: Partial<Organisation>): void;
}

function OrganisationProvider(props: OrganisationProviderProps) {
  const { children, organisation, setOrganisation } = props;

  const actions = useMemo<OrganisationActionsContextType>(
    () => ({
      update: async (organisation) => {
        const result = await OrganisationService.update(organisation);

        setOrganisation(organisation);

        return result;
      },

      get: async () => {
        const result = await OrganisationService.get();

        setOrganisation(result);
      },
    }),
    [setOrganisation]
  );

  if (organisation === null) return <>{children}</>;

  return (
    <OrganisationActionsContext.Provider value={actions}>
      <OrganisationContext.Provider value={organisation}>{children}</OrganisationContext.Provider>
    </OrganisationActionsContext.Provider>
  );
}

export default OrganisationProvider;
