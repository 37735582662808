import { useRequest } from "hooks";
import { useEffect, useState } from "react";
import UserService from "services/UserService";
import { OrganisationRole } from "types/organisation";
import RoleContext from "./RoleContext";

interface RoleProviderProps {
  children?: React.ReactNode;
  role: OrganisationRole;
}

function RoleProvider(props: RoleProviderProps) {
  const [role, setRole] = useState<OrganisationRole | undefined>();
  const getRole = useRequest(UserService.getRole, true);

  useEffect(() => {
    (async () => {
      const result = await getRole();

      setRole(result);
    })();
  }, [getRole]);

  if (typeof role === "undefined") {
    return <>Loading Role...</>;
  }

  return <RoleContext.Provider value={role}>{props.children}</RoleContext.Provider>;
}

export default RoleProvider;
