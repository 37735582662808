export const PROPERTIES = {
  ATHLETE_COUNT: "athleteCount",
  EVENT_PROPERTY_APPLICATION: "application",
  USER_PROPERTY_ORGANISATION_ID: "organisationId",
  USER_PROPERTY_FIRST_SEEN: "hubFirstSeen",
  USER_PROPERTY_LAST_SEEN: "hubLastSeen",
  CHART_TYPE: "chartType",
  VISUALIZATION_TYPE: "visType",
  EXERCISE_ID: "exerciseId", // String
  EXERCISE_CATEGORY: "exerciseCategory", // String
  METRIC_FIELD: "metricField", // String
  PERIOD: "period", // day, week
  FILTER_GROUP_COUNT: "filterGroupCount", // Number
  FILTER_ATHLETE_COUNT: "filterAthleteCount", // Number
  EVENT_CLICK_LOCATION: "eventClickLocation", // leaderboardExerciseSelector, liveActivityFeed

  CHARTING_MEASURE_COUNT: "measureCount",
  CHARTING_SOURCE_COUNT: "sourceCount",
  CHARTING_MEASURE_ID: "measureId",
  CHARTING_MEASURE_TYPE: "measureType",

  WORKOUTS_COUNT: "workoutsCount", // Number
  WORKOUTS_LABEL_ASSIGN_FLAG: "workoutsLabelAssignFlag", // Boolean
  WORKOUTS_LABEL_FILTERS_COUNT: "workoutsLabelFiltersCount", // Number

  CUSTOM_EXERCISE_DERIVED_FROM_ID: "derivedFromExerciseId",
  CUSTOM_EXERCISE_IS_WEIGHTED: "isWeighted",
  CUSTOM_EXERCISE_IS_REP_BASED: "isRepBased",
  CUSTOM_EXERCISE_IS_BILATERAL: "isBilateral",
  CUSTOM_EXERCISE_IS_MIGRATION: "isMigration",
  CUSTOM_EXERCISE_LOCATION: "location",
  PAGE: "page",
  DASHBOARD_TYPE: "type",
  DASHBOARD_GLOBAL: "isGlobal",
  DASHBOARD_WIDGET_TYPE: "widgetType",

  GROUP_MEMBER_COUNT: "groupMemberCount",
  GROUP_MEMBER_SELECTED_COUNT: "groupMemberSelectedCount",
} as const;

export const EVENTS = {
  ATHLETE_REPORT_GENERATION: "hub:reports:athleteReportGenerate",
  GROUP_REPORT_GENERATION: "hub:reports:groupReportGenerate",
  ATHLETE_DATA_EXPORT: "hub:athletes:dataExport",

  HUB_LIVE_VIEW: "hub:live:viewPage",
  HUB_LIVE_FILTER: "hub:live:filter", // PROPERTIES: filterGroupCount, filterAthleteCount
  HUB_LIVE_TOGGLE_PERIOD: "hub:live:togglePeriod", // PROPERTIES: period,
  HUB_LIVE_SET_LEADERBOARD_EXERCISE: "hub:live:setLeaderboardExercise", // PROPERTIES: exerciseId, eventClickLocation
  HUB_LIVE_ATHLETE_PROFILE_CHART_EXERCISE: "hub:live:athleteProfileChartExercise", // PROPERTIES: exerciseId, eventClickLocation

  ATHLETE_CREATE: "hub:athlete:create",
  ATHLETES_VIEW_ALL: "hub:athletes:viewAll",
  ATHLETES_VIEW_PROFILE: "hub:athletes:viewProfile",
  ATHLETE_PROFILE_VIEW_DATA: "hub:athleteProfile:viewChart",
  ATHLETE_PROFILE_VIEW_1RM_MODAL: "hub:athleteProfile:view1RMModal",
  GROUP_CREATE: "hub:group:create",
  GROUPS_VIEW_ALL: "hub:groups:viewAll",
  GROUPS_VIEW_ONE: "hub:groups:viewOne",
  GROUP_OVERVIEW_VIEW_DATA: "hub:groupOverview:viewChart",
  LEADERBOARDS_VIEW_ALL: "hub:leaderboards:viewAll",
  REPORTS_VIEW_ALL: "hub:reports:viewAll",
  WORKOUT_CREATE: "hub:workout:create",
  WORKOUTS_VIEW_ALL: "hub:workouts:viewAll",
  WORKOUTS_OVERVIEW_VIEW: "hub:workouts:viewWorkoutOverview",
  WORKOUTS_LABEL_ASSIGN: "hub:workouts:labelAssign", // PROPERTIES: workoutsCount
  WORKOUTS_LABEL_UNASSIGN: "hub:workouts:labelUnassign", // PROPERTIES: workoutsCount
  WORKOUTS_LABEL_FILTER_CLOSE: "hub:workouts:LabelFilterClose", // PROPERTIES: workoutsLabelFilterCount

  CUSTOM_EXERCISE_CREATE: "hub:customExercises:create", // PROPERTIES: derivedFromExerciseId, isWeighted, isRepBased, isBilateral, location, isMigration

  CHARTING_V3_VIEW_PAGE: "hub:chartingV3:viewPage",
  CHARTING_V3_VIEW_DATA: "hub:chartingV3:viewData", // PROPERTIES": measureId, sourceCount, measureCount, period, measureType
  CHARTING_V3_VIEW_1RM_LINE: "hub:chartingV3:view1RMLine",

  INFO_CARD_VIEW: "hub:infoCard:view", // PROPERTIES: page

  HUB_GLOBAL_SEARCH: "hub:globalSearch:click", // PROPERTIES: section
  HUB_FAB_OPEN: "hub:fab:open",
  HUB_FAB_ACTION: "hub:fab:action", // PROPERTIES: actionName

  /**
   * ** Leaderboards ** */
  LEADERBOARD_CREATE: "hub:leaderboard:create",

  /**
   * ** Dashboards ** */
  DASHBOARD_CREATE: "hub:dashboard:create", // Create new dashboard // Type of dashboard created // Group // Athlete
  DASHBOARD_NAME_UPDATE: "hub:dashboard:nameUpdate", // Edit dashboard name
  DASHBOARD_SELECTED_CHANGE: "hub:dashboard:selectedChange", // Change dashboard
  DASHBOARD_ATHLETE_UPDATE: "hub:dashboard:athleteFilterEdit", // Edit athlete
  DASHBOARD_ATHLETES_EXCLUDED: "hub:dashboard:athletesFilterExcluded", // Exclude athlete
  DASHBOARD_GROUP_UPDATE: "hub:dashboard:groupFilterEdit", // Edit group
  DASHBOARD_GROUP_RESET: "hub:dashboard:groupFilterReset", // Edit group
  DASHBOARD_TIME_FILTER_CHANGE: "hub:dashboard:timeFilterChange", // Time period filters
  DASHBOARD_TIME_FILTER_RESET: "hub:dashboard:timeFilterReset",
  DASHBOARD_ACCESS_UPDATED: "hub:dashboard:globalAccessUpdate", // Sharing
  DASHBOARD_DELETE: "hub:dashboard:delete",
  DASHBOARD_DUPLICATE: "hub:dashboard:duplicate",
  DASHBOARD_SET_DEFAULT: "hub:dashboard:setDefault",
  DASHBOARD_WIDGET_CREATE: "hub:dashboard:widgetCreate", // Type of widgets selected (when creating a dashboard) // Widgets created
  DASHBOARD_WIDGET_UPDATE: "hub:dashboard:widgetEdit", // Edit widget
  DASHBOARD_WIDGET_DELETE: "hub:dashboard:widgetDelete",
  // // DASHBOARD_WIDGET_DUPLICATE: "hub:dashboard:widgetDuplicate", // Duplicate widget
} as const;
