import { ChartingSelections, SelectedChartMeasure, SelectedChartMeasureExercise } from "components/charting/types";
import useOneRepMaxStore from "providers/OneRepMaxProvider/useOneRepMaxStore";
import { useMemo } from "react";
import { MetadataType } from "types/metadata";
import { OneRepMaxValue } from "types/onerepmax";
import MeasureHelpers from "../helpers/MeasureHelpers";

function useChartYScalesOptions(selections: ChartingSelections) {
  const oneRepMaxStore = useOneRepMaxStore();

  return useMemo(() => {
    const valid1RMLineMeasuresCount = MeasureHelpers.get1RMLineMeasures(selections.measures).length;
    if (valid1RMLineMeasuresCount === 0) return null;

    const selectedIds = selections.sources.map((source) => source.id);
    const selectedMeasures = selections.measures
      .filter<SelectedChartMeasureExercise>(
        (measure: SelectedChartMeasure): measure is SelectedChartMeasureExercise =>
          measure.type === MetadataType.EXERCISE
      )
      .map<string>((m) => m.id);

    const maxWeightValues = selectedIds
      .map((athleteId) =>
        selectedMeasures
          .map((measure) => (oneRepMaxStore[athleteId] ? oneRepMaxStore[athleteId]![measure] : undefined))
          .flat()
          .filter(Boolean)
          .map((oneRepMaxValue) => (oneRepMaxValue as OneRepMaxValue).weight)
      )
      .flat();

    return maxWeightValues.length > 0 ? Math.max(...maxWeightValues) : null;
  }, [selections.measures, selections.sources, oneRepMaxStore]);
}

export default useChartYScalesOptions;
