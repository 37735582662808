import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VerticalPageLayout from "components/VerticalPageLayout";
import PageContent from "shared/components/PageContent";
import { useParams } from "react-router-dom";
import useGetCompletedWorkoutById from "../hooks/useGetCompletedWorkoutById";
import useAthlete from "hooks/useAthlete";
import moment from "moment";
import RPEIndicator from "components/RPEIndicator";
import WorkoutAthleteReviewMeasurements from "./WorkoutAthleteReviewMeasurements";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import TimeHelper from "helpers/TimeHelper";
import * as Components from "./WorkoutAthleteReview.components";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";
import AthleteHelpers from "helpers/AthleteHelpers";

interface WorkoutAthleteReviewProps {}

function WorkoutAthleteReview(props: WorkoutAthleteReviewProps) {
  const routeMatch = useParams<{ workoutId: string; completedWorkoutId: string }>();
  const completedWorkout = useGetCompletedWorkoutById(`${routeMatch.workoutId}`, `${routeMatch.completedWorkoutId}`);
  const athlete = useAthlete(completedWorkout?.athleteId);
  const profilePhoto = useMakeProfileImageURL(AthleteHelpers.getProfileImagePath(athlete));

  return (
    <VerticalPageLayout
      top={
        <Box p={2} pr={0} pb={1}>
          <PageContent
            pageHeading={`Workout: ${completedWorkout?.workoutName}`}
            pageHeadingLink={`/workouts/${routeMatch.workoutId}`}
            enableFAB={false}
          />
        </Box>
      }
      title={
        <Box p={2} py={1}>
          <Box pb={2}>
            <Grid container spacing="8px" alignItems="center">
              <Grid item>
                <Components.ReviewAthleteAvatar
                  alt={`${athlete?.firstName} ${athlete?.lastName}`}
                  src={profilePhoto || undefined}
                >
                  {[athlete?.firstName[0], athlete?.lastName[0]].filter(Boolean).join("")}
                </Components.ReviewAthleteAvatar>
              </Grid>
              <Grid item>
                <Typography variant="h1">
                  <Box component="span" fontWeight="fontWeightRegular">
                    {athlete?.firstName} {athlete?.lastName}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing="8px">
            <Grid item xs={12}>
              <Typography variant="h1" gutterBottom>
                <Box component="span" fontWeight="fontWeightBold">
                  {completedWorkout?.workoutName} {completedWorkout && "- "}
                  {moment(completedWorkout?.startTime).format("ddd, Do MMMM YYYY")}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Box pt={3}>
            <Grid container spacing="24px">
              <Grid item>
                <Typography variant="h3" gutterBottom noWrap>
                  <Box component="span" fontWeight="fontWeightBold">
                    Duration
                  </Box>
                </Typography>
                <Typography variant="h3">
                  <Box component="span" fontWeight="fontWeightLight">
                    {TimeHelper.getDiff(completedWorkout?.startTime, completedWorkout?.endTime) || "-"}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="h3" gutterBottom noWrap>
                  <Box component="span" fontWeight="fontWeightBold">
                    RPE
                  </Box>
                </Typography>
                <Typography variant="h3">
                  <Box component="span" fontWeight="fontWeightLight">
                    <RPEIndicator score={completedWorkout?.rpe} />
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h3" gutterBottom noWrap>
                  <Box component="span" fontWeight="fontWeightBold">
                    Comment
                  </Box>
                </Typography>
                <Typography variant="h3">
                  <Box component="span" fontWeight="fontWeightLight">
                    {completedWorkout?.comment ?? "n/a"}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      main={
        <Box p={2} pb={0} width="100%">
          <LocalisedLoadingProvider>
            <WorkoutAthleteReviewMeasurements completedWorkoutId={`${routeMatch.completedWorkoutId}`} />
          </LocalisedLoadingProvider>
        </Box>
      }
    />
  );
}

export default WorkoutAthleteReview;
