// import {} from "react";

import useOneRepMaxStore from "providers/OneRepMaxProvider/useOneRepMaxStore";
import { useMemo } from "react";

interface UseGetOneRepMaxFromStoreProps {
  athleteId?: string | null;
  exerciseId?: string | null;
}

function useGetOneRepMaxFromStore(
  props: UseGetOneRepMaxFromStoreProps
): { default?: number; Left?: number; Right?: number } | undefined {
  const { athleteId, exerciseId } = props;
  const oneRepMaxStore = useOneRepMaxStore();

  return useMemo(() => {
    const athlete = athleteId && oneRepMaxStore[athleteId];
    const exercise = athlete && exerciseId ? athlete[exerciseId] : undefined;

    if (!(exercise && exercise.length)) {
      return;
    }

    return exercise.reduce<{ default?: number; Left?: number; Right?: number }>(
      (acc, cur) => ({ ...acc, [cur.variant || "default"]: cur.weight }),
      {}
    );
  }, [athleteId, exerciseId, oneRepMaxStore]);
}

export default useGetOneRepMaxFromStore;
