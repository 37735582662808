import { ArchivedAthletesContext } from "./ArchivedAthletes.contexts";
import useDefinedContext from "hooks/useDefinedContext";
import { useMemo } from "react";
import { ArchivedAthlete } from "types/athlete";

export function useArchivedAthletes(onlyIds: true): string[];
export function useArchivedAthletes(onlyIds?: false): ArchivedAthlete[];
export function useArchivedAthletes(onlyIds?: boolean) {
  const archivedAthletesContextValue = useDefinedContext(ArchivedAthletesContext);

  return useMemo(() => {
    if (onlyIds) {
      return archivedAthletesContextValue.map((athlete) => athlete.id);
    }

    return archivedAthletesContextValue;
  }, [archivedAthletesContextValue, onlyIds]);
}
