import { LinePath } from "@visx/shape";
import { useUnitFormatter } from "hooks";
import useChartingColors from "pages/Charting/hooks/useChartingColors";
import useChartPbApi from "pages/Charting/hooks/useChartPbApi";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { ChartLine } from "components/charting/types/Chart.types";
import React, { useCallback } from "react";
import useColorRegistry from "../../../../pages/Charting/hooks/useColorRegistry";
import useChartAnnotationLineLabelPosition from "../hooks/useChartAnnotationLineLabelPosition";

function PbLine(props: ChartLine) {
  const { entityId } = props;
  const getEntityColor = useColorRegistry();
  const pbLineData = useChartPbApi(props);
  const { axis } = useChartingColors();
  const unitFormatter = useUnitFormatter();
  const selections = useChartSelections();

  const getX = useCallback((data: { x: number; y: number }) => data.x, []);
  const getY = useCallback((data: { x: number; y: number }) => data.y, []);

  const setLineLabelPosition = useChartAnnotationLineLabelPosition();

  return (
    <>
      {pbLineData
        .filter((pb) => (pb.variant ? pb.variant === props.variant : pb))
        .slice(0, 1) // can be sliced because pbline is called for each line rendered
        .map((lineData) => (
          <React.Fragment key={[props.source.id, entityId, props.variant].filter(Boolean).join("_")}>
            {lineData.pb.map((pb, index) => (
              <text
                key={["PB-LABEL", props.source.id, entityId, lineData.variant, index].filter(Boolean).join("_")}
                y={getY(pb) + setLineLabelPosition(lineData.variant, Boolean(index))}
                x={getX(pb)}
                textAnchor={index ? "end" : undefined}
                fontSize={12}
                fill={axis.line}
              >
                {[
                  "🥇",
                  unitFormatter(lineData.value, selections.measures.find((m) => m.id === props.measure.id)?.metric),
                  lineData.variant && `(${lineData.variant.charAt(0)})`,
                ]
                  .filter(Boolean)
                  .join(" ")}
              </text>
            ))}
            <LinePath
              data={lineData.pb}
              x={getX}
              y={getY}
              stroke={getEntityColor(entityId)}
              strokeWidth={2}
              strokeLinecap="round"
              shapeRendering="geometricPrecision"
            />
          </React.Fragment>
        ))}
    </>
  );
}

export default PbLine;
