import MeasureHelpers from "../../helpers/MeasureHelpers";
import SourceHelper from "../../helpers/SourceHelper";
import useChartSelections from "../../../../components/charting/hooks/useChartSelections";
import useValidChartMeasures from "../useValidChartMeasures";

function useChartName() {
  const selections = useChartSelections();
  const validMeasures = useValidChartMeasures();

  const singleSelectedSource = selections.sources.length === 1 && selections.sources[0];
  const singleSelectedMeasure = validMeasures.length === 1 && validMeasures[0];

  const sourceLabel = singleSelectedSource ? SourceHelper.getLabel(singleSelectedSource) : null;
  let measureLabel: string | null = null;

  if (singleSelectedMeasure) {
    const { label, subLabel } = MeasureHelpers.getCommonProperties(singleSelectedMeasure);

    measureLabel = `${label} (${subLabel})`;
  }

  if (sourceLabel || measureLabel) {
    return [sourceLabel, measureLabel].filter(Boolean).join(" // ");
  }

  return "Charting";
}
export default useChartName;
