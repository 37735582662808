import DateCalendar from "./DateCalendar";
import DatePicker from "./DatePicker";
import DateTimePickersLocalizationProvider from "./DateTimePickersLocalizationProvider";
import MultiInputDateRangeField from "./MultiInputDateRangeField";
import StaticDateRangePicker from "./StaticDateRangePicker";

const DateTimePickers = {
  DateCalendar,
  DatePicker,
  LocalizationProvider: DateTimePickersLocalizationProvider,
  MultiInputDateRangeField,
  StaticDateRangePicker,
};
export default DateTimePickers;

export type { DateRange, DateRangeValidationError, MultiInputDateRangeFieldProps } from "@mui/x-date-pickers-pro";
