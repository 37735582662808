import { useState } from "react";

function useTogglePasswordInputType() {
  const [show, setShow] = useState(false);

  const type = show ? "text" : "password";

  return { type, toggleType: () => setShow((prev) => !prev) };
}
export default useTogglePasswordInputType;
