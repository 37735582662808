import useChartOptions from "pages/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import { Metadata, MetadataType } from "types/metadata";
import { useCallback, useMemo } from "react";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import ExerciseHelpers from "helpers/ExerciseHelpers";

function useChartMeasures() {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();

  const setMeasureAtIndex = useCallback(
    (measureIndex: number) => (event: unknown, measure: Metadata | null) => {
      if (!measure) throw new Error("Should not be able to set null as a Measure value");

      setSelections((prev) => ({
        measures: prev.measures.map((prevMeasure, prevMeasureIndex) => {
          if (measureIndex !== prevMeasureIndex) {
            return prevMeasure;
          }

          if (measure.type === MetadataType.EXERCISE) {
            return {
              type: measure.type,
              id: measure.metadata.id,
              metadata: measure.metadata,
              metric: ExerciseHelpers.getMetricByFieldNameOrDefault(measure.metadata, prevMeasure.metric?.field),
              repMetric: ExerciseMetadataService.repMetricByFieldNameOrFirstOrNull(
                measure.metadata,
                prevMeasure.repMetric?.field
              ),
            };
          }

          if (measure.type === MetadataType.ANTHROPOMETRIC) {
            return {
              type: measure.type,
              id: measure.metadata.value,
              metadata: measure.metadata,
              metric: null,
              repMetric: null,
            };
          }

          return {
            type: measure.type,
            id: measure.metadata.id,
            metadata: measure.metadata,
            metric: null,
            repMetric: null,
          };
        }),
      }));
    },
    [setSelections]
  );

  return useMemo(
    () => ({
      options: options.measures,
      values: selections.measures,
      onChangeAtIndex: setMeasureAtIndex,
      getOptionLabel: (option: Metadata) =>
        option.type === MetadataType.ANTHROPOMETRIC ? option.metadata?.label : option.metadata?.name,
      groupBy: (option: Metadata) => (option.type === MetadataType.EXERCISE ? option.metadata?.category : option.type),
    }),
    [options.measures, selections.measures, setMeasureAtIndex]
  );
}
export default useChartMeasures;
