import { useContext } from "react";
import { FireStoreMeasurement } from "types";
import MeasurementsFilterContext from "../../../contexts/MeasurementsFilterContext";

function useMeasurementsFilter(measurements: FireStoreMeasurement[]) {
  const filter = useContext(MeasurementsFilterContext);

  if (typeof filter !== "undefined") {
    return filter(measurements);
  }

  return measurements;
}
export default useMeasurementsFilter;
