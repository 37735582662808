import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import SettingsMeasurementSystem from "./SettingsMeasurementSystem";
import SettingsTimeZone from "./SettingsTimeZone";
import * as Components from "./SettingsPreferencesEdit.components";

interface SettingsPreferencesEditProps {}

function SettingsPreferencesEdit(props: SettingsPreferencesEditProps) {
  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <Components.BottomDividerGrid item xs={12}>
          <Box p={6}>
            <Box pb={4}>
              <Typography variant="h1">
                <Box component="span" fontWeight="fontWeightMedium">
                  Preferences
                </Box>
              </Typography>
            </Box>
            <SettingsMeasurementSystem />
          </Box>
        </Components.BottomDividerGrid>
        <Components.BottomDividerGrid item xs={12}>
          <Box p={6}>
            <SettingsTimeZone />
          </Box>
        </Components.BottomDividerGrid>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsPreferencesEdit;
