import ExerciseHelpers from "helpers/ExerciseHelpers";
import { useUnitFormatter } from "hooks";
import useMetadataActions from "providers/MetadataProvider/useMetadataActions";
import { useMemo } from "react";
import { Exercise, Measurement } from "types";

export function useProcessMeasurementTableRowData(measurement: Measurement) {
  const metadataActions = useMetadataActions();
  const exercise = metadataActions.getById(measurement.exerciseId)?.metadata as Exercise | undefined;

  const formatUnit = useUnitFormatter();

  const defaultMetricMetadata = exercise && ExerciseHelpers.getMetricByFieldNameOrDefault(exercise, null);
  const defaultMetricValue = measurement.metrics.find((metric) => metric.field === defaultMetricMetadata?.field);
  const weightMetricMetadata =
    exercise &&
    ExerciseHelpers.hasWeightMetric(exercise) &&
    ExerciseHelpers.getMetricByFieldNameOrDefault(exercise, "weight");
  const weightMetricValue = weightMetricMetadata
    ? measurement.metrics.find((metric) => metric.field === "weight")
    : undefined;

  return useMemo(
    () => ({
      metadata: exercise,
      defaultMetric: {
        metadata: defaultMetricMetadata,
        value: formatUnit(defaultMetricValue?.value, defaultMetricMetadata),
      },
      weightMetric: {
        metadata: weightMetricMetadata,
        value: formatUnit(weightMetricValue?.value, weightMetricMetadata),
      },
      // metrics: measurement.metrics.map(metric => ({
      //   label: exercise.metrics[metric.field].name,
      //   value: formatUnit(metric.value, exercise.metrics[metric.field]),
      // })),
      /**
       * Not including repetitions/metrics here will process it only
       * when it is rendered (expanded table state)
       */
    }),
    [
      // measurement,
      defaultMetricMetadata,
      defaultMetricValue?.value,
      exercise,
      formatUnit,
      weightMetricMetadata,
      weightMetricValue,
    ]
  );
}
