import { ArchivedAthleteAlertContainer } from "./ArchivedAthleteAlert.components";
import Mui5 from "library/mui5";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { sxArchivedAthleteAlert } from "./ArchievedAthleteAlert.style";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAthleteLimits from "hooks/useAthleteLimits";

interface ArchivedAthleteAlertProps {
  isArchived: boolean;
  unarchiveAthlete(): void;
}

function ArchivedAthleteAlert(props: ArchivedAthleteAlertProps) {
  const { t } = useTranslation();
  const { isArchived, unarchiveAthlete } = props;
  const athleteLimits = useAthleteLimits();

  const organisationActions = useOrganisationActions();

  useEffect(() => {
    organisationActions.get();
  }, [organisationActions]);

  if (!isArchived) return null;

  const unarchiveButton = !athleteLimits.active.isFull ? (
    <Mui5.Button size="small" variant="outlined" color="primary" onClick={unarchiveAthlete}>
      {t("ArchivedAthleteAlert.unarchiveButton")}
    </Mui5.Button>
  ) : undefined;

  return (
    <ArchivedAthleteAlertContainer position="absolute" display="flex" justifyContent="center" alignItems="center">
      <Alert
        icon={<ArchiveOutlinedIcon />}
        variant="filled"
        color="warning"
        sx={sxArchivedAthleteAlert}
        action={unarchiveButton}
      >
        <AlertTitle>{t("ArchivedAthleteAlert.alertTitle")}</AlertTitle>
        {t("ArchivedAthleteAlert.alertBody", {
          activeUsage: `${athleteLimits.active.count}/${athleteLimits.active.limit}`,
          archivedUsage: `${athleteLimits.archived.count}/${athleteLimits.archived.limit}`,
        })}
      </Alert>
    </ArchivedAthleteAlertContainer>
  );
}

export default ArchivedAthleteAlert;
