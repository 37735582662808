import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import usePreferences from "hooks/usePreferences";
import UnitHelper from "../../../../helpers/UnitHelper";
import moment from "moment";

function AthleteBiometrics(props) {
  const { dateOfBirth = null, height = null, weight = null } = props;
  const [preferences] = usePreferences();

  function formatWeight(weight) {
    const toUnit = preferences.imperialSystemEnabled && UnitHelper.units.Pound;

    return UnitHelper.process(weight, {
      from: UnitHelper.units.Kilogram,
      to: toUnit,
      decimals: preferences.imperialSystemEnabled ? 0 : 1,
    });
  }

  function formatHeight(height) {
    const toUnit = preferences.imperialSystemEnabled ? "FeetInches" : UnitHelper.units.Centimetre;

    return UnitHelper.process(height, {
      from: UnitHelper.units.Metre,
      to: toUnit,
      decimals: 0,
    });
  }

  const values = [
    dateOfBirth !== null && moment().diff(moment(dateOfBirth), "years"),
    weight && formatWeight(weight),
    height && formatHeight(height),
  ]
    .filter(Boolean)
    .map((info, index) => (
      <React.Fragment key={index}>
        {index > 0 && (
          <Box component="span" fontSize={16} color="primary.main" pl={1} pr={1}>
            &bull;
          </Box>
        )}
        {info}
      </React.Fragment>
    ));

  return (
    <Typography variant="h4">
      <Box component="span" color="primary.main">
        {values}
      </Box>
    </Typography>
  );
}

AthleteBiometrics.propTypes = {
  dateOfBirth: PropTypes.string,
  height: PropTypes.number,
  weight: PropTypes.number,
};

export default AthleteBiometrics;
