import { AlertProps, alpha, styled } from "@mui/material";
import MuiTooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import { useCallback, useEffect, useRef, useState } from "react";

const enterDelay = 300;
const leaveDelay = 100;

/**
 *
 * Demos:
 *
 * - [Tooltips](https://mui.com/material-ui/react-tooltip/)
 *
 * API:
 *
 * - [Tooltip API](https://mui.com/material-ui/api/tooltip/)
 */
export interface TooltipProps extends MuiTooltipProps {
  /**
   * The severity of the alert. This defines the color and icon used.
   */
  severity?: AlertProps["severity"];
  /**
   * Small (400px) or medium size (480px)
   */
  size?: "small" | "medium";
}
const Tooltip = styled(({ className, ...props }: TooltipProps) => {
  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleShow = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setShow(true);
    }, enterDelay);
  }, []);

  const handleHide = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setShow(false);
    }, leaveDelay);
  }, []);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <MuiTooltip
      disableHoverListener
      disableFocusListener
      open={show}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
      onClick={handleHide}
      {...props}
      classes={{ popper: className, arrow: className }}
    />
  );
})<{ severity?: AlertProps["severity"] }>(({ theme, ...props }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: alpha(props.severity ? theme.palette[props.severity].main : theme.palette.common.white, 0.99),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(props.severity ? theme.palette[props.severity].main : theme.palette.common.white, 0.99),
    padding: props.severity ? 0 : undefined,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    maxWidth: props.size === "small" ? 400 : props.size === "medium" ? 480 : 480,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    borderRadius: "8px",
  },
  [`& .${tooltipClasses.tooltipPlacementLeft}, & .${tooltipClasses.tooltipPlacementRight}`]: {
    textAlign: "left",
  },
  [`& .${tooltipClasses.tooltipPlacementTop}, & .${tooltipClasses.tooltipPlacementBottom}`]: {
    textAlign: "center",
  },
}));
export default Tooltip;
