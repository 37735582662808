import { Fragment } from "react";
import groupBy from "lodash/groupBy";
import { Measurement } from "types";
import moment from "moment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MeasurementsTable from "components/MeasurementsTable";

interface AthleteProfileMeasurementsTableRowsProps {
  measurements: Measurement[];
}

function AthleteProfileMeasurementsTableRows(props: AthleteProfileMeasurementsTableRowsProps) {
  const groupedMeasurements = groupBy(props.measurements, (measurement) =>
    moment(measurement.completedDate).startOf("day").toISOString()
  );

  return (
    <Fragment>
      {Object.entries(groupedMeasurements).map(([completedDate, measurements]) => (
        <Fragment key={completedDate}>
          <Box pl={1} pb={2} pt={1}>
            <Typography variant="h3">
              <Box component="span" fontWeight={500}>
                {moment(completedDate).format("D MMM YYYY")}
              </Box>
            </Typography>
          </Box>
          <MeasurementsTable measurements={measurements} />
        </Fragment>
      ))}
    </Fragment>
  );
}

export default AthleteProfileMeasurementsTableRows;
