import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";

const buttonActiveColor = "rgba(0, 0, 0, 0.02)";

export const VerticalDividerGrid = styled(Grid)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}));

export const SettingsListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 0, 1.5, 3),
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: buttonActiveColor,
  },
}));
