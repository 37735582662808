import React from "react";
import { CanDoActions } from "types/CanDoActions";
import useCanDo from "hooks/useCanDo";

interface CanDoActionProps {
  action: CanDoActions;
  children?: React.ReactNode;
}

function CanDoAction(props: CanDoActionProps) {
  const isAllowed = useCanDo()(props.action);

  if (!isAllowed) return <React.Fragment />;

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default CanDoAction;
