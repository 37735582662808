import http from "axiosSetup";
import { OneRepMax, OneRepMaxValue } from "types/onerepmax";

class OneRepMaxService {
  static getByAthleteIds(exerciseId: string, athleteIds: string[], params?: { mostRecent?: boolean }) {
    return http
      .post<OneRepMax[]>("/athletes/one-rep-maxes/exercise", { athleteIds, exerciseId }, { params })
      .then((response) => response.data);
  }

  static updateByAthleteIds(
    oneRepMaxes: {
      athleteId: string;
      oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
    }[]
  ) {
    return http
      .post<Pick<OneRepMaxValue, "id">[]>("/athletes/one-rep-maxes", oneRepMaxes)
      .then((response) => response.data);
  }

  static deleteByOneRepMaxId(athleteId: string, oneRepMaxId: string) {
    return http.delete(`/athletes/${athleteId}/one-rep-maxes/${oneRepMaxId}`).then((response) => response.data);
  }
}

export default OneRepMaxService;
