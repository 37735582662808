import { firstBy } from "thenby";
import { Measurement } from "types";

export enum MeasurementReducerActions {
  RESET = "RESET",
  ADD_MEASUREMENTS = "ADD_MEASUREMENTS",
  DELETE_MEASUREMENT = "DELETE_MEASUREMENT",
}

export const initialState: Measurement[] = [];

export function reducer(
  state: Measurement[],
  action:
    | { type: MeasurementReducerActions.RESET }
    | { type: MeasurementReducerActions.ADD_MEASUREMENTS; payload: Measurement[] }
    | { type: MeasurementReducerActions.DELETE_MEASUREMENT; payload: { id: string } }
) {
  switch (action.type) {
    case MeasurementReducerActions.RESET:
      return initialState;
    case MeasurementReducerActions.ADD_MEASUREMENTS:
      return [...state, ...action.payload].sort(firstBy("completedDate", "desc"));
    case MeasurementReducerActions.DELETE_MEASUREMENT:
      return state.filter(({ id }) => id !== action.payload.id);
    default:
      return state;
  }
}
