import moment from "moment";
import { DateTime } from "types";

import { Exercise, Variants } from "./WorkoutForm/types/Exercise.types";
import { VBTConfiguration } from "types/VBT.types";

export interface Workout extends WorkoutBase {
  id: string;
  organisationId?: string;
  creatorId?: string;
  createdAt?: moment.Moment | string;
  updatedAt?: moment.Moment | string;
  isShared?: boolean;
}

export interface WorkoutBase {
  name: string;
  description: string;
  duration: number | null;
  steps: WorkoutStep[];
  isPublished: boolean;
  groupIds: string[];
  athleteIds: string[];
  labels: string[];
}

export interface WorkoutLabelOption {
  id: string;
  name: string;
  count: number;
}

export interface WorkoutStep {
  id: string;
  tasks: WorkoutTask[];
}

export interface WorkoutTaskSetMetadata {
  id: string;
  amrap: boolean;
  repCount: number | null;
  weight: number;
  percentage1Rm: number;
  variant: Variants | null;
  configuration: VBTConfiguration | null;
}

export type WorkoutTask =
  | WorkoutTaskExerciseType
  | WorkoutTaskNoteType
  | WorkoutTaskCustomExerciseType
  | WorkoutTaskVideoType;

export enum WorkoutTaskType {
  EXERCISE = "Exercise",
  NOTE = "Note",
  CUSTOM_EXERCISE = "CustomExercise",
  VIDEO = "Video",
}

interface WorkoutTaskBase {
  id: string;
  type: WorkoutTaskType;
}

export interface WorkoutTaskExerciseType extends WorkoutTaskBase {
  type: WorkoutTaskType.EXERCISE;
  weightType: WeightTypes;
  exerciseId?: string;
  exercise: Exercise;
  restPeriod: null | number;
  note: string | null;
  useSensor: boolean | null;
  setMetadata: WorkoutTaskSetMetadata[];
}

export interface WorkoutTaskCustomExerciseType extends WorkoutTaskBase {
  type: WorkoutTaskType.CUSTOM_EXERCISE;
  weightType: WeightTypes | null;
  title: string;
  restPeriod: null | number;
  note: string | null;
  setMetadata: WorkoutTaskSetMetadata[];
  options: {
    withReps: boolean;
    withVariants: boolean;
  };
}

export interface WorkoutTaskVideoType extends WorkoutTaskBase {
  type: WorkoutTaskType.VIDEO;
  title: string;
  note: string;
  youTubeVideoId: string;
}

export interface WorkoutTaskNoteType extends WorkoutTaskBase {
  type: WorkoutTaskType.NOTE;
  title: string;
  note: string | null;
}

export enum WeightTypes {
  ABSOLUTE = "Absolute",
  PERCENTAGE_1RM = "Percentage1RM",
}

export enum DroppableArea {
  EXERCISES = "EXERCISES",
  OTHER_TASKS = "OTHER_TASKS",
  STEPS = "STEPS",
}

export enum AfterSubmitActions {
  CONTINUE = "CONTINUE",
  EXIT = "EXIT",
}

export interface CompletedWorkout {
  id: string;
  athleteId: string;
  workoutId: string | null;
  workoutName: string;
  comment: string | null;
  startTime: DateTime;
  endTime: DateTime;
  lastUpdated: DateTime;
  rpe: number | null;
}

export interface CompletedWorkoutsStore {
  [completedWorkoutId: string]: CompletedWorkout | undefined;
}

export interface CompletedWorkoutActions {
  getAll(params: CompletedWorkoutGetAllQueryParams): Promise<CompletedWorkout[]>;
  getById(id: string): Promise<CompletedWorkout>;
}

export type CompletedWorkoutsContextType = [CompletedWorkoutsStore, CompletedWorkoutActions];

export interface CompletedWorkoutGetAllQueryParams {
  workoutId?: string;
  athleteId?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: string;
}
