import CompletedWorkoutService from "pages/Workouts/services/CompletedWorkoutService";
import { CompletedWorkout } from "pages/Workouts/Workouts.types";
import { useEffect, useState } from "react";
import { DateTime } from "types";
import { getTodayDateRangeParams } from "../../AthleteProfileActivityFeed.helpers";

function useActivityFeedWorkouts(props: { id: string; date: DateTime }) {
  const { id, date } = props;
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkout[]>([]);

  useEffect(() => {
    (async () => {
      const dateParams = getTodayDateRangeParams(date);

      const result = await CompletedWorkoutService.getAll({ ...dateParams, athleteId: id });
      setCompletedWorkouts(result);
    })();

    return () => setCompletedWorkouts([]);
  }, [date, id]);

  return completedWorkouts;
}

export default useActivityFeedWorkouts;
