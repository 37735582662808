import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function AnalyticsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.5C6.55228 9.5 7 9.94772 7 10.5V19.5C7 20.0523 6.55228 20.5 6 20.5C5.44772 20.5 5 20.0523 5 19.5V10.5C5 9.94772 5.44772 9.5 6 9.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.5C10.5523 4.5 11 4.94772 11 5.5L11 19.5C11 20.0523 10.5523 20.5 10 20.5C9.44772 20.5 9 20.0523 9 19.5L9 5.5C9 4.94772 9.44772 4.5 10 4.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12.5C14.5523 12.5 15 12.9477 15 13.5L15 19.5C15 20.0523 14.5523 20.5 14 20.5C13.4477 20.5 13 20.0523 13 19.5L13 13.5C13 12.9477 13.4477 12.5 14 12.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.5C18.5523 7.5 19 7.94772 19 8.5L19 19.5C19 20.0523 18.5523 20.5 18 20.5C17.4477 20.5 17 20.0523 17 19.5L17 8.5C17 7.94772 17.4477 7.5 18 7.5Z"
      />
    </SvgIcon>
  );
}
export default AnalyticsIcon;
