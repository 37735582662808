import { useState, useEffect, useMemo } from "react";
import { ChartLine } from "components/charting/types/Chart.types";
import { MetadataType } from "types/metadata";
import { PersonalBest } from "types/personalbest";
import http from "axiosSetup";
import ChartHelpers from "pages/Charting/helpers/ChartHelpers";
import useXScale from "../useXScale";
import useYScaleById from "../useYScaleById";

function useChartPbApi(line: ChartLine) {
  const { metadataType, measure, source } = line;
  const athleteId = source.id;
  const exerciseId = measure.id;
  const scaleId = ChartHelpers.getScaleId(measure);
  const xScale = useXScale();
  const yScale = useYScaleById(scaleId);

  const [personalBest, setPersonalBest] = useState<PersonalBest | null>(null);

  useEffect(() => {
    if (metadataType !== MetadataType.EXERCISE) return;

    (async () => {
      try {
        const result = await http
          .get<PersonalBest>(`athletes/${athleteId}/personal-bests/${exerciseId}`)
          .then((response) => response.data);

        setPersonalBest(result);
      } catch (error) {}
    })();
  }, [athleteId, exerciseId, metadataType]);

  return useMemo(() => {
    const pbVariants = personalBest?.metrics.find((metric) => metric.metricField === measure.metricField)?.variants;

    return (pbVariants || []).map((pbVariant) => {
      const y = yScale(pbVariant.value);
      const xRange = xScale.range();

      return { ...pbVariant, pb: xRange.map((x) => ({ variant: pbVariant.variant, x, y })) };
    });
  }, [measure.metricField, personalBest?.metrics, xScale, yScale]);
}

export default useChartPbApi;
