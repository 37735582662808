import { alpha, styled } from "@mui/material";
import Button from "@mui/material/Button";

export const AddMeasureButton = styled(Button)((props) => ({
  marginTop: props.theme.spacing(1),
  color: props.theme.palette.primary.main,
  backgroundColor: alpha(props.theme.palette.primary.main, 0.06),
  textTransform: "none",
  "&:hover": {
    backgroundColor: alpha(props.theme.palette.primary.main, 0.2),
  },
}));
