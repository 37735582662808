import http from "axiosSetup";
import { BaseCustomExercise, CustomExercise } from "types/custom-exercise";

class CustomExerciseService {
  static getAll() {
    return http.get<CustomExercise[]>("/custom-exercises").then((response) => response.data);
  }

  static getById(id: string) {
    return http.get<CustomExercise>(`/custom-exercises/${id}`).then((response) => response.data);
  }

  static async create(exercise: BaseCustomExercise) {
    const result = await http.post<{ id: string }>(`/custom-exercises`, exercise).then((response) => response.data);

    return CustomExerciseService.getById(result.id);
  }

  static async update(
    id: string,
    exercise: Pick<
      BaseCustomExercise,
      "instructions" | "name" | "videoId" | "bodySegments" | "equipment" | "searchTerms"
    >
  ) {
    const result = await http
      .put<{ id: string }>(`/custom-exercises/${id}`, exercise)
      .then((response) => response.data);

    return CustomExerciseService.getById(result.id);
  }

  static toggleArchived(id: string, isArchived: boolean) {
    return http
      .patch<{ id: string }>(`/custom-exercises/${id}/archived`, { isArchived })
      .then((response) => response.data);
  }
}

export default CustomExerciseService;
