import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import LibraryPaper, { paperClasses } from "library/mui5/Paper";

export const ControlsGrid = styled(Grid)(({ theme }) => ({
  width: theme.spacing(40),
}));

export const Paper = styled(LibraryPaper, { shouldForwardProp: (propName: string) => propName !== "p" })<{
  p?: number;
}>((props) => ({
  [`&.${paperClasses.root}`]: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: props.p && props.theme.spacing(props.p),
  },
}));

export const ChartContainerDiv = styled("div")({
  position: "relative",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  // @Jack Special use case for Image download
  "& .controls": {},
  "& .logoContainer": {
    position: "relative",
    transform: "scale(0.5)",
    transformOrigin: "right",
  },
  "& .logo": {
    padding: "1px",
    position: "relative",
    backgroundColor: "transparent",
  },
});

export const ChartDiv = styled("div")({
  flex: 1,
  position: "relative",
});

export const ScaffoldingCtrDiv = styled("div")(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(3),
}));

export const FullScreenDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  backgroundColor: "#FFF",
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(3),
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));
