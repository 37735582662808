interface LbSizes {
  xsWidth: 3 | 4 | 6;
}

export function calculateLeaderboardSize(count: number, clientWidth: number): LbSizes {
  const lbTotalCount = count;

  let xs: 3 | 4 | 6 = 6;

  if (clientWidth > 1280) {
    if (lbTotalCount === 1) {
      xs = 4;
    } else if (lbTotalCount === 2) {
      xs = 4;
    } else if (lbTotalCount === 3) {
      xs = 4;
    } else if (lbTotalCount === 4) {
      xs = 3;
    }
  } else {
    if (lbTotalCount === 1) {
      xs = 6;
    } else if (lbTotalCount === 2) {
      xs = 6;
    } else if (lbTotalCount === 3) {
      xs = 4;
    } else if (lbTotalCount === 4) {
      xs = 3;
    }
  }

  return { xsWidth: xs };
}
