import useChartSelections from "components/charting/hooks/useChartSelections";
import AverageLine from "./AverageLine";
import useChartAnnotations from "components/charting/annotations/hooks/useChartAnnotations";

interface LineChartAverageLineProps {
  annotations: ReturnType<typeof useChartAnnotations>;
}

function LineChartAverageLine(props: LineChartAverageLineProps) {
  const selections = useChartSelections();

  if (!selections.showAverage) {
    return null;
  }

  return (
    <>
      {props.annotations.map((annotation) => (
        <AverageLine key={["AVG", annotation.variant, annotation.scaleId].filter(Boolean).join(" ")} {...annotation} />
      ))}
    </>
  );
}

export default LineChartAverageLine;
