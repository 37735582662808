import Box from "@mui/material/Box";
import Grid, { gridClasses } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useAthlete from "hooks/useAthlete";
import { useUnitFormatter } from "hooks";
import moment from "moment";
import useExerciseMap from "hooks/useExerciseMap";
import AthleteName from "components/AthleteName";
import { Exercise, FireStoreMeasurement } from "types";
import { grey } from "@mui/material/colors";
import { useIsPbChecker } from "../../providers/LivePbCheckProvider";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import logEvent from "utils/logEvent";
import AnalyticsService from "services/AnalyticsService";
import { styled } from "@mui/material";
import ActiveIndicator from "library/ActiveIndicator";
import ExerciseHelpers from "helpers/ExerciseHelpers";

export function LiveActivityFeedVirtualTableRowCell(props: {
  label: string;
  value?: string;
  align?: "center" | "right" | "left";
}) {
  const { align = "center" } = props;
  return (
    <Box textAlign={align}>
      <Box>
        <Typography variant="caption">
          <Box component="span" color={grey[900]} fontWeight="fontWeightLight" fontSize={12}>
            {props.label}
          </Box>
        </Typography>
      </Box>
      <Typography variant="h4">{props.value}</Typography>
    </Box>
  );
}

function ExerciseCell(props: {
  athleteId: string;
  exerciseId: string;
  exercise: Exercise | undefined;
  measurementId: string;
}) {
  const isPb = useIsPbChecker(props.athleteId, props.exerciseId, props.measurementId);

  return (
    <LiveActivityFeedVirtualTableRowCell
      align="left"
      label={props.exercise?.category || ""}
      // 🥇 🏆
      value={[props.exercise?.name || props.exerciseId, isPb && "🥇"].filter(Boolean).join(" ")}
    />
  );
}

export function LiveActivityFeedVirtualTableRowData(props: FireStoreMeasurement) {
  const { exerciseId, completedDate, athleteId, id } = props;
  const exercise = useExerciseMap(exerciseId);
  const defaultMetricMetadata = exercise && ExerciseHelpers.getMetricByFieldNameOrDefault(exercise, null);
  const defaultMetricValue = defaultMetricMetadata && props[defaultMetricMetadata.field];
  const repMetric: number | undefined = props["repCount"];
  const formatUnit = useUnitFormatter();
  const navigate = useNavigate();

  const time = moment(completedDate);

  const clickableProps = {
    component: ButtonBase,
    onClick: () => {
      logEvent("HUB_LIVE_ATHLETE_PROFILE_CHART_EXERCISE", {
        [AnalyticsService.PROPERTIES.EXERCISE_ID]: exerciseId,
        [AnalyticsService.PROPERTIES.EVENT_CLICK_LOCATION]: "liveActivityFeed",
      });
      navigate(`/athlete-hub/${athleteId}?chartType=LINE&metadataId=${exerciseId}`);
    },
  };

  return (
    <Box {...clickableProps} width="100%">
      <Box m={1} ml={2} mr={2} width="100%">
        <Grid container alignItems="center" spacing="0px" p={0}>
          <Grid item xs={4}>
            <ExerciseCell athleteId={athleteId} exerciseId={exerciseId} exercise={exercise} measurementId={id} />
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing="0px" p={0}>
              <Grid item xs={9}>
                <Grid container spacing="0px" p={0}>
                  <Grid item xs={3} title={time.toLocaleString()}>
                    <LiveActivityFeedVirtualTableRowCell label="Time" value={time.format("HH:mm")} />
                  </Grid>
                  {!!repMetric && (
                    <Grid item xs={3}>
                      <LiveActivityFeedVirtualTableRowCell label="Reps" value={`${repMetric}`} />
                    </Grid>
                  )}
                  {!!props.weight && (
                    <Grid item xs={3}>
                      <LiveActivityFeedVirtualTableRowCell
                        label="Weight"
                        value={formatUnit(props.weight, ExerciseHelpers.getWeightMetric(exercise))}
                      />
                    </Grid>
                  )}
                  {!!props.variant && (
                    <Grid item xs={3}>
                      <LiveActivityFeedVirtualTableRowCell label="Variant" value={props.variant} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                {defaultMetricMetadata &&
                  defaultMetricMetadata.field !== "weight" &&
                  typeof defaultMetricValue === "number" &&
                  !isNaN(defaultMetricValue) && (
                    <LiveActivityFeedVirtualTableRowCell
                      label={defaultMetricMetadata.name}
                      value={formatUnit(defaultMetricValue, defaultMetricMetadata)}
                    />
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const AthleteCardButton = styled(ButtonBase)(({ theme }) => ({
  // TODO: remove `!important` after MuiV5 migration completion
  justifyContent: "flex-start!important",
  borderRadius: theme.spacing(1.5),
}));

export function LiveActivityFeedVirtualTableRowAthleteLabel(props: { athleteId: string; completedDate: string }) {
  const { athleteId, completedDate } = props;

  const athlete = useAthlete(athleteId, true);
  const navigate = useNavigate();

  function updateLeaderboardExercise() {
    navigate(`/athlete-hub/${athleteId}`);
  }
  const time = moment(completedDate);

  function isOnline() {
    return moment().diff(time, "minutes") < 30;
  }

  return (
    <Box p={0} pl={2} pr={2}>
      <Grid container alignItems="center" spacing="0px" p={0} pt={1} pb={0.5}>
        <Grid item xs component={AthleteCardButton} onClick={updateLeaderboardExercise}>
          <ActiveIndicator
            title="Online"
            color="secondary"
            overlap="rectangular"
            variant={isOnline() ? "dot" : undefined}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <AthleteName {...athlete} id={athleteId} />
          </ActiveIndicator>
        </Grid>
        <Grid item>
          <Box component="span" fontWeight="fontWeightMedium" fontSize={14} title={time.toLocaleString()}>
            {time.fromNow(false /* suffix */)}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export const ExerciseCardGrid = styled(Grid)(({ theme }) => {
  const border = `1px solid ${grey[300]}`;

  return {
    [`& > .${gridClasses.container}`]: {
      border,
      borderBottomWidth: 0,
    },
    [`&:nth-of-type(1) > .${gridClasses.container}`]: {
      // Athlete Name
      border: "1px dashed transparent",
    },
    [`&:nth-of-type(2) > .${gridClasses.container}`]: {
      // First Measurement
      border,
      borderTopRightRadius: theme.spacing(1.5),
      borderTopLeftRadius: theme.spacing(1.5),
      borderBottomWidth: 0,
    },
    [`&:last-of-type > .${gridClasses.container}`]: {
      // Last Measurement
      border,
      borderBottomRightRadius: theme.spacing(1.5),
      borderBottomLeftRadius: theme.spacing(1.5),
    },
  };
});
