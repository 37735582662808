import useDefinedContext from "hooks/useDefinedContext";
import GroupActionsContext from "../GroupActionsContext";

interface UseGroupActionsProps {}

function useGroupActions(props?: UseGroupActionsProps) {
  return useDefinedContext(GroupActionsContext);
}

export default useGroupActions;
