import { useUserContext } from "hooks";
import moment from "moment-timezone";
import { useEffect, useRef } from "react";
import UserService from "services/UserService";

function TimeZoneAutoSetter() {
  const requestRef = useRef(false);
  const [{ profile }, { onUpdate }] = useUserContext();
  const timeZone = profile?.preferences.timeZone;

  useEffect(() => {
    if (timeZone !== null) return;

    const zone = moment.tz.guess(true) || "Europe/Dublin";

    if (requestRef.current) return;

    (async () => {
      try {
        requestRef.current = true;
        await UserService.updateTimeZone(zone);
        // @ts-ignore
        onUpdate({ preferences: { ...profile?.preferences, timeZone: zone } });
      } catch (e) {}
      requestRef.current = false;
    })();
  }, [onUpdate, profile, timeZone]);

  return null;
}

export default TimeZoneAutoSetter;
