import useOneRepMaxStore from "providers/OneRepMaxProvider/useOneRepMaxStore";
import React, { useEffect, useMemo } from "react";
import { ACTIONS, STATUS, SelectedExercisesActionsTypes, SelectedExercisesStateType } from "../../reducer";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions";
import OneRepMaxBulkEditFormHelpers from "helpers/OneRepMaxBulkEditFormHelpers";
import usePreferences from "hooks/usePreferences";
import useMetadataActions from "providers/MetadataProvider/useMetadataActions";
import { Exercise } from "types";

interface useAthleteProfile1RMFormInitialValuesProps {
  selectedExercises: SelectedExercisesStateType[];
  athleteId: string;
  dispatchToSelectedExercises: React.Dispatch<SelectedExercisesActionsTypes>;
}

function useAthleteProfile1RMFormInitialValues(props: useAthleteProfile1RMFormInitialValuesProps) {
  const { selectedExercises, athleteId, dispatchToSelectedExercises } = props;
  const oneRepMaxStore = useOneRepMaxStore();
  const oneRepMaxActions = useOneRepMaxActions();
  const metadataActions = useMetadataActions();
  const [{ imperialSystemEnabled }] = usePreferences();

  useEffect(() => {
    selectedExercises.forEach((selectedExercise) => {
      if (selectedExercise.status === STATUS.PENDING) {
        oneRepMaxActions.getByAthleteIds(selectedExercise.exerciseId, [athleteId]);
        dispatchToSelectedExercises({ type: ACTIONS.SET_STATUS_CALLED, payload: selectedExercise.exerciseId });
      }
    });
  }, [selectedExercises, oneRepMaxActions, athleteId, dispatchToSelectedExercises]);

  const initialValues = useMemo(() => {
    const athleteRef = oneRepMaxStore[athleteId];

    if (!athleteRef) return [];

    const exerciseOneRepMaxes: FormOneRepMax[] = selectedExercises.map(({ exerciseId }) => {
      const exercise = metadataActions.getById(exerciseId)?.metadata as Exercise | undefined;

      const isVariantExercise = Boolean(exercise?.variants.length);

      return OneRepMaxBulkEditFormHelpers.toFormRow(oneRepMaxStore[athleteId]?.[exerciseId], {
        athleteId,
        exerciseId,
        isVariantExercise,
        imperialSystemEnabled,
      });
    });

    return exerciseOneRepMaxes;
  }, [oneRepMaxStore, athleteId, selectedExercises, metadataActions, imperialSystemEnabled]);

  return initialValues;
}

export default useAthleteProfile1RMFormInitialValues;
