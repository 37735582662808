import { ChartLine } from "components/charting/types/Chart.types";
import ChartHelpers from "pages/Charting/helpers/ChartHelpers";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions/useOneRepMaxActions";
import useOneRepMaxStore from "providers/OneRepMaxProvider/useOneRepMaxStore/useOneRepMaxStore";
import { useEffect } from "react";
import useXScale from "../useXScale";
import useYScaleById from "../useYScaleById";
import { useInfoMessage } from "hooks";

function useChart1RMApi(line: ChartLine) {
  const { measure, source } = line;
  const athleteId = source.id;
  const exerciseId = measure.id;
  const scaleId = ChartHelpers.getScaleId(measure);
  const xScale = useXScale();
  const yScale = useYScaleById(scaleId);
  const setInfoMessage = useInfoMessage({ type: "error" });

  const oneRepMax = useOneRepMaxStore();
  const oneRepMaxActions = useOneRepMaxActions();

  useEffect(() => {
    (async () => {
      try {
        await oneRepMaxActions.getByAthleteIds(exerciseId, [athleteId]);
      } catch (error) {
        setInfoMessage({ message: (error as Error).message });
      }
    })();
  }, [athleteId, exerciseId, oneRepMaxActions, setInfoMessage]);

  const oneRepMaxValue = oneRepMax[athleteId]?.[exerciseId];

  return (oneRepMaxValue || []).map((oneRepMax) => {
    const variant = oneRepMax.variant;
    const y = yScale(oneRepMax.weight);
    const xRange = xScale.range();

    return { ...oneRepMax, oneRepMaxLineCoords: xRange.map((x) => ({ variant, x, y })) };
  });
}
export default useChart1RMApi;
