import PropTypes from "prop-types";
import calendarInput from "../../../assets/images/calendar-input.svg";
import { useField } from "formik";
import DateTimePickers from "library/mui5/DateTimePickers";
import Mui5 from "library/mui5";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useCallback, useMemo, useRef, useState } from "react";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import PopoverDateRangePicker from "library/Inputs/PopoverDateRangePicker/PopoverDateRangePicker";

function CustomDateRange(props) {
  const pickerAnchorRef = useRef(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [startInputField, startMeta, startHelpers] = useField(props.startInputName);
  const [endInputField, endMeta, endHelpers] = useField(props.endInputName);

  const togglePicker = useCallback(() => setPickerOpen((prev) => !prev), []);

  const multiInputDateRangeFieldProps = useMemo(
    () => ({
      slots: { textField: Mui5.TextField, separator: () => <Typography textAlign="center">to</Typography> },
      slotProps: {
        textField: ({ position }) => ({
          variant: "standard",
          size: "small",
          InputProps: {
            placeholder: "DD / MM / YYYY",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePicker}>
                  <img style={{ opacity: 0.5 }} alt="calendar icon" src={calendarInput} />
                </IconButton>
              </InputAdornment>
            ),
          },
          error: Boolean(
            position === "start"
              ? Boolean(startMeta.touched && startMeta.error)
              : Boolean(endMeta.touched && endMeta.error)
          ),
          helperText:
            position === "start"
              ? Boolean(startMeta.touched && startMeta.error)
                ? startMeta.error
                : undefined
              : Boolean(endMeta.touched && endMeta.error)
                ? endMeta.error
                : undefined,
        }),
      },
    }),
    [endMeta.error, endMeta.touched, startMeta.error, startMeta.touched, togglePicker]
  );

  const handleUpdate = useCallback(
    ([startDate, endDate]) => {
      startHelpers.setValue(moment(startDate));
      endHelpers.setValue(moment(endDate));
    },
    [endHelpers, startHelpers]
  );

  const dateRangeValues = useMemo(
    () => [moment(startInputField.value), moment(endInputField.value)],
    [endInputField.value, startInputField.value]
  );

  return (
    <div ref={pickerAnchorRef}>
      <DateTimePickers.MultiInputDateRangeField
        value={dateRangeValues}
        onChange={handleUpdate}
        slots={multiInputDateRangeFieldProps.slots}
        disableFuture
        slotProps={multiInputDateRangeFieldProps.slotProps}
        format="DD / MM / YYYY"
      />
      <PopoverDateRangePicker
        anchorEl={pickerAnchorRef.current}
        endDate={dateRangeValues[1]}
        open={pickerOpen}
        setPeriod={(startDate, endDate) => {
          handleUpdate([startDate, endDate]);
        }}
        setOpen={setPickerOpen}
        startDate={dateRangeValues[0]}
      />
    </div>
  );
}

CustomDateRange.propTypes = {
  startInputName: PropTypes.string.isRequired,
  endInputName: PropTypes.string.isRequired,
};
export default CustomDateRange;
