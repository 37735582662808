import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { CompletedWorkout } from "pages/Workouts/Workouts.types";
import * as Components from "./AthleteProfileActivityFeedCompleteWorkouts.components";
import ChevronRight from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import LinkUtilityComponent from "library/mui5/components/LinkUtilityComponent";
import Mui5 from "library/mui5";
import { useTranslation } from "react-i18next";

interface AthleteProfileActivityFeedCompleteWorkoutsProps {
  completedWorkouts: CompletedWorkout[];
}

function AthleteProfileActivityFeedCompleteWorkouts(props: AthleteProfileActivityFeedCompleteWorkoutsProps) {
  const { completedWorkouts } = props;
  const { t } = useTranslation();

  if (!completedWorkouts.length) return null;

  return (
    <Box>
      <Box pt={1} pb={2}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightBold">
            {t("AthleteProfileActivityFeedCompleteWorkouts.title")}
          </Box>
        </Typography>
      </Box>
      <Grid container>
        {completedWorkouts.map((completedWorkout) => (
          <Grid item xs={12} key={completedWorkout.id} pb={1}>
            <Mui5.Paper
              component={(props) => (
                <Components.CompletedWorkoutButtonBase
                  {...props}
                  LinkComponent={LinkUtilityComponent}
                  href={`/workouts/completed/${completedWorkout.id}`}
                />
              )}
            >
              <Box p={2} pt={1.6} pb={1.6} position="relative" width="100%">
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs>
                    <Typography variant="h4" noWrap title={completedWorkout.workoutName}>
                      {completedWorkout.workoutName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton size="small" color="primary">
                      <ChevronRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Mui5.Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AthleteProfileActivityFeedCompleteWorkouts;
