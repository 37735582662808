import { useFilteredExercises } from "hooks";
import useExercises from "providers/ExercisesProvider/useExercises";
import { useMemo } from "react";

function ReportMetadataHOC(ReportComponent) {
  return function ReportMetadataWrapper(props) {
    const exercises = useExercises();
    const { categories } = useFilteredExercises(exercises);

    const modifiedExercises = useMemo(
      () =>
        exercises.map((exercise) => ({
          ...exercise,
          value: exercise.id,
          label: exercise.name,
        })),
      [exercises]
    );

    const modifiedCategories = useMemo(
      () =>
        categories.map((category) => ({
          value: category.replace(/[ `~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, "_").toUpperCase(),
          label: category,
        })),
      [categories]
    );

    if (!exercises.length) {
      return <>Loading Exercises...</>;
    }

    return <ReportComponent {...props} exercises={modifiedExercises} categories={modifiedCategories} />;
  };
}
export default ReportMetadataHOC;
