import { useState } from "react";
import SelectedEntitiesContext from "./SelectedEntitiesContext";
import { SelectedEntitiesContextType } from "./SelectedEntitiesProvider.types";
import SetSelectedEntitiesContext from "./SetSelectedEntitiesContext";

interface SelectedEntitiesProviderProps {
  children?: React.ReactNode;
}

function SelectedEntitiesProvider(props: SelectedEntitiesProviderProps) {
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntitiesContextType>([]);

  return (
    <SetSelectedEntitiesContext.Provider value={setSelectedEntities}>
      <SelectedEntitiesContext.Provider value={selectedEntities}>{props.children}</SelectedEntitiesContext.Provider>
    </SetSelectedEntitiesContext.Provider>
  );
}

export default SelectedEntitiesProvider;
