import * as Yup from "yup";

export const errorMessages = {
  exercise: "Select an exercise",
  repetitionField: "Select an exercise repetition metric",
  setComparisonFilters: "Please select at least one set",
};

export const schema = Yup.object()
  .shape({
    exerciseId: Yup.object().required(errorMessages.exercise),
    repetitionField: Yup.object().required(errorMessages.repetitionField),
    setComparisonFilters: Yup.array().of(Yup.object().required()).min(1, errorMessages.setComparisonFilters).required(),
  })
  .required();
