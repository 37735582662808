import { useEffect, useState } from "react";
import { WellnessStore } from "./WellnessMetadataProvider.types";
import WellnessMetadataContext from "./contexts/WellnessMetadataContext";
import WellnessMetadataService from "services/WellnessMetadataService";
import { WellnessMetadata } from "types";

interface WellnessMetadataProviderProps {
  children?: React.ReactNode;
}

function WellnessMetadataProvider(props: WellnessMetadataProviderProps) {
  const [wellness, setWellness] = useState<WellnessStore>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = (await WellnessMetadataService.getAll()) as WellnessMetadata[];

        setWellness(response);
      } catch (error) {}
    })();
  }, []);

  return <WellnessMetadataContext.Provider value={wellness}>{props.children}</WellnessMetadataContext.Provider>;
}

export default WellnessMetadataProvider;
