import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import UnitHelper from "helpers/UnitHelper";
import { OneRepMaxValue, Variants } from "types";

class OneRepMaxBulkEditFormHelpers {
  static defaults = Object.freeze({
    id: "",
    exerciseId: "",
    createdDate: "",
    variant: null,
    weight: null,
    type: "ManualInput" as const,
  });

  static toFormRow(
    oneRepMaxes: OneRepMaxValue[] = [],
    {
      athleteId,
      exerciseId,
      imperialSystemEnabled,
      isVariantExercise,
    }: {
      athleteId: string;
      exerciseId: string;
      imperialSystemEnabled?: boolean;
      isVariantExercise?: boolean;
    }
  ): FormOneRepMax {
    const defaultOrLeftOneRepMax = isVariantExercise
      ? oneRepMaxes.find(({ variant }) => variant === Variants.Left)
      : (oneRepMaxes[0] as OneRepMaxValue | undefined);

    const rightOneRepMax = isVariantExercise
      ? oneRepMaxes.find(({ variant }) => variant === Variants.Right)
      : undefined;

    const formOneRepMax: FormOneRepMax = {
      athleteId,
      exerciseId,
      oneRepMaxes: [
        {
          ...OneRepMaxBulkEditFormHelpers.defaults,
          exerciseId,
          variant: isVariantExercise ? Variants.Left : null,
          ...defaultOrLeftOneRepMax,
        },
        {
          ...OneRepMaxBulkEditFormHelpers.defaults,
          exerciseId,
          variant: Variants.Right,
          ...rightOneRepMax,
        },
      ].slice(0, isVariantExercise ? 2 : 1),
    };

    return {
      ...formOneRepMax,
      oneRepMaxes: formOneRepMax.oneRepMaxes.map((oneRepMax) => ({
        ...oneRepMax,
        weight: imperialSystemEnabled
          ? UnitHelper.convert(oneRepMax.weight, UnitHelper.units.Kilogram, UnitHelper.units.Pound)
          : oneRepMax.weight,
      })),
    };
  }

  static fromFormRow(
    values: FormOneRepMax,
    { imperialSystemEnabled }: { imperialSystemEnabled?: boolean }
  ): {
    athleteId: string;
    oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
  } {
    return {
      athleteId: values.athleteId,
      oneRepMaxes: values.oneRepMaxes.map(({ exerciseId, weight, variant }) => ({
        exerciseId,
        variant,
        weight:
          (imperialSystemEnabled
            ? UnitHelper.convert(Number(weight), UnitHelper.units.Pound, UnitHelper.units.Kilogram)
            : Number(weight)) || 0,
      })),
    };
  }
}

export default OneRepMaxBulkEditFormHelpers;
