import { firebaseApp } from "config/firebase";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";

class RemoteConfigService {
  static getBooleanValue(key) {
    const rc = getRemoteConfig(firebaseApp);

    rc.settings.minimumFetchIntervalMillis = process.env.REACT_APP_REMOTE_CONFIG_MINIMUM_FETCH_INTERVAL_MILLIS;

    return fetchAndActivate(rc).then(() => {
      return getValue(rc, key).asBoolean();
    });
  }

  static getStringValue(key) {
    const rc = getRemoteConfig(firebaseApp);

    rc.settings.minimumFetchIntervalMillis = process.env.REACT_APP_REMOTE_CONFIG_MINIMUM_FETCH_INTERVAL_MILLIS;

    return fetchAndActivate(rc).then(() => {
      return getValue(rc, key).asString() || "";
    });
  }

  static getJsonValue(key) {
    const rc = getRemoteConfig(firebaseApp);

    rc.settings.minimumFetchIntervalMillis = process.env.REACT_APP_REMOTE_CONFIG_MINIMUM_FETCH_INTERVAL_MILLIS;

    return fetchAndActivate(rc).then(() => {
      return JSON.parse(getValue(rc, key).asString() || "[]");
    });
  }
}
export default RemoteConfigService;
