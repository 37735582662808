import { ScaleLinear, ScaleTime } from "d3-scale";
import { createContext } from "react";

export interface ChartScalesContextType {
  y: { [scaleId: string]: ScaleLinear<number, number, never> };
  x: ScaleTime<number, number, never> | ScaleLinear<number, number, never>;
}

const ChartScalesContext = createContext<ChartScalesContextType | undefined>(undefined);

export default ChartScalesContext;
