import * as Yup from "yup";

export const errorMessages = {
  name: "Add your name",
};

export const schema = Yup.object()
  .shape({
    name: Yup.string().max(100).required(errorMessages.name),
  })
  .required();
