import useDefinedContext from "hooks/useDefinedContext";
import SearchTextContext from "../SearchTextContext";

interface UseSearchTextProps {}

function useSearchText(props?: UseSearchTextProps) {
  return useDefinedContext(SearchTextContext);
}

export default useSearchText;
