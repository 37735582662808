import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import * as LoginSchema from "./LoginForm.schema";
import Typography from "@mui/material/Typography";
import ForgotPassword from "../ForgotPasswordButton";
import eyeIconDark from "../../../assets/images/eye-icon-dark.svg";
import ohLogo from "../../../assets/images/Logo.svg";
import Mui5 from "library/mui5";
import * as LoginComponents from "../Login.components";
import Box from "@mui/material/Box";
import useTogglePasswordInputType from "hooks/useTogglePasswordInputType";

const initialValues = {
  email: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_DEVELOPMENT_EMAIL_ACCOUNT}` : "",
  password: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_DEVELOPMENT_PASSWORD}` : "",
};

interface LoginFormProps {
  onSignIn(values: typeof initialValues): Promise<void>;
  errorMessage?: string;
  clearErrors(): void;
}

function LoginForm(props: LoginFormProps) {
  const { type, toggleType } = useTogglePasswordInputType();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={props.onSignIn}
      validationSchema={LoginSchema.schema}
      onReset={props.clearErrors}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={480}>
            <Grid item xs={12} justifyContent="center">
              <img src={ohLogo} className="oh-logo" alt="logo" width={240} />
            </Grid>
            <Grid item xs={12}>
              <LoginComponents.LoginFormText
                id="login-email-address-input"
                name="email"
                label="Email Address"
                placeholder="Enter your email address"
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box position="relative">
                <LoginComponents.LoginFormText
                  id="login-password-input"
                  type={type}
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  variant="standard"
                  color="secondary"
                  fullWidth
                  InputProps={{
                    endAdornment: <img src={eyeIconDark} onClick={toggleType} alt="show plain text password" />,
                  }}
                />
                <ForgotPassword />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                type="submit"
                color="secondary"
                fullWidth
                size="large"
                variant="contained"
                disabled={isSubmitting}
                disableElevation
              >
                Submit
              </Mui5.Button>
            </Grid>
            {!!props.errorMessage && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  {props.errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
