import { Coach } from "types/coach";

class CoachHelpers {
  static getProfileImagePath(coach?: Pick<Coach, "id" | "profilePhotoName"> | null) {
    return coach?.profilePhotoName && `coaches/${coach.id}/${coach.profilePhotoName}`;
  }

  static getInitials(coach: Pick<Coach, "name">) {
    return coach.name
      .split(" ")
      .slice(0, 2)
      .filter(Boolean)
      .reduce((initials, name) => `${initials}${name[0]}`, "");
  }
}

export default CoachHelpers;
