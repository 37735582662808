import React from "react";

interface NoDataWrapperProps {
  hasData: boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

function NoDataWrapper(props: NoDataWrapperProps) {
  const { hasData = false, children, fallback = null } = props;

  if (!hasData) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}

export default NoDataWrapper;
