import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  "12": (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.00118" cy="3.97573" r="2.47573" stroke="currentColor" />
      <path
        d="M2.04001 10.5001C2.74345 9.0364 4.25275 8.02441 6.00117 8.02441C7.74959 8.02441 9.2589 9.0364 9.96234 10.5001"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  ),
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="8.00153" cy="5.30097" rx="3.30097" ry="3.30097" stroke="currentColor" strokeWidth="1.2" />
      <path
        d="M2.71997 14C3.65789 12.0483 5.6703 10.699 8.00152 10.699C10.3327 10.699 12.3452 12.0483 13.2831 14"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12.0024" cy="7.95146" rx="4.95146" ry="4.95146" stroke="currentColor" strokeWidth="1.8" />
      <path
        d="M4.08002 21C5.4869 18.0725 8.50551 16.0485 12.0023 16.0485C15.4992 16.0485 18.5178 18.0725 19.9247 21"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="16.0031" cy="10.6019" rx="6.60194" ry="6.60194" stroke="currentColor" strokeWidth="2.2" />
      <path
        d="M5.44 28C7.31584 24.0967 11.3407 21.3981 16.0031 21.3981C20.6656 21.3981 24.6904 24.0967 26.5662 28"
        stroke="currentColor"
        strokeWidth="2.2"
        strokeLinecap="round"
      />
    </svg>
  ),
};

function ProfileIcon(props: Omit<SvgIconProps, "fontSize"> & { fontSize?: "large" | "small" | "12" }) {
  const item = size[props.fontSize || "default"];

  return (
    <SvgIcon
      {...props}
      fontSize={props.fontSize === "12" ? undefined : props.fontSize}
      sx={props.fontSize === "12" ? { fontSize: 12 } : undefined}
    >
      {item}
    </SvgIcon>
  );
}
export default ProfileIcon;
