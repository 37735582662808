import { AxisBottom as VisxAxisBottom } from "@visx/axis";
import { AxisScale } from "@visx/axis";
import useChartingColors from "pages/Charting/hooks/useChartingColors";

interface AxisBottomProps {
  top?: number;
  scale: AxisScale;
}

function AxisBottom(props: AxisBottomProps) {
  const { top, scale } = props;
  const colors = useChartingColors();

  return (
    <>
      <VisxAxisBottom
        top={top}
        scale={scale}
        stroke={colors.axis.line} // line color
        tickStroke={colors.axis.line} // tick line color
        tickLabelProps={() => ({
          fill: colors.axis.line, // tick line color
          fontSize: 12,
          textAnchor: "middle",
          // dy: "-0.3em",
          dx: "-0.2em",
        })}
        strokeWidth={1}
        // hideTicks
        // numTicks={}
      />
    </>
  );
}

export default AxisBottom;
