import { useEffect, useCallback } from "react";
import { useAthleteMonitoringStatusesContext, useLoadingContext } from "hooks";
import useMonitoringTemplateItems from "hooks/useMonitoringTemplateItems";
import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";

interface UseAthleteRagStatusesProps {
  athleteIds: string[];
}

function useAthleteRagStatuses(props: UseAthleteRagStatusesProps) {
  const [ragStatusesStore, { getTemplateByAthleteIds }] = useAthleteMonitoringStatusesContext();
  const [, setLoading] = useLoadingContext();
  const template = useMonitoringTemplateItems();
  const archivedCustomExerciseIds = useArchivedCustomExercises(true);

  useEffect(() => {
    if (!props.athleteIds.length) return;

    (async () => {
      try {
        setLoading(true);
        await getTemplateByAthleteIds(props.athleteIds);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    })();
  }, [getTemplateByAthleteIds, props.athleteIds, setLoading]);

  const getAthleteRagStatuses = useCallback(
    (athleteId: string) => {
      return template
        ?.filter((item) => !archivedCustomExerciseIds.includes(item.metadataId))
        .map((ragMetadata) => {
          const athleteRagStatuses = ragStatusesStore[athleteId];
          const ragStatus = athleteRagStatuses && athleteRagStatuses[ragMetadata.metadataId];
          return ragStatus;
        });
    },
    [archivedCustomExerciseIds, ragStatusesStore, template]
  );

  return getAthleteRagStatuses;
}

export default useAthleteRagStatuses;
