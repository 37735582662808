import { MultiFactorError } from "firebase/auth";
import { useCallback, useMemo, useState } from "react";
import MfaService from "services/MfaService";
import UserService from "services/UserService";

interface UseLoginHandlersProps {
  setError(error: null | Error): void;
}

function useLoginHandlers({ setError }: UseLoginHandlersProps) {
  const [showMfa, setShowMfa] = useState(false);

  const mfaService = useMemo(() => new MfaService("recaptcha-mfa-signin-container"), []);

  const clearError = useCallback(() => setError(null), [setError]);

  const catchSignInError = useCallback(
    async (error: MultiFactorError) => {
      if (error.code === "auth/user-disabled") {
        error.message = "Your account has been disabled.";
      }
      setError(error);
      await UserService.signOut();
    },
    [setError]
  );

  const onMfaFlowRequest = useCallback(
    async (error: MultiFactorError) => {
      if (error.code !== "auth/multi-factor-auth-required") throw error;

      await mfaService.init(error);

      setShowMfa(true);
    },
    [mfaService]
  );

  const onVerifyMfaCode = useCallback(
    async (smsCode: string) => {
      await mfaService.verifyCode(smsCode).catch(catchSignInError);
    },
    [catchSignInError, mfaService]
  );

  const onSignIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      clearError();

      await UserService.signIn(email, password) //
        .catch(onMfaFlowRequest)
        .catch(catchSignInError);
    },
    [clearError, catchSignInError, onMfaFlowRequest]
  );

  const onMfaCancel = useCallback(() => {
    setShowMfa(false);
  }, []);

  return {
    showMfa,
    onSignIn,
    onMfaCancel,
    onVerifyMfaCode,
    requestVerificationCode: mfaService.requestVerificationCode,
    mfaHint: mfaService.hint,
  };
}
export default useLoginHandlers;
