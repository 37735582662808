import React from "react";
import GroupsContext from "../contexts/GroupsContext";

function useGroupsContext() {
  const metadata = React.useContext(GroupsContext);

  if (!metadata) throw new Error("GroupsContext.Provider does not exist");

  return metadata;
}
export default useGroupsContext;
