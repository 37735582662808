import { useCallback } from "react";
import useRole from "providers/RoleProvider/useRole";
import CanDoService from "services/CanDoService";
import { CanDoActions } from "../../types/CanDoActions";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useRemoteConfig from "providers/RemoteConfigProvider/useRemoteConfig";

function useCanDo(): (actionName: CanDoActions) => boolean;
function useCanDo(actionName: CanDoActions): boolean;
function useCanDo(actionName?: CanDoActions) {
  const role = useRole();
  const organisation = useOrganisation();
  const HUB_DASHBOARDS_ENABLED = useRemoteConfig("HUB_DASHBOARDS_ENABLED");

  const check = useCallback(
    (action: CanDoActions) => {
      return CanDoService.check(action, {
        role,
        organisation,
        remoteConfig: {
          HUB_DASHBOARDS_ENABLED,
        },
      });
    },
    [role, organisation, HUB_DASHBOARDS_ENABLED]
  );

  if (typeof actionName !== "undefined") return check(actionName);

  return check;
}

export default useCanDo;
