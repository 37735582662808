import { useEffect } from "react";
import ActivityFeedService from "services/ActivityFeedService";
import { FireStoreMeasurement } from "types";

function useMeasurementsSubscription(
  {
    organisationId,
    limit = 25,
    startDate,
    endDate,
  }: {
    organisationId: string;
    limit?: number;
    startDate?: string;
    endDate?: string;
  },
  setUpdatedActivity: (measurements: FireStoreMeasurement[]) => void
) {
  useEffect(() => {
    const unsubscribe = ActivityFeedService.subscribe(
      { organisationId, startDate, limit, endDate },
      setUpdatedActivity
    );

    return () => {
      unsubscribe();
    };
  }, [endDate, limit, organisationId, setUpdatedActivity, startDate]);
}
export default useMeasurementsSubscription;
