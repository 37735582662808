import { createContext } from "react";
import { MeasurementSet } from "types";

export interface ChartSetSelectContextType {
  value: MeasurementSet[];
  onChange(sets: MeasurementSet[]): void;
  options: MeasurementSet[];
}

const ChartSetSelectContext = createContext<ChartSetSelectContextType | undefined>(undefined);

ChartSetSelectContext.displayName = "ChartSetSelectContext";

export default ChartSetSelectContext;
