import { useUnitFormatter } from "hooks";
import { LinePointData } from "components/charting/types/Chart.types";
import { useCallback, useMemo } from "react";
import { Wellness } from "types";
import { MetadataType } from "types/metadata";
import useChartOptions from "./useChartOptions";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";
import useAnthropometricMetadata from "providers/AnthropometricMetadataProvider/useAnthropometricMetadata";

function useChartMeasureData(props: LinePointData) {
  const options = useChartOptions();
  const wellnesses = useWellnessMetadata();
  const anthropometrics = useAnthropometricMetadata();
  const unitFormatter = useUnitFormatter();

  const repIndex = "repIndex" in props ? props.repIndex : undefined;

  const filterMetricsWhenMeasurementComputed = useCallback(
    (metric: { value: any }) => (props.computed ? typeof metric.value !== "undefined" : true),
    [props.computed]
  );

  const measure = useMemo(() => {
    const measure = options.measures.find((metadata) => metadata.id === props.measure.id);

    // defaults
    let category = props.metadataType;
    let name = props.measure.id;
    let completedDate = "";

    if (measure?.type === MetadataType.WELLNESS && measure?.type === props.metadataType) {
      return {
        category: measure?.type,
        type: measure?.type,
        name: measure?.metadata.name,
        completedDate: props.measurement.measurementDate,
        metadata: measure.metadata,
        metrics: wellnesses
          .map((metadata) => ({
            id: metadata.id,
            field: metadata.field,
            value: props.measurement[metadata.field as keyof Wellness],
            label: metadata.name,
          }))
          .filter(filterMetricsWhenMeasurementComputed),
      };
    } else if (measure?.type === MetadataType.ANTHROPOMETRIC && measure?.type === props.metadataType) {
      return {
        category: measure?.type,
        type: measure?.type,
        name: measure?.metadata.label,
        completedDate: props.measurement.measurementDate,
        metadata: measure.metadata,
        metrics: anthropometrics
          .map((metadata) => ({
            id: metadata.value,
            field: metadata.value,
            value: unitFormatter(props.measurement.value, metadata),
            label: metadata.label,
          }))
          .filter((m) => m.id === props.measure.id)
          .filter(filterMetricsWhenMeasurementComputed),
      };
    } else if (measure?.type === MetadataType.EXERCISE && measure?.type === props.metadataType) {
      return {
        category: measure?.type,
        type: measure?.type,
        name: measure?.metadata.name,
        completedDate: props.measurement.completedDate,
        metadata: measure.metadata,
        metrics: measure.metadata.metrics
          .map((metricMetadata) => ({
            id: metricMetadata.field,
            field: metricMetadata.field,
            value: unitFormatter(
              props.measurement.metrics.find((metric) => metric.field === metricMetadata.field)?.value,
              metricMetadata
            ),
            label: metricMetadata.name,
          }))
          .filter(filterMetricsWhenMeasurementComputed),
        repMetrics:
          typeof repIndex === "number"
            ? measure.metadata.repetitions.map((repMetricMetadata) => ({
                id: repMetricMetadata.field,
                field: repMetricMetadata.field,
                value:
                  typeof repIndex === "number"
                    ? unitFormatter(
                        props.measurement.repetitions[repIndex].find(
                          (metric) => metric.field === repMetricMetadata.field
                        )?.value,
                        repMetricMetadata
                      )
                    : null,
                label: repMetricMetadata.name,
              }))
            : [],
      };
    }

    return { category, name, completedDate, metrics: [] };
  }, [
    anthropometrics,
    filterMetricsWhenMeasurementComputed,
    options.measures,
    props.measure.id,
    props.measurement,
    props.metadataType,
    repIndex,
    unitFormatter,
    wellnesses,
  ]);

  return measure;
}
export default useChartMeasureData;
