import useLoading from "hooks/useLoading";
import * as Components from "./Loader20.components";

interface Loader20Props {
  disableBackdrop?: boolean;
}

function Loader20(props: Loader20Props) {
  const { disableBackdrop = false } = props;
  const [loading] = useLoading();

  if (!loading) return null;

  return (
    <>
      {disableBackdrop || <Components.BackdropDiv />}
      <Components.LoaderDiv />
    </>
  );
}

export default Loader20;
