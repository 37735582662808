import Grid from "@mui/material/Grid";
import useAthleteRagStatuses from "hooks/useAthleteRagStatuses";
import MonitoringIndicator from "./MonitoringIndicator";

interface MonitoringIndicatorsRowProps {
  athleteId: string;
}

function MonitoringIndicatorsRow(props: MonitoringIndicatorsRowProps) {
  const { athleteId } = props;
  const getAthleteRagStatuses = useAthleteRagStatuses({ athleteIds: [] });
  const ragStatuses = getAthleteRagStatuses(athleteId);

  return (
    <Grid container justifyContent="space-evenly" alignItems="center" height="100%">
      {ragStatuses?.map((ragStatus, index) => {
        return (
          <Grid key={ragStatus?.metadataId + "-" + index} item xs={2} textAlign="center">
            <MonitoringIndicator
              athleteId={athleteId}
              hasValues={Boolean(ragStatus?.values.find((value) => value.progressArrow !== -2))}
              {...ragStatus}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default MonitoringIndicatorsRow;
