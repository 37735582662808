import { Athlete } from "types/athlete";
import { useAthleteRagStatuses } from "hooks";
import { useMemo } from "react";

interface AthleteMonitoringFetchWrapperProps {
  athletes: Athlete[];
  children: React.ReactNode;
}

function AthleteMonitoringFetchWrapper({ children, athletes }: AthleteMonitoringFetchWrapperProps) {
  const athleteIds = useMemo(() => athletes.map((athlete) => athlete.id), [athletes]);

  useAthleteRagStatuses({ athleteIds });

  return <>{children}</>;
}

export default AthleteMonitoringFetchWrapper;
