import PropTypes from "prop-types";
import muiTheme from "./muiTheme";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

function MuiThemeProvider(props) {
  return <ThemeProvider theme={createTheme(muiTheme)}>{props.children}</ThemeProvider>;
}

MuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiThemeProvider;
