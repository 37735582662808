import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function AssignAthletesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.89543 3.89543 4 5 4H15.9829C16.6316 4 17.24 4.31462 17.6149 4.84397L21.9755 11L19.441 14.578C19.1218 15.0287 18.4977 15.1353 18.047 14.816C17.5963 14.4968 17.4897 13.8727 17.809 13.422L19.5245 11L15.9829 6L5 6V16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H5C3.89543 18 3 17.1046 3 16V6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 16C18.6642 16 19 16.3358 19 16.75V22.75C19 23.1642 18.6642 23.5 18.25 23.5C17.8358 23.5 17.5 23.1642 17.5 22.75V16.75C17.5 16.3358 17.8358 16 18.25 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 19.75C22 20.1642 21.6642 20.5 21.25 20.5L15.25 20.5C14.8358 20.5 14.5 20.1642 14.5 19.75C14.5 19.3358 14.8358 19 15.25 19L21.25 19C21.6642 19 22 19.3358 22 19.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default AssignAthletesIcon;
