import { useField } from "formik";

/**
 * Added to fix type error
 * @returns {[{ error: boolean }]} */
function useMuiField(name, props = {}) {
  const [field, meta, helpers] = useField(name);

  const muiFieldProps = {
    name,
    onChange: (event) => helpers.setValue(event.target.value),
    value: field.value,
    onBlur: helpers.setTouched,
    error: !!Boolean(meta.touched && meta.error),
    helperText: meta.touched && meta.error ? meta.error : null,
  };

  return [muiFieldProps, meta, helpers];
}
export default useMuiField;
