import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { useMemo } from "react";
import ChartTooltipManagerContext from "components/charting/contexts/ChartTooltipManagerContext";
import useParentDimensions from "components/charting/hooks/useParentDimensions";

interface ChartTooltipProviderProps {
  children: React.ReactElement;
}

function ChartTooltipManagerProvider<T>(props: ChartTooltipProviderProps) {
  const { width, height } = useParentDimensions();
  const tooltipParams = useTooltip<T>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: false,
    detectBounds: true,
  });

  const contextValue = useMemo(() => ({ ...tooltipParams, TooltipInPortal }), [TooltipInPortal, tooltipParams]);

  return (
    <ChartTooltipManagerContext.Provider value={contextValue}>
      <svg ref={containerRef} width={width} height={height}>
        {props.children}
      </svg>
    </ChartTooltipManagerContext.Provider>
  );
}

export default ChartTooltipManagerProvider;
