import { CsvColumn } from "./CsvHelpers.types";

class CsvHelpers {
  /**
   * Simple safari detection based on user agent test
   */
  static isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  /**
   * Inspired by {@link|https://github.com/react-csv/react-csv/blob/master/src/core.js}
   */
  static buildURI<T extends {}>(
    headers: CsvColumn<T>[],
    data: Partial<T>[],
    options?: { uFEFF: boolean; separator: string }
  ) {
    const { uFEFF = false, separator = "," } = options || {};

    const csvContent = [
      headers.map((header) => header.label).join(separator),
      ...data.map((row) => headers.map((header) => `${row[header.key]}`).join(separator)),
    ];

    const csv = csvContent.join(`\n`);

    const type = CsvHelpers.isSafari() ? "application/csv" : "text/csv";
    const blob = new Blob([uFEFF ? "\uFEFF" : "", csv], { type });
    const dataURI = `data:${type};charset=utf-8,${uFEFF ? "\uFEFF" : ""}${csv}`;

    const URL = window.URL || window.webkitURL;

    return typeof URL.createObjectURL === "undefined" ? dataURI : URL.createObjectURL(blob);
  }

  static readFileAsText(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const csvString = reader.result as string;
          const rowDelimiter = csvString.indexOf("\r\n") !== -1 ? "\r\n" : "\n";
          const rowArray = (reader.result as string).split(rowDelimiter);
          const rowAndColumnArray = rowArray.map((row) => row.split(","));
          resolve(rowAndColumnArray);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsText(file);
    });
  }
}

export default CsvHelpers;
