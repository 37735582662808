import { FireStoreMeasurement } from "types";
import { useCallback, useEffect, useMemo, useReducer } from "react";

import { firstBy } from "thenby";
import liveActivityFeedReducer, { LiveActivityFeedActions } from "./LiveActivityFeed.reducer";

function useLiveActivityFeedStore(
  oldestCompletedDateRef: React.MutableRefObject<string | undefined>
): [FireStoreMeasurement[], (measurements: FireStoreMeasurement[]) => void] {
  const [store, dispatch] = useReducer(liveActivityFeedReducer, {});

  const setActivity = useCallback((measurements: FireStoreMeasurement[]) => {
    dispatch({ type: LiveActivityFeedActions.ADD, payload: measurements });
  }, []);

  const activity: FireStoreMeasurement[] = useMemo(() => {
    // 1. Here, just check if athleteId exists in store. If not exclude the measurement
    // 2. Shoot a http request for athletes that don't exist in store
    // If they've not already been requested
    return Object.values(store); // .filter(includedAllowedAthleteIds);
  }, [store]);

  useEffect(() => {
    // Refresh 'since' timers on live feed every 10 seconds.
    const interval = setInterval(() => {
      dispatch({ type: LiveActivityFeedActions.REFRESH });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return useMemo(() => {
    const sortedActivity = activity.sort(firstBy("completedDate", "desc"));

    // set the oldest completed date for the show more on scroll API call.
    oldestCompletedDateRef.current = [...sortedActivity].pop()?.completedDate;

    return [sortedActivity, setActivity];
  }, [activity, oldestCompletedDateRef, setActivity]);
}
export default useLiveActivityFeedStore;
