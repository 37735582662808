import { LineData } from "components/charting/types/Chart.types";
import { useMemo } from "react";
import { Variants } from "types/exercise";

function useChartLineVariantSeparator(lines: LineData[], entityIds: string[]) {
  return useMemo(
    () =>
      lines
        .filter((line) => entityIds.includes(line.entityId))
        .reduce<LineData[]>((acc, lineData) => {
          const bilateral: LineData = {
            ...lineData,
            line: [],
          };
          const left: LineData = {
            ...lineData,
            variant: Variants.Left,
            line: [],
          };
          const right: LineData = {
            ...lineData,
            variant: Variants.Right,
            line: [],
          };

          lineData.line.forEach((linePoint) => {
            if (linePoint.variant === Variants.Left) {
              left.line.push(linePoint);
            } else if (linePoint.variant === Variants.Right) {
              right.line.push(linePoint);
            } else {
              bilateral.line.push(linePoint);
            }
          });

          return [...acc, bilateral, left, right];
        }, [])
        .filter((lineData) => lineData.line.length > 0),
    [lines, entityIds]
  );
}
export default useChartLineVariantSeparator;
