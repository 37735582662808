import ButtonGroup from "components/ButtonGroup";
import useChartOptions from "../../../hooks/useChartOptions";
import useChartSelections from "../../../../../components/charting/hooks/useChartSelections";
import useSetChartSelections from "../../../hooks/useSetChartSelections";

interface AggregationSelectorProps {}

function AggregationSelector(props: AggregationSelectorProps) {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();

  return (
    <ButtonGroup
      setOption={(aggregationPeriod) => setSelections({ aggregationPeriod })}
      value={selections.aggregationPeriod}
      options={options.aggregationPeriod}
    />
  );
}

export default AggregationSelector;
