import { useCallback } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import ButtonGroup from "components/ButtonGroup";
import MenuItemCheckbox from "library/Inputs/MenuItemCheckbox";
import useChartOptions from "pages/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import MeasureHelpers from "pages/Charting/helpers/MeasureHelpers";
import useDataVisualizationType from "../hooks/useDataVisualizationType";
import { DataVisualizationType } from "../config/chartTypes";
import Tooltip from "components/Tooltip";
import { EntityType } from "types";
import { useTranslation } from "react-i18next";

interface ChartingAdvancedOptionsButtonsProps {}

function ChartingAdvancedOptionsButtons(props: ChartingAdvancedOptionsButtonsProps) {
  const { t } = useTranslation();
  const selections = useChartSelections();
  const options = useChartOptions();
  const setSelections = useSetChartSelections();
  const vizType = useDataVisualizationType();

  const curryToggleOption = useCallback(
    (key: "showPersonalBest" | "showAverage" | "showStandardDeviation" | "showPlotFromZero" | "showOneRepMax") =>
      () => {
        setSelections((prevSelections) => ({ [key]: !prevSelections[key] }));
      },
    [setSelections]
  );

  const isNotLineChart = vizType !== DataVisualizationType.LINE_CHART;

  const disablePbs = isNotLineChart;
  const disable1RM = Boolean(MeasureHelpers.get1RMLineMeasures(selections.measures).length === 0) || isNotLineChart;
  const disableAverage = Boolean(MeasureHelpers.getValidMeasures(selections.measures).length > 1) || isNotLineChart;
  const disableStandardDeviation = Boolean(
    disableAverage ||
      selections.sources.length > 1 ||
      selections.sources.find((source) => source.type === EntityType.GROUP) ||
      isNotLineChart
  );
  const disableAggregationType = selections.aggregationPeriod.value === null || isNotLineChart;
  const disablePlotFromZero =
    vizType !== DataVisualizationType.LINE_CHART && vizType !== DataVisualizationType.ONE_REP_MAX_CHART;

  return (
    <MenuList
      sx={{
        paddingTop: 0,
        "& .MuiMenuItem-root:first-of-type": { paddingTop: 0 },
      }}
    >
      <MenuItem onClick={curryToggleOption("showPersonalBest")} disabled={disablePbs}>
        <Box p={0.6} pr={3} display="flex" alignItems="center">
          <MenuItemCheckbox size="medium" checked={selections.showPersonalBest} />
          <Typography variant="h4">{t("Charting.advancedOptionsShowPersonalBestHeader")}</Typography>
        </Box>
      </MenuItem>
      <Tooltip
        title={disable1RM ? t("Charting.advancedOptionsShow1RMLineInfoTooltip") : undefined}
        arrow
        placement="right"
      >
        <Box pt={"6px"}>
          <MenuItem onClick={curryToggleOption("showOneRepMax")} disabled={disable1RM}>
            <Box p={0.6} pr={3} display="flex" alignItems="center">
              <MenuItemCheckbox size="medium" checked={selections.showOneRepMax} />
              <Typography variant="h4">{t("Charting.advancedOptionsShow1RMLineHeader")}</Typography>
            </Box>
          </MenuItem>
        </Box>
      </Tooltip>
      <MenuItem onClick={curryToggleOption("showAverage")} disabled={disableAverage}>
        <Box p={0.6} pr={3} display="flex" alignItems="center">
          <MenuItemCheckbox size="medium" checked={selections.showAverage} />
          <Typography variant="h4">{t("Charting.advancedOptionsShowAverageValue")}</Typography>
        </Box>
      </MenuItem>
      <MenuItem onClick={curryToggleOption("showStandardDeviation")} disabled={disableStandardDeviation}>
        <Box p={0.6} pr={3} display="flex" alignItems="center">
          <MenuItemCheckbox size="medium" checked={selections.showStandardDeviation} />
          <Typography variant="h4">{t("Charting.advancedOptionsShowStandardDeviation")}</Typography>
        </Box>
      </MenuItem>
      <MenuItem onClick={curryToggleOption("showPlotFromZero")} disabled={disablePlotFromZero}>
        <Box p={0.6} pr={3} display="flex" alignItems="center">
          <MenuItemCheckbox size="medium" checked={selections.showPlotFromZero} />
          <Typography variant="h4">{t("Charting.advancedOptionsPlotFromZero")}</Typography>
        </Box>
      </MenuItem>
      <ListItem>
        <Grid container width="100%" spacing={0} columns={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom={true}>
              {t("Charting.advancedOptionsAggregationHeader")}
            </Typography>
          </Grid>
          <Grid item xs={12} maxWidth={0}>
            <ButtonGroup
              disabled={disableAggregationType}
              size="small"
              setOption={(aggregationType) => setSelections({ aggregationType })}
              value={selections.aggregationType}
              options={options.aggregationType}
            />
          </Grid>
        </Grid>
      </ListItem>
    </MenuList>
  );
}

export default ChartingAdvancedOptionsButtons;
