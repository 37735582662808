import { styled } from "@mui/material";

const StatusHandlerContainer = styled("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: props.theme.spacing(1.25),
  width: "100%",
  height: "100%",
  padding: props.theme.spacing(6),
}));
export default StatusHandlerContainer;
