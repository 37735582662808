import Mui5 from "library/mui5";
import { useMemo, useState } from "react";
import * as Components from "./GroupsTable.components";
import GroupsTableRow from "./GroupsTableRow";
import GroupsTableHeader from "./GroupsTableHeader";
import { firstBy } from "thenby";
import { Group } from "contexts/GroupsContext/GroupsContext";
import GroupsTableControls from "./GroupsTableControls";
import naturalCompare from "config/sort/naturalSort";

interface GroupsTableProps {
  groups: Group[];
}

function GroupsTable(props: GroupsTableProps) {
  const [sortBy, setSortBy] = useState<{ key: keyof Group; direction: "desc" | "asc" }>({
    key: "name",
    direction: "asc",
  });

  const groups = useMemo(() => {
    const naturalSort = (a: Group, b: Group) => naturalCompare(a.name, b.name);

    return props.groups
      .slice()
      .sort(
        sortBy.key === "name"
          ? firstBy(naturalSort, { ignoreCase: true, direction: sortBy.direction })
          : firstBy(sortBy.key, { ignoreCase: true, direction: sortBy.direction }).thenBy(naturalSort)
      );
  }, [props.groups, sortBy.direction, sortBy.key]);

  return (
    <Mui5.Paper component={Components.CardWrapper}>
      <GroupsTableControls groups={props.groups} />
      <GroupsTableHeader sortBy={sortBy} onSort={setSortBy} groups={groups} />
      <Components.ScrollBox>
        {groups.map((group) => (
          <GroupsTableRow key={group.id} {...group} />
        ))}
      </Components.ScrollBox>
    </Mui5.Paper>
  );
}

export default GroupsTable;
