import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface ModuleModalWrapperProps {
  children: React.ReactNode;
  title: string;
  close(): void;
}

function ModuleModalWrapper(props: ModuleModalWrapperProps) {
  return (
    <div className="oh-leader-board-modal module-form">
      <Box position="absolute" top={8} right={8}>
        <IconButton onClick={props.close}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="h2" paragraph align="center">
        {props.title}
      </Typography>
      <Box p={2} />
      {props.children}
    </div>
  );
}

export default ModuleModalWrapper;
