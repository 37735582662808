import { styled } from "@mui/material";
import Chip, { chipClasses } from "@mui/material/Chip";

export default styled(Chip, {
  shouldForwardProp: (propName: string) => !["customColor"].includes(propName),
})<{ customColor?: string | undefined }>(({ theme, variant, customColor, icon }) => ({
  color: theme.palette.getContrastText(customColor || theme.palette.primary.main),
  cursor: "inherit",
  paddingLeft: !!icon ? 4 : undefined,
  backgroundColor: variant !== "outlined" ? customColor || theme.palette.primary.main : undefined,
  [`.${chipClasses.label}`]: {
    fontSize: theme.typography.body2.fontSize,
  },
  [`.${chipClasses.outlined}`]: {
    borderColor: customColor,
    color: customColor,
    borderWidth: 2,
  },
  [`.${chipClasses.deleteIcon}`]: {
    "& > *": {
      fill: theme.palette.getContrastText(customColor || theme.palette.primary.main),
    },
  },
}));
