import { useEffect } from "react";
import AnalyticsService from "services/AnalyticsService";

function AmplitudeAnalytics(props) {
  useEffect(() => {
    AnalyticsService.init();

    return () => AnalyticsService.clear();
  }, []);

  return null;
}
export default AmplitudeAnalytics;
