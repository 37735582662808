import { useContext, useEffect, useState } from "react";
import Loader from "../../shared/components/Loader/Loader";
import UserContext from "../../contexts/UserContext";
import UserService from "../../services/UserService";
import DrawerMenu from "../../library/DrawerMenu/DrawerMenu";
import GlobalConfig from "components/GlobalConfig";
import Login from "../../pages/Login";
import TimeZoneAutoSetter from "components/TimeZoneAutoSetter";
import InfoMessageProvider from "providers/InfoMessageProvider";
import LoadingProvider from "providers/LoadingProvider";
import AppRoutes from "./AppRoutes";
import RoleProvider from "providers/RoleProvider";
import "./App.scss";
import FeatureTrialEndingBanner from "./FeatureTrialEndingBanner";
import moment from "moment";

function App() {
  const [{ user }, { onLoginSuccess, onSignOut }] = useContext(UserContext);
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let authUnsubscribe;

    (async () => {
      const params = new URLSearchParams(window.location.search);
      const loginToken = params.get("loginToken");

      if (loginToken) {
        try {
          await UserService.signOut();
          await UserService.signInWithCustomToken(loginToken);
          window.location.href = "/"; // Refreshes page
        } catch (error) {}
      }

      authUnsubscribe = UserService.onAuthStateChanged(async (user) => {
        setVerifying(true);
        if (user) {
          /**
           * set Identity for Hotjar
           * allows us to filter Output Employees */
          window.hj &&
            window.hj("identify", user?.uid, {
              outputSportsEmployee: Boolean(user?.email?.includes("outputsports")),
            });
          try {
            await onLoginSuccess(user);
          } catch (loginError) {
            setError(loginError);
            console.error(loginError);
          }
        } else {
          try {
            await onSignOut();
          } catch (e) {
            console.error(e);
          }
        }

        setVerifying(false);
      });
    })();

    return () => {
      authUnsubscribe && authUnsubscribe();
    };
  }, [onLoginSuccess, onSignOut]);

  if (verifying) {
    return <Loader withDarkBackground />;
  }

  const isAuthenticated = Boolean(user);

  if (!isAuthenticated) {
    return (
      <InfoMessageProvider>
        <Login error={error} setError={setError} />
      </InfoMessageProvider>
    );
  }

  return (
    <LoadingProvider>
      <RoleProvider>
        <GlobalConfig>
          <TimeZoneAutoSetter />
          <DrawerMenu>
            <FeatureTrialEndingBanner enabled={moment().isBefore("2024-05-31")} />
            <AppRoutes />
          </DrawerMenu>
        </GlobalConfig>
      </RoleProvider>
    </LoadingProvider>
  );
}

export default App;
