import { v4 as uuid } from "uuid";
import { Exercise, ExerciseMetric } from "../types/Exercise.types";
import {
  WeightTypes,
  WorkoutStep,
  WorkoutTask,
  WorkoutTaskCustomExerciseType,
  WorkoutTaskSetMetadata,
  WorkoutTaskType,
} from "../../Workouts.types";
import { customExerciseConfigMetadata } from "../WorkoutStepsList/WorkoutStepPreviewExerciseSubTitle/WorkoutStepPreviewExerciseSubTitle.config";

export function baseStep(): WorkoutStep {
  return {
    id: uuid(),
    tasks: [],
  };
}

export function baseTask({
  exercise,
  setMetadata,
}: {
  exercise: Exercise;
  setMetadata: WorkoutTaskSetMetadata[];
}): WorkoutTask {
  return {
    id: uuid(),
    note: "",
    type: WorkoutTaskType.EXERCISE,
    weightType: WeightTypes.ABSOLUTE,
    exercise: exercise,
    restPeriod: null,
    useSensor: null,
    setMetadata: setMetadata,
  };
}

export function createSingleMeasurementStep(exercise: Exercise): WorkoutStep {
  return {
    ...baseStep(),
    tasks: [
      baseTask({
        exercise,
        setMetadata: [
          {
            id: uuid(),
            repCount: null,
            amrap: false,
            weight: NaN,
            percentage1Rm: NaN,
            variant: null,
            configuration: null,
          },
        ],
      }),
    ],
  };
}

export function createCustomExerciseTask(): WorkoutTaskCustomExerciseType {
  return {
    id: uuid(),
    title: "",
    type: WorkoutTaskType.CUSTOM_EXERCISE,
    note: "",
    weightType: WeightTypes.ABSOLUTE,
    restPeriod: null,
    setMetadata: [
      {
        id: uuid(),
        repCount: null,
        amrap: false,
        weight: NaN,
        percentage1Rm: NaN,
        variant: null,
        configuration: null,
      },
    ],
    options: {
      withReps: false,
      withVariants: false,
    },
  };
}

export function makeSubtitle(
  setMetadata: WorkoutTaskSetMetadata[],
  weightMetadata: ExerciseMetric | undefined,
  formatUnit: (value: any, metricMetadata: any) => string | undefined
) {
  function checkAllValuesMatch(
    allValues: (number | "∞" | null)[],
    firstValue: number | "∞" | null | undefined
  ): boolean {
    return !allValues.filter((value) => value !== firstValue).length;
  }

  const setCount = setMetadata.length;
  const allReps = setMetadata.map((set) => (set.amrap ? "∞" : set.repCount));
  const firstRep = [...allReps].shift();
  const repCountsMatch = checkAllValuesMatch(allReps, firstRep);
  const allPerc1RMs = setMetadata.map((set) => Number(set.percentage1Rm) - Number.EPSILON);
  const firstPerc1RM = Number([...allPerc1RMs].shift());
  const perc1RMsMatch = checkAllValuesMatch(allPerc1RMs, firstPerc1RM);
  const allWeights = setMetadata.map((set) => set.weight);
  const firstWeight = Number([...allWeights].shift());
  const weightsMatch = checkAllValuesMatch(allWeights, firstWeight);

  const roundPercentage = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

  return [
    `${setCount} set${setCount > 1 ? "s" : ""}`,
    firstRep && "of",
    firstRep && (repCountsMatch ? firstRep : `(${allReps.map((rep) => rep || "?").join("/")})`),
    firstRep && `rep${parseInt(String(firstRep)) === 1 ? "" : "s"}`,
    (!isNaN(firstPerc1RM) || !isNaN(firstWeight)) && `${!isNaN(firstPerc1RM) ? "@" : "x"}`,
    (!isNaN(firstPerc1RM) || !isNaN(firstWeight)) &&
      `${
        !isNaN(firstPerc1RM)
          ? `${
              perc1RMsMatch
                ? `${roundPercentage(firstPerc1RM)}%`
                : `${allPerc1RMs.map((perc) => roundPercentage(perc) || "-").join("/")} %`
            }`
          : `${
              weightsMatch
                ? `${formatUnit(firstWeight, weightMetadata ? weightMetadata : customExerciseConfigMetadata)}`
                : `${allWeights
                    .map(
                      (weight) =>
                        formatUnit(weight, weightMetadata ? weightMetadata : customExerciseConfigMetadata) || "-"
                    )
                    .join("/")}`
            }`
      }`,
  ].filter(Boolean);
}
