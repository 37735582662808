import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CameraSvgIcon from "library/Icons/CameraIcon";
import DeleteSvgIcon from "@mui/icons-material/Delete";
import noAvatar from "assets/images/no-avatar.svg";

export const HiddenFileInput = styled("input")({ display: "none" });

export const ProfilePhotoButtonBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(14),
}));

export const CameraIconButton = styled(IconButton)({
  position: "absolute",
  top: 0,
  right: 0,
}) as typeof IconButton;

export const CameraIcon = styled(CameraSvgIcon)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 50,
})) as typeof IconButton;

export const DeleteIconButton = styled(IconButton)({
  position: "absolute",
  bottom: 0,
  right: 0,
}) as typeof IconButton;

export const DeleteIcon = styled(DeleteSvgIcon)(({ theme }) => ({
  padding: 4,
  borderRadius: 50,
  backgroundColor: theme.palette.common.white,
})) as typeof IconButton;

export const ProfilePhoto = styled("div", {
  shouldForwardProp: (key: string) => !["photoUrl", "size"].includes(key),
})<{
  size?: "medium" | "large";
  photoUrl?: string | null;
}>(({ theme, photoUrl, size = "medium" }) => {
  const dimensionSize = { medium: theme.spacing(14), large: theme.spacing(20) }[size];

  return {
    height: dimensionSize,
    width: dimensionSize,
    borderRadius: dimensionSize,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${photoUrl || noAvatar})`,
    maxWidth: "100%",
  };
});
