import moment from "moment";
import { DateTime } from "types";

export function getTodayDateRangeParams(date: DateTime) {
  const momentDate = moment(date);

  return {
    startDate: momentDate.startOf("day").toISOString(),
    endDate: momentDate.clone().endOf("day").toISOString(),
  };
}
export function getMonthDateRangeParams(date: DateTime) {
  const momentDate = moment(date);

  return {
    startDate: momentDate.startOf("month").toISOString(),
    endDate: momentDate.clone().endOf("month").toISOString(),
  };
}
