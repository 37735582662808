import { mean } from "mathjs";
import moment from "moment";
import { LinePointData } from "components/charting/types/Chart.types";
import { MetadataType } from "types/metadata";

class AggregationHelper {
  static computeAverageLinePointData(
    aggregates: LinePointData[],
    aggregatedDate: string,
    aggregatedDateFormat: moment.MomentFormatSpecification
  ): LinePointData {
    const average: number = mean(aggregates.map((a) => a.y));
    const aggregate = aggregates[0] as LinePointData;

    const measurementDate = moment(aggregatedDate, aggregatedDateFormat);

    if (aggregate.metadataType === MetadataType.ONE_REP_MAX) {
      throw new Error("One rep max aggregation not implemented");
    }

    if (aggregate.metadataType === MetadataType.ANTHROPOMETRIC) {
      const anthropometricLinePointData: LinePointData = {
        ...aggregate,
        metadataType: aggregate.metadataType,
        x: measurementDate.toDate().valueOf(),
        y: average,
        computed: true,
        measurement: {
          measurementDate: measurementDate.toISOString(),
          value: average,
        },
      };

      return anthropometricLinePointData;
    }

    if (aggregate.metadataType === MetadataType.WELLNESS) {
      const wellnessLinePointData: LinePointData = {
        ...aggregate,
        metadataType: aggregate.metadataType,
        x: measurementDate.toDate().valueOf(),
        y: average,
        computed: true,
        measurement: {
          measurementDate: measurementDate.toISOString(),
          physicalHealthComment: null,
          physicalHealthScore: null,
          psychologicalHealthComment: null,
          psychologicalHealthScore: null,
          readinessToTrainComment: null,
          readinessToTrainScore: null,
          sleepComment: null,
          sleepScore: null,
          [aggregate.measure.id]: average,
        },
      };

      return wellnessLinePointData;
    }

    const { id, athleteId, exerciseId, variant } = aggregate.measurement;

    const exerciseLinePointData: LinePointData = {
      ...aggregate,
      metadataType: aggregate.metadataType,
      x: measurementDate.toDate().valueOf(),
      y: average,
      computed: true,
      measurement: {
        id: `COMPUTED_AGGREGATE_MEASUREMENT_${id}`,
        athleteId,
        exerciseId,
        completedDate: measurementDate.toISOString(),
        completedWorkoutId: null,
        variant,
        metrics: [
          {
            field: aggregate.measure.metricField as string,
            value: average,
          },
        ],
        repetitions: [],
      },
    };

    return exerciseLinePointData;
  }
}
export default AggregationHelper;
