import ExerciseHelpers from "helpers/ExerciseHelpers";
import type { Metadata } from "types";
import { MetadataType } from "types";

class MetadataHelpers {
  static getMetadataMetricLabel(attribute: Metadata | null, metricField?: string | null): string | null {
    if (!attribute) return null;

    if (attribute.type === MetadataType.EXERCISE) {
      const { metadata } = attribute;

      const metric = ExerciseHelpers.getMetricByFieldNameOrDefault(metadata, metricField);
      if (!metric) return null;

      return metric.name;
    }

    if (attribute.type === MetadataType.WELLNESS) {
      const { metadata } = attribute;

      return metadata.name;
    }

    if (attribute.type === MetadataType.ANTHROPOMETRIC) {
      const { metadata } = attribute;

      return metadata.label;
    }

    return null;
  }

  static getMetadataName(attribute: Metadata | null): string | null {
    if (!attribute) return null;

    if (attribute.type === MetadataType.EXERCISE) {
      const { metadata } = attribute;

      return metadata.name;
    }

    if (attribute.type === MetadataType.WELLNESS) {
      const { metadata } = attribute;

      return metadata.name;
    }

    if (attribute.type === MetadataType.ANTHROPOMETRIC) {
      const { metadata } = attribute;

      return metadata.label;
    }

    return null;
  }

  static getMetadataCategory(attribute: Metadata | null): string | null {
    if (!attribute) return null;

    if (attribute.type === MetadataType.EXERCISE) {
      const { metadata } = attribute;

      return metadata.category;
    }

    return attribute.type;
  }

  static getMetadataMetricByField(
    attribute: Metadata | null,
    metricField?: string | null,
    opts?: { excludedMetricFields?: string[] }
  ) {
    if (!attribute) return null;

    if (attribute.type === MetadataType.EXERCISE) {
      const { metadata } = attribute;

      const metric = ExerciseHelpers.getMetricByFieldNameOrDefault(
        {
          ...metadata,
          metrics: opts?.excludedMetricFields?.length
            ? metadata.metrics.filter((m) => !opts.excludedMetricFields!.includes(m.field))
            : metadata.metrics,
        },
        metricField
      );
      if (!metric) return null;

      return metric;
    }

    return null;
  }

  static hasVariants(attribute: Metadata | null): boolean {
    if (!attribute) return false;

    if (attribute.type === MetadataType.EXERCISE) {
      const { metadata } = attribute;
      return !!metadata.variants.length;
    }
    return false;
  }
}

export default MetadataHelpers;
