import AthleteSetComparisonChartModuleForm from "./Forms/AthleteSetComparisonChartModuleForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function ComparisonGraph(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal, reportAthletes } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add a Set Comparison Chart module" close={closeModal}>
      <AthleteSetComparisonChartModuleForm
        athleteId={reportAthletes[0] && reportAthletes[0].id}
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={moduleToEdit}
      />
    </ModuleModalWrapper>
  );
}

export default ComparisonGraph;
