import useInfoMessage from "../useInfoMessage";

function useNotifications({ info, error, success, warning }) {
  const onInfoMessage = useInfoMessage({ type: "info" });
  const onErrorMessage = useInfoMessage({ type: "error" });
  const onSuccessMessage = useInfoMessage({ type: "success" });
  const onWarningMessage = useInfoMessage({ type: "warning" });

  return {
    onInfo: () => onInfoMessage({ message: info }),
    onError: () => onErrorMessage({ message: error }),
    onSuccess: () => onSuccessMessage({ message: success }),
    onWarning: () => onWarningMessage({ message: warning }),
  };
}
export default useNotifications;
