import * as Components from "./LiveActivityFeedVirtualTableRow.components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { memo } from "react";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import AnalyticsService from "services/AnalyticsService";
import logEvent from "utils/logEvent";
import { LiveActivityFeedMeasurements } from "../LiveActivityFeedVirtualTable/LiveActivityFeedVirtualTable.utils";

function LiveActivityFeedVirtualTableRow(props: LiveActivityFeedMeasurements) {
  const { completedDate, measurements, athleteId } = props;
  const navigate = useNavigate();
  const location = useLocation();

  function updateLeaderboardExercise(id: string) {
    const search = new URLSearchParams(location.search);
    search.set("exerciseId", id);

    logEvent("HUB_LIVE_SET_LEADERBOARD_EXERCISE", {
      [AnalyticsService.PROPERTIES.EXERCISE_ID]: id,
      [AnalyticsService.PROPERTIES.EVENT_CLICK_LOCATION]: "liveActivityFeed",
    });

    navigate(`${location.pathname}?${search.toString()}`);
  }
  return (
    <Grid container spacing="0px" p={0} width="calc(100% - 8px)">
      <Grid item xs={12} mx={1} borderRadius={0}>
        <Components.LiveActivityFeedVirtualTableRowAthleteLabel completedDate={completedDate} athleteId={athleteId} />
      </Grid>
      {measurements.map((measurement) => (
        <Components.ExerciseCardGrid key={measurement.id} item xs={12}>
          <Grid container alignItems="center" borderRadius={0} mx={1} spacing="0px" p={0}>
            <Grid item xs>
              <Components.LiveActivityFeedVirtualTableRowData {...measurement} />
            </Grid>
            <Grid item>
              <Box pr={1}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => updateLeaderboardExercise(measurement.exerciseId)}
                >
                  <PlaylistPlayIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Components.ExerciseCardGrid>
      ))}
    </Grid>
  );
}

export default memo(LiveActivityFeedVirtualTableRow);
