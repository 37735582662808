import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function AssignAthletesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16.25C18.4142 16.25 18.75 16.5858 18.75 17V23C18.75 23.4142 18.4142 23.75 18 23.75C17.5858 23.75 17.25 23.4142 17.25 23V17C17.25 16.5858 17.5858 16.25 18 16.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.75 20C21.75 20.4142 21.4142 20.75 21 20.75L15 20.75C14.5858 20.75 14.25 20.4142 14.25 20C14.25 19.5858 14.5858 19.25 15 19.25L21 19.25C21.4142 19.25 21.75 19.5858 21.75 20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0003 5.60248C10.0419 5.60248 9.26496 6.3794 9.26496 7.33778C9.26496 8.29615 10.0419 9.07307 11.0003 9.07307C11.9586 9.07307 12.7356 8.29615 12.7356 7.33778C12.7356 6.3794 11.9586 5.60248 11.0003 5.60248ZM7.52967 7.33778C7.52967 5.42102 9.08351 3.86719 11.0003 3.86719C12.917 3.86719 14.4708 5.42102 14.4708 7.33778C14.4708 9.25453 12.917 10.8084 11.0003 10.8084C9.08351 10.8084 7.52967 9.25453 7.52967 7.33778ZM7.20993 14.5203C6.27243 15.19 5.79438 16.0369 5.79438 16.8819C5.79438 17.3611 5.40592 17.7495 4.92673 17.7495C4.44754 17.7495 4.05908 17.3611 4.05908 16.8819C4.05908 15.331 4.94063 14.0088 6.20131 13.1083C7.46366 12.2066 9.16311 11.676 11.0003 11.676C12.8374 11.676 14.5369 12.2066 15.7992 13.1083C16.2089 13.4009 16.5786 13.7381 16.891 14.1133C17.1635 14.5229 17.1302 15.0451 16.795 15.3294C16.4296 15.6392 15.8438 15.5489 15.4866 15.1277L15.4866 15.1276L15.4866 15.1396C15.2931 14.9213 15.061 14.7135 14.7906 14.5203C13.8547 13.8519 12.5174 13.4113 11.0003 13.4113C9.48309 13.4113 8.14577 13.8519 7.20993 14.5203Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default AssignAthletesIcon;
