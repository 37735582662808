import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LiveLeaderboard from "./LiveLeaderboard";
import LiveActivityFeed from "./LiveActivityFeed";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ColorableChip from "library/ColorableChip";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGroupsContext from "hooks/useGroupsContext";
import MeasurementsFilterContext from "contexts/MeasurementsFilterContext";
import logEvent from "utils/logEvent";
import AnalyticsService from "services/AnalyticsService";
import LivePbCheckProvider from "./providers/LivePbCheckProvider";
import GlobalSearch from "components/GlobalSearch";
import { EntityType } from "types/entity";
import { CombinedAthletesAndGroups } from "./Live.types";
import Mui5 from "library/mui5";
import useFilterMeasurementsByAthleteIds from "hooks/useFilterMeasurementsByAthleteIds";
import { sx100PercentHeight, sxPaperLiveActivityFeedContainer, sxPaperLiveLeaderboardContainer } from "./Live.styles";
import * as Components from "./Live.components";
import useCanDo from "hooks/useCanDo";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import Stack from "@mui/material/Stack";

function Live() {
  const athletes = useAthleteStore();
  const [groups] = useGroupsContext();
  const canViewGroups = useCanDo()("group.view");
  const combinedAthletesAndGroups: CombinedAthletesAndGroups[] = useMemo(() => {
    return [
      ...athletes.map((athlete) => ({ ...athlete, type: EntityType.ATHLETE as const })),
      ...groups.map((group) => ({ ...group, type: EntityType.GROUP as const })),
    ];
  }, [athletes, groups]);

  const [filterEntities, setFilterEntities] = useState<CombinedAthletesAndGroups[]>([]);
  const startDate = moment().subtract(1, "year").startOf("day").toISOString();

  const filterMeasurements = useFilterMeasurementsByAthleteIds(filterEntities);

  useEffect(() => {
    logEvent("HUB_LIVE_VIEW");
  }, []);

  useEffect(() => {
    if (!filterEntities.length) return;

    logEvent("HUB_LIVE_FILTER", {
      [AnalyticsService.PROPERTIES.FILTER_GROUP_COUNT]: filterEntities.filter((e) => e.type === "GROUP").length,
      [AnalyticsService.PROPERTIES.FILTER_ATHLETE_COUNT]: filterEntities.filter((e) => e.type === "ATHLETE").length,
    });
  }, [filterEntities]);

  const getFilterOptionLabel = useCallback(
    (option: CombinedAthletesAndGroups) =>
      option.type === "ATHLETE" ? [option.firstName, option.lastName].filter(Boolean).join(" ") : option.name,
    []
  );

  return (
    <Container maxWidth="xl" sx={sx100PercentHeight} disableGutters>
      <Stack height="100%">
        <Box pt={4} px={2}>
          <Grid container>
            <Grid item xs={6}>
              <Components.MinHeightBox mb={1.5}>
                <Mui5.Paper
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1.5),
                    width: "75%",
                    minHeight: theme.spacing(7),
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <Box ml={2} mr={2} width="100%" minHeight="100%">
                    <Mui5.Autocomplete
                      options={combinedAthletesAndGroups}
                      multiple
                      value={filterEntities}
                      onChange={(_, v) => setFilterEntities(v)}
                      getOptionLabel={getFilterOptionLabel}
                      limitTags={4}
                      filterSelectedOptions
                      groupBy={(option) => option.type}
                      renderTags={(
                        tagValue: CombinedAthletesAndGroups[],
                        getTagProps: (opts: { index: number }) => Object
                      ) =>
                        tagValue.map((option, index) => {
                          const { ...tagProps } = getTagProps({ index });

                          return (
                            <ColorableChip
                              key={option.id}
                              label={getFilterOptionLabel(option)}
                              customColor={option.type === "ATHLETE" ? undefined : option.colour}
                              deleteIcon={
                                <ClearOutlinedIcon aria-label="Remove Item" color="primary" viewBox="-6 -6 36 36" />
                              }
                              {...tagProps}
                            />
                          );
                        })
                      }
                      renderOption={(props, option) => (
                        <AutocompleteOptionColourable
                          {...props}
                          key={option.id}
                          colour={option.type === EntityType.GROUP ? option.colour : undefined}
                        >
                          {getFilterOptionLabel(option)}
                        </AutocompleteOptionColourable>
                      )}
                      renderInput={(params: any) => (
                        <Mui5.TextField
                          {...params}
                          variant="standard"
                          InputLabelProps={{
                            ...params.InputLabelProps,
                            shrink: true,
                            focused: false,
                            hidden: true,
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          sx={{
                            width: "100%",
                            minHeight: "100%",
                          }}
                          fullWidth
                          color="primary"
                          margin="dense"
                          placeholder={
                            filterEntities.length ? undefined : `Filter by Athlete${canViewGroups ? " or Group" : ""}`
                          }
                        />
                      )}
                    />
                  </Box>
                </Mui5.Paper>
              </Components.MinHeightBox>
            </Grid>
            <Grid item xs />
            <Grid item>
              <GlobalSearch />
            </Grid>
          </Grid>
        </Box>
        <MeasurementsFilterContext.Provider value={filterMeasurements}>
          <Components.OverflowHiddenGrid container spacing={0} width="100%" height="100%">
            <Grid item xs={7} height="100%" pr={1}>
              <Stack height="100%">
                <Typography variant="h3" gutterBottom component="span" fontWeight="fontWeightMedium">
                  Activity
                </Typography>
                <Mui5.Paper sx={sxPaperLiveActivityFeedContainer}>
                  <LiveActivityFeed startDate={startDate} />
                </Mui5.Paper>
              </Stack>
            </Grid>
            <Grid item xs={5} height="100%" pl={1}>
              <Stack height="100%">
                <Typography variant="h3" gutterBottom component="span" fontWeight="fontWeightMedium">
                  Leaderboard
                </Typography>
                <Mui5.Paper sx={sxPaperLiveLeaderboardContainer}>
                  <LiveLeaderboard />
                </Mui5.Paper>
              </Stack>
            </Grid>
          </Components.OverflowHiddenGrid>
        </MeasurementsFilterContext.Provider>
      </Stack>
    </Container>
  );
}

function LiveProviders(props: { children?: React.ReactNode }) {
  return (
    <LivePbCheckProvider>
      <Live />
    </LivePbCheckProvider>
  );
}

export default LiveProviders;
