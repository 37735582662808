import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 * @deprecated
 */
function TrashIcon2(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M10 2.5a1 1 0 00-1 1v1H5.5a1 1 0 000 2h13a1 1 0 100-2H15v-1a1 1 0 00-1-1h-4zM17.5 7.5a1 1 0 011 1V17a4 4 0 01-4 4h-5a4 4 0 01-4-4V8.5a1 1 0 112 0V17a2 2 0 002 2h5a2 2 0 002-2V8.5a1 1 0 011-1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M9.5 8.75a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6a.75.75 0 01.75-.75zM12 8.75a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6a.75.75 0 01.75-.75zM14.5 8.75a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
export default TrashIcon2;
