import { useReducer, useEffect, useMemo, useCallback } from "react";
import { MeasurementReducerActions, initialState, reducer } from "./useAthleteMeasurementsApi.reducer";
import useInfoMessage from "hooks/useInfoMessage";
import MeasurementService from "services/MeasurementService";
import moment from "moment";
import AnalyticsService from "services/AnalyticsService";
import { Measurement } from "types";
import useSetLoading from "hooks/useSetLoading";
import useLogEventContext from "hooks/useLogEventContext";
import { DataVisualizationType } from "components/charting/config/chartTypes";
import { SelectedChartMeasure } from "components/charting/types";

function useAthleteMeasurementsApi({
  athleteId,
  measure,
}: {
  athleteId: string;
  measure?: SelectedChartMeasure | null;
}) {
  const pageSize = 50;
  const [measurements, dispatch] = useReducer(reducer, initialState);
  const setLoading = useSetLoading();

  const setInfoMessage = useInfoMessage({ type: "error" });
  const logEvent = useLogEventContext();

  const getMeasurementsUpToEndDate = useCallback(
    async (aId: string, opts: { endDate: string | moment.Moment; measure?: SelectedChartMeasure | null }) => {
      if (!opts.measure?.id) return;

      try {
        setLoading(true);

        logEvent({
          [AnalyticsService.PROPERTIES.CHART_TYPE]: DataVisualizationType.MEASUREMENTS_TABLE,
          [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: DataVisualizationType.MEASUREMENTS_TABLE,
          [AnalyticsService.PROPERTIES.CHARTING_MEASURE_ID]: opts.measure.id,
          [AnalyticsService.PROPERTIES.EXERCISE_ID]: opts.measure.type === "Exercise" ? opts.measure.id : undefined,
          [AnalyticsService.PROPERTIES.EXERCISE_CATEGORY]:
            opts.measure.type === "Exercise" ? opts.measure.metadata.category : undefined,
          [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: opts.measure.type,
        });

        const result = await MeasurementService.getByAthleteId(aId, {
          exerciseId: opts.measure.id,
          size: pageSize,
          startDate: new Date(2019, 0, 1).toISOString(),
          endDate: moment(opts.endDate).subtract(1, "second").toISOString(),
        });

        dispatch({ type: MeasurementReducerActions.ADD_MEASUREMENTS, payload: result });
        setLoading(false);

        return result;
      } catch (error) {
        setInfoMessage(error);
        setLoading(false);
      }
    },
    [setLoading, logEvent, setInfoMessage]
  );

  useEffect(() => {
    (async () => {
      await getMeasurementsUpToEndDate(athleteId, { endDate: moment().toISOString(), measure });
    })();
    return () => {
      dispatch({ type: MeasurementReducerActions.RESET });
    };
  }, [getMeasurementsUpToEndDate, athleteId, measure]);

  const deleteMeasurementById = useCallback(
    (measurementId: string) => {
      return (async () => {
        setLoading(true);
        try {
          const result = await MeasurementService.deleteById(measurementId);

          dispatch({ type: MeasurementReducerActions.DELETE_MEASUREMENT, payload: { id: measurementId } });

          setLoading(false);
          return result;
        } catch (error) {
          setInfoMessage(error);
        }
        setLoading(false);
      })();
    },
    [setLoading, setInfoMessage]
  );

  return useMemo<
    [
      Measurement[],
      {
        getMeasurementsUpToEndDate(
          athleteId: string,
          opts: {
            endDate?: string | moment.Moment;
            measure?: SelectedChartMeasure | null;
          }
        ): Promise<Measurement[] | undefined>;
        deleteMeasurementById(id: string): Promise<{ id: string } | undefined>;
      },
    ]
  >(
    () => [measurements, { deleteMeasurementById, getMeasurementsUpToEndDate }],
    [measurements, deleteMeasurementById, getMeasurementsUpToEndDate]
  );
}
export default useAthleteMeasurementsApi;
