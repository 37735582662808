export function handleThresholdValueChange(fieldName, { setFieldValue, values }) {
  return ({ value }) => {
    const maxValue = values.thresholdType === "Percentage" ? 100 : 10;

    const intValue = parseInt(value, 10);

    if (fieldName === "greenThreshold") {
      setFieldValue("amberThreshold", Math.min(intValue + 1, maxValue));
    }

    setFieldValue(fieldName, Math.min(intValue, maxValue));
  };
}

export function getThresholdSuffix(thresholdType) {
  return ` ${thresholdType === "Percentage" ? "%" : "σ"} of average`;
}
