import { styled } from "@mui/material";
import WorkoutIcon from "library/Icons/WorkoutsIcon";

export const FABImgIconStyled = styled("img")(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  verticalAlign: "text-bottom",
}));

export const FABWorkoutIconStyled = styled(WorkoutIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 0,
  margin: 0,
  paddingTop: theme.spacing(1),
  height: theme.spacing(5),
  width: theme.spacing(5),
}));
