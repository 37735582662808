import { Trans, useTranslation } from "react-i18next";
import AthleteIcon from "library/Icons/AthletesIcon";
import InfoCard from "components/InfoCard";
import Grid from "@mui/material/Grid";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import Tables from "components/Tables";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SettingsIcon from "library/Icons/SettingsIcon";
import OneRepMaxIcon from "components/Tables/icons/OneRepMaxIcon";
import NotePadIcon from "library/IconsStandardized/NotePadIcon/NotePadIcon";
import ProfilingChartIcon from "library/Icons/charts/ProfilingChartIcon";
import SetComparisonChartIcon from "library/Icons/charts/SetComparisonChartIcon";
import MeasurementsTableIcon from "library/Icons/charts/MeasurementsTableIcon";
import LineChartIcon from "library/Icons/charts/LineChartIcon";
import CalendarIcon from "library/IconsStandardized/CalendarIcon";
import Link from "@mui/material/Link";

export interface AthleteProfileInfoCardProps {
  onClose(): void;
}

function AthleteProfileInfoCard(props: AthleteProfileInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("AthleteProfileInfoCard.title")} icon={<AthleteIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("AthleteProfileInfoCard.description1")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<NotePadIcon fontSize="small" />}
            title={t("AthleteProfileInfoCard.editLabel")}
            label={
              <Trans i18nKey="AthleteProfileInfoCard.editDescription">
                Edit Athlete details by clicking the note pad icon. You can setup
                <Link
                  href="http://25970650.hs-sites-eu1.com/knowledge/how-to-set-up-an-athlete-level-login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Athlete level login
                </Link>{" "}
                here by adding an email address for the Athlete.
              </Trans>
            }
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<OneRepMaxIcon fontSize="small" />}
            title={t("AthleteProfileInfoCard.1rmLabel")}
            label={t("AthleteProfileInfoCard.1rmDescription")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<CalendarIcon />}
            title={t("AthleteProfileInfoCard.calendarLabel")}
            label={t("AthleteProfileInfoCard.calendarDescription")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h2">
            {t("AthleteProfileInfoCard.chartingTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.lineChartTitle")}
            control={<LineChartIcon />}
            label={t("AthleteProfileInfoCard.lineChartDescription")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.measurementsTitle")}
            control={<MeasurementsTableIcon />}
            label={t("AthleteProfileInfoCard.measurementsDescription")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.setComparisonTitle")}
            control={<SetComparisonChartIcon />}
            label={t("AthleteProfileInfoCard.setComparisonDescription")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.profilingChartTitle")}
            control={<ProfilingChartIcon />}
            label={
              <Trans i18nKey="AthleteProfileInfoCard.profilingChartDescription">
                Profile and compare an Athlete's capability across a selection of weights and sets.
                <Link
                  href="https://www.outputsports.com/blog/load-velocity-profiling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More Info
                </Link>
              </Trans>
            }
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.view1rmTitle")}
            control={<Tables.Icons.OneRepMaxNoOutline />}
            label={t("AthleteProfileInfoCard.view1rmDescription")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("AthleteProfileInfoCard.settingsTitle")}
            control={<SettingsIcon />}
            label={t("AthleteProfileInfoCard.settingsDescription")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default AthleteProfileInfoCard;
