import TickerIdContext from "../TickerIdContext";
import { useContext } from "react";

interface UseTickerProps {}

function useTickerId(props?: UseTickerProps) {
  return useContext(TickerIdContext);
}

export default useTickerId;
