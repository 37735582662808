import useChartSelections from "components/charting/hooks/useChartSelections";
import { PolynomialRegressionProcessor } from "components/charting/processors/PolynomialRegressionProcessor";
import { ProfilingChartEntityId, SelectedChartMeasureExercise } from "components/charting/types";
import { LineData } from "components/charting/types/Chart.types";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import usePreferences from "hooks/usePreferences";
import { useMemo } from "react";
import { Measurement, MetadataType } from "types";

interface ProfilingMeasurementBestMetrics {
  measurement: Measurement;
  weight: number;
  best: {
    field: string;
    value: number;
  };
}

function useProfilingLine(measurements: Measurement[], profilingChartEntityId: ProfilingChartEntityId) {
  const selections = useChartSelections();
  const measure = selections.measures[0] as SelectedChartMeasureExercise;
  const source = selections.sources[0];
  const [prefs] = usePreferences();

  const measurementsWithBestRepMetric: ProfilingMeasurementBestMetrics[] = useMemo(
    () =>
      measurements
        .map((measurement) => {
          const weight = measurement.metrics.find((metric) => metric?.field === "weight")?.value;
          const best = measurement.repetitions.reduce<{ field: string; value: number } | undefined>(
            (best, repetitionMetrics) => {
              const metric = repetitionMetrics.find(
                (repetitionMetric) => repetitionMetric.field === measure.repMetric?.field
              );

              if (best && metric && best.value <= metric.value) return metric;

              return best || metric;
            },
            undefined
          );

          return { measurement, weight, best };
        })
        .filter((m) => m.weight && m.best) as ProfilingMeasurementBestMetrics[],
    [measure.repMetric?.field, measurements]
  );

  const data: LineData[] = useMemo(() => {
    const getCommon = (measurement: Measurement) => ({
      entityId: profilingChartEntityId,
      source,
      measure: { id: measure.id, metricField: measure.metric?.field, repMetricField: measure.repMetric?.field },
      metadataType: MetadataType.EXERCISE,
      sortOrder: measure.repMetric?.sortType === 0 ? ("asc" as const) : ("desc" as const),
      variant: measurement.variant || undefined,
    });

    const rawDatasets = measurementsWithBestRepMetric.map((measurement): [number, number] => [
      measurement.weight,
      measurement.best.value,
    ]);

    const leastSquaresProcessor = new PolynomialRegressionProcessor(
      rawDatasets,
      prefs.imperialSystemEnabled,
      ExerciseHelpers.isPowerMetric(measure.repMetric?.field)
        ? PolynomialRegressionProcessor.degrees.QUADRATIC
        : PolynomialRegressionProcessor.degrees.LINEAR
    );

    const profilingLine = measurementsWithBestRepMetric.slice(0, 1).map(({ measurement }): LineData => {
      const common = getCommon(measurement);
      measurement.completedDate = "";
      return {
        ...common,
        line: leastSquaresProcessor.line.map(([x, y], index) => ({
          ...common,
          x,
          y,
          metadataType: MetadataType.EXERCISE,
          measurement,
          profiling: {
            x,
            y,
          },
        })),
      };
    });

    const selectedSetMeasurements = measurementsWithBestRepMetric.map(({ measurement, weight, best }): LineData => {
      const common = getCommon(measurement);
      const entityId = [profilingChartEntityId, measurement.id].join("-");

      return {
        ...common,
        entityId,
        line: [
          {
            ...common,
            entityId,
            x: weight,
            y: best.value,
            metadataType: MetadataType.EXERCISE,
            measurement,
            profiling: {
              x: weight,
              y: best.value,
            },
          },
        ],
      };
    });
    return profilingLine.concat(selectedSetMeasurements).filter(Boolean);
  }, [
    measure.id,
    measure.metric?.field,
    measure.repMetric?.field,
    measure.repMetric?.sortType,
    measurementsWithBestRepMetric,
    prefs.imperialSystemEnabled,
    profilingChartEntityId,
    source,
  ]);

  return data;
}

export default useProfilingLine;
