import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Mui5 from "library/mui5";
import Box from "@mui/material/Box";

function InputAdornmentSwitcher(props) {
  return (
    <Box maxWidth={60}>
      <Mui5.Button variant="text" type="button" role="button" size="small" onClick={props.onSwitch} disableRipple>
        <Typography component="span" color={!props.value ? "textSecondary" : "primary"}>
          {props.defaultLabel}
        </Typography>
        <Typography component="span" color="textSecondary">
          &nbsp;/&nbsp;
        </Typography>
        <Typography component="span" color={props.value ? "textSecondary" : "primary"}>
          {props.specialLabel}
        </Typography>
      </Mui5.Button>
    </Box>
  );
}

InputAdornmentSwitcher.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  specialLabel: PropTypes.string.isRequired,
  onSwitch: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default InputAdornmentSwitcher;
