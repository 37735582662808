import type { Moment } from "moment";
export interface Organisation {
  id: string;
  name: string;
  email: string;
  profilePhotoName: string | null;
  accountInfo: {
    athleteLimit: number;
    activeAthleteCount: number;
    activeCoachCount: number;
    archivedAthleteCount: number;
    coachLimit: number;
  };
  subscriptionEndDate: string | Moment;
  featureConfig: {
    groupsEnabled: boolean;
    customExercisesEnabled?: boolean;
    isWorkoutsEnabled?: boolean;
    isDashboardsEnabled?: boolean;
  };
}

export enum OrganisationRole {
  OWNER = "OWNER",
  COACH = "COACH",
  ATHLETE = "ATHLETE",
}
