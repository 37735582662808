import OrganisationProvider from "providers/OrganisationProvider";
import { useMemo, useCallback, useState } from "react";
import OrganisationService from "services/OrganisationService";
import UserContext from "../../contexts/UserContext";
import UserService from "../../services/UserService";
import { usePostHog } from "posthog-js/react";

function UserProvider(props) {
  const [auth, setAuth] = useState({
    user: null,
    profile: null,
    organisation: null,
  });

  const posthog = usePostHog();

  const getUser = useCallback(async (user) => {
    const role = await UserService.getRole();

    if (role === "OWNER") {
      const hubUser = await UserService.checkHubUser();

      if (!hubUser)
        throw new Error(
          "You currently do not have access to Hub. Please contact support@outputsports.com for further help."
        );
    }

    const [profile, organisation] = await Promise.all([UserService.getMe(), OrganisationService.get()]);

    setAuth((prevAuth) => ({ user: { ...prevAuth.user, ...user }, profile, organisation }));
  }, []);

  const onLoginSuccess = useCallback(
    async (user) => {
      await getUser(user);
      await UserService.setLastSeen();
    },
    [getUser]
  );

  const onUpdate = useCallback(
    (profile) => setAuth((prevAuth) => ({ ...prevAuth, profile: { ...prevAuth.profile, ...profile } })),
    []
  );

  const onSignOut = useCallback(async () => {
    await UserService.signOut();
    setAuth({ user: null, profile: null, organisation: null });
    posthog.reset();
  }, [posthog]);

  const onSetProfileImage = useCallback(
    async (photo) => {
      await UserService.setProfileImage(photo);
      await getUser();
    },
    [getUser]
  );

  const onDeleteProfileImage = useCallback(async () => {
    await UserService.deleteProfileImage();

    onUpdate({ profilePhotoUrl: null, profilePhotoName: null });
  }, [onUpdate]);

  const contextValue = useMemo(
    () => [auth, { onLoginSuccess, onUpdate, onSignOut, onSetProfileImage, onDeleteProfileImage }],
    [auth, onLoginSuccess, onUpdate, onSignOut, onSetProfileImage, onDeleteProfileImage]
  );

  const setOrganisation = useCallback((organisation) => {
    setAuth((prev) => ({
      ...prev,
      organisation: { ...prev.organisation, ...organisation },
    }));
  }, []);

  return (
    <UserContext.Provider value={contextValue}>
      <OrganisationProvider organisation={auth.organisation} setOrganisation={setOrganisation}>
        {props.children}
      </OrganisationProvider>
    </UserContext.Provider>
  );
}
export default UserProvider;
