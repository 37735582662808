import { Formik } from "formik";
import { LeaderboardBase } from "types";
import { useMemo } from "react";
import { LeaderboardFormValues } from "../LeaderboardEditable.types";
import { leaderboardDefaults } from "../LeaderboardEditable.config";
import useGroups from "providers/GroupsProvider/useGroups";
import LeaderboardHelpers from "pages/Leaderboards/helpers/LeaderboardHelpers";
import useExercises from "providers/ExercisesProvider/useExercises";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";

interface LeaderboardFormikProviderProps {
  leaderboard?: LeaderboardBase;
  onSubmit(leaderboard: LeaderboardBase): void;
  children: React.ReactNode;
}

function LeaderboardFormikProvider(props: LeaderboardFormikProviderProps) {
  const { onSubmit, children, leaderboard = leaderboardDefaults } = props;
  const exercises = useExercises();
  const groups = useGroups();
  const organisation = useOrganisation();

  const initialValues: LeaderboardFormValues = useMemo(
    () => LeaderboardHelpers.toForm(leaderboard, { groups, exercises }),
    [exercises, groups, leaderboard]
  );

  async function handleSubmit(submittedLeaderboard: LeaderboardFormValues) {
    const updatedLeaderboard = LeaderboardHelpers.fromForm(submittedLeaderboard);

    return onSubmit({
      ...updatedLeaderboard,
      groups: organisation.featureConfig.groupsEnabled ? updatedLeaderboard.groups : leaderboard.groups,
    });
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
}

export default LeaderboardFormikProvider;
