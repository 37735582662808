import { Component } from "react";
import PageContent from "shared/components/PageContent";
import athleteLogo from "../../../assets/images/athlete-logo.svg";
import groupLogo from "../../../assets/images/group-logo.svg";
import "./CreateReport.scss";
import { Link } from "react-router-dom";
import Constants from "../../../shared/utils/Constants";
import Box from "@mui/material/Box";
import CanDoAction from "components/CanDoAction";

class CreateReport extends Component {
  render() {
    return (
      <div className="oh-report">
        <PageContent pageHeadingLink={Constants.routes.reportGeneration.route} pageHeading="Create a Report">
          <Box display="flex" className="create-report">
            <div className="oh-report-fixed-cta-wrapper">I want to create a report for</div>
          </Box>
          <Box display="flex" className="panel-wrapper" justifyContent="space-around">
            <Link className="panel" to={Constants.routes.createAthleteReport.route}>
              <div className="logo-wrapper">
                <img src={athleteLogo} alt="athlete-logo" />
              </div>
              <div className="text-wrapper">Athlete</div>
            </Link>
            <CanDoAction action="group.any">
              <Link className="panel" to={Constants.routes.createGroupReport.route}>
                <div className="logo-wrapper">
                  <img src={groupLogo} alt="group-logo" />
                </div>
                <div className="text-wrapper">Group</div>
              </Link>
            </CanDoAction>
          </Box>
        </PageContent>
      </div>
    );
  }
}

export default CreateReport;
