import SvgIcon from "@mui/material/SvgIcon";
const size = {
  12: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.32501 2.25H2.67501C2.15034 2.25 1.72501 2.67284 1.72501 3.19444V9.80556C1.72501 10.3272 2.15034 10.75 2.67501 10.75H9.32501C9.84968 10.75 10.275 10.3272 10.275 9.80556V3.19444C10.275 2.67284 9.84968 2.25 9.32501 2.25Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.89999 1.25V3.15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.10001 1.25V3.15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.20001 5.25H10.2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4333 3.00002H3.56665C2.86709 3.00002 2.29999 3.56381 2.29999 4.25928V13.0741C2.29999 13.7696 2.86709 14.3334 3.56665 14.3334H12.4333C13.1329 14.3334 13.7 13.7696 13.7 13.0741V4.25928C13.7 3.56381 13.1329 3.00002 12.4333 3.00002Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5333 1.66667V4.20001"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.46667 1.66667V4.20001"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.93335 7H13.6" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.65 4.5H5.35001C4.30067 4.5 3.45001 5.34568 3.45001 6.38889V19.6111C3.45001 20.6543 4.30067 21.5 5.35001 21.5H18.65C19.6994 21.5 20.55 20.6543 20.55 19.6111V6.38889C20.55 5.34568 19.6994 4.5 18.65 4.5Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.8 2.5V6.3" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.20001 2.5V6.3" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.40002 10.5H20.4"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.8666 6H7.13331C5.73419 6 4.59998 7.12758 4.59998 8.51852V26.1482C4.59998 27.5391 5.73419 28.6667 7.13331 28.6667H24.8666C26.2658 28.6667 27.4 27.5391 27.4 26.1482V8.51852C27.4 7.12758 26.2658 6 24.8666 6Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0667 3.33333V8.4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9333 3.33333V8.4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.86664 14H27.2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
};

/**
 * @param {Omit<import("@mui/material").SvgIconProps, "fontSize"> & { fontSize?: "large" | "small" | "12" }} props
 */
function CalendarIcon(props) {
  const item = size[props.fontSize || "default"];

  return (
    <SvgIcon
      {...props}
      fontSize={props.fontSize === "12" ? undefined : props.fontSize}
      sx={props.fontSize === "12" ? { fontSize: 12 } : undefined}
    >
      {item}
    </SvgIcon>
  );
}
export default CalendarIcon;
