import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66666 7V12.3333C2.66666 12.687 2.80713 13.0261 3.05718 13.2761C3.30723 13.5262 3.64637 13.6667 3.99999 13.6667H12C12.3536 13.6667 12.6928 13.5262 12.9428 13.2761C13.1928 13.0261 13.3333 12.687 13.3333 12.3333V7"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 4.99999L8.00001 2.33333L5.33334 4.99999"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 2.5V9" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 11V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V11"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 7L12 3L8 7" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 3V15" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33331 15V25.6667C5.33331 26.3739 5.61426 27.0522 6.11436 27.5523C6.61446 28.0524 7.29274 28.3333 7.99998 28.3333H24C24.7072 28.3333 25.3855 28.0524 25.8856 27.5523C26.3857 27.0522 26.6666 26.3739 26.6666 25.6667V15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3334 9.00001L16 3.66667L10.6667 9.00001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.66667V19.6667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function ExportIcon(props: SvgIconProps) {
  const item = props.fontSize === "large" || props.fontSize === "small" ? size[props.fontSize] : size.default;

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default ExportIcon;
