import { DataVisualizationType } from "components/charting/config/chartTypes";
import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import { ProfilingChartEntityId } from "components/charting/types";
import useColorRegistry from "pages/Charting/hooks/useColorRegistry";
import { useContext, useMemo } from "react";

function useLineDotColor(entityId: string) {
  const selectedSetsContext = useContext(ChartSetSelectContext);
  const selectedCompareSetsContext = useContext(ChartCompareSetSelectContext);
  const visType = useDataVisualizationType();
  const getColor = useColorRegistry();

  const color = useMemo(() => {
    const isProfilingChart = visType === DataVisualizationType.PROFILING_CHART;
    const isProfilingComparisonChart = selectedCompareSetsContext?.value.length && selectedSetsContext?.value.length;

    if (!isProfilingChart) {
      return getColor(entityId);
    }

    if (!isProfilingComparisonChart) {
      return getColor(
        entityId
          .replace(`${ProfilingChartEntityId.PROFILING_LINE}-`, "")
          .replace(`${ProfilingChartEntityId.COMPARATIVE_LINE}-`, "")
      );
    }

    const modifiedEntityId =
      entityId.indexOf(ProfilingChartEntityId.PROFILING_LINE) > -1
        ? ProfilingChartEntityId.PROFILING_LINE
        : ProfilingChartEntityId.COMPARATIVE_LINE;

    return getColor(modifiedEntityId);
  }, [entityId, getColor, selectedCompareSetsContext?.value.length, selectedSetsContext?.value.length, visType]);

  return color;
}

export default useLineDotColor;
