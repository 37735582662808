import MessageCard from "components/MessageCard";
import { ImportantText } from "./AthleteProfileMeasurementsNoData.components";
import { Trans } from "react-i18next";

interface AthleteProfileMeasurementsNoDataProps {}

function AthleteProfileMeasurementsNoData(props: AthleteProfileMeasurementsNoDataProps) {
  return (
    <MessageCard title="Measurements Table">
      <Trans i18nKey="AthleteProfileMeasurementsNoData.noDataMessage">
        No data to display. Please update <ImportantText> Measurement</ImportantText> selection.
      </Trans>
    </MessageCard>
  );
}

export default AthleteProfileMeasurementsNoData;
