import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";

const iconColor = {
  primary: "primary.main",
  secondary: "secondary.main",
  white: "common.white",
  "grey.100": "grey.100",
  inherit: "inherit",
};
/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function SearchIcon(props) {
  const color = iconColor[props.color | "primary"];

  return (
    <Box color={color} lineHeight={0} display="inline-block" sx={props.sx}>
      <SvgIcon width="30" height="30" viewBox="0 0 30 30" fontSize={props.fontSize}>
        <path
          d="M27.12 25.2003L22.74 20.8503C26.16 16.4703 25.8 10.2303 21.87 6.30033C19.8 4.23033 17.04 3.09033 14.1 3.09033C11.16 3.09033 8.4 4.23033 6.33 6.30033C4.26 8.37033 3.12 11.1303 3.12 14.0703C3.12 17.0103 4.26 19.7703 6.33 21.8403C8.4 23.9103 11.16 25.0503 14.1 25.0503C16.53 25.0503 18.93 24.2403 20.85 22.7103L25.2 27.1203C25.47 27.3903 25.8 27.5103 26.16 27.5103C26.52 27.5103 26.85 27.3603 27.12 27.1203C27.66 26.6103 27.66 25.7403 27.12 25.2003ZM22.38 14.0703C22.38 16.2903 21.51 18.3603 19.95 19.9203C18.39 21.4803 16.29 22.3503 14.1 22.3503C11.91 22.3503 9.81 21.4803 8.25 19.9203C6.69 18.3603 5.82 16.2603 5.82 14.0703C5.82 11.8503 6.69 9.78033 8.25 8.22033C9.81 6.66033 11.91 5.79033 14.1 5.79033C16.32 5.79033 18.39 6.66033 19.95 8.22033C21.51 9.78033 22.38 11.8503 22.38 14.0703Z"
          fill="currentColor"
        />
      </SvgIcon>
    </Box>
  );
}

SearchIcon.propTypes = {
  color: PropTypes.oneOf(Object.keys(iconColor)),
};

export default SearchIcon;
