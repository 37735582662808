import useDefinedContext from "hooks/useDefinedContext";
import WorkoutsContext from "../WorkoutsContext";

interface UseWorkoutsProps {}

function useWorkoutsStore(props?: UseWorkoutsProps) {
  return useDefinedContext(WorkoutsContext);
}

export default useWorkoutsStore;
