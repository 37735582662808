import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";

export const TooltipColorStripContainer = styled(Grid)({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  alignItems: "center",
  justifyContent: "center",
});
