import React, { useCallback } from "react";
import { firstBy } from "thenby";

/**
 *
 * @deprecated
 * Name clash with useFilteredExercises from FilteredExercisesContext */
function useFilteredExercises(exercises, repExercisesOnly) {
  const filterNonRepExercises = useCallback(
    function filterNonRepExercises(exercise) {
      if (repExercisesOnly) {
        return !!exercise.repetitions?.length;
      }
      return true;
    },
    [repExercisesOnly]
  );

  const categories = React.useMemo(
    () =>
      exercises
        .filter(filterNonRepExercises)
        .reduce((uniqueCategories, currentExercise) => {
          if (!uniqueCategories.includes(currentExercise.category)) {
            return [...uniqueCategories, currentExercise.category];
          }

          return uniqueCategories;
        }, [])
        .sort(firstBy((v) => v)),
    [exercises, filterNonRepExercises]
  );

  const categoryExercises = React.useCallback(
    (category) => {
      return exercises
        .filter(filterNonRepExercises)
        .filter((exercise) => exercise.category === category)
        .sort(firstBy("name"));
    },
    [exercises, filterNonRepExercises]
  );

  const exerciseMetrics = React.useCallback(
    (exercise) => {
      if (!exercise) {
        return [];
      }

      const metrics = exercises
        .filter(filterNonRepExercises)
        .find((item) => item.id === exercise.id)
        ?.metrics?.sort(firstBy("name"));

      if (!metrics) {
        return [];
      }

      return metrics;
    },
    [exercises, filterNonRepExercises]
  );

  const exerciseRepMetrics = React.useCallback(
    (exercise) => {
      if (!exercise) {
        return [];
      }

      const repMetrics = exercises
        .filter(filterNonRepExercises)
        .find((item) => item.id === exercise.id)
        ?.repetitions?.sort(firstBy("name"));

      if (!repMetrics) {
        return [];
      }

      return repMetrics;
    },
    [exercises, filterNonRepExercises]
  );

  return {
    exercises: exercises.filter(filterNonRepExercises),
    categories,
    categoryExercises,
    exerciseMetrics,
    exerciseRepMetrics,
  };
}
export default useFilteredExercises;
