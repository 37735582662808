import http from "axiosSetup";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";

class RAGSettingsService {
  static getAll(): Promise<RAGSettings[]> {
    return http.get<RAGSettings[]>("/rag-status-templates/").then((response) => response.data);
  }

  static create(ragTemplate: RAGSettings): Promise<{ id: string }> {
    return http.post("/rag-status-templates/", ragTemplate).then((response) => response.data);
  }

  static update(id: string, ragTemplate: RAGSettings): Promise<{ id: string }> {
    return http.put(`/rag-status-templates/${id}`, ragTemplate).then((response) => response.data);
  }

  static delete(id: string): Promise<void> {
    return http.delete(`/rag-status-templates/${id}`);
  }
}
export default RAGSettingsService;
