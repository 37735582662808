import WorkoutHelpers from "helpers/WorkoutHelpers";
import { useRequest } from "hooks";
import { Workout } from "pages/Workouts/Workouts.types";
import useRole from "providers/RoleProvider/useRole";
import { useCallback, useMemo } from "react";
import Tooltip from "components/Tooltip";
import Box from "@mui/material/Box";
import Tables from "components/Tables";
import allWorkoutsConfig from "pages/Workouts/AllWorkouts/AllWorkouts.config";
import Alert from "@mui/material/Alert";
import PublishedStatusIconButton from "./components/PublishedStatusIconButton";
import useWorkoutsActions from "pages/Workouts/providers/WorkoutsProvider/useWorkoutsActions";

function WorkoutPublishSwitch(props: Workout) {
  const actions = useWorkoutsActions();
  const role = useRole();
  const toggleWorkoutPublishId = useRequest(actions.togglePublishById);

  const handlePublish = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      toggleWorkoutPublishId(props.id, !props.isPublished);
    },
    [props.id, props.isPublished, toggleWorkoutPublishId]
  );

  const canTogglePublished = WorkoutHelpers.canTogglePublished(props, { role });
  const isChangeAffectingUnkownAthletesOrGroups = WorkoutHelpers.isChangeAffectingUnkownAthletesOrGroups(props);

  const hoverTooltipText = useMemo(() => {
    let tooltipText: React.ReactNode = <Box>{props.isPublished ? "Published" : "Unpublished"}</Box>;

    if (role !== "OWNER" && !canTogglePublished) {
      return allWorkoutsConfig.publish.errors.orgOwnerOnly;
    }

    if (role !== "OWNER" && canTogglePublished && isChangeAffectingUnkownAthletesOrGroups) {
      return (
        <Alert sx={{ m: 0, py: 0 }} variant="filled" severity="warning">
          {allWorkoutsConfig.publish.warnings.isShared}
        </Alert>
      );
    }

    return tooltipText;
  }, [canTogglePublished, isChangeAffectingUnkownAthletesOrGroups, props.isPublished, role]);

  const PublishedStatusIcon = props.isPublished ? Tables.Icons.Visible : Tables.Icons.Hidden;

  return (
    <Tooltip title={hoverTooltipText} placement="left" arrow>
      <span>
        <PublishedStatusIconButton
          onClick={handlePublish}
          aria-label={`publish workout ${props.name}`}
          disabled={!canTogglePublished}
          published={props.isPublished}
        >
          <PublishedStatusIcon />
        </PublishedStatusIconButton>
      </span>
    </Tooltip>
  );
}

export default WorkoutPublishSwitch;
