import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */

function ProfilingChartIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.5 4.5V19.5H19.5"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7.34692"
        y="6.23291"
        width="2.25"
        height="1.5"
        transform="rotate(40.2481 7.34692 6.23291)"
        fill="currentColor"
      />
      <rect
        x="12.4988"
        y="10.5942"
        width="2.25"
        height="1.5"
        transform="rotate(40.2481 12.4988 10.5942)"
        fill="currentColor"
      />
      <rect
        x="9.92285"
        y="8.41357"
        width="2.25"
        height="1.5"
        transform="rotate(40.2481 9.92285 8.41357)"
        fill="currentColor"
      />
      <rect
        x="15.075"
        y="12.7747"
        width="2.25"
        height="1.5"
        transform="rotate(40.2481 15.075 12.7747)"
        fill="currentColor"
      />
      <rect
        x="17.6509"
        y="14.9553"
        width="2.25"
        height="1.5"
        transform="rotate(40.2481 17.6509 14.9553)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default ProfilingChartIcon;
