import { alpha, styled } from "@mui/material";
import MuiCheckbox from "@mui/material/Checkbox";

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  label: {
    color: alpha("#828282", 1),
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export default Checkbox;
