import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function BroadUpArrow(props) {
  return (
    <SvgIcon {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 14L12 10L4 14" stroke="#C4C4C4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
}
export default BroadUpArrow;
