import useAthleteMonitoringStatusesContext from "hooks/useAthleteMonitoringStatusesContext";
import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import useExercises from "providers/ExercisesProvider/useExercises";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";
import { useMemo } from "react";

function useMonitoringTemplateItems() {
  const [monitoringTemplates] = useMonitoringTemplates();
  const [, { currentRagTemplateId }] = useAthleteMonitoringStatusesContext();
  const wellnesses = useWellnessMetadata();
  const exercises = useExercises();

  const metadata = useMemo(() => {
    const template = monitoringTemplates.find((template) => template.id === currentRagTemplateId);

    if (!template?.items) {
      return;
    }

    /**
     * @param {"Exercise" | "Wellness"} metadataType
     * @param {string} metadataId
     * */
    function getMetadata(metadataType: string | number, metadataId: string) {
      if (metadataType === 1 || metadataType === "Wellness") {
        return wellnesses.find((wellness) => wellness.id === metadataId);
      }

      // metadataType === 0 || metadataType === "Exercise"
      return exercises.find((exercise) => exercise.id === metadataId);
    }

    return template.items.map((item) => {
      const metadata = getMetadata(item.metadataType, item.metadataId);
      return {
        ...item,
        name: metadata?.name,
      };
    });
  }, [currentRagTemplateId, exercises, monitoringTemplates, wellnesses]);

  return metadata;
}
export default useMonitoringTemplateItems;
