import * as Yup from "yup";

export const errorMessages = {
  ragStatusTemplate: "Select an Athlete Monitoring Template",
};

export const schema = Yup.object()
  .shape({
    ragStatusTemplate: Yup.object().required(errorMessages.ragStatusTemplate).nullable(),
  })
  .required();
