import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function OneRepMaxIconNoOutlineIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.69494 8.40039V15.6004H4.19814V10.0388L2.3999 10.5816V9.46139L5.53383 8.40039H5.69494Z"
        fill="currentColor"
      />
      <path
        d="M6.43707 8.4152H9.25917C9.83779 8.4152 10.335 8.49744 10.7508 8.66194C11.17 8.82644 11.4922 9.06989 11.7175 9.3923C11.9427 9.71472 12.0553 10.1112 12.0553 10.5816C12.0553 10.9665 11.986 11.2972 11.8474 11.5735C11.7123 11.8466 11.52 12.0752 11.2705 12.2595C11.0245 12.4404 10.7352 12.5852 10.4026 12.6937L9.90882 12.9405H7.45573L7.44534 11.7857H9.26956C9.54328 11.7857 9.77023 11.7397 9.9504 11.6475C10.1306 11.5554 10.2657 11.4271 10.3558 11.2626C10.4493 11.0981 10.4961 10.9073 10.4961 10.6902C10.4961 10.4599 10.4511 10.2608 10.361 10.0931C10.2709 9.92527 10.134 9.79696 9.9504 9.70814C9.76676 9.61931 9.53635 9.57489 9.25917 9.57489H7.99624V15.6004H6.43707V8.4152ZM10.6676 15.6004L8.94214 12.3976L10.5897 12.3878L12.3359 15.5313V15.6004H10.6676Z"
        fill="currentColor"
      />
      <path
        d="M13.755 8.4152H15.0751L17.0188 13.6906L18.9626 8.4152H20.2827L17.5489 15.6004H16.4887L13.755 8.4152ZM13.043 8.4152H14.3579L14.5969 13.5573V15.6004H13.043V8.4152ZM19.6798 8.4152H20.9999V15.6004H19.4407V13.5573L19.6798 8.4152Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default OneRepMaxIconNoOutlineIcon;
