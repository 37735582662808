import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";

export const TableRowStyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(theme.typography.htmlFontSize !== 18 ? 1.2 : 0.6, 2),
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[100],
  },
  display: "block",
  width: "100%",
  textAlign: "left" as const,
}));

export const PositionCellGrid = styled(Grid)(({ theme }) => ({
  width: theme.spacing(5),
  textAlign: "right",
  paddingRight: theme.spacing(2),
}));

export const MetricCellGrid = styled(Grid)(({ theme }) => ({
  textAlign: "right",
  paddingRight: theme.spacing(0),
}));
