import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import { MeasurementSet } from "types";
import { useMemo, useState } from "react";
import useDefinedContext from "hooks/useDefinedContext";
import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";

interface ChartCompareSetSelectProviderProps {
  children?: React.ReactNode;
}

function ChartCompareSetSelectProvider(props: ChartCompareSetSelectProviderProps) {
  const { options: availableSets } = useDefinedContext(ChartSetSelectContext);
  const [selectedCompareSets, setSelectedCompareSets] = useState<MeasurementSet[]>([]);

  const contextValue = useMemo(
    () => ({
      value: selectedCompareSets.filter((set) => availableSets.includes(set)),
      options: availableSets,
      onChange: (sets: MeasurementSet[]) => {
        setSelectedCompareSets(sets);
      },
    }),
    [availableSets, selectedCompareSets]
  );

  return (
    <ChartCompareSetSelectContext.Provider value={contextValue}>{props.children}</ChartCompareSetSelectContext.Provider>
  );
}

export default ChartCompareSetSelectProvider;
