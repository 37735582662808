import { DataVisualizationType } from "components/charting/config/chartTypes";
import { SelectedChartMeasure } from "components/charting/types";
import { LineData } from "components/charting/types/Chart.types";
import { ScaleLinear } from "d3-scale";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import { ExerciseRepetition } from "types";
import { MetadataType, Metadata } from "types/metadata";
import { TimePeriod } from "types/period";

class ChartHelpers {
  static getScaleId(measure: SelectedChartMeasure | LineData["measure"]): string {
    const metricField = "metric" in measure ? measure.metric?.field : measure.metricField;

    return [measure.id, metricField].filter(Boolean).join("-");
  }

  static getScaleForMeasure(
    measure: SelectedChartMeasure | LineData["measure"],
    scales: { [scaleId: string]: ScaleLinear<number, number, never> }
  ) {
    const scaleId = ChartHelpers.getScaleId(measure);

    const scale = scales[scaleId];

    if (typeof scales === "undefined") {
      throw new Error(`Scale with id '${scaleId}' does not exist`);
    }

    return scale;
  }
  static getMeasureByScaleId(scaleId: string, measures: SelectedChartMeasure[]) {
    const measure = measures.find((measure) => ChartHelpers.getScaleId(measure) === scaleId);

    if (typeof measure === "undefined") {
      throw new Error(`Measure for the scale '${scaleId}' does not exist`);
    }

    return measure;
  }

  static getScaleMinMax(data: LineData[], selectedMeasure: SelectedChartMeasure) {
    return data
      .filter((lineData) => {
        const lineMeasureId = lineData.measure.id;
        const lineMetricField = lineData.measure.metricField || null;

        const selectedMeasureId = selectedMeasure.id;
        const selectedMeasureMetricField = selectedMeasure.metric?.field || null;

        return lineMeasureId === selectedMeasureId && lineMetricField === selectedMeasureMetricField;
      })
      .reduce(
        (acc, cur, index) => ({
          min: cur.common?.min ? (!index ? cur.common?.min : Math.min(cur.common?.min, acc.min)) : acc.min,
          max: cur.common?.max ? (!index ? cur.common?.max : Math.max(cur.common?.max, acc.max)) : acc.max,
        }),
        { min: 0, max: 0 }
      );
  }

  static filterRepExercisesOnly(measure: SelectedChartMeasure | Metadata) {
    return Boolean(measure.type === MetadataType.EXERCISE && measure.metadata.repetitions.length);
  }

  static filter1RMExercisesOnly(measure: SelectedChartMeasure | Metadata) {
    return Boolean(measure.type === MetadataType.EXERCISE && ExerciseHelpers.isOneRepMaxExercise(measure.metadata));
  }

  static filterProfilingExercisesOnly(measure: SelectedChartMeasure | Metadata) {
    return Boolean(measure.type === MetadataType.EXERCISE && ExerciseHelpers.hasWeightMetric(measure.metadata));
  }

  static filterMeasuresByDataVisType(measures: Metadata[], options: { visType: DataVisualizationType }) {
    if (options.visType === DataVisualizationType.PROFILING_CHART) {
      return measures.filter(ChartHelpers.filterProfilingExercisesOnly);
    }

    if (options.visType === DataVisualizationType.SET_COMPARISON_CHART) {
      return measures.filter(ChartHelpers.filterRepExercisesOnly);
    }

    if (options.visType === DataVisualizationType.ONE_REP_MAX_CHART) {
      return measures.filter(ChartHelpers.filter1RMExercisesOnly);
    }

    return measures;
  }

  static filterProfilingRepMetrics(repetitions: ExerciseRepetition[], options: { visType: DataVisualizationType }) {
    if (options.visType === DataVisualizationType.PROFILING_CHART) {
      return repetitions.filter(
        (r) =>
          `${r.field}`.toUpperCase().includes("VELOCITY") ||
          `${r.field}`.toUpperCase().includes("FORCE") ||
          `${r.field}`.toUpperCase().includes("POWER")
      );
    }
    return repetitions;
  }

  static generateTimePhrase(value: string | null) {
    if (value === TimePeriod.YEAR) {
      return " for the past year";
    } else if (value === TimePeriod.MONTH) {
      return " for the past month";
    } else if (value === TimePeriod.WEEK) {
      return " for the past week";
    } else if (value === TimePeriod.TODAY) {
      return " for today";
    } else return "";
  }
}
export default ChartHelpers;
