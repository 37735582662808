import { useMemo } from "react";

interface UseEntityFilterById {
  id: string;
}

function useEntityFilterById<T extends UseEntityFilterById>(ids: string[], items: T[]) {
  return useMemo(() => items.filter((workout) => ids.includes(workout.id)), [ids, items]);
}

export default useEntityFilterById;
