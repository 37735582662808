import * as Yup from "yup";
import i18n from "config/i18n";

class CommonSchemas {
  static readonly profilePhoto = Yup.mixed<File>()
    .test("fileSize", i18n.t("profilePhotoFileSizeError"), (value) =>
      value !== null && value !== undefined && typeof value !== "string" ? value.size <= 10000000 : true
    )
    .test("fileNameLength", i18n.t("profilePhotoFileNameError"), (value) =>
      value !== null && value !== undefined && typeof value !== "string" ? value.name.length < 100 : true
    )
    .transform((v) => v || null)
    .nullable();
}

export default CommonSchemas;
