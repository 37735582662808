import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const DataGridComponents = {
  NoRowsOverlay: () => (
    <Grid container height="400px" justifyContent="center" alignItems="center">
      <Grid minWidth={240} maxWidth={320}>
        <Typography variant="h3" textAlign="center" gutterBottom>
          You don't have any coaches yet.
        </Typography>
      </Grid>
    </Grid>
  ),
};
