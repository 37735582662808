import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface DateTimePickersLocalizationProviderProps {
  children?: React.ReactNode;
}

function DateTimePickersLocalizationProvider(props: DateTimePickersLocalizationProviderProps) {
  return <LocalizationProvider dateAdapter={AdapterMoment}>{props.children}</LocalizationProvider>;
}

export default DateTimePickersLocalizationProvider;
