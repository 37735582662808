import { styled } from "@mui/material";

export const Container = styled("div")((props) => {
  const spacing = 3.5;
  const spacingPx = props.theme.spacing(spacing * 2);

  return {
    outline: "none",
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: "translate(-50%, -50%)",
    maxWidth: props.theme.breakpoints.values.md,
    maxHeight: props.theme.breakpoints.values.md,
    width: `calc(100% - ${spacingPx})`,
    height: `calc(100% - ${spacingPx})`,
    boxSizing: "border-box",
  };
});
