import TickerPortal from "components/Ticker/TickerPortal";
import { TransitionGroup } from "react-transition-group";
import { FireStoreMeasurement } from "types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

interface LeaderboardTickerPortalProps {
  measurementId?: FireStoreMeasurement["id"];
  children?: React.ReactNode;
}

function LeaderboardTickerPortal(props: LeaderboardTickerPortalProps) {
  const { measurementId } = props;

  return (
    <TickerPortal>
      <TransitionGroup>
        {measurementId && (
          <Collapse key={measurementId}>
            <Box
              borderRadius={"12px"}
              overflow="hidden"
              m={1}
              bgcolor="transparent"
              boxShadow={(theme) => theme.shadows[8]}
            >
              {props.children}
            </Box>
          </Collapse>
        )}
      </TransitionGroup>
    </TickerPortal>
  );
}

export default LeaderboardTickerPortal;
