import React from "react";
import PropTypes from "prop-types";
import useMuiField from "../useMuiField";
import InputAdornmentSwitcher from "../../../Inputs/InputAdornmentSwitcher";
import usePreferences from "hooks/usePreferences";

function useImperialMuiField(props, options) {
  const [preferences] = usePreferences();
  const [fieldProps, meta, helpers] = useMuiField(props.name);
  const { convertToMetric, convertToImperial, metricAdornmentLabel, imperialAdornmentLabel } = options;

  const [isImperial, setIsImperial] = React.useState(Boolean(preferences.imperialSystemEnabled));
  const [imperialValue, setImperialValue] = React.useState(fieldProps.value ? convertToImperial(fieldProps.value) : "");

  function handleOnChange(value) {
    if (isImperial) setImperialValue(value);
    helpers.setValue(parseFloat(isImperial ? convertToMetric(value) : value), 10);
  }

  function toggleImperial() {
    setIsImperial(!isImperial);
  }

  React.useEffect(() => {
    if (!isImperial) {
      setImperialValue(fieldProps.value ? convertToImperial(fieldProps.value) : "");
    }
  }, [isImperial, fieldProps.value, setImperialValue, convertToImperial]);

  return [
    {
      ...fieldProps,
      onChange: (event) => handleOnChange(event.target.value),
      value: isImperial ? imperialValue : fieldProps.value,
      InputProps: {
        ...props.InputProps,
        endAdornment: options && (
          <InputAdornmentSwitcher
            defaultLabel={metricAdornmentLabel}
            specialLabel={imperialAdornmentLabel}
            value={!isImperial}
            onSwitch={toggleImperial}
          />
        ),
      },
    },
    {
      ...meta,
      isImperial,
    },
    {
      ...helpers,
      toggleImperial,
    },
  ];
}
useImperialMuiField.propTypes = {
  convertToMetric: PropTypes.func.isRequired,
  convertToImperial: PropTypes.func.isRequired,
  metricAdornmentLabel: PropTypes.string.isRequired,
  imperialAdornmentLabel: PropTypes.string.isRequired,
};

export default useImperialMuiField;
