import Checkbox from "./Checkbox";
import RadioGroup from "./RadioGroup";
import Text from "./FormText";
import { Formik, Form as Wrapper } from "formik";
import Weight from "./WeightInput";
import Height from "./HeightInput";
import ProfilePhoto from "./ProfilePhoto";
import DependantFormField from "./DependantFormField";
import CustomDateRange from "./CustomDateRange";

const Form = Formik;

Form.Wrapper = Wrapper;
Form.Checkbox = Checkbox;
Form.RadioGroup = RadioGroup;
Form.Text = Text;
Form.Height = Height;
Form.Weight = Weight;
Form.ProfilePhoto = ProfilePhoto;
Form.DependantField = DependantFormField;
Form.CustomDateRange = CustomDateRange;

export default Form;
