import { ChartLine, LineData } from "components/charting/types/Chart.types";
import { useMemo } from "react";
import { ScaleLinear, ScaleTime } from "d3-scale";
import ChartHelpers from "../helpers/ChartHelpers";

function useChartLinesCoordinatePlotter(
  lines: LineData[],
  xScale: ScaleTime<number, number, never> | ScaleLinear<number, number, never>,
  yScales: { [scaleId: string]: ScaleLinear<number, number, never> }
): ChartLine[] {
  return useMemo(
    () =>
      lines.map((lineData) => {
        const yScale = ChartHelpers.getScaleForMeasure(lineData.measure, yScales);

        return {
          ...lineData,
          line: lineData.line.map((linePoint) => ({
            ...linePoint,
            x: xScale(linePoint.x),
            y: yScale(linePoint.y),
          })),
        };
      }),
    [lines, xScale, yScales]
  );
}
export default useChartLinesCoordinatePlotter;
