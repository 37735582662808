import AthleteTableViewForm from "./Forms/AthleteTableViewForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function TableView(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add a Measurements Table module" close={closeModal}>
      <AthleteTableViewForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}

export default TableView;
