import PropTypes from "prop-types";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { Checkbox } from "./MeuItemCheckbox.components";

function MenuItemCheckbox(props) {
  const { size = "small", checked, ...rest } = props;

  return (
    <Checkbox
      {...rest}
      icon={<CheckBoxOutlineBlankIcon fontSize={size} sx={{ fill: rest?.sx?.color }} />}
      checkedIcon={<CheckBoxIcon fontSize={size} sx={{ fill: rest?.sx?.color }} />}
      checked={checked}
    />
  );
}

MenuItemCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default MenuItemCheckbox;
