import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EventHelpers from "helpers/EventHelpers";
import Mui5 from "library/mui5";

function ConfirmationDialog(props) {
  function handleClose(event) {
    props.setOpen(false);
  }

  function handleConfirm(event) {
    props.onConfirm();
    handleClose();
  }

  return (
    <Dialog open={props.open} onClose={handleClose} onClick={EventHelpers.stopPropagation}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      {props.alert}
      <DialogActions>
        <Mui5.Button fullWidth={false} onClick={handleConfirm} color="error" variant="text">
          {props.confirmText}
        </Mui5.Button>
        <Mui5.Button fullWidth={false} onClick={handleClose} color="primary" variant="text">
          {props.cancelText}
        </Mui5.Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
