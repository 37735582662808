import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function ClickableRAGStatusWrapper(props) {
  const { to, children, ...rest } = props;
  const navigate = useNavigate();

  function handleClick() {
    navigate(to);
  }

  return (
    <span role="button" style={{ cursor: "pointer" }} onClick={handleClick} {...rest}>
      {children}
    </span>
  );
}

ClickableRAGStatusWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default ClickableRAGStatusWrapper;
