import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import { KpiType } from "./AthleteKpiModuleForm.types";

export const defaultInitialValues = {
  exercise: null,
  metricField: null,
  exercises: [],
  rangeType: RangeType.Month,
  kpiType: KpiType.Average,
  startDate: null,
  endDate: null,
  moduleType: 0 as const,
};
