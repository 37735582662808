import { useExercise, useUnitFormatter } from "hooks";
import { WorkoutTaskSetMetadata } from "pages/Workouts/Workouts.types";
import Typography from "@mui/material/Typography";
import { makeSubtitle } from "../../helpers/TaskHelpers";

interface WorkoutStepPreviewExerciseSubTitleProps {
  setMetadata: WorkoutTaskSetMetadata[];
  exerciseId?: string;
}

function WorkoutStepPreviewExerciseSubTitle(props: WorkoutStepPreviewExerciseSubTitleProps) {
  const formatUnit = useUnitFormatter();
  const weightMetadata = useExercise(props.exerciseId)?.metrics.find((metric) => metric.field === "weight");

  const previewSubTitleText = makeSubtitle(props.setMetadata, weightMetadata, formatUnit);

  if (!previewSubTitleText.length) {
    return null;
  }

  return (
    <Typography fontWeight="fontWeightLight" variant="body1" color="textSecondary" noWrap>
      {previewSubTitleText.join(" ")}
    </Typography>
  );
}

export default WorkoutStepPreviewExerciseSubTitle;
