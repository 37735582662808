import { useCallback, useMemo, useRef, useState } from "react";
import http from "axiosSetup";
import LivePbCheckContext, { LivePbCheckContextType } from "./LivePbCheckContext";
import useExercises from "providers/ExercisesProvider/useExercises";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import { PersonalBest } from "types";

interface LivePbCheckProviderProps {
  children?: React.ReactNode;
}

function LivePbCheckProvider(props: LivePbCheckProviderProps) {
  const requestRef = useRef<{ [requestId: string]: boolean | undefined }>({});
  const measurementCheckRef = useRef<{ [measurementId: string]: boolean | undefined }>({});
  const [pbCheckMap, setPbCheckMap] = useState<{
    [athleteId: string]: { [exerciseId: string]: { [measurementId: string]: string } | undefined } | undefined;
  }>({});
  const exercises = useExercises();

  const testForBp = useCallback(
    (athleteId: string, exerciseId: string, measurementId: string) => {
      const exerciseRequestId = `${athleteId}_${exerciseId}`;
      const existingRequest = requestRef.current[exerciseRequestId];

      if (typeof measurementCheckRef.current[measurementId] === "boolean" || existingRequest) {
        return;
      }

      measurementCheckRef.current[measurementId] = true;
      requestRef.current[exerciseRequestId] = true;

      setTimeout(() => {
        (async () => {
          try {
            const result = await http
              .get<PersonalBest>(`athletes/${athleteId}/personal-bests/${exerciseId}`)
              .then((response) => response.data);

            const defaultMetric = ExerciseMetadataService.defaultMetric(
              exercises.find((exercise) => exerciseId === exercise.id)
            );

            const personalBestsForDefaultMetric = result.metrics
              .find(({ metricField }) => defaultMetric.field === metricField)
              ?.variants.reduce((acc, cur) => ({ ...acc, [cur.measurementId]: true }), {});

            setPbCheckMap((prev) => ({
              ...prev,
              [athleteId]: {
                ...prev[athleteId],
                [exerciseId]: {
                  ...personalBestsForDefaultMetric,
                },
              },
            }));
          } catch (error) {}

          requestRef.current[exerciseRequestId] = false;
        })();
      }, 5000);
    },
    [exercises]
  );

  const contextValue = useMemo<LivePbCheckContextType>(() => [pbCheckMap, testForBp], [pbCheckMap, testForBp]);

  return <LivePbCheckContext.Provider value={contextValue}>{props.children}</LivePbCheckContext.Provider>;
}

export default LivePbCheckProvider;
