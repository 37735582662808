class StringHelpers {
  /**
   * @author Carden
   * @description A common method to remove Unicode characters introduce
   * by implicit component formatters e.g. input masks
   */
  static cleanInvisibleChars(str: string) {
    return str.replace(/(⁩|⁦)/g, "");
  }

  static capitalize(str: string) {
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .filter(Boolean)
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" ");
  }

  static cleanWhitespace(str: string) {
    return str.split(" ").filter(Boolean).join(" ");
  }
}
export default StringHelpers;
