import { useMemo } from "react";

interface IntersectingAthleteIdsEntity {
  athleteIds: string[];
}

function useIntersectingAthleteIds<T extends IntersectingAthleteIdsEntity>(items: T[]) {
  return useMemo(() => {
    const athleteIds = items.map((item) => item.athleteIds);
    const commonAthleteIds = athleteIds.reduce(
      (acc, curr) => acc.filter((id) => curr.includes(id)),
      athleteIds[0] || []
    );

    return commonAthleteIds;
  }, [items]);
}

export default useIntersectingAthleteIds;
