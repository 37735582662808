import { useInfoMessage } from "hooks";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions";
import { useCallback } from "react";
import { OneRepMaxValue } from "types/onerepmax";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import OneRepMaxBulkEditFormHelpers from "helpers/OneRepMaxBulkEditFormHelpers";
import usePreferences from "hooks/usePreferences";

interface UseAthleteProfileBulkOneRepMaxUpdateProps {
  athleteId: string;
}

function useAthleteProfileBulkOneRepMaxUpdate(props: UseAthleteProfileBulkOneRepMaxUpdateProps) {
  const { athleteId } = props;
  const oneRepMaxActions = useOneRepMaxActions();
  const successMessage = useInfoMessage({ type: "success" });
  const errorMessage = useInfoMessage({ type: "error" });
  const [{ imperialSystemEnabled }] = usePreferences();

  return useCallback(
    async (values: { selectedOneRepMaxes: FormOneRepMax[] }) => {
      try {
        const updatedOneRepMaxes: {
          athleteId: string;
          oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
        }[] = values.selectedOneRepMaxes
          .map((formOneRepMax) => OneRepMaxBulkEditFormHelpers.fromFormRow(formOneRepMax, { imperialSystemEnabled }))
          .map(({ oneRepMaxes, ...rest }) => ({ ...rest, oneRepMaxes: oneRepMaxes.filter(({ weight }) => weight) }))
          .filter((oneRepMax) => oneRepMax.oneRepMaxes.length);

        if (updatedOneRepMaxes.length) {
          await oneRepMaxActions.updateByExerciseIds(athleteId, updatedOneRepMaxes);
          successMessage({ message: "1RM Values Submitted" });
        }
      } catch (error) {
        errorMessage({ message: "Failed to Submit 1RM Values" });
      }
    },
    [imperialSystemEnabled, oneRepMaxActions, athleteId, successMessage, errorMessage]
  );
}

export default useAthleteProfileBulkOneRepMaxUpdate;
