import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import useQuery from "hooks/useQuery";
import { useMemo } from "react";

function useMakeProfileImageURL(profileImagePath?: string | null): string | null {
  const organisation = useOrganisation();

  const key = useMemo(() => {
    if (!profileImagePath) return null;
    return ["firebase/storage:getProfileImageURL", { profilePath: profileImagePath, orgId: organisation.id }] as const;
  }, [profileImagePath, organisation.id]);

  const { data: photoUrl = null } = useQuery(
    key,
    ([_, params]) => getDownloadURL(ref(getStorage(), `images/users/${params.orgId}/${params.profilePath}`)),
    { dedupingInterval: 60 * 1000 * 60 }
  );

  return photoUrl;
}

export default useMakeProfileImageURL;
