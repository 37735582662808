import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import * as Yup from "yup";

export const errorMessages = {
  exercise: "Select an exercise",
  metricField: "Select an exercise metric",
  groupComparisonFilters: "Please select at least one athlete",
  customDate: "Required when using custom date range",
};

function isCustomDateRequired([rangeType]: any, schema: Yup.ObjectSchema<{}, Yup.AnyObject, {}, "">) {
  if (rangeType === RangeType.Other) {
    return schema.required();
  }
  return schema.nullable();
}

export const schema = Yup.object()
  .shape({
    exerciseId: Yup.object().required(errorMessages.exercise),
    metricField: Yup.object().required(errorMessages.metricField),
    groupComparisonFilters: Yup.array()
      .of(Yup.object().required())
      .min(1, errorMessages.groupComparisonFilters)
      .required(),
    startDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
    endDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
  })
  .required();
