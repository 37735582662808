import { styled } from "@mui/material";

export const BackdropDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: theme.palette.common.white,
}));

export const LoaderDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "calc(50% - 30px)",
  left: "calc(50% - 30px)",
  border: "6px solid #f3f3f3",
  borderRadius: "50%",
  borderTop: `6px solid ${theme.palette.secondary.main}`,
  width: "60px",
  height: "60px",
  animation: "2s infinite linear spin",
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
