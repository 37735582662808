import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";
import Mui5 from "library/mui5";
import FormDatePicker from "library/Form/DatePicker";
import { leaderboardComparePeriods } from "../../LeaderboardEditable.config";
import { LeaderboardComparePeriod, LeaderboardFormValues } from "../../LeaderboardEditable.types";
import { useField, useFormikContext } from "formik";
import { LeaderboardRankType } from "types/leaderboard";
import { useCallback } from "react";
import FormAutocomplete from "library/Form/FormAutocomplete";
import LeaderboardFormHelpers from "pages/Leaderboards/helpers/LeaderboardFormHelpers";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

function PercentageRankingConfig() {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<LeaderboardFormValues>();
  const [rankTypeField] = useField<LeaderboardFormValues["rankType"]>("rankType");
  const [comparePeriodTypeField] = useField<LeaderboardFormValues["comparePeriodType"]>("comparePeriodType");

  const handleRankTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newRankType = checked ? LeaderboardRankType.PercentageOfBest : LeaderboardRankType.Absolute;
      LeaderboardFormHelpers.onRankTypeChange(setValues, newRankType);
    },
    [setValues]
  );

  const handleComparePeriodChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, comparePeriodType: LeaderboardComparePeriod) => {
      LeaderboardFormHelpers.onComparePeriodChange(setValues, comparePeriodType);
    },
    [setValues]
  );

  return (
    <Grid
      container
      mt={1}
      mb={1}
      px={2}
      pt={1}
      pb={0}
      bgcolor={"grey.100"}
      borderRadius="8px"
      alignItems="center"
      spacing={0}
      width="100%"
    >
      <Grid item pb={1}>
        <Typography variant="h3" noWrap>
          {t("Leaderboard.relativeRankingTitle")}
        </Typography>
      </Grid>
      <Grid item pb={1} xs>
        <Tooltip title={t("Leaderboard.relativeRankingTooltip")} arrow placement="top">
          <IconButton size="small" color="primary">
            <InfoOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item pb={1}>
        <Switch
          checked={rankTypeField.value === LeaderboardRankType.PercentageOfBest}
          onChange={handleRankTypeChange}
          size="medium"
          aria-label="percentage-rank-enable-switch"
        />
      </Grid>
      <Collapse in={rankTypeField.value === LeaderboardRankType.PercentageOfBest} unmountOnExit sx={{ width: "100%" }}>
        <Grid item container spacing={1} columns={12} pb={2}>
          <Grid item sm={12} lg={6}>
            <FormAutocomplete
              name="comparePeriodType"
              disableClearable
              multiple={false}
              options={leaderboardComparePeriods}
              getOptionDisabled={LeaderboardFormHelpers.isComparePeriodOptionDisabled(values)}
              onChange={handleComparePeriodChange}
              renderInput={(params: any) => (
                <Mui5.TextField
                  {...params}
                  label={t("Leaderboard.configInputComparePeriodLabel")}
                  variant="outlined"
                  color="primary"
                  size="small"
                  fullWidth
                  sx={{ bgcolor: "common.white" }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <Collapse in={comparePeriodTypeField.value.value === "Custom"} unmountOnExit>
              <FormDatePicker
                maxDate={LeaderboardFormHelpers.getMaxCustomStartDate(values)}
                name="startDateComparePeriod"
                sx={{ bgcolor: "common.white" }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    label: t("Leaderboard.configInputStartDateLabel"),
                    variant: "outlined",
                  },
                }}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default PercentageRankingConfig;
