import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

function NotePadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M24 10.8V20C24 21.0609 23.5786 22.0783 22.8284 22.8284C22.0783 23.5786 21.0609 24 20 24H4C2.93913 24 1.92172 23.5786 1.17157 22.8284C0.421427 22.0783 0 21.0609 0 20V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0H13.2C13.4652 0 13.7196 0.105357 13.9071 0.292893C14.0946 0.48043 14.2 0.734784 14.2 1C14.2 1.26522 14.0946 1.51957 13.9071 1.70711C13.7196 1.89464 13.4652 2 13.2 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V10.8C22 10.5348 22.1054 10.2804 22.2929 10.0929C22.4804 9.90536 22.7348 9.8 23 9.8C23.2652 9.8 23.5196 9.90536 23.7071 10.0929C23.8946 10.2804 24 10.5348 24 10.8ZM8.975 9.46L17.658 0.777C18.1632 0.294103 18.8351 0.0246129 19.534 0.0246129C20.2329 0.0246129 20.9048 0.294103 21.41 0.777L23.223 2.59C23.7196 3.08806 23.9984 3.76268 23.9984 4.466C23.9984 5.16932 23.7196 5.84394 23.223 6.342L21.706 7.859L14.539 15.025C13.7883 15.7751 12.7712 16.1975 11.71 16.2H11.63L8.84 16.144C8.58186 16.1389 8.3357 16.0341 8.15304 15.8517C7.97038 15.6692 7.86537 15.4231 7.86 15.165L7.8 12.37C7.79071 11.8309 7.88995 11.2955 8.09181 10.7956C8.29366 10.2957 8.59402 9.84147 8.975 9.46ZM19.075 2.191L18.263 3L21 5.738L21.81 4.928C21.9321 4.80526 22.0007 4.63915 22.0007 4.466C22.0007 4.29285 21.9321 4.12674 21.81 4.004L20 2.191C19.8753 2.07099 19.709 2.00394 19.536 2.00394C19.363 2.00394 19.1967 2.07099 19.072 2.191H19.075ZM9.8 12.329L9.837 14.16L11.667 14.196C11.9359 14.1988 12.2027 14.1485 12.4522 14.0482C12.7017 13.9478 12.9289 13.7992 13.121 13.611L19.581 7.152L16.849 4.415L10.389 10.875C10.1983 11.0654 10.0478 11.2923 9.94663 11.5421C9.84544 11.7919 9.79557 12.0595 9.8 12.329Z" />
    </SvgIcon>
  );
}
export default NotePadIcon;
