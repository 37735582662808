import { Athlete } from "types/athlete";
import useSelectedEntities from "providers/SelectedEntitiesProvider/useSelectedEntities";
import useSearchText from "providers/SearchTextProvider/useSearchText";
import useTableSort from "providers/TableSortProvider/useTableSort";
import useEntityTextSearch from "hooks/useEntityTextSearch";
import {
  ALPHABETICAL_ASC,
  sortAthletes as sortAthletesHelper,
  LAST_TESTED,
  NEEDS_ATTENTION,
  TOP_PERFORMING,
  LAST_MONITORING_UPDATE,
} from "../../AthleteTable/AthleteTableControls/AthleteTableControlSort/AthleteTableControlSort.helpers";
import useAthleteRagStatuses from "hooks/useAthleteRagStatuses";
import { Group } from "types/groups";
import useFilterByAthletesOrGroups from "hooks/useFilterByAthletesOrGroups";

const searchableProperties: (keyof Athlete)[] = ["firstName", "lastName", "fullName", "sex"];

function useAthletesFilter(athletes: Athlete[]): Athlete[] {
  const sortBy = useTableSort();
  const searchText = useSearchText();
  const selectedItems = useSelectedEntities<Group>();
  const getAthleteRagStatuses = useAthleteRagStatuses({ athleteIds: [] });

  const searchedAthletes = useEntityTextSearch(searchText, athletes, searchableProperties);

  const filteredByGroups = useFilterByAthletesOrGroups(selectedItems, searchedAthletes);

  if (![ALPHABETICAL_ASC, LAST_TESTED, NEEDS_ATTENTION, TOP_PERFORMING, LAST_MONITORING_UPDATE].includes(sortBy.key)) {
    throw new Error(`The key '${sortBy.key}' is not a valid sortKey`);
  }

  const sortedAthletes = sortAthletesHelper(filteredByGroups, sortBy.key, getAthleteRagStatuses);

  return sortedAthletes;
}

export default useAthletesFilter;
