import useGroupsContext from "hooks/useGroupsContext";
import { useCallback, useEffect, useState } from "react";
import logEvent from "utils/logEvent";
import VerticalPageLayout from "components/VerticalPageLayout";
import Box from "@mui/material/Box";
import PageContent from "shared/components/PageContent";
import Loader from "shared/components/Loader/Loader";
import Mui5 from "library/mui5";
import { useNavigate } from "react-router-dom";
import Constants from "shared/utils/Constants";
import Grid from "@mui/material/Grid";
import NoDataWrapper from "components/NoDataWrapper";
import Typography from "@mui/material/Typography";
import outputLogoStripes from "library/DrawerMenu/outputLogoStripes.svg";
import * as Components from "./Groups.components";
import GroupsTable from "./GroupsTable";
import MultiSelectProvider from "providers/MultiSelectProvider";
import { useTranslation } from "react-i18next";
import GroupsInfoCard from "./GroupsInfoCard";

function Groups() {
  const [loading, setLoading] = useState(false);
  const [groups, { fetchAll: fetchAllGroups }] = useGroupsContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        logEvent("GROUPS_VIEW_ALL");
        setLoading(true);
        await fetchAllGroups();
      } catch (e) {}
      setLoading(false);
    })();
  }, [fetchAllGroups]);

  const handleAddGroupClick = useCallback(() => {
    navigate(Constants.routes.groupsAdd.route);
  }, [navigate]);

  const { t } = useTranslation();

  return (
    <VerticalPageLayout
      top={
        <Box p={2} pb={0}>
          <PageContent pageHeading={Constants.routes.groups.title} infoCardComponent={GroupsInfoCard} />
        </Box>
      }
      main={
        <>
          <Components.GroupContentDiv>
            <NoDataWrapper
              hasData={Boolean(groups.length)}
              fallback={
                <Grid container justifyContent="center" alignItems="center" height="100%">
                  <Grid item>
                    <Typography variant="h4" align="center" paragraph>
                      {t("Group.All.noGroupsText")}
                    </Typography>
                    <Mui5.Button
                      color="primary"
                      fullWidth
                      disableElevation
                      variant="contained"
                      onClick={handleAddGroupClick}
                    >
                      {t("Group.All.addNewGroupButtonText")}
                    </Mui5.Button>
                    <Box pt={3} pb={16} textAlign="center">
                      <img src={outputLogoStripes} alt={t("logoAltText")} />
                    </Box>
                  </Grid>
                </Grid>
              }
            >
              <MultiSelectProvider>
                <GroupsTable groups={groups} />
              </MultiSelectProvider>
            </NoDataWrapper>
          </Components.GroupContentDiv>
          {loading && <Loader />}
        </>
      }
    />
  );
}

export default Groups;
