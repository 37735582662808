import { useMemo } from "react";
import useCoaches from "../useCoaches";

function useCoach(coachId?: string) {
  const coaches = useCoaches();

  return useMemo(() => coaches.find((coach) => coach.id === coachId) || null, [coachId, coaches]);
}

export default useCoach;
