import { FireStoreMeasurement } from "types";

interface State {
  [measurementId: string]: FireStoreMeasurement;
}

enum Actions {
  ADD = "ADD_MEASUREMENT",
  REFRESH = "REFRESH",
}

export type { State as LiveActivityFeedState };
export { Actions as LiveActivityFeedActions };

function liveActivityFeedReducer(
  state: State,
  action: { type: Actions.ADD; payload: FireStoreMeasurement[] } | { type: Actions.REFRESH }
) {
  switch (action.type) {
    case Actions.ADD: {
      return {
        ...state,
        ...action.payload.reduce(
          (accumulator, measurement) => ({
            ...accumulator,
            [measurement.id]: measurement,
          }),
          {}
        ),
      };
    }
    case Actions.REFRESH: {
      return { ...state };
    }
    default:
      return state;
  }
}
export default liveActivityFeedReducer;
