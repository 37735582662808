export const periods = {
  day: {
    label: "Today",
    value: "day" as const,
  },
  week: {
    label: "Past Week",
    value: "week" as const,
  },
  month: {
    label: "Past Month",
    value: "month" as const,
  },
  all: {
    label: "All Time",
    value: "all" as const,
  },
};
