import AthleteMonitoringIndicatorsForm from "./Forms/AthleteMonitoringIndicatorsForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function AthleteMonitoringIndicators(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Select an Athlete Monitoring Template" close={closeModal}>
      <AthleteMonitoringIndicatorsForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}
export default AthleteMonitoringIndicators;
