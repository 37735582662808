import { ExerciseType } from "./exercise";

export type ExerciseMetricDisplayMetadataSystem = {
  decimals: null | number;
  unit: null | string;
};

export type ExerciseMetricDisplayMetadata = {
  imperial: null | ExerciseMetricDisplayMetadataSystem;
  metric: null | ExerciseMetricDisplayMetadataSystem;
};

/**
 * @description
 * Indicates how to sort values of this metric and usually indicates whether a higher or lower value is better.
 *
 * - 0 - Ascending (lower is better)
 * - 1 - Descending (higher is better)
 */
export type SortType = 0 | 1;

export type ExerciseMetric = {
  displayMetadata: null | ExerciseMetricDisplayMetadata;
  isDefault: boolean;
  field: string;
  name: string;
  sortType: SortType;
  unitOfMeasure: string;
  isPbEnabled: boolean;
};

export type ExerciseRepetition = Omit<ExerciseMetric, "isDefault" | "isPbEnabled">;

export enum Variants {
  Left = "Left",
  Right = "Right",
}
export const VariantsCombined = "LeftAndRight";

export interface BaseExercise {
  id: string;
  type: ExerciseType;
  isEnabled: boolean;
  category: string;
  name: string;
  metrics: ExerciseMetric[];
  repetitions: ExerciseRepetition[];
  minimumVelocityThreshold?: number | null;
  variants: Variants[];
  isOneRepMaxEnabled: boolean;
  /**
   * @description
   * The YouTube videoId of the exercise.
   * Output Exercise: (legacy)
   * Custom Exercise: Where the user can add a videoId to the exercise */
  videoId: string | null;
  instructionVideoId: string | null;
  instructions: string | null;
  bodySegments: string[];
  equipment: string[];
  searchTerms: string[];
  sensorPlacements: string[];
}

export interface OutputExercise extends BaseExercise {
  type: ExerciseType.Output;
}
