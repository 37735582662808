import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import MeasurementService from "services/MeasurementService";
import { Measurement } from "types";
import { firstBy } from "thenby";
import useRequest from "hooks/useRequest";
import WorkoutAthleteReviewMeasurementsTable from "./WorkoutAthleteReviewMeasurementsTable";
import * as Components from "./WorkoutAthleteReviewMeasurements.components";
import useExcludeArchivedExerciseItems from "hooks/useExcludeArchivedExerciseItems";

interface WorkoutAthleteReviewMeasurementsProps {
  completedWorkoutId: string;
}

function WorkoutAthleteReviewMeasurements(props: WorkoutAthleteReviewMeasurementsProps) {
  const [workoutMeasurements, setWorkoutMeasurements] = useState<Measurement[]>([]);
  const { completedWorkoutId } = props;
  const getCompletedWorkout = useRequest(MeasurementService.getCompletedWorkout, true);

  useEffect(() => {
    (async () => {
      const result = await getCompletedWorkout(completedWorkoutId);

      result && setWorkoutMeasurements(result.sort(firstBy("completedDate")));
    })();
  }, [completedWorkoutId, getCompletedWorkout]);

  const excludeArchivedExerciseMeasurements = useExcludeArchivedExerciseItems(workoutMeasurements);

  return (
    <Components.MeasurementsCtrBox height="100%" width="100%" marginX="-24px">
      <Box maxWidth={1240}>
        <WorkoutAthleteReviewMeasurementsTable measurements={excludeArchivedExerciseMeasurements} />
      </Box>
    </Components.MeasurementsCtrBox>
  );
}

export default WorkoutAthleteReviewMeasurements;
