import AthleteLineChartModuleForm from "./Forms/AthleteLineChartModuleForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function LineGraph(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add a Line Chart module" close={closeModal}>
      <AthleteLineChartModuleForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}

export default LineGraph;
