import { FormikHelpers } from "formik";
import {
  leaderboardComparePeriodMonth,
  leaderboardComparePeriodYear,
  leaderboardTimePeriodMonth,
} from "../../LeaderboardTables/LeaderboardEditable/LeaderboardEditable.config";
import {
  LeaderboardComparePeriod,
  LeaderboardFormValues,
  LeaderboardTimePeriod,
} from "../../LeaderboardTables/LeaderboardEditable/LeaderboardEditable.types";
import { LeaderboardRankType } from "types/leaderboard";
import moment from "moment";

class LeaderboardFormHelpers {
  static onRankTypeChange(
    setValues: FormikHelpers<LeaderboardFormValues>["setValues"],
    newRankType: LeaderboardRankType
  ) {
    setValues((values) => {
      const timePeriod =
        newRankType === LeaderboardRankType.PercentageOfBest && values.type.value === "All"
          ? leaderboardTimePeriodMonth
          : values.type;

      const comparePeriodType =
        newRankType === LeaderboardRankType.PercentageOfBest
          ? LeaderboardFormHelpers.getValidComparePeriodForTimePeriod(timePeriod, values.comparePeriodType)
          : values.comparePeriodType;

      const startDateComparePeriod = LeaderboardFormHelpers.getValidStartDateForCustomComparePeriod(
        timePeriod,
        comparePeriodType,
        values.startDateComparePeriod
      );

      return {
        ...values,
        startDateComparePeriod,
        type: timePeriod,
        comparePeriodType,
        rankType: newRankType,
      };
    });
  }

  static onTimePeriodChange(
    setValues: FormikHelpers<LeaderboardFormValues>["setValues"],
    newTimePeriod: LeaderboardTimePeriod
  ) {
    setValues((values) => {
      const comparePeriodType = LeaderboardFormHelpers.getValidComparePeriodForTimePeriod(
        newTimePeriod,
        values.comparePeriodType
      );

      const startDateComparePeriod = LeaderboardFormHelpers.getValidStartDateForCustomComparePeriod(
        newTimePeriod,
        comparePeriodType,
        values.startDateComparePeriod
      );

      return { ...values, type: newTimePeriod, comparePeriodType, startDateComparePeriod };
    });
  }

  static onComparePeriodChange(
    setValues: FormikHelpers<LeaderboardFormValues>["setValues"],
    newComparePeriodType: LeaderboardComparePeriod
  ) {
    setValues((values) => {
      const startDateComparePeriod = LeaderboardFormHelpers.getValidStartDateForCustomComparePeriod(
        values.type,
        newComparePeriodType,
        values.startDateComparePeriod
      );

      return {
        ...values,
        startDateComparePeriod,
        comparePeriodType: newComparePeriodType,
      };
    });
  }

  static isTimePeriodOptionDisabled(timePeriodOption: LeaderboardTimePeriod, values: LeaderboardFormValues) {
    return values.rankType === LeaderboardRankType.PercentageOfBest && timePeriodOption.value === "All";
  }

  static isComparePeriodOptionDisabled(values: LeaderboardFormValues) {
    return (comparePeriodOption: LeaderboardComparePeriod) => {
      if (values.rankType === LeaderboardRankType.PercentageOfBest) {
        if (
          (values.type.value === "Week" && ["Week"].includes(comparePeriodOption.value)) ||
          (values.type.value === "Month" && ["Week", "Month"].includes(comparePeriodOption.value))
        ) {
          return true;
        }
      }
      return false;
    };
  }

  static getTimePeriodStartDate(timePeriod: LeaderboardTimePeriod) {
    const time = moment();

    if (timePeriod.value === "Day") {
      return time.startOf("day");
    }

    const timePeriodKey = timePeriod.value.toLowerCase() as "week" | "month";

    return time.endOf("day").subtract(1, timePeriod.value === "All" ? "month" : timePeriodKey);
  }

  static getMaxCustomStartDate(values: LeaderboardFormValues) {
    const timePeriodKey = values.type.value.toLowerCase() as "day" | "week" | "month";

    return moment()
      .startOf("day")
      .subtract(1, values.type.value === "All" ? "year" : timePeriodKey);
  }

  static getValidComparePeriodForTimePeriod(
    timePeriod: LeaderboardTimePeriod,
    comparePeriodType: LeaderboardComparePeriod
  ) {
    if (timePeriod.value === "Week" && ["Week"].includes(comparePeriodType.value)) {
      return leaderboardComparePeriodMonth;
    } else if (timePeriod.value === "Month" && ["Week", "Month"].includes(comparePeriodType.value)) {
      return leaderboardComparePeriodYear;
    }

    return comparePeriodType;
  }

  static getValidStartDateForCustomComparePeriod(
    timePeriod: LeaderboardTimePeriod,
    comparePeriod: LeaderboardComparePeriod,
    startDateComparePeriod: LeaderboardFormValues["startDateComparePeriod"]
  ) {
    if (comparePeriod.value === "Custom") {
      const timePeriodStart = LeaderboardFormHelpers.getTimePeriodStartDate(timePeriod);

      const isComparePeriodStartDateValid = moment(startDateComparePeriod).isValid();
      const isTimePeriodAfterComparePeriodStartDate = timePeriodStart.isAfter(moment(startDateComparePeriod));

      if (!isComparePeriodStartDateValid || !isTimePeriodAfterComparePeriodStartDate) {
        return timePeriodStart.subtract(1, "year").toISOString();
      }
    }

    return startDateComparePeriod;
  }

  static getPercentageComparisonForMeasurements(
    recentValue: number,
    bestValueFromAll: number,
    isSortDescending: boolean
  ) {
    const percentage = isSortDescending
      ? recentValue / bestValueFromAll
      : 1 - Math.abs(recentValue - bestValueFromAll) / recentValue;

    const roundingMultiplier = Math.pow(10, 2);

    return Math.round(percentage * roundingMultiplier) / roundingMultiplier;
  }
}

export default LeaderboardFormHelpers;
