import ChartLegend from "components/charting/ChartLegend";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import useDefinedContext from "hooks/useDefinedContext";
import usePreferences from "hooks/usePreferences";
import useColorRegistry from "pages/Charting/hooks/useColorRegistry";
import { useCallback, useMemo } from "react";
import SetComparisonChartService from "services/SetComparisonChartService";
import { MeasurementSet } from "types/measurement";

interface SetComparisonChartLegendProps {}

function SetComparisonChartLegend(props: SetComparisonChartLegendProps) {
  const { value: values, onChange } = useDefinedContext(ChartSetSelectContext);
  const getColor = useColorRegistry();
  const [{ imperialSystemEnabled }] = usePreferences();

  const makeLabel = useCallback(
    (set: MeasurementSet) => SetComparisonChartService.makeLabel(set, imperialSystemEnabled),
    [imperialSystemEnabled]
  );

  const items = useMemo(
    () =>
      values.map((value) => ({
        id: value.measurementId,
        color: getColor(value.measurementId),
        label: makeLabel(value),
        remove: () => onChange(values.filter((v) => v !== value)),
      })),
    [getColor, makeLabel, onChange, values]
  );

  return <ChartLegend items={items} />;
}

export default SetComparisonChartLegend;
