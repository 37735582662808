import { Group } from "types/groups";
import Grid from "@mui/material/Grid";
import Mui5 from "library/mui5";
import Box from "@mui/material/Box";
import { useCallback, useMemo, useState } from "react";
import Popover from "@mui/material/Popover";

interface WorkoutOverviewAssignmentsGroupsProps {
  groups: Group[];
  maxDisplayed: number;
}

function WorkoutOverviewAssignmentsGroups(props: WorkoutOverviewAssignmentsGroupsProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const visibleGroups = useMemo(() => props.groups.slice(0, props.maxDisplayed), [props]);
  const otherGroups = useMemo(() => props.groups.slice(props.maxDisplayed), [props]);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderGroup = useCallback((group: Group) => {
    return (
      <Grid key={group.id} item>
        <Mui5.Chip title={group.name} label={group.name} customColor={group.colour} />
      </Grid>
    );
  }, []);

  return (
    <Grid container alignItems="center" spacing={0.5}>
      {visibleGroups.map(renderGroup)}
      {Boolean(otherGroups.length) && (
        <Grid item pl={1}>
          <Mui5.Button size="medium" variant="text" onClick={handleClick}>
            <Box component="span" color="grey.500" fontWeight="fontWeightRegular">
              Show More (+{otherGroups.length})
            </Box>
          </Mui5.Button>
          <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box minWidth={(theme) => theme.spacing(24)} maxWidth={(theme) => theme.spacing(36)} p={2}>
              <Grid container spacing="8px">
                {otherGroups.map(renderGroup)}
              </Grid>
            </Box>
          </Popover>
        </Grid>
      )}
    </Grid>
  );
}

export default WorkoutOverviewAssignmentsGroups;
