import AthletesService from "services/AthleteService";
import { AthleteMonitoringStatusesContextValue } from "../../contexts/AthleteMonitoringStatusesContext/AthleteMonitoringStatusesContext";

export function updateRagStatusStore(
  ragStatuses: AthleteMonitoringStatusesContextValue,
  updatedRagSatuses: Await<ReturnType<typeof AthletesService.getTemplateByAthleteIds>>
) {
  return updatedRagSatuses.reduce(
    (prevRagStatuses, athlete) => {
      const prevForAthlete = prevRagStatuses[athlete.athleteId] ?? {};

      const updatedForAthlete = athlete.ragStatuses.reduce(
        (prev, ragStatus) => {
          return { ...prev, [ragStatus.metadataId]: ragStatus };
        },
        { ...prevForAthlete }
      );

      return {
        ...prevRagStatuses,
        [athlete.athleteId]: {
          ...prevForAthlete,
          ...updatedForAthlete,
        },
      };
    },
    { ...ragStatuses }
  );
}
