import http from "../../axiosSetup";

class ExerciseMetadataService {
  /**
   * @returns {Promise<import("types").Exercise[]>} */
  static get() {
    return http.get("/exercises").then((response) => response.data);
  }

  /**
   * @param {import("types").Exercise | undefined} exercise
   * @returns {import("types").ExerciseMetric} */
  static defaultMetric(exercise) {
    return exercise?.metrics?.find((metric) => metric.isDefault) || {};
  }

  /**
   * @param {import("types").Exercise | undefined} exercise
   * @param {string | undefined | null} metricName
   * @returns {import("types").ExerciseMetric} */
  static metric(exercise, metricName) {
    return exercise?.metrics?.find((metric) => metric.field === metricName) || {};
  }

  /**
   * @param {import("types").Exercise | undefined} exercise
   * @param {string | undefined | null} repMetricName */
  static repMetricByFieldNameOrFirstOrNull(exercise, repMetricName) {
    const repMetric =
      exercise?.repetitions?.find((metric) => metric.field === repMetricName) ||
      exercise?.repetitions?.find((metric) => metric);

    return repMetric || null;
  }
}
export default ExerciseMetadataService;
