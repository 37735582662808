import AthleteMonitoringIndicatorsForm from "../../AthleteReport/ModuleModal/Forms/AthleteMonitoringIndicatorsForm";
import { ReportModuleType } from "../../ModuleModal.types";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function AthleteMonitoringIndicators(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Select an Athlete Monitoring Template" close={closeModal}>
      <AthleteMonitoringIndicatorsForm
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={moduleToEdit}
        moduleType={ReportModuleType.GROUP_RAG_STATUS}
      />
    </ModuleModalWrapper>
  );
}
export default AthleteMonitoringIndicators;
