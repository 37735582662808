import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import { style } from "config";
import useDefinedContext from "hooks/useDefinedContext";
import SetSelector from "library/Inputs/SetSelector";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MeasurementSet } from "types";

export interface AthleteProfileChartCompareSetSelectorProps {}

function AthleteProfileChartCompareSetSelector(props: AthleteProfileChartCompareSetSelectorProps) {
  const { t } = useTranslation();
  const { value, options, onChange } = useDefinedContext(ChartCompareSetSelectContext);
  const { value: selectedPrimarySets } = useDefinedContext(ChartSetSelectContext);

  const getOptionDisabled = useCallback(
    (option: MeasurementSet) => selectedPrimarySets.includes(option),
    [selectedPrimarySets]
  );

  const color = style.colors.options[1];

  return (
    <SetSelector
      value={value}
      options={options}
      onChange={onChange}
      AutoCompleteProps={{ getOptionDisabled }}
      CheckboxProps={{ sx: { color } }}
      TextFieldProps={{ label: t("AthleteProfileChartCompareSetSelector.compareSetSelectLabel") }}
    />
  );
}

export default AthleteProfileChartCompareSetSelector;
