import ExportDataAthleteForm from "../../../../library/Forms/ExportDataAthleteForm";
import { useExportAthletes } from "../../../../hooks";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGroupsContext from "hooks/useGroupsContext";
import useExercises from "providers/ExercisesProvider/useExercises";

function ExportAthleteData() {
  const athletes = useAthleteStore();
  const [groups] = useGroupsContext();
  const exportAthletes = useExportAthletes();
  const exercises = useExercises();

  function handleSubmit(values, formikBag) {
    exportAthletes(values);
  }

  return <ExportDataAthleteForm onSubmit={handleSubmit} athletes={athletes} groups={groups} exercises={exercises} />;
}
export default ExportAthleteData;
