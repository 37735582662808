import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { FieldArray, FieldArrayRenderProps, Form, useFormikContext } from "formik";
import AthleteBulkEditRow from "../AthleteBulkEditRow";
import Mui5 from "library/mui5";
import { Exercise } from "types/exercise";
import usePreferences from "hooks/usePreferences";
import { memo, useCallback, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { FormOneRepMax } from "../hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import { useTranslation } from "react-i18next";

interface AthleteBulkEditFormFieldsProps {
  exercise: Exercise | null;
  spacingX: number;
  onCancel(): void;
}

function AthleteBulkEditFormFields(props: AthleteBulkEditFormFieldsProps) {
  const { spacingX, exercise } = props;
  const { values } = useFormikContext<{ athletesOneRepMaxes: FormOneRepMax[] }>();
  const parentRef = useRef<HTMLDivElement>(null);
  const [{ imperialSystemEnabled }] = usePreferences();
  const { t } = useTranslation();

  const weightInputWidth = 256;
  const weightInputAlignment = exercise?.variants.length ? "center" : "right";

  const rowVirtualizer = useVirtualizer({
    count: values.athletesOneRepMaxes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 56, []),
    overscan: 5,
  });

  return (
    <Box component={Form} display="flex" flexDirection="column" height={(theme) => `calc(100% - ${theme.spacing(9)})`}>
      <Grid
        container
        columns={12}
        py={2}
        px={spacingX}
        color="#FFF"
        sx={{ backgroundColor: "primary.main" }}
        minHeight={(theme) => theme.spacing(6)}
      >
        <Grid item xs>
          <Typography variant="h4" color="inherit">
            {t("Athlete.bulkEdit1RMTableHeaderLeft")}
          </Typography>
        </Grid>
        <Grid item width={weightInputWidth} textAlign={weightInputAlignment}>
          <Typography variant="h4" color="inherit">
            {t("Athlete.bulkEdit1RMTableHeaderRight", { unit: imperialSystemEnabled ? "lbs" : "kg" })}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container columns={12} spacing="0px" height={(theme) => `calc(100% - ${theme.spacing(11 + 6)})`}>
        <Grid item xs={12} pt={1} height="100%">
          <FieldArray
            name="athletesOneRepMaxes"
            render={(arrayHelpers: FieldArrayRenderProps) => (
              <div ref={parentRef} style={{ overflow: "auto", height: "100%" }}>
                <div
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <div
                      style={{
                        transform: `translateY(${virtualRow.start}px)`,
                        height: `${virtualRow.size}px`,
                        top: 0,
                        left: 0,
                        position: "absolute",
                        width: "100%",
                      }}
                      key={`${values.athletesOneRepMaxes[virtualRow.index].athleteId}-${
                        values.athletesOneRepMaxes[virtualRow.index].exerciseId
                      }`}
                    >
                      <AthleteBulkEditRow
                        athlete1rmValue={values.athletesOneRepMaxes[virtualRow.index]}
                        athlete1rmValueIndex={virtualRow.index}
                        arrayHelpers={arrayHelpers}
                        spacingX={spacingX}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container alignItems="center" px={spacingX} py={1} minHeight={(theme) => theme.spacing(11)}>
        <Grid item xs>
          <Mui5.Button fullWidth onClick={props.onCancel} variant="outlined">
            {t("cancelButtonText")}
          </Mui5.Button>
        </Grid>
        <Grid item>
          <Box px={1.5} />
        </Grid>
        <Grid item xs>
          <Mui5.Button fullWidth type="submit">
            {t("submitButtonText")}
          </Mui5.Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(AthleteBulkEditFormFields);
