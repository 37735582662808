import NotFound from "pages/NotFound";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExerciseLibraryHome = lazy(() => import("./ExerciseLibraryHome"));

function ExerciseLibrary() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Suspense fallback={<div>Loading</div>}>
            <ExerciseLibraryHome />
          </Suspense>
        }
      />
      <Route path="*" element={<NotFound link="/exercise-library" buttonText={t("Library.goBack404Text")} />} />
    </Routes>
  );
}

export default ExerciseLibrary;
