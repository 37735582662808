import { useMemo } from "react";

type SearchableEntity = {};

function useEntityTextSearch<T extends SearchableEntity>(searchText: string, items: T[], properties: (keyof T)[]) {
  return useMemo(() => {
    if (!searchText) return items;

    const inputKeywords = searchText.toLowerCase().split(" ");

    const filteredItems: T[] = items.filter((item) => {
      const candidateKeywordString = properties
        .map((property) => {
          const value = item[property];
          if (Array.isArray(value)) {
            return value
              .filter((value) => typeof value === "string")
              .join(" ")
              .toUpperCase();
          }

          return value;
        })
        .filter((value) => typeof value === "string")
        .filter(Boolean)
        .join(" ")
        .toLowerCase();

      return inputKeywords.every((inputKeyword) => candidateKeywordString.indexOf(inputKeyword) > -1);
    });

    return filteredItems;
  }, [searchText, items, properties]);
}

export default useEntityTextSearch;
