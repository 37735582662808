import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export interface InfoCardControlDescriptorProps {}

export const InfoCardControlDescriptor = ({
  title,
  control,
  label,
}: {
  title: string;
  control: React.ReactNode;
  label: React.ReactNode;
}) => (
  <Grid container alignItems="center" spacing={1} p={0.5} pl={0.25}>
    <Grid item width={140} pr={0}>
      <Typography variant="h3">{title}</Typography>
    </Grid>
    <Grid item width={100} textAlign="center" lineHeight={0}>
      {control}
    </Grid>
    <Grid item xs>
      <Typography variant="h4">{label}</Typography>
    </Grid>
  </Grid>
);

export default InfoCardControlDescriptor;
