import { styled } from "@mui/material";
import useTickerId from "../TickerProvider/useTickerId";

const TickerNodeRoot = styled("div")<{ id: string }>({
  width: "100%",
});

function TickerNode() {
  const tickerId = useTickerId();

  if (typeof tickerId === "undefined") {
    if (process.env.NODE_ENV === "development") {
      console.warn("TickerNode must be a descendant of TickerIdContext");
    }

    return null;
  }

  return <TickerNodeRoot id={tickerId!} />;
}

export default TickerNode;
