import useCanDo from "hooks/useCanDo";
import { useEffect, useMemo, useState } from "react";
import CoachService from "services/CoachService";
import { Coach, CoachBase } from "types/coach";
import CoachesActionsContext, { CoachesActionsContextType } from "./CoachesActionsContext";
import CoachesContext from "./CoachesContext";

interface CoachesProviderProps {
  children?: React.ReactNode;
}

function CoachesProvider(props: CoachesProviderProps) {
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const canDo = useCanDo();

  const coachActions: CoachesActionsContextType = useMemo(() => {
    const getAll = async () => {
      const result = await CoachService.getAll();
      setCoaches(result);
      return result;
    };

    const getById = async (id: string) => {
      const coach = await CoachService.getById(id);
      setCoaches((prev) => [...prev.filter((coach) => coach.id !== id), coach]);
      return coach;
    };

    const create = async (coach: CoachBase) => {
      const { id } = await CoachService.create(coach);
      await getById(id);
      return { id };
    };

    const update = async (id: string, coach: CoachBase) => {
      const result = await CoachService.update(id, coach);
      await getById(id);
      return result;
    };

    const deleteCoach = async (id: string) => {
      await CoachService.delete(id);
      setCoaches((prev) => prev.filter((coach) => coach.id !== id));
      return;
    };

    return { create, delete: deleteCoach, getAll, getById, update };
  }, []);

  useEffect(() => {
    canDo("coach.any") && coachActions.getAll();
  }, [canDo, coachActions]);

  return (
    <CoachesActionsContext.Provider value={coachActions}>
      <CoachesContext.Provider value={coaches}>{props.children}</CoachesContext.Provider>
    </CoachesActionsContext.Provider>
  );
}

export default CoachesProvider;
