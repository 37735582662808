import { ArchivedAthletesContext } from "./ArchivedAthletes.contexts";
import { ArchivedAthlete } from "types/athlete";

interface ArchivedAthletesProviderProps {
  children?: React.ReactNode;
  athletes: ArchivedAthlete[];
}

function ArchivedAthletesProvider(props: ArchivedAthletesProviderProps) {
  const { children, athletes } = props;

  return <ArchivedAthletesContext.Provider value={athletes}>{children}</ArchivedAthletesContext.Provider>;
}
export default ArchivedAthletesProvider;
