import Grid from "@mui/material/Grid";
import DependantFormField from "library/Form/DependantFormField";
import FormAutoComplete from "library/Form/FormAutocomplete";
import { Formik, Form, FormikContextType, FormikHelpers } from "formik";
import { Exercise, ExerciseMetric } from "types";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useExercises from "providers/ExercisesProvider/useExercises";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useCallback, useMemo } from "react";
import Button from "library/mui5/Button";
import { ReportModuleType, GroupComparisonFilter, RangeType } from "../../../../ModuleModal.types";
import { schema } from "./GroupAthleteComparisonChartModuleForm.schema";
import { Athlete } from "types/athlete";
import moment from "moment";
import SetDateRangeHelper from "helpers/SetDateRangeHelper";
import RadioGroup from "library/Form/RadioGroup";
import CustomDateRangeFullWidthFix from "library/Forms/CustomDateRangeFullWidthFix";
import CustomDateRange from "library/Form/CustomDateRange";
import FormHelperText from "@mui/material/FormHelperText";
import Mui5 from "library/mui5";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import AthleteHelpers from "helpers/AthleteHelpers";

interface GroupAthleteComparisonChartModuleFormInitialValues<T = string, U = string> {
  exerciseId: null | T;
  metricField: null | (T extends string ? string : ExerciseMetric);
  groupComparisonFilters?: T extends string ? GroupComparisonFilter[] : Athlete[];
  moduleType: ReportModuleType.ATHLETE_COMPARISON;
  rangeType: RangeType;
  startDate: undefined | U;
  endDate: undefined | U;
}

interface GroupAthleteComparisonChartModuleFormProps {
  groupAthleteIds?: string[];
  onSubmit(values: GroupAthleteComparisonChartModuleFormInitialValues): void;
  onCancel(): void;
  initialValues?: GroupAthleteComparisonChartModuleFormInitialValues;
}

function GroupAthleteComparisonChartModuleForm(props: GroupAthleteComparisonChartModuleFormProps) {
  const { onSubmit, initialValues, onCancel, groupAthleteIds = [] } = props;
  const exercises = useExercises();
  const athletes = useAthleteStore();

  const groupAthletes = athletes.filter((athlete) => groupAthleteIds.includes(athlete.id));

  const onMetricDependancyChange = useCallback(
    ({ setFieldValue, dependancyValue }: FormikHelpers<typeof initialValues> & { dependancyValue: Exercise }) => {
      const exercise: Exercise = dependancyValue;
      const defaultMetric = exercise?.metrics.find((metric) => metric) || null;
      return setFieldValue("metricField", defaultMetric);
    },
    []
  );

  const onCustomDateDependancyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("rangeType", RangeType.Other),
    []
  );

  const memoizedInitialValues = useMemo(() => {
    const { exerciseId, metricField, groupComparisonFilters = [], startDate, endDate, rangeType } = initialValues || {};
    const exercise = exercises.find((exercise) => exercise.id === exerciseId);

    return {
      exerciseId: exercise || null,
      metricField: exercise?.metrics.find((metric) => metric.field === metricField) || null,
      groupComparisonFilters: groupComparisonFilters
        .map(({ athleteId }) => {
          const athlete = athletes.find((athlete) => athlete.id === athleteId);
          return athlete as Athlete;
        })
        .filter(Boolean),
      moduleType: ReportModuleType.ATHLETE_COMPARISON as const,
      rangeType: rangeType ?? RangeType.Month,
      startDate: startDate ? moment(startDate).startOf("day") : undefined,
      endDate: endDate ? moment(endDate).endOf("day") : undefined,
    };
  }, [athletes, exercises, initialValues]);

  function handleSubmit(values: GroupAthleteComparisonChartModuleFormInitialValues<Exercise, moment.Moment>) {
    const rangeType = parseInt(`${values.rangeType}`);

    return onSubmit({
      ...values,
      groupComparisonFilters: (values.groupComparisonFilters || [])?.map((athlete) => ({
        athleteId: athlete.id,
        athleteName: athlete.fullName,
      })),
      ...SetDateRangeHelper.calculateDateRange({
        rangeType,
        startDate: values.startDate,
        endDate: values.endDate,
      }),
      exerciseId: values.exerciseId?.id || null,
      metricField: values.metricField?.field || null,
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={memoizedInitialValues} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormAutoComplete
              name="exerciseId"
              options={exercises}
              groupBy={(option: Exercise) => option.category}
              getOptionLabel={(option: Exercise) => option.name || ""}
              renderInput={(params: any) => (
                <Mui5.TextField {...params} label="Exercise" placeholder="Select Exercise" variant="standard" />
              )}
              isOptionEqualToValue={(option: Exercise, value: Exercise | undefined) => option.id === value?.id}
              filterOptions={exerciseAutocompleteFilterOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <DependantFormField dependancyName="exerciseId" onDependancyChange={onMetricDependancyChange}>
              {({ values }: FormikContextType<GroupAthleteComparisonChartModuleFormInitialValues<Exercise>>) => {
                const options = values.exerciseId?.metrics || [];

                return (
                  <FormAutoComplete
                    name="metricField"
                    options={options}
                    disabled={!options.length}
                    defaultValue={null}
                    getOptionLabel={(option: ExerciseMetric) => option.name || ""}
                    renderInput={(params: any) => (
                      <Mui5.TextField {...params} label="Metric" placeholder="Select Metric" variant="standard" />
                    )}
                    isOptionEqualToValue={(option: ExerciseMetric, value: ExerciseMetric) =>
                      option.field === value?.field
                    }
                  />
                );
              }}
            </DependantFormField>
          </Grid>

          <Grid item xs={12}>
            <FormAutoComplete<Athlete, true, true, false>
              options={groupAthletes}
              name="groupComparisonFilters"
              multiple
              disabled={!groupAthletes.length}
              defaultValue={[] as Athlete[]}
              disableCloseOnSelect
              renderTags={(tagValue, getTagProps: any) =>
                tagValue.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return <Mui5.Chip key={option.id} label={option.fullName} {...tagProps} />;
                })
              }
              renderOption={(props, option) => (
                <AutocompleteOptionColourable {...props} key={option.id}>
                  {AthleteHelpers.getFullName(option)}
                </AutocompleteOptionColourable>
              )}
              getOptionLabel={(option: Athlete) => option.fullName || ""}
              renderInput={(params: any) => <Mui5.TextField {...params} label="Add Athletes" variant="standard" />}
              isOptionEqualToValue={(option: Athlete, value: Athlete) => option.id === value?.id}
            />
            {!groupAthletes.length && (
              <FormHelperText error sx={{ letterSpacing: 0.4, marginTop: 1 }}>
                You must select a single group before you will be able to add athletes to this module.
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              label="Date Range"
              name="rangeType"
              color="primary"
              options={[
                { label: "Today", value: RangeType.Day },
                { label: "Week", value: RangeType.Week },
                { label: "Month", value: RangeType.Month },
                { label: "Year", value: RangeType.Year },
                {
                  label: "Custom",
                  value: RangeType.Other,
                  component: (
                    <CustomDateRangeFullWidthFix>
                      <DependantFormField
                        dependancyName="startDate"
                        onDependancyChange={onCustomDateDependancyChange}
                      />
                      <DependantFormField dependancyName="endDate" onDependancyChange={onCustomDateDependancyChange} />
                      <CustomDateRange startInputName="startDate" endInputName="endDate" />
                    </CustomDateRangeFullWidthFix>
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default GroupAthleteComparisonChartModuleForm;
