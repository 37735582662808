import { createContext } from "react";
import { Leaderboard, LeaderboardBase } from "types";

interface LeaderboardActions {
  fetchAll(): Promise<void>;
  delete(id: string): Promise<void>;
  create(leaderboard: LeaderboardBase): Promise<void>;
  update(id: string, leaderboard: LeaderboardBase): Promise<void>;
}

export type LeaderboardsContextType = [Leaderboard[], LeaderboardActions];

const LeaderboardsContext = createContext<LeaderboardsContextType | undefined>(undefined);

export default LeaderboardsContext;
