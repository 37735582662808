import DateNavigator from "library/DateNavigator";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DateTime } from "types";
import AthleteService from "services/AthleteService";
import useRequest from "hooks/useRequest";
import AthleteProfileActivityFeedWellness from "./AthleteProfileActivityFeedWellness";
import { getMonthDateRangeParams } from "./AthleteProfileActivityFeed.helpers";
import AthleteProfileAllActivity from "./AthleteProfileAllActivity";
import AthleteProfileActivityFeedSkeleton from "./AthleteProfileActivityFeedSkeleton";
import useActivityFeedWellness from "./hooks/useActivityFeedWellness";
import useActivityFeedAllActivity from "./hooks/useActivityFeedAllActivity";
import useActivityFeedWorkouts from "./hooks/useActivityFeedWorkouts";
import AthleteProfileActivityFeedCompleteWorkouts from "./AthleteProfileActivityFeedCompleteWorkouts";
import useActivityDateFinder from "./hooks/useActivityDateFinder";
import NoDataWrapper from "components/NoDataWrapper";
import * as Components from "./AthleteProfileActivityFeed.components";
import { useTranslation } from "react-i18next";
import CanDoAction from "components/CanDoAction";

interface AthleteProfileActivityFeedProps {
  id: string;
}

function AthleteProfileActivityFeed(props: AthleteProfileActivityFeedProps) {
  const { id } = props;
  const { t } = useTranslation();
  const [activity, setActivity] = useState<Await<ReturnType<typeof AthleteService.getActivity>>>({});
  const [date, setDate] = useState<moment.Moment>(moment());
  const getActivity = useRequest(AthleteService.getActivity, true);
  const [activityDates, activityDateActions] = useActivityDateFinder(id, moment(date));

  const handleGetActivity = useCallback(
    async (date: DateTime) => {
      const dateParams = getMonthDateRangeParams(date);
      const result = await getActivity(id, {
        ...dateParams,
      });
      setActivity(result || {});
    },
    [getActivity, id]
  );

  useEffect(() => {
    handleGetActivity(date);

    return () => {
      setActivity({});
    };
  }, [handleGetActivity, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePreviousDayClick = useMemo(() => {
    if (activityDates.previous === null) return undefined;

    const checkbeforeDate = activityDates.previous;

    return () => {
      activityDateActions.findPrevious();
      setDate(moment(checkbeforeDate));
    };
  }, [activityDates.previous, activityDateActions]);

  const handleNextDayClick = useMemo(() => {
    if (activityDates.next === null) return undefined;

    const checkAfterDate = activityDates.next;

    return () => {
      activityDateActions.findNext();
      setDate(moment(checkAfterDate));
    };
  }, [activityDates.next, activityDateActions]);

  const wellness = useActivityFeedWellness({ id, date });
  const completedWorkouts = useActivityFeedWorkouts({ id, date });
  const measurements = useActivityFeedAllActivity({ id, date });

  const hasWellness = Boolean(Object.values(wellness).length);
  const hasCompletedWorkouts = Boolean(completedWorkouts.length);
  const hasMeasurements = Boolean(measurements.length);
  const hasData = Boolean(hasWellness || hasCompletedWorkouts || hasMeasurements);

  function handleCalendarDateChange(selectedDate: DateTime) {
    activityDateActions.findPreviousAndNext(id, moment(selectedDate).toISOString());

    setDate(moment(selectedDate));
  }

  return (
    <Components.Root>
      <DateNavigator
        value={date}
        onChange={handleCalendarDateChange}
        highlightedDays={activity}
        onMonthChange={handleGetActivity}
        onOpen={() => handleGetActivity(date)}
        onPreviousDayClick={handlePreviousDayClick}
        onNextDayClick={handleNextDayClick}
      />
      <Components.ScrollBox>
        <NoDataWrapper
          hasData={hasData}
          fallback={<AthleteProfileActivityFeedSkeleton message={t("AthleteProfileActivityFeed.noDataMessage")} />}
        >
          <AthleteProfileActivityFeedWellness id={id} date={date} wellness={wellness} />
          <CanDoAction action="workout.any">
            <AthleteProfileActivityFeedCompleteWorkouts completedWorkouts={completedWorkouts} />
          </CanDoAction>
          <AthleteProfileAllActivity id={id} date={date} measurements={measurements} />
        </NoDataWrapper>
      </Components.ScrollBox>
    </Components.Root>
  );
}

export default AthleteProfileActivityFeed;
