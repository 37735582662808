import ModuleModalWrapper from "../../ModuleModalWrapper";
import GroupAthleteComparisonChartModuleForm from "./Forms/GroupAthleteComparisonChartModuleForm";

function ComparisonGraphModule(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal, reportSelectedGroups } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add Athlete Comparison Module" close={closeModal}>
      <GroupAthleteComparisonChartModuleForm
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={moduleToEdit}
        groupAthleteIds={reportSelectedGroups[0] ? reportSelectedGroups[0].athleteIds : undefined}
      />
    </ModuleModalWrapper>
  );
}

export default ComparisonGraphModule;
