import { lazy, Suspense } from "react";
import { LoaderDiv } from "components/Loader20/Loader20.components";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";

const AthleteBulkUploadComponent = lazy(() => import("./AthleteBulkUpload"));

function AthleteBulkUpload(props: any) {
  return (
    <LocalisedLoadingProvider>
      <Suspense fallback={<LoaderDiv />}>
        <AthleteBulkUploadComponent {...props} />
      </Suspense>
    </LocalisedLoadingProvider>
  );
}
export default AthleteBulkUpload;
