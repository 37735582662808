import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import * as Components from "../MeasurementsTable.components";
import { useProcessMeasurementTableRowData } from "./MeasurementsTableRow.hooks";
import { Measurement } from "types";
import moment from "moment";
import { useMemo, useState } from "react";
import MeasurementsTableRowMetrics from "./MeasurementsTableRowExpanded";
import RepetitionsTable from "components/Chart/ChartRenderer/TableView/AthleteProfileMeasurementsTableRows/RepetitionsTable";
import TableDeleteMeasurementButton from "components/Chart/ChartRenderer/TableView/AthleteProfileMeasurementsTableRows/TableDeleteMeasurementButton";
import * as MeasurementsTableRowComponents from "./MeasurementsTableRow.components";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface MeasurementsTableRowProps {
  measurement: Measurement;
}

function MeasurementsTableRow(props: MeasurementsTableRowProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { measurement } = props;
  const rowData = useProcessMeasurementTableRowData(props.measurement);

  const repCount: number | undefined = useMemo(
    () => measurement.metrics.find((metric) => metric.field === "repCount")?.value,
    [measurement.metrics]
  );

  return (
    <MeasurementsTableRowComponents.TableCardPaper elevation={0}>
      <Box p={1.8}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Components.PrimaryMetricsGrid item container xs alignItems="center">
            <Components.GridItem label="Time" value={moment(measurement.completedDate).format("HH:mm")} />
            {rowData.defaultMetric.metadata && (
              <Components.GridItem
                label={rowData.defaultMetric.metadata.name}
                value={rowData.defaultMetric.value || "-"}
              />
            )}
            {typeof repCount !== "undefined" && <Components.GridItem label="Reps" value={repCount} />}
            {rowData.weightMetric.metadata && <Components.GridItem label="Weight" value={rowData.weightMetric.value} />}
            {measurement.variant && <Components.GridItem label="Variant" value={measurement.variant[0]} />}
            <Components.GridSpacers />
          </Components.PrimaryMetricsGrid>
          <Grid item>
            <Box>
              <IconButton size="small" aria-label="expand row" onClick={() => setOpen(!open)}>
                {!open ? <KeyboardArrowDownIcon color="primary" /> : <KeyboardArrowUpIcon color="primary" />}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Collapse in={open} unmountOnExit>
          <MeasurementsTableRowMetrics exercise={rowData.metadata} measurement={measurement} />
          {measurement.note && (
            <Box pt={2} pb={1}>
              <Typography variant="h4" fontWeight={500}>
                {t("MeasurementsTableRow.noteSectionTitle")}
              </Typography>
              <Box pb={1} />
              <Typography variant="h4" title={measurement.note}>
                {props.measurement.note}
              </Typography>
            </Box>
          )}
          {Boolean(measurement.repetitions.length && rowData.metadata) && (
            <RepetitionsTable
              repetitionMetadata={rowData.metadata?.repetitions || []}
              repetitions={measurement.repetitions}
            />
          )}
          <TableDeleteMeasurementButton id={measurement.id} />
        </Collapse>
      </Box>
    </MeasurementsTableRowComponents.TableCardPaper>
  );
}

export default MeasurementsTableRow;
