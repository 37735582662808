import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import * as Yup from "yup";

export const errorMessages = {
  leaderboardName: "Add a name for your leaderboard",
  exercise: "Select an exercise",
  metricField: "Select an exercise metric",
  customDate: "Required when using custom date range",
};

function isCustomDateRequired([rangeType]: any, schema: Yup.ObjectSchema<{}, Yup.AnyObject, {}, "">) {
  if (rangeType === RangeType.Other) {
    return schema.required();
  }
  return schema.nullable();
}

export const schema = Yup.object()
  .shape({
    leaderboardName: Yup.string().required(errorMessages.leaderboardName),
    rangeType: Yup.number().required(),
    exerciseId: Yup.object().required(errorMessages.exercise),
    metricField: Yup.object().required(errorMessages.metricField),
    startDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
    endDate: Yup.object().typeError(errorMessages.customDate).when("rangeType", isCustomDateRequired),
  })
  .required();
