import { useMemo } from "react";
import useMultiSelect from "../useMultiSelectIds";
import useSetMultiSelect from "../useSetMultiSelect";

interface Selectable {
  id: string;
}

function useSelectAllHandlers(selectables: Selectable[]) {
  const setSelectedIds = useSetMultiSelect();
  const selectedIds = useMultiSelect();

  return useMemo(() => {
    const unselectedAndVisible = selectables.filter((athlete) => !selectedIds.includes(athlete.id));
    const allVisibleSelected = unselectedAndVisible.length === 0;

    return {
      value: selectables.length !== 0 && allVisibleSelected,
      indeterminate: !allVisibleSelected && selectables.length !== unselectedAndVisible.length,
      toggle: (event: React.ChangeEvent<HTMLInputElement>) => {
        const values: { [athleteId: string]: boolean | undefined } = {};

        selectables.forEach(({ id }) => {
          values[id] = event.target.checked;
        });

        setSelectedIds(values, true);
      },
    };
  }, [selectables, selectedIds, setSelectedIds]);
}

export default useSelectAllHandlers;
