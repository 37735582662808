import Grid from "@mui/material/Grid";
import * as Components from "./TooltipColorStrip.components";

interface TooltipColorStripProps {
  entityColor: string;
  letter?: string | undefined;
}

function TooltipColorStrip(props: TooltipColorStripProps) {
  const { entityColor, letter } = props;

  return (
    <Components.TooltipColorStripContainer
      container
      width={(theme) => theme.spacing(letter ? 2 : 1.5)}
      bgcolor={entityColor}
      color={(theme) => theme.palette.getContrastText(entityColor)}
    >
      {Boolean(letter) && (
        <Grid item fontSize="16px">
          {letter}
        </Grid>
      )}
    </Components.TooltipColorStripContainer>
  );
}

export default TooltipColorStrip;
