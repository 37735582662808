import useGroupsContext from "hooks/useGroupsContext";
import { Athlete } from "types/athlete";
import { useMemo } from "react";
import Grid from "@mui/material/Grid";

function GroupColorBlocks(props: Athlete) {
  const [groups] = useGroupsContext();

  const athleteGroups = useMemo(
    () => groups.filter((group) => props.groupIds.includes(group.id)),
    [groups, props.groupIds]
  );

  return (
    <Grid container height="100%">
      {athleteGroups.map((group) => (
        <Grid
          item
          aria-label={`${group.name} Colour Indicator`}
          key={group.id}
          bgcolor={group.colour}
          maxWidth={8}
          flex={1}
        />
      ))}
    </Grid>
  );
}

export default GroupColorBlocks;
