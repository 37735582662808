import MeasurementsContext from "contexts/MeasurementsContext";
import { MeasurementsContextType } from "contexts/MeasurementsContext/MeasurementsContext";
import { useMemo } from "react";

interface MeasurementsProviderProps {
  children?: React.ReactNode;
  deleteById(id: string): Promise<{ id: string } | undefined>;
}

function MeasurementsProvider(props: MeasurementsProviderProps) {
  const { children, deleteById } = props;

  const contextValue: MeasurementsContextType = useMemo(() => [undefined, { deleteById }], [deleteById]);

  return <MeasurementsContext.Provider value={contextValue}>{children}</MeasurementsContext.Provider>;
}

export default MeasurementsProvider;
