import { Fragment } from "react";
import groupBy from "lodash/groupBy";
import { Exercise, Measurement } from "types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MeasurementsTable from "components/MeasurementsTable";
import useMetadataActions from "providers/MetadataProvider/useMetadataActions";

interface WorkoutAthleteReviewMeasurementsTableProps {
  measurements: Measurement[];
}

function WorkoutAthleteReviewMeasurementsTable(props: WorkoutAthleteReviewMeasurementsTableProps) {
  const { getById: getMetadataById } = useMetadataActions();
  const groupedMeasurements = groupBy(props.measurements, (measurement) => measurement.exerciseId);

  return (
    <Fragment>
      {Object.entries(groupedMeasurements).map(([exerciseId, measurements]) => {
        const exercise = getMetadataById(exerciseId)?.metadata as Exercise | undefined;

        return (
          <Fragment key={exerciseId}>
            <Box pl={1} py={1}>
              <Typography>
                <Box component="span" fontSize={14} color="grey.800" fontWeight="fontWeightNormal">
                  {exercise?.category}
                </Box>
              </Typography>
              <Typography variant="h3">
                <Box component="span" fontWeight={500}>
                  {exercise?.name || exerciseId}
                </Box>
              </Typography>
            </Box>
            <MeasurementsTable measurements={measurements} />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

export default WorkoutAthleteReviewMeasurementsTable;
