import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import useAutocomplete, { AutocompleteGroupedOption, UseAutocompleteProps } from "@mui/material/useAutocomplete";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import Mui5 from "library/mui5";
import Collapse from "@mui/material/Collapse";
import CheckIcon from "@mui/icons-material/Check";
import * as IconPopperMenu from "library/IconPopperMenu";
import { Fragment } from "react";
import { Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";

type TableFiltersPopperContentUIProps<T extends { id: string }> = Pick<
  UseAutocompleteProps<T, true, false, false>,
  "getOptionLabel" | "groupBy" | "options" | "filterOptions"
> & {
  getOptionLabel: Exclude<UseAutocompleteProps<T, true, false, false>["getOptionLabel"], undefined>;
  getColor?(option: T): string | undefined;
  isOptionEqualToValue?(option: T, value: T): boolean;
  SearchInputProps: {
    placeholder: string;
  };
  selectedEntities: T[];
  setSelectedEntities: Dispatch<SetStateAction<T[]>>;
  disableCapitalization?: boolean;
};

function TableFiltersPopperContentUI<T extends { id: string }>(props: TableFiltersPopperContentUIProps<T>) {
  const { selectedEntities, setSelectedEntities, filterOptions } = props;

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: T[]) => {
      setSelectedEntities(value);
    },
    [setSelectedEntities]
  );

  const clearGroups = useCallback(() => setSelectedEntities([]), [setSelectedEntities]);

  const { getOptionLabel, options, groupBy, getColor, SearchInputProps, disableCapitalization } = props;

  const { getRootProps, getClearProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: "item-filters-autocomplete",
      options,
      value: selectedEntities,
      getOptionLabel: getOptionLabel,
      open: true,
      onChange: handleChange,
      multiple: true,
      clearOnEscape: true,
      groupBy,
      isOptionEqualToValue: props.isOptionEqualToValue,
      filterOptions,
    });

  const renderOption = useCallback(
    (option: T, index: number) => {
      const colour = getColor && getColor(option);

      return (
        <IconPopperMenu.ListItem {...getOptionProps({ option, index })} key={option.id} colour={colour}>
          <Grid container width="100%">
            <Grid item xs textTransform={Boolean(disableCapitalization) ? "none" : "capitalize"}>
              {getOptionLabel(option)}
            </Grid>
            <Grid item lineHeight={0}>
              {selectedEntities.some((entity) => entity.id === option.id) ? (
                <CheckIcon fontSize="small" color="primary" />
              ) : undefined}
            </Grid>
          </Grid>
        </IconPopperMenu.ListItem>
      );
    },
    [getColor, getOptionLabel, getOptionProps, selectedEntities, disableCapitalization]
  );

  const renderGroup = useCallback(
    (groupedItems: AutocompleteGroupedOption<T>) => (
      <Fragment key={groupedItems.key}>
        <ListItem sx={{ height: "33px" }}>
          <Grid container width="100%">
            <Grid item xs>
              <Typography variant="h4" fontWeight={500}>
                {groupedItems.group}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </ListItem>
        {groupedItems.options.map((opt, optIndex) => renderOption(opt, optIndex + groupedItems.index))}
      </Fragment>
    ),
    [renderOption]
  );

  const selectedOptionsListComponents = useMemo(() => {
    if (groupBy) {
      return (groupedOptions as Array<AutocompleteGroupedOption<T>>).map(renderGroup);
    }

    return (groupedOptions as T[]).map(renderOption);
  }, [groupBy, groupedOptions, renderGroup, renderOption]);

  const inputProps = getInputProps();
  const clearProps = getClearProps();
  const listboxProps = getListboxProps();

  const itemCount = useMemo(
    () =>
      !groupBy
        ? (groupedOptions as T[]).length
        : (groupedOptions as AutocompleteGroupedOption<T>[]).reduce(
            (acc, cur) => acc + cur.options.length,
            groupedOptions.length
          ),
    [groupBy, groupedOptions]
  );

  return (
    <Grid container {...getRootProps()} flexDirection="column" flexWrap="nowrap" height="100%">
      <Grid item width="100%" height="62px">
        <IconPopperMenu.SearchInput
          inputProps={inputProps}
          placeholder={SearchInputProps.placeholder}
          onClickClear={clearProps.onClick}
        />
        <Divider />
      </Grid>
      <Collapse in={Boolean(selectedEntities.length)}>
        <Grid item width="100%" height="43px">
          <Mui5.Button
            variant="text"
            startIcon={<ClearIcon sx={{ pl: "1.5px" }} />}
            size="large"
            onClick={clearGroups}
            sx={{ justifyContent: "flex-start", "&.MuiButton-sizeLarge": { px: 2.5, py: 1 } }}
          >
            Clear Filters
          </Mui5.Button>
          <Divider />
        </Grid>
      </Collapse>
      <Grid
        item
        width="100%"
        position="relative"
        height={itemCount * 33} // 33 === IconPopperMenuListItem height
        minHeight={43} // 43 === "No results found" height
        maxHeight={320}
      >
        <IconPopperMenu.Listbox {...listboxProps}>
          {itemCount > 0 ? (
            selectedOptionsListComponents
          ) : (
            <Box p={1.25} pl={3} pb={0} color="grey.600">
              No results found
            </Box>
          )}
        </IconPopperMenu.Listbox>
      </Grid>
    </Grid>
  );
}

export default TableFiltersPopperContentUI;
