// import {} from "react";

import { useState, useRef, useMemo, useEffect } from "react";

function useTickerQueue<T>(timeout: number = 5000) {
  const queueState = useState<T[]>([]);

  const queue = queueState[0];
  const setQueue = queueState[1];

  const timeoutRef = useRef<NodeJS.Timeout>();

  const nextItem = useMemo(() => {
    return queue.slice(0).shift();
  }, [queue]);

  useEffect(() => {
    if (typeof nextItem === "undefined") {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setQueue((prevQueue) => prevQueue.slice(1));
    }, timeout);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [nextItem, timeout, setQueue]);

  return queueState;
}

export default useTickerQueue;
