import greenIndicator from "../../assets/images/measurement-green.svg";
import redIndicator from "../../assets/images/measurement-red.svg";
import yellowIndicator from "../../assets/images/measurement-yellow.svg";
import neutralIndicator from "../../assets/images/measurement-neutral.svg";

import lhsIndicatorRed from "./svgs/lhs-red.svg";
import lhsIndicatorGreen from "./svgs/lhs-green.svg";
import lhsIndicatorYellow from "./svgs/lhs-yellow.svg";
import lhsIndicatorNeutral from "./svgs/lhs-neutral.svg";

import rhsIndicatorRed from "./svgs/rhs-red.svg";
import rhsIndicatorGreen from "./svgs/rhs-green.svg";
import rhsIndicatorYellow from "./svgs/rhs-yellow.svg";
import rhsIndicatorNeutral from "./svgs/rhs-neutral.svg";

const multi = {
  Left: {
    red: lhsIndicatorRed,
    green: lhsIndicatorGreen,
    yellow: lhsIndicatorYellow,
    neutral: lhsIndicatorNeutral,
  },
  Right: {
    red: rhsIndicatorRed,
    green: rhsIndicatorGreen,
    yellow: rhsIndicatorYellow,
    neutral: rhsIndicatorNeutral,
  },
};

export default function ragStatusSvgSelector({ progressArrow, variant }) {
  switch (progressArrow) {
    case -1:
      return { src: !variant ? redIndicator : multi[variant].red, alt: "negative red status indicator" };
    case 1:
      return { src: !variant ? greenIndicator : multi[variant].green, alt: "positive green status indicator" };
    case 0:
      return { src: !variant ? yellowIndicator : multi[variant].yellow, alt: "moderate yellow status indicator" };
    default:
      return { src: !variant ? neutralIndicator : multi[variant].neutral, alt: "no data status indicator" };
  }
}
