import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import { ProfilingChartEntityId } from "components/charting/types";
import useDefinedContext from "hooks/useDefinedContext";
import ChartColorRegistryProvider from "pages/Charting/providers/ChartColorRegistryProvider";
import { useMemo } from "react";

interface AthleteProfilingChartEntityColorProviderProps {
  children?: React.ReactNode;
}

function AthleteProfilingChartEntityColorProvider(props: AthleteProfilingChartEntityColorProviderProps) {
  const { value: selectedSets } = useDefinedContext(ChartSetSelectContext);
  const { value: selectedCompareSets } = useDefinedContext(ChartCompareSetSelectContext);

  const entityIds = useMemo(
    () =>
      ([] as string[])
        .concat(ProfilingChartEntityId.PROFILING_LINE, ProfilingChartEntityId.COMPARATIVE_LINE)
        .concat(...selectedSets.map((v) => v.measurementId))
        .concat(...selectedCompareSets.map((v) => v.measurementId)),
    [selectedCompareSets, selectedSets]
  );

  return <ChartColorRegistryProvider entityIds={entityIds}>{props.children}</ChartColorRegistryProvider>;
}

export default AthleteProfilingChartEntityColorProvider;
