import { useEffect, useState } from "react";
import SetComparisonChartService from "services/SetComparisonChartService";
import { firstBy } from "thenby";
import useLoading from "../useLoading";
import { MeasurementSet } from "types";

function useMeasurementSetsApi(props: {
  athleteId: string | null | undefined;
  exerciseId: string | null | undefined;
  enabled?: boolean;
}): MeasurementSet[] {
  const { athleteId, exerciseId, enabled } = props;
  const [availableSets, setAvailableSets] = useState<MeasurementSet[]>([]);
  const [, setLoading] = useLoading();

  useEffect(() => {
    let completePromise = true;

    (async () => {
      if (!(enabled && exerciseId && athleteId)) {
        return;
      }

      try {
        setLoading(true);

        const response = await SetComparisonChartService.getSets(athleteId, { exerciseId });

        if (completePromise) {
          setAvailableSets(response.sort(firstBy("completedDate", "desc")));
        }
      } catch (error) {
        // setInfoMessage(error);
      }

      setLoading(false);
    })();

    return () => {
      completePromise = false;

      setAvailableSets([]);
    };
  }, [athleteId, exerciseId, enabled, setLoading]);

  return availableSets;
}
export default useMeasurementSetsApi;
