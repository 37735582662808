import http from "../../axiosSetup";
import type {
  CreateGroupResponse,
  DeleteGroupResponse,
  GetGroupResponse,
  GetGroupsResponse,
  CreateGroupRequest,
  UpdateGroupResponse,
  UpdateGroupRequest,
} from "./GroupService.types";

class GroupService {
  static async getAll(): Promise<GetGroupsResponse> {
    return http.get("/groups").then((response) => response.data);
  }

  static async fetchById(id: string): Promise<GetGroupResponse> {
    return http.get(`/groups/${id}`).then((response) => response.data);
  }

  static async delete(id: string): Promise<DeleteGroupResponse> {
    return http.delete(`/groups/${id}`).then((response) => response.data);
  }

  static async create(group: CreateGroupRequest): Promise<CreateGroupResponse> {
    return http.post("/groups", group).then((response) => response.data);
  }

  static async update(id: string, group: UpdateGroupRequest): Promise<UpdateGroupResponse> {
    return http.put(`/groups/${id}`, group).then((response) => response.data);
  }
}
export default GroupService;
