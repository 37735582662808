import SpeedDial, { speedDialClasses } from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled, emphasize } from "@mui/material";

export const PrimaryFabSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(3),
  right: theme.spacing(4),
  zIndex: theme.zIndex.tooltip + 1,
  [`.${speedDialClasses.fab}`]: {
    width: "65px",
    height: "65px",
    lineHeight: "8px",
    "&:focus": {
      outline: "none",
    },
  },
}));

export const OptionFabSpeedDialAction = styled(SpeedDialAction)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: emphasize(theme.palette.secondary.main, 0.1),
  },
  "&:focus": {
    outline: "none",
  },
}));
