import { useCallback } from "react";

function useImmutableSetState<T>(setState: React.Dispatch<React.SetStateAction<T>>) {
  return useCallback(
    (updatedOptions: Partial<T> | ((o: T) => Partial<T>)) => {
      const isFunction = typeof updatedOptions === "function";

      if (isFunction) {
        return setState((prev: T) => ({ ...prev, ...updatedOptions(prev) }));
      }

      return setState((prev: T) => ({ ...prev, ...updatedOptions }));
    },
    [setState]
  );
}
export default useImmutableSetState;
