import { alpha, styled } from "@mui/material";
import { StaticDateRangePicker as MuiStaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { calendarPickerStyle } from "../DateTimePickers.style";

const StaticDateRangePicker = styled(MuiStaticDateRangePicker)(({ theme }) => {
  const highlightedRangeColor = alpha(theme.palette.secondary.main, 0.5);

  return {
    ...calendarPickerStyle({ theme }),
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight:not(.MuiDateRangePickerDay-outsideCurrentMonth.MuiDateRangePickerDay-hiddenDayFiller)":
      {
        backgroundColor: highlightedRangeColor,
        ...calendarPickerStyle({ theme }),
      },
  };
}) as typeof MuiStaticDateRangePicker;

export default StaticDateRangePicker;
