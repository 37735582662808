import ExercisesContext from "./ExercisesContext";
import { useEffect, useMemo, useState } from "react";
import { ExercisesContextType } from "./ExercisesProvider.types";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import useInfoMessage from "hooks/useInfoMessage";
import { Exercise } from "types";
import ArchivedCustomExercisesContext from "./ArchivedCustomExercisesContext";
import CustomExercisesActionsContext from "./CustomExercisesActionsContext";
import CustomExerciseService from "services/CustomExerciseService";
import ExerciseHelpers from "helpers/ExerciseHelpers";
import { CustomExercise } from "types/custom-exercise";
import FilteredExercisesContext from "providers/ExercisesProvider/FilteredExercisesContext";
import useCanDo from "hooks/useCanDo";

interface ExercisesProviderProps {
  children?: React.ReactNode;
}

function ExercisesProvider(props: ExercisesProviderProps) {
  const [exercises, setExercises] = useState<ExercisesContextType>([]);
  const customExercisesFeatureEnabled = useCanDo("library.any");

  const onError = useInfoMessage({ type: "error" });

  const customExerciseActions = useMemo(() => {
    const upsertExercise = (upsertedExercise: Exercise) =>
      setExercises((prevExercises) => {
        const filteredExercises = prevExercises.filter((exercise) => exercise.id !== upsertedExercise.id);

        return [...filteredExercises, upsertedExercise];
      });

    const getById: typeof CustomExerciseService.getById = async (id: string) => {
      const exercise = await CustomExerciseService.getById(id);

      upsertExercise(exercise);

      return exercise;
    };

    const create: typeof CustomExerciseService.create = async (newCustomExercise) => {
      const newExercise = await CustomExerciseService.create(newCustomExercise);

      upsertExercise(newExercise);

      return newExercise;
    };

    const update: typeof CustomExerciseService.update = async (id, updatedCustomExercise) => {
      const updatedExercise = await CustomExerciseService.update(id, updatedCustomExercise);

      upsertExercise(updatedExercise);

      return updatedExercise;
    };

    const toggleArchived: typeof CustomExerciseService.toggleArchived = async (id, isArchived) => {
      await CustomExerciseService.toggleArchived(id, isArchived);

      setExercises((prevExercises) =>
        prevExercises.map((exercise) => {
          if (exercise.id === id) return { ...exercise, isEnabled: !isArchived };

          return exercise;
        })
      );

      return { id };
    };

    return { getById, create, update, toggleArchived };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const exercises = await ExerciseMetadataService.get();

        setExercises(exercises);
      } catch (error) {
        onError(error);
      }
    })();
  }, [onError]);

  const { activeExercises, archivedExercises } = useMemo(() => {
    const active: Exercise[] = [];
    const archived: CustomExercise[] = [];

    ExerciseHelpers.sort(exercises).forEach((exercise) => {
      if (["Speed & Agility"].includes(exercise.category)) return;

      if (
        exercise.type === "Custom" &&
        /**
         * @densk1
         * Treat all custom exercises as archived when
         * custom exercises account feature is disabled */
        (exercise.isEnabled === false || customExercisesFeatureEnabled === false)
      ) {
        archived.push({ ...exercise, isEnabled: false });
      } else {
        active.push(exercise);
      }
    });

    return { activeExercises: active, archivedExercises: archived };
  }, [customExercisesFeatureEnabled, exercises]);

  return (
    <CustomExercisesActionsContext.Provider value={customExerciseActions}>
      <ExercisesContext.Provider value={activeExercises}>
        <ArchivedCustomExercisesContext.Provider value={archivedExercises}>
          <FilteredExercisesContext.Provider value={activeExercises}>
            {props.children}
          </FilteredExercisesContext.Provider>
        </ArchivedCustomExercisesContext.Provider>
      </ExercisesContext.Provider>
    </CustomExercisesActionsContext.Provider>
  );
}

export default ExercisesProvider;
