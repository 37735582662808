import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useMemo } from "react";

/**
 * @param {{ direction: "asc" | "desc" } & import("@mui/material").SvgIconProps} props
 */

function SortIcon({ direction, ...props }) {
  const Icon = useMemo(
    () =>
      ({
        asc: ArrowUpwardIcon,
        desc: ArrowDownwardIcon,
      })[direction],
    [direction]
  );
  return <Icon {...props} />;
}
export default SortIcon;
