import Box from "@mui/material/Box";
import { CSSObject, Theme, styled } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Badge, { badgeClasses } from "@mui/material/Badge";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import MuiTurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import { Link } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import noAvatar from "../../assets/images/no-avatar.svg";

const buttonActiveColor = "rgba(255, 255, 255, 0.15)";

export const LogoContainerStyledBox = styled(Box)(({ theme }) => ({
  height: theme.spacing(3.5),
}));

export const ListItemLink = styled(ListItem)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(1.2, 0, 1.2, 2),
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: buttonActiveColor,
  },
}));

export const MenuLabel = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const DrawerOpenIconButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0.5),
  "&:hover": {
    backgroundColor: buttonActiveColor,
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: "transparent",
    },
  },
}));

export const NewIndicatorBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: theme.palette.secondary.main,
    top: "0%",
    right: "0%",
  },
  [`& .${badgeClasses.dot}`]: {
    height: 8,
    minWidth: 8,
  },
}));

export const Logo = styled("img")<{ show?: boolean }>(({ theme, show }) => ({
  position: "absolute",
  height: theme.spacing(3.5),
  ...(show
    ? {
        transition: theme.transitions.create("opacity", {
          easing: theme.transitions.easing.sharp,
          delay: theme.transitions.duration.enteringScreen,
        }),
        opacity: 1,
      }
    : {
        transition: theme.transitions.create("opacity", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        opacity: 0,
      }),
}));

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(10),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(10) + 1,
  },
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  [`& .${drawerClasses.paper}.MuiPaper-root`]: {
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden",
    color: theme.palette.common.white,
  },
  ...(open
    ? {
        ...openedMixin(theme),
        [`& .${drawerClasses.paper}`]: openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
        [`& .${drawerClasses.paper}`]: closedMixin(theme),
      }),
}));

export const TurnedInIcon = styled(MuiTurnedInNotIcon)(({ theme }) => ({
  marginTop: theme.spacing(0.25),
  marginLeft: theme.spacing(1.825),
  paddingRight: theme.spacing(0),
  color: theme.palette.common.white,
  fontSize: 20,
}));

export const StyledLinkAvatar = styled(Link, { shouldForwardProp: (key) => key !== "photoUrl" })<{
  photoUrl?: string | null;
}>(({ theme, photoUrl }) => ({
  height: theme.spacing(5),
  width: theme.spacing(5),
  display: "block",
  borderRadius: "50%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundImage: `url(${photoUrl || noAvatar})`,
  marginLeft: theme.spacing(0.5),
}));

interface LinkAvatarProps extends LinkProps {
  photoUrl?: string | null;
}

export const LinkAvatar = (props: LinkAvatarProps) => {
  const { photoUrl, ...rest } = props;
  return <StyledLinkAvatar className="ph-no-capture" photoUrl={photoUrl} {...rest} />;
};

export const Content = styled("main")({
  backgroundColor: "#FAFBFE",
  height: "100vh",
  flex: 1,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
});
