import { styled } from "@mui/material";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ToggleButtonGroup = styled(MuiToggleButtonGroup, { shouldForwardProp: (key: string) => key !== "square" })<{
  square?: boolean;
}>(({ square, theme, color }) => ({
  "& .MuiToggleButton-root": {
    borderRadius: square ? 0 : undefined,
    borderWidth: "1px",
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  "& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected": {
    borderLeft: `1px solid ${color && color !== "standard" && theme.palette[color].main}`,
  },
}));
export default ToggleButtonGroup;
