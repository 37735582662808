import {
  ExerciseMetric,
  ExerciseMetricDisplayMetadata,
  ExerciseMetricDisplayMetadataSystem,
  ExerciseRepetition,
  OutputExercise,
  Variants,
} from "./output-exercise";
import { CustomExercise } from "./custom-exercise";

export { Variants };

export type {
  ExerciseMetric,
  ExerciseRepetition,
  OutputExercise,
  ExerciseMetricDisplayMetadataSystem,
  ExerciseMetricDisplayMetadata,
};

export enum ExerciseType {
  Output = "Output",
  Custom = "Custom",
}

export type Exercise = OutputExercise | CustomExercise;
