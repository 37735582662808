import { alpha, styled } from "@mui/material";
import Box from "@mui/material/Box";

export const ArchivedAthleteAlertContainer = styled(Box)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: alpha(theme.palette.common.white, 0.7),
  zIndex: theme.zIndex.drawer - 1,
}));
