import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import "./index.scss";

/**
 * @type {React.Context<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>}
 */
export const LoadingContext = React.createContext([false, () => null]);
export const LocalisedLoadingContext = LoadingContext;

export function useLoadingContext() {
  return React.useContext(LoadingContext);
}

function LoadingProvider(props) {
  const loadingState = React.useState(false);

  const [loading] = loadingState;

  const loadingComponent = (
    <Box height="100%" position="absolute" top={0} right={0} bottom={0} left={0}>
      <div className="loader small" />
    </Box>
  );

  return (
    <LoadingContext.Provider value={loadingState}>
      {props.children}
      {loading && loadingComponent}
    </LoadingContext.Provider>
  );
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default LoadingProvider;
