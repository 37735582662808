import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.65002"
        y="5.70007"
        width="4.20001"
        height="12.6"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M4.65 7.80014H2.5C1.94772 7.80014 1.5 8.24786 1.5 8.80014V15.2002C1.5 15.7524 1.94772 16.2002 2.5 16.2002H4.65"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M19.35 16.2L21.5 16.2C22.0523 16.2 22.5 15.7523 22.5 15.2L22.5 8.8C22.5 8.24771 22.0523 7.8 21.5 7.8L19.35 7.8"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <rect
        x="15.15"
        y="5.70007"
        width="4.20001"
        height="12.6"
        rx="1"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <rect x="9.48755" y="11.775" width="5.25" height="0.75" fill="#828990" stroke="currentColor" strokeWidth="0.75" />
    </svg>
  ),
};

function ExercisesIcon(props: SvgIconProps) {
  return <SvgIcon {...props}>{size.default}</SvgIcon>;
}
export default ExercisesIcon;
