import { createContext } from "react";
import { ReportTemplate, ReportTemplateBase } from "types";

export interface ReportsContextActions {
  generateAthleteReport(template: ReportTemplate): Promise<void>;
  generateGroupReport(template: ReportTemplate): Promise<void>;
  getById(id: string): Promise<ReportTemplate>;
  getAll(): Promise<ReportTemplate[]>;
  create(templateBase: ReportTemplateBase): Promise<ReportTemplate>;
  delete(id: string): Promise<void>;
}
export type ReportsContextType = [ReportTemplate[], ReportsContextActions];

const ReportsContext = createContext<undefined | ReportsContextType>(undefined);

export default ReportsContext;
