import { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AthleteProfileChartAdvancedOptions from "./AthleteProfileChartAdvancedOptions";
import { dataVisualizationOptions } from "components/charting/config/chartTypes";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import useSetDataVisualizationType from "components/charting/hooks/useSetDataVisualizationType";
import AthleteProfileChartControls from "./AthleteProfileChartControls";
import AthleteProfileDataVisualizationSelector from "../AthleteProfileDataVisualizationSelector";
import useChartQueryParams from "components/charting/hooks/useChartQueryParams";
import Tooltip from "components/Tooltip";
import * as Components from "./AthleteProfileChart.components";

function AthleteProfileChart() {
  const dataVisualizationType = useDataVisualizationType();
  const setDataVisualizationType = useSetDataVisualizationType();

  useChartQueryParams();

  const buttons = useMemo(
    () =>
      // TEMP: (TO-DO) OS-4243 Clear Remote Config Values post-release
      dataVisualizationOptions.map(({ type, icon, description }) => (
        <Tooltip key={type} title={description} arrow placement="right">
          <Button
            aria-label={`select ${type} visualization`}
            onClick={() => setDataVisualizationType(type)}
            sx={(theme) => ({ fontSize: 32, padding: theme.spacing(1, 0) })}
            variant={dataVisualizationType === type ? "contained" : undefined}
          >
            {icon}
          </Button>
        </Tooltip>
      )),
    [dataVisualizationType, setDataVisualizationType]
  );

  return (
    <Box height="100%" display="flex">
      <Components.SideMenuStyledBox
        display="flex"
        justifyContent="stretch"
        flexDirection="column"
        p={1}
        pt={1.5}
        pb={1.5}
        position="relative"
      >
        <Grid container alignItems="center" justifyContent="space-between" flexDirection="column" flex={1}>
          <Grid item>
            <ButtonGroup
              size="small"
              orientation="vertical"
              disableElevation
              color="primary"
              sx={(theme) => ({
                "& .MuiButton-contained": {
                  border: `1px solid ${theme.palette.primary.main}`,
                  "& + .MuiButton-outlined": {
                    borderTop: `1px solid ${theme.palette.primary.main}`,
                  },
                },
              })}
            >
              {buttons}
            </ButtonGroup>
          </Grid>
          <Grid item>
            <AthleteProfileChartAdvancedOptions />
          </Grid>
        </Grid>
      </Components.SideMenuStyledBox>
      <Components.BorderLeftBox display="flex" flexDirection="column" flex={1}>
        <Components.BorderBottomBox
          pl={1}
          pr={1}
          pb={0.5}
          sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[400]}` })}
        >
          <AthleteProfileChartControls />
        </Components.BorderBottomBox>
        <AthleteProfileDataVisualizationSelector />
      </Components.BorderLeftBox>
    </Box>
  );
}

export default AthleteProfileChart;
