import { styled } from "@mui/material";
import FormText from "library/Form/FormText";

export const LoginFormText = styled(FormText)({
  "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
    color: "#FFF",
  },
  "& .MuiInput-root": {
    color: "#FFF",
    "&.MuiInput-underline": {
      "&:before": {
        borderWidth: 1,
        // TODO: not sure why this is not defaulting to this value
        borderColor: "#FFF",
      },
      "&:hover:not(.Mui-disabled):before": {
        borderWidth: 2,
        borderColor: "#FFF",
      },
    },
  },
});
