import {
  MultiFactorError,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
} from "firebase/auth";
import UserService from "services/UserService";

class MfaService {
  readonly auth = UserService.getAuth();

  private resolver?: MultiFactorResolver;
  private phoneAuthProvider?: PhoneAuthProvider;
  private recaptchaVerifier?: RecaptchaVerifier;

  public hint?: MultiFactorInfo;
  public verificationId?: string;

  constructor(private recaptchaElementId: string) {}

  init = async (error: MultiFactorError) => {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new RecaptchaVerifier(this.auth, this.recaptchaElementId, { size: "invisible" });
    }

    if (!this.phoneAuthProvider) {
      this.phoneAuthProvider = new PhoneAuthProvider(this.auth);
    }

    this.resolver = getMultiFactorResolver(this.auth, error);

    await this.requestVerificationCode();
  };

  requestVerificationCode = async () => {
    const phoneInfoOptions = {
      multiFactorHint: this.resolver!.hints.find(({ factorId }) => factorId === PhoneMultiFactorGenerator.FACTOR_ID),
      session: this.resolver!.session,
    };

    this.hint = phoneInfoOptions.multiFactorHint;

    this.verificationId = await this.phoneAuthProvider!.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier!);
  };

  verifyCode = async (code: string) => {
    const phoneAuthProviderCredential = PhoneAuthProvider.credential(this.verificationId!, code);

    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthProviderCredential);

    const userCredential = await this.resolver!.resolveSignIn(multiFactorAssertion);

    return userCredential.user;
  };
}
export default MfaService;
