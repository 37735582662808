import Badge, { badgeClasses } from "@mui/material/Badge";
import { styled } from "@mui/material";

const ActiveIndicator = styled(Badge)(({ theme }) => ({
  [`.${badgeClasses.badge}`]: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    marginTop: 3,
    marginLeft: 3,
    "&::after": {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "1.2s infinite ease-in-out ripple",
      border: `1px solid ${theme.palette.secondary.main}`,
      content: '""',
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  },
}));

export default ActiveIndicator;
