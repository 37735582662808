export const ACTIONS = {
  SET_STATUS_PENDING: "SET_STATUS_PENDING",
  CLEAR_STATUS: "CLEAR_STATUS",
  ADD_MEASUREMENT: "ADD_MEASUREMENT",
};

export const STATUS = {
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
};

export const initialState = {
  status: {},
  measurements: [],
};

export function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_STATUS_PENDING:
      return {
        ...state,
        status: { ...state.status, [payload.measurementId]: STATUS.PENDING },
      };
    case ACTIONS.CLEAR_STATUS:
      return {
        ...state,
        status: { ...state.status, [payload.measurementId]: undefined },
      };
    case ACTIONS.ADD_MEASUREMENT:
      return {
        ...state,
        status: { ...state.status, [payload.id]: STATUS.RESOLVED },
        measurements: [...state.measurements, payload],
      };
    default:
      return state;
  }
}
