import { styled } from "@mui/material";

export const Root = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const ScrollBox = styled("div")(({ theme }) => ({
  flex: 1,
  overflowY: "scroll",
  padding: theme.spacing(0, 1),
}));
