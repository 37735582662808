import { Component } from "react";
import moment from "moment";
import PageContent from "shared/components/PageContent";
import Constants from "../../../../shared/utils/Constants";
import expandButton from "../../../../assets/images/Expand Button.svg";
import ModuleModal from "./ModuleModal/ModuleModal";
import { isEmailValid } from "../../../../shared/utils/utils";
import ModuleItemMenu from "../ModuleItemMenu/ModuleItemMenu";
import Confirmation from "../../../../shared/components/Confirmation/Confirmation";
import Loader from "../../../../shared/components/Loader/Loader";
import Button from "../../../../shared/components/Button/Button";
import "./AthleteReport.scss";
import errorIcon from "../../../../assets/images/error-icon.svg";
import warningIcon from "../../../../assets/images/warning-icon.svg";
import closeIcon from "../../../../assets/images/close-icon.svg";
import whiteCloseIcon from "../../../../assets/images/plus-icon.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import AthleteStoreContext from "providers/AthletesProvider/contexts/AthleteStoreContext";
import ReportMetadataHOC from "../ReportMetadataHOC";
import RAGSettingsContext from "contexts/RAGSettingsContext";
import ExerciseMetadataService from "services/ExerciseMetadataService";
import { RangeType, ReportModuleType } from "../ModuleModal.types";
import FormHelperText from "@mui/material/FormHelperText";
import ReportsContext from "contexts/ReportsContext";
import withNavigate from "utils/withNavigate";
import Mui5 from "library/mui5";
import { firstBy } from "thenby";
import * as PropTypes from "prop-types";
import AthleteHelpers from "helpers/AthleteHelpers";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import ExercisesContext from "providers/ExercisesProvider/ExercisesContext";

class CreateAthleteReport extends Component {
  static contextType = ReportsContext;

  state = {
    // added after defactoring redux
    loadingReport: false,
    generatingReport: false,
    reportGenerationError: null,
    // Pre-existing
    reportNotes: "",
    reportName: "",
    autocompleteEmailValue: "",
    reportAthletes: [],
    reportEmails: [],
    isSaveAsChecked: false,
    isOpenModal: false,
    athleteModuleReport: {
      rangeType: 0,
      resultType: -1,
      startDate: new Date(moment().startOf("day").subtract(1, "month").toISOString()),
      endDate: new Date(moment().endOf("day").toISOString()),
      customStartDate: null,
      customEndDate: null,
      exercise: null,
      exerciseId: null,
      metricObject: null,
      metricField: null,
      category: null,
      moduleType: null,
    },
    kpiReport: {
      moduleType: 0,
      rangeType: -1,
      resultType: -1,
      exercises: [],
    },
    setComparisonModule: {
      moduleType: 4,
      category: null,
      exercise: null,
      exerciseId: null,
      repetitionField: null,
      repetitionFieldObject: null,
      setComparisonFilters: [],
      availableSets: [],
      needsEdits: false,
    },
    metricOptions: [],
    exerciseOptions: [],
    repetitionFieldsOptions: [],
    isMetricDropDownDisabled: true,
    isExerciseDropDownDisabled: true,
    modalErrors: {
      hasCategoryErrors: false,
      hasExerciseErrors: false,
      hasMetricErrors: false,
      hasTypeErrors: false,
      hasExerciseSetsErrors: false,
      hasKPIErrors: false,
    },
    templateFormErrors: {
      hasAthletesErrors: false,
      hasModulesErrors: false,
      hasNameErrors: false,
      hasNotesErrors: false,
      hasSetComparisonErrors: false,
    },
    moduleTemplates: [],
    isConfirmationOpen: false,
    moduleToDelete: null,
    moduleToEdit: null,
    moduleKPIToEdit: null,
    isEditAndUse: false,
    selectedModuleType: null,
  };

  /** @returns {import("contexts/ReportsContext").ReportsContextActions} */
  get reportActions() {
    if (typeof this.context === "undefined") {
      throw new Error("ReportsContext.Provider is not defined");
    }

    return this.context[1];
  }

  modalFormHandlers = {
    submitModule_V2: (values) => {
      if (this.state.moduleToEdit === null) {
        // Create Monitoring Module
        this.setState((prevState) => ({
          ...prevState,
          moduleToEdit: null,
          moduleTemplates: [...prevState.moduleTemplates, { ...values }],
        }));
      } else {
        // Edit Monitoring Module
        this.setState((prevState) => {
          const moduleIndex = this.state.moduleTemplates.findIndex((module) => module === this.state.moduleToEdit);
          const moduleTemplatesCopy = this.state.moduleTemplates.slice();
          const editableModule = moduleTemplatesCopy[moduleIndex] || {};
          moduleTemplatesCopy[moduleIndex] = { ...editableModule, ...values };

          return {
            ...prevState,
            moduleToEdit: null,
            moduleTemplates: [...moduleTemplatesCopy],
          };
        });
      }
      this.handleCloseModal();
    },
    onCloseModalHandler: () => {
      this.setState({
        athleteModuleReport: {
          rangeType: 0,
          startDate: new Date(moment().subtract(24, "hours").format()),
          endDate: new Date(moment().endOf("day").toISOString()),
          customStartDate: null,
          customEndDate: null,
          exercise: null,
          exerciseId: null,
          category: null,
          metricField: null,
          metricObject: null,
          moduleType: null,
        },
        setComparisonModule: {
          moduleType: 4,
          category: null,
          exercise: null,
          exerciseId: null,
          repetitionField: null,
          repetitionFieldObject: null,
          setComparisonFilters: [],
          availableSets: [],
        },
        metricOptions: [],
        repetitionFieldsOptions: [],
        exerciseOptions: [],
        isMetricDropDownDisabled: true,
        isExerciseDropDownDisabled: true,
        moduleToEdit: null,
        modalErrors: {
          hasCategoryErrors: false,
          hasExerciseErrors: false,
          hasMetricErrors: false,
          hasTypeErrors: false,
          hasExerciseSetsErrors: false,
        },
        selectedModuleType: null,
      });
    },
  };

  handleReportNotesChange = ({ target }) => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasNotesErrors: false },
      reportNotes: target.value,
    }));
  };
  reportNameChange = ({ target }) => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasNameErrors: false },
      reportName: target.value,
    }));
  };

  handleAthleteSelector = (_, newValue) => {
    this.setState({ reportAthletes: newValue });
  };

  handleEmailAutocompleteChange = ({ target }) => {
    this.setState({ autocompleteEmailValue: target.value });
  };

  addReportEmail = ({ target }) => {
    if (target.value.length) {
      let emailList = this.state.autocompleteEmailValue.split(",");
      let validEmails = [];
      emailList.forEach((email) => {
        if (isEmailValid(email.trim())) {
          validEmails.push(email.trim());
        }
      });
      this.setState((prevState) => ({
        reportEmails: [...prevState.reportEmails, ...validEmails],
        autocompleteEmailValue: "",
      }));
    }
  };

  handleRemoveEmailClick = (email) => {
    this.setState({ reportEmails: this.state.reportEmails.filter((selectedEmail) => selectedEmail !== email) });
  };

  // Module modal close/open

  changeStatus = () => {
    this.setState({ isSaveAsChecked: !this.state.isSaveAsChecked });
  };
  handleOpenModal = () => {
    this.setState((prevState) => ({
      templateFormErrors: { ...prevState.templateFormErrors, hasModulesErrors: false },
      isOpenModal: true,
    }));
  };
  handleCloseModal = () => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => this.modalFormHandlers.onCloseModalHandler()
    );
  };

  // Delete Module Confirmation
  handleOpenDeleteConfirmation = (modulePanel) => {
    this.setState({
      isConfirmationOpen: true,
      moduleToDelete: modulePanel,
    });
  };

  editModule = (item) => {
    this.setState({
      athleteModuleReport: item,
      moduleToEdit: item,
    });
    this.handleOpenModal();
  };

  saveReport = () => {
    const modulesToSend = this.state.moduleTemplates;

    const templateFormErrors = {};

    if (this.state.reportAthletes.length < 1) {
      templateFormErrors["hasAthletesErrors"] = true;
    }
    if (this.state.moduleTemplates.length < 1) {
      templateFormErrors["hasModulesErrors"] = true;
    }
    if (this.state.isSaveAsChecked && (this.state.reportName.length < 1 || this.state.reportName.length > 100)) {
      templateFormErrors["hasNameErrors"] = true;
    }
    if (this.state.reportNotes.length > 1000) {
      templateFormErrors["hasNotesErrors"] = true;
    }

    const setComparisonModules = modulesToSend.filter(
      (module) => module.moduleType === ReportModuleType.SET_COMPARISON
    );
    if (this.state.reportAthletes.length !== 1 && setComparisonModules.length) {
      templateFormErrors["hasSetComparisonErrors"] = true;
    } else if (
      setComparisonModules.length &&
      setComparisonModules.find((m) => !m?.setComparisonFilters || m?.setComparisonFilters?.length === 0)
    ) {
      templateFormErrors["hasSetComparisonErrors"] = true;
    }

    if (Object.values(templateFormErrors).find((x) => x)) {
      return this.setState((prevState) => ({
        templateFormErrors: { ...templateFormErrors },
      }));
    }

    const reportData = {
      reportTemplate: {
        name: this.state.reportName,
        notes: this.state.reportNotes,
        modules: modulesToSend,
        reportType: 0,
      },
      athleteIds: this.state.reportAthletes.map((athlete) => athlete.id),
      emailAddresses: this.state.reportEmails,
    };

    (async () => {
      this.setState({ reportGenerationError: null, generatingReport: true });
      const actions = this.reportActions;
      try {
        if (this.state.isSaveAsChecked) await actions.create(reportData.reportTemplate);
        await actions.generateAthleteReport(reportData);
        this.setState({ generatingReport: false }, () => {
          this.props.navigate(Constants.routes.reportGeneration.route);
        });
      } catch (error) {
        this.setState({ reportGenerationError: error, generatingReport: false });
      }
    })();
  };

  async getByTemplateId() {
    this.setState({ loadingReport: true });
    try {
      const template = await this.reportActions.getById(this.props.match.params.id);

      const modulesWithModifiedDates = template.modules
        /**
         * @densk1
         * This code filters out references to Archived Custom Exercises
         */
        .map((module) => ({
          ...module,
          exerciseId: this.props.archivedCustomExerciseIds.includes(module.exerciseId) ? null : module.exerciseId,
          exercises: module.exercises.filter(
            ({ exerciseId }) => !this.props.archivedCustomExerciseIds.includes(exerciseId)
          ),
        }))
        .map((module) => {
          const range = ["day", "week", "month", "year"][module.rangeType];

          if (range) {
            const endDate = moment().endOf("day");
            const startDate = range === "day" ? endDate.clone().startOf("day") : endDate.clone().subtract(1, range);

            return {
              ...module,
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString(),
            };
          }

          return module;
        });

      this.setState({
        reportNotes: template.notes,
        reportName: template.name,
        moduleTemplates: modulesWithModifiedDates,
        isEditAndUse: true,
      });
    } catch (e) {}
    this.setState({ loadingReport: false });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getByTemplateId();
    }
  }

  confirmModuleDeletionHandler = () => {
    const allModulesUpdated = this.state.moduleTemplates;
    allModulesUpdated.splice(allModulesUpdated.indexOf(this.state.moduleToDelete), 1);

    this.setState({ moduleTemplates: allModulesUpdated });
    this.handleCloseDeleteConfirmation();
  };

  handleCloseDeleteConfirmation = () => {
    this.setState({ isConfirmationOpen: false });
  };

  renderDateRange(startDate, endDate, rangeType) {
    if (rangeType !== RangeType.Other) {
      return ["Today", "Past Week", "Past Month", "Past Year"][rangeType];
    }

    const startString = new Date(startDate).toDateString();
    const endString = new Date(endDate).toDateString();

    return startDate && endDate ? `${startString} - ${endString}` : null;
  }

  getModuleCardWrapperProps = (item) => {
    if (this.state.reportAthletes.length !== 1 && item.moduleType === 4) {
      return {
        className: "module-card-wrapper",
      };
    }
    return {
      className: "module-card-wrapper module-card-wrapper-clickable",
      onClick: () => this.editModule(item),
    };
  };

  render() {
    const {
      isSaveAsChecked,
      isEditAndUse,
      isOpenModal,
      moduleTemplates,
      exerciseOptions,
      metricOptions,
      repetitionFieldsOptions,
      setComparisonModule,
      reportNotes,
      reportName,
      moduleToEdit,
      templateFormErrors,
      modalErrors,
      isExerciseDropDownDisabled,
      isMetricDropDownDisabled,
      athleteModuleReport,
      reportAthletes,
      reportEmails,
      selectedModuleType,
    } = this.state;

    return (
      <div className="oh-athlete-report">
        {this.state.loadingReport && <Loader />}
        <PageContent
          pageHeadingLink={isEditAndUse ? Constants.routes.reportGeneration.route : Constants.routes.createReport.route}
          pageHeading={isEditAndUse ? "Create athlete report from template" : "Create an Athlete Report"}
        >
          <Grid container justifyContent="center">
            <Grid item md={10} lg={8} xl={5}>
              <Mui5.Paper>
                <Box p={3}>
                  <div className="oh-add-athlete-report-form">
                    <div className="oh-add-athlete-report-data">
                      <div>
                        <Box pb={2}>
                          <AthleteStoreContext.Consumer>
                            {(athletes) => (
                              <Mui5.Autocomplete
                                options={athletes
                                  .filter((athlete) => !athlete.isArchived)
                                  .sort(firstBy("firstName").thenBy("lastName").thenBy("id"))}
                                value={reportAthletes}
                                onChange={this.handleAthleteSelector}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                multiple
                                disableCloseOnSelect
                                renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                      <Mui5.Chip
                                        key={option.id}
                                        label={`${option.firstName} ${option.lastName}`}
                                        customColor={option.colour}
                                        {...tagProps}
                                      />
                                    );
                                  })
                                }
                                renderOption={(props, option) => (
                                  <AutocompleteOptionColourable {...props} key={option.id}>
                                    {AthleteHelpers.getFullName(option)}
                                  </AutocompleteOptionColourable>
                                )}
                                renderInput={(params) => (
                                  <Mui5.TextField
                                    {...params}
                                    InputLabelProps={{
                                      ...params.InputLabelProps,
                                      shrink: true,
                                      focused: false,
                                    }}
                                    className="oh-athlete-report-athlete-selector"
                                    color="secondary"
                                    margin="normal"
                                    placeholder="Start typing athlete name"
                                    label="Add Athletes to Report"
                                    error={this.state.templateFormErrors.hasAthletesErrors}
                                    helperText={
                                      this.state.templateFormErrors.hasAthletesErrors
                                        ? "At least 1 athlete should be chosen"
                                        : undefined
                                    }
                                  />
                                )}
                              />
                            )}
                          </AthleteStoreContext.Consumer>
                        </Box>
                        {(moduleTemplates || []).map((item, index) => (
                          <div key={index} {...this.getModuleCardWrapperProps(item)}>
                            <div
                              className={`module-card ${
                                reportAthletes.length !== 1 && item.moduleType === 4 && "is-disabled"
                              }`}
                            >
                              <div className={`module-image icon-${item.moduleType}`} />
                              <div className="module-text">
                                {item.moduleType === 0 ? (
                                  <Box pr={2}>
                                    <span>
                                      Athlete KPI{" "}
                                      <Box component="span" fontSize="subtitle2.fontSize" fontWeight="fontWeightLight">
                                        ({{ 0: "Latest", 1: "Average", 2: "Best" }[`${item?.kpiType}`]} Scores)
                                      </Box>
                                    </span>
                                    <Typography variant="subtitle2" color="textPrimary">
                                      <Box pt={0.5} pb={1}>
                                        <Grid container spacing={1}>
                                          <ExercisesContext.Consumer>
                                            {(exercises) => {
                                              return item?.exercises?.map(({ exerciseId, metricField }, index) => {
                                                const exercise = exercises.find(
                                                  (exercise) => exercise.id === exerciseId
                                                );
                                                const metric = ExerciseMetadataService.metric(exercise, metricField);

                                                const label = `${exercise?.category} / ${exercise?.name} / ${metric?.name}`;

                                                return (
                                                  <Grid key={index} item title={label}>
                                                    <Mui5.Chip label={label} style={{ maxWidth: "100%" }} />
                                                  </Grid>
                                                );
                                              });
                                            }}
                                          </ExercisesContext.Consumer>
                                        </Grid>
                                      </Box>
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary">
                                      <Box component="span" fontWeight="fontWeightLight" fontSize={12}>
                                        {this.renderDateRange(item.startDate, item.endDate, item.rangeType)}
                                      </Box>
                                    </Typography>
                                  </Box>
                                ) : item.moduleType === 3 ? (
                                  <Box>
                                    <span>Athlete Monitoring Indicators</span>
                                    <Typography variant="subtitle2" color="textPrimary">
                                      <Box component="span" fontWeight="fontWeightLight">
                                        <RAGSettingsContext.Consumer>
                                          {([templates]) => {
                                            const name = templates.find(
                                              ({ id }) => id === item.ragStatusTemplateId
                                            )?.name;

                                            return name || "Default Template";
                                          }}
                                        </RAGSettingsContext.Consumer>
                                      </Box>
                                    </Typography>
                                  </Box>
                                ) : item.moduleType === 4 ? (
                                  <Box>
                                    <span>
                                      Set Comparison Chart{" "}
                                      <Box component="span" fontSize="subtitle2.fontSize" fontWeight="fontWeightLight">
                                        ({`${item?.setComparisonFilters?.length || 0} sets selected`})
                                      </Box>
                                    </span>
                                    <Typography variant="subtitle2" color="textPrimary">
                                      <Box component="span" fontWeight="fontWeightLight">
                                        <ExercisesContext.Consumer>
                                          {(exercises) => {
                                            const exercise = exercises.find(
                                              (exercise) => exercise.id === item.exerciseId
                                            );
                                            const repMetric = exercise.repetitions.find(
                                              (repMetric) => repMetric.field === item.repetitionField
                                            );

                                            return (
                                              <Typography variant="subtitle2" color="textPrimary">
                                                <Box component="span" fontWeight="fontWeightLight">
                                                  {exercise?.category} / {exercise?.name}{" "}
                                                  {Boolean(repMetric?.name) && `/ ${repMetric?.name}`}
                                                </Box>
                                              </Typography>
                                            );
                                          }}
                                        </ExercisesContext.Consumer>
                                      </Box>
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box>
                                    <Box>
                                      <span>{item.moduleType === 2 ? "Measurements" : "Line Chart"}</span>
                                      <ExercisesContext.Consumer>
                                        {(exercises) => {
                                          const exercise = exercises.find(
                                            (exercise) => exercise.id === item.exerciseId
                                          );
                                          const metric = ExerciseMetadataService.metric(exercise, item.metricField);

                                          return (
                                            <Typography variant="subtitle2" color="textPrimary">
                                              <Box component="span" fontWeight="fontWeightLight">
                                                {exercise?.category} / {exercise?.name}{" "}
                                                {Boolean(metric?.name) && `/ ${metric?.name}`}
                                              </Box>
                                            </Typography>
                                          );
                                        }}
                                      </ExercisesContext.Consumer>
                                      <Typography variant="subtitle2" color="textSecondary">
                                        <Box component="span" fontWeight="fontWeightLight" fontSize={12}>
                                          {this.renderDateRange(item.startDate, item.endDate, item.rangeType)}
                                        </Box>
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </div>
                              <div className="action-buttons">
                                <ModuleItemMenu
                                  deleteHandler={() => this.handleOpenDeleteConfirmation(item)}
                                  moduleTemplates={item}
                                  editModule={this.editModule}
                                />
                              </div>
                            </div>
                            <div className="module-notices">
                              {reportAthletes.length === 1 &&
                              item.moduleType === 4 &&
                              !item?.setComparisonFilters?.length ? (
                                <>
                                  <img src={warningIcon} alt="error" />
                                  <p>Edit module to select comparable sets</p>
                                </>
                              ) : reportAthletes.length !== 1 && item.moduleType === 4 ? (
                                <>
                                  <img src={errorIcon} alt="error" />
                                  <p>This module is only available for single athlete reports</p>
                                </>
                              ) : null}
                            </div>
                          </div>
                        ))}
                        <Mui5.Paper
                          style={{
                            width: "100%",
                            borderRadius: "16px",
                            boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.12)",
                          }}
                          component={ButtonBase}
                          onClick={this.handleOpenModal}
                        >
                          <Box display="flex" height={96} alignItems="center">
                            <img className="plus-icon" src={expandButton} alt="add-logo" />
                            <Typography component="span" variant="h3">
                              Add a new module
                            </Typography>
                          </Box>
                        </Mui5.Paper>
                        {templateFormErrors.hasModulesErrors ? (
                          <p className="oh-form-error">At least 1 module should be chosen</p>
                        ) : null}
                        {isOpenModal && (
                          <ModuleModal
                            {...this.props}
                            handleCloseModal={this.handleCloseModal}
                            metricOptions={metricOptions}
                            repetitionFieldsOptions={repetitionFieldsOptions}
                            setComparisonModule={setComparisonModule}
                            exerciseOptions={exerciseOptions}
                            athleteModuleReport={athleteModuleReport}
                            {...this.modalFormHandlers}
                            isMetricDropDownDisabled={isMetricDropDownDisabled}
                            isExerciseDropDownDisabled={isExerciseDropDownDisabled}
                            isEditingModule={moduleToEdit !== null}
                            moduleToEdit={moduleToEdit}
                            selectedModuleType={selectedModuleType}
                            reportAthletes={reportAthletes}
                            athletesCount={reportAthletes.length}
                            errors={modalErrors}
                          />
                        )}
                        <Box p={1} />
                        <Mui5.TextField
                          InputLabelProps={{
                            shrink: true,
                            focused: false,
                          }}
                          label={
                            <>
                              Report notes
                              <Box component="span" fontWeight="fontWeightLight">
                                &nbsp;(Optional)
                              </Box>
                            </>
                          }
                          name="report-notes"
                          id="report-notes"
                          className="mui-text-field"
                          color="secondary"
                          margin="normal"
                          fullWidth
                          value={reportNotes}
                          onChange={this.handleReportNotesChange}
                          error={this.state.templateFormErrors.hasNotesErrors}
                          helperText={
                            templateFormErrors.hasNotesErrors ? "Note max length is 1000 characters" : undefined
                          }
                        />
                        <Mui5.TextField
                          InputLabelProps={{
                            shrink: true,
                            focused: false,
                          }}
                          label={
                            <>
                              Forward Report to Emails
                              <Box component="span" fontWeight="fontWeightLight">
                                &nbsp;(Optional)
                              </Box>
                            </>
                          }
                          className="mui-text-field"
                          name="report-emails"
                          id="report-emails"
                          placeholder="Enter email addresses separated by a comma..."
                          color="secondary"
                          margin="normal"
                          fullWidth
                          onChange={this.handleEmailAutocompleteChange}
                          value={this.state.autocompleteEmailValue}
                          onBlur={this.addReportEmail}
                        />
                        <div className="report-email-list">
                          {reportEmails.map((email, index) => (
                            <div className="oh-value-wrapper" key={`selected-${index}`}>
                              <span className="oh-value">{email}</span>
                              <button type="button" onClick={(e) => this.handleRemoveEmailClick(email)}>
                                <img src={whiteCloseIcon} alt="close" />
                              </button>
                            </div>
                          ))}
                        </div>
                        <Mui5.TextField
                          InputLabelProps={{
                            shrink: true,
                            focused: false,
                          }}
                          label={
                            <>
                              Report Template Name
                              <Box component="span" fontWeight="fontWeightLight">
                                &nbsp;(Optional)
                              </Box>
                            </>
                          }
                          placeholder="Add a template name..."
                          name="template-name"
                          id="template-name"
                          onChange={this.reportNameChange}
                          value={reportName}
                          color="secondary"
                          margin="normal"
                          className="mui-text-field"
                          fullWidth
                          error={this.state.templateFormErrors.hasNameErrors}
                          helperText={
                            templateFormErrors.hasNameErrors
                              ? "Template name length must be between 1 and 100 characters"
                              : undefined
                          }
                        />
                        <div className="input-wrapper">
                          <label className="custom-checkbox">
                            <input type="checkbox" onChange={this.changeStatus} />
                            <span className="checkmark" />
                            Save this Report as a template
                          </label>
                        </div>
                        <Button handleClick={this.saveReport} classNames="add-report btn-cyan">
                          {isSaveAsChecked ? "Save template and Generate report" : "Generate Report"}
                        </Button>
                        <Box pl={3}>
                          {this.state.templateFormErrors.hasAthletesErrors && (
                            <FormHelperText error>- You have not selected any Athletes </FormHelperText>
                          )}
                          {this.state.templateFormErrors.hasSetComparisonErrors && (
                            <FormHelperText error>- A Set Comparison module needs editing</FormHelperText>
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                </Box>
              </Mui5.Paper>
            </Grid>
            <Confirmation
              confirmationMessage={`Are you sure you want to delete this Module? `}
              positiveHandler={this.confirmModuleDeletionHandler}
              negativeHandler={this.handleCloseDeleteConfirmation}
              buttonText="Delete Module"
              hasOwnButton={false}
              isConfirmationOpen={this.state.isConfirmationOpen}
            />
          </Grid>
          <div
            className={
              "oh-report-gen-overlay" +
              (this.state.generatingReport || this.state.reportGenerationError ? " is-active" : "")
            }
          >
            <div className="oh-report-gen-popup">
              {this.state.reportGenerationError && (
                <button
                  type="button"
                  className="oh-report-gen-close"
                  onClick={() => {
                    this.setState({ reportGenerationError: null });
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </button>
              )}
              <div className="oh-rep-gen-progress">
                {this.state.reportGenerationError ? (
                  <>
                    <img className="error-icon" src={errorIcon} alt="error" />
                    <h1 className="title has-error">
                      An error occurred while generating the report. Please check each of your modules configuration.
                    </h1>
                  </>
                ) : (
                  <>
                    <div className="oh-rep-gen-loader" />
                    <h1 className="title">Report Generation in progress...</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </PageContent>
      </div>
    );
  }
}

CreateAthleteReport.propTypes = {
  archivedCustomExerciseIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ReportMetadataHOC(withNavigate(CreateAthleteReport));
