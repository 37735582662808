import { useMemo } from "react";
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import FormAutocomplete from "library/Form/FormAutocomplete";
import RadioGroup from "library/Form/RadioGroup";
import * as Helpers from "../RAGSettings.helpers";
import NumberFormat from "react-number-format";
import greenRAG from "assets/images/measurement-green.svg";
import yellowRAG from "assets/images/measurement-yellow.svg";
import redRAG from "assets/images/measurement-red.svg";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import FormCheckbox from "library/Form/Checkbox";
import { validationSchema } from "./RAGSettingsForm.schema";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";
import { firstBy } from "thenby";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import Mui5 from "library/mui5";
import { CombinedMeasurement } from "./RAGSettingsForm.types";
import FormText from "library/Form/FormText";
import Alert from "@mui/material/Alert";
import useExercises from "providers/ExercisesProvider/useExercises";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";

interface RAGSettingsFormProps {
  initialValues?: RAGSettings;
  onSubmit(values: any): Promise<any>;
  onCancel(): void;
}

const defaultInitialValues = {
  name: "",
  thresholdType: "Percentage",
  greenThreshold: "10",
  amberThreshold: "20",
  default: false,
  items: [null, null, null, null, null, null] as unknown as undefined[], // Keep inputs controlled but appease Typescript
};

const measurementGroupBy = (option: { category: string }) => option.category;
const measurementGetLabel = (option: CombinedMeasurement) => option.name || "";

function RAGSettingsForm(props: RAGSettingsFormProps) {
  const exercises = useExercises();
  const wellnesses = useWellnessMetadata();

  const combinedMetadata = useMemo(
    () =>
      [...wellnesses.map((wellness) => ({ ...wellness, category: "Wellness" })), ...exercises].sort(
        firstBy("category").thenBy("name")
      ),
    [exercises, wellnesses]
  );

  const initialValues = useMemo(() => {
    if (typeof props.initialValues === "undefined") {
      return defaultInitialValues;
    }

    return {
      ...props.initialValues,
      thresholdType: props.initialValues.thresholdType,
      items: props.initialValues.items
        .map((item) => combinedMetadata.find((metaItem) => item.metadataId === metaItem.id))
        .concat(defaultInitialValues.items.slice(0, 6 - props.initialValues.items.length)),
    };
  }, [combinedMetadata, props.initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: typeof initialValues) => props.onSubmit(validationSchema.cast(values))}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting, errors, handleBlur, touched, setFieldValue }) => (
        <Form>
          <Grid container spacing="24px">
            <Grid item xs>
              <FormText name="name" label="Template Name" variant="standard" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                label="Calculate Threshold By"
                color="primary"
                options={[
                  { label: "Percentage (%)", value: "Percentage" },
                  { label: "Standard Deviation (σ)", value: "StandardDeviation" },
                ]}
                name="thresholdType"
              />
              <Alert variant="filled" icon={false} severity="warning">
                <Typography variant="body2">
                  Scores are calculated by comparing the latest measurement with the last 10
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                id="greenThresholdInput"
                customInput={Mui5.TextField}
                suffix={Helpers.getThresholdSuffix(values.thresholdType)}
                name="greenThreshold"
                value={values.greenThreshold}
                allowNegative={false}
                onValueChange={Helpers.handleThresholdValueChange("greenThreshold", {
                  setFieldValue,
                  values,
                })}
                fullWidth
                error={!!(touched.greenThreshold && errors.greenThreshold)}
                helperText={touched.greenThreshold && errors.greenThreshold}
                label="Athlete Is within"
                onBlur={handleBlur}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={greenRAG} alt="green" className="rag-indicator" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                id="amberThresholdInput"
                customInput={Mui5.TextField}
                suffix={Helpers.getThresholdSuffix(values.thresholdType)}
                name="amberThreshold"
                value={values.amberThreshold}
                allowNegative={false}
                onValueChange={Helpers.handleThresholdValueChange("amberThreshold", {
                  setFieldValue,
                  values,
                })}
                fullWidth
                error={!!(touched.amberThreshold && errors.amberThreshold)}
                helperText={touched.amberThreshold && errors.amberThreshold}
                label="Athlete Is within"
                onBlur={handleBlur}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={yellowRAG} alt="yellow" className="rag-indicator" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography component="div" variant="caption" color="textSecondary">
                <Box color="grey.500" lineHeight={1.5}>
                  Athlete is below
                </Box>
              </Typography>
              <Box color="#4D4D4D" fontSize={14} pt={1.6} fontWeight="fontWeightLight">
                <Box pr={1} display="inline-block">
                  <img src={redRAG} alt="red" className="rag-indicator" style={{ verticalAlign: "text-bottom" }} />
                </Box>
                {Boolean(values.amberThreshold)
                  ? values.amberThreshold + Helpers.getThresholdSuffix(values.thresholdType)
                  : " - "}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[0]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={Boolean(values.items[1])}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[1]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={!Boolean(values.items[0])}
                disableClearable={Boolean(values.items[2])}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[2]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={!Boolean(values.items[1])}
                disableClearable={Boolean(values.items[3])}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[3]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={!Boolean(values.items[2])}
                disableClearable={Boolean(values.items[4])}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[4]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={!Boolean(values.items[3])}
                disableClearable={Boolean(values.items[5])}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                name="items[5]"
                options={combinedMetadata}
                groupBy={measurementGroupBy}
                getOptionLabel={measurementGetLabel}
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params: any) => (
                  <Mui5.TextField
                    {...params}
                    label="Measurement"
                    placeholder="Select an Exercise or Wellness Measurement"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={!Boolean(values.items[4])}
              />
            </Grid>
            <Grid item container xs={12} spacing="24px" alignItems="center">
              <Grid item xs={4} md={3}>
                <Mui5.Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  aria-label="Save Changes"
                  fullWidth
                  disableElevation
                  disabled={isSubmitting}
                >
                  Save
                </Mui5.Button>
              </Grid>
              <Grid item xs={4} md={3}>
                <Mui5.Button
                  variant="outlined"
                  color="primary"
                  aria-label="Cancel"
                  fullWidth
                  disableElevation
                  onClick={props.onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Mui5.Button>
              </Grid>
              <Grid item>
                <FormCheckbox label="Set as default template" name="default" />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default RAGSettingsForm;
