import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import TickerIdContext from "./TickerIdContext";

interface TickerProviderProps {
  children?: React.ReactNode;
}

function TickerProvider(props: TickerProviderProps) {
  const tickerId = useMemo(() => `ticker-${uuid()}`, []);

  return <TickerIdContext.Provider value={tickerId}>{props.children}</TickerIdContext.Provider>;
}

export default TickerProvider;
