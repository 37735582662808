import { styled } from "@mui/material";

export const ChartControlsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export const ChartControlsInputs = styled("div")((props) => ({
  padding: props.theme.spacing(2, 1),
  margin: props.theme.spacing(1),
  overflowY: "auto",
  overflowX: "hidden",
  flex: 1,
}));
