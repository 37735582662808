import { Theme } from "@mui/material";

export const sxTimePeriodLabelTypography = (theme: Theme) => ({
  pl: theme.typography.htmlFontSize === 18 ? 1.5 : 1.8,
  py: 0,
});

export const sxVariantToggleBox = (theme: Theme) => ({
  marginTop: 0.5,
});
