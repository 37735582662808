import useRequest from "hooks/useRequest";
import { useEffect } from "react";
import useWorkoutsStore from "../providers/WorkoutsProvider/useWorkoutsStore";
import useWorkoutsActions from "../providers/WorkoutsProvider/useWorkoutsActions";

function useGetWorkoutById(id: string) {
  const workouts = useWorkoutsStore();
  const actions = useWorkoutsActions();
  const getById = useRequest(actions.getById);

  useEffect(() => {
    (async () => await getById(id))();
  }, [id, getById]);

  return workouts.find((workout) => workout.id === id);
}
export default useGetWorkoutById;
