import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import useCanDo from "hooks/useCanDo";
import CoachesProvider from "providers/CoachesProvider";
import { Route, Routes } from "react-router-dom";
import SettingsAccountArchive from "./SettingsAccountArchive";
import SettingsAccountEdit from "./SettingsAccountEdit";
import SettingsAccountSubscription from "./SettingsAccountSubscription";
import SettingsCoachCreate from "./SettingsCoachCreate";
import SettingsCoachEdit from "./SettingsCoachEdit";
import SettingsCoachManagement from "./SettingsCoachManagement";
import SettingsMenu from "./SettingsMenu";
import SettingsPreferencesEdit from "./SettingsPreferencesEdit";
import RAGSettingsV2 from "./SettingsPreferencesEdit/RAGSettingsV2";

function Settings() {
  const canDo = useCanDo();

  return (
    <LocalisedLoadingProvider>
      <CoachesProvider>
        <SettingsMenu>
          <Routes>
            <Route path="/" element={<SettingsAccountEdit />} />
            {canDo("athlete.archive") && <Route path="/athlete-archive" element={<SettingsAccountArchive />} />}
            {canDo("subscription.any") && <Route path="/subscription" element={<SettingsAccountSubscription />} />}
            {canDo("group.any") && canDo("coach.any") && (
              <>
                <Route path="/coaches" element={<SettingsCoachManagement />} />
                <Route path="/coaches/create" element={<SettingsCoachCreate />} />
                <Route path="/coaches/:coachId/edit" element={<SettingsCoachEdit />} />
              </>
            )}
            <Route path="preferences" element={<SettingsPreferencesEdit />} />
            <Route path="athlete-monitoring" element={<RAGSettingsV2 />} />
          </Routes>
        </SettingsMenu>
      </CoachesProvider>
    </LocalisedLoadingProvider>
  );
}

export default Settings;
