import { useMemo, useState } from "react";
import { OpenAddWidgetDialogState } from "./OpenAddWidgetDialogProvider.types";
import OpenAddWidgetDialogStateContext from "./contexts/OpenAddWidgetDialogStateContext";
import OpenAddWidgetDialogHandlersContext from "./contexts/OpenAddWidgetDialogHandlersContext";

interface OpenAddWidgetDialogProviderProps {
  children?: React.ReactNode;
}

function OpenAddWidgetDialogProvider(props: OpenAddWidgetDialogProviderProps) {
  const [openAddWidgetDialog, setOpenAddWidgetDialog] = useState<OpenAddWidgetDialogState>(false);

  const actions = useMemo(
    () => ({ onOpen: () => setOpenAddWidgetDialog(true), onClose: () => setOpenAddWidgetDialog(false) }),
    []
  );

  return (
    <OpenAddWidgetDialogHandlersContext.Provider value={actions}>
      <OpenAddWidgetDialogStateContext.Provider value={openAddWidgetDialog}>
        {props.children}
      </OpenAddWidgetDialogStateContext.Provider>
    </OpenAddWidgetDialogHandlersContext.Provider>
  );
}

export default OpenAddWidgetDialogProvider;
