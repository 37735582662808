import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import { useCallback, useMemo } from "react";
import { MetadataType } from "types/metadata";
import { ExerciseMetric } from "types";

function useChartMetrics() {
  const setSelections = useSetChartSelections();

  const setMeasureMetricAtIndex = useCallback(
    (measureIndex: number) => (event: unknown, measureMetric: ExerciseMetric) => {
      setSelections((prev) => ({
        measures: prev.measures.map((prevMeasure, prevMeasureIndex) => {
          if (measureIndex !== prevMeasureIndex) {
            return prevMeasure;
          }

          if (prevMeasure.type !== MetadataType.EXERCISE) {
            throw new Error("You can only selet a metric on an Exercise");
          }

          return { ...prevMeasure, metric: measureMetric };
        }),
      }));
    },
    [setSelections]
  );

  return useMemo(
    () => ({
      onChangeAtIndex: setMeasureMetricAtIndex,
      getOptionLabel: (option: ExerciseMetric) => option.name,
    }),
    [setMeasureMetricAtIndex]
  );
}
export default useChartMetrics;
