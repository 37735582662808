import { useEffect, useReducer } from "react";
import MeasurementService from "../../services/MeasurementService";
import { reducer, initialState, ACTIONS, STATUS } from "./reducer";
import useInfoMessage from "../useInfoMessage";
import { useLoadingContext } from "components/LocalisedLoadingProvider";

/**
 * @param {{ measurementIds: string[] }} param0
 * @returns {{ measurements: import("types").Measurement[] }}
 */
function useGetMeasurementsById({ measurementIds }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setInfoMessage = useInfoMessage({ type: "error" });
  const [chartLoading, setChartLoading] = useLoadingContext();

  useEffect(() => () => setChartLoading(false), [setChartLoading]);

  useEffect(() => {
    measurementIds.forEach((measurementId) => {
      if (state.status[measurementId]) return;

      setChartLoading(true);
      dispatch({ type: ACTIONS.SET_STATUS_PENDING, payload: { measurementId } });

      (async () => {
        try {
          const result = await MeasurementService.getById(measurementId);

          dispatch({ type: ACTIONS.ADD_MEASUREMENT, payload: result });
        } catch (error) {
          setInfoMessage(error);
          dispatch({ type: ACTIONS.CLEAR_STATUS, payload: { measurementId } });
        }
      })();
    });
  }, [chartLoading, state.status, setChartLoading, measurementIds, setInfoMessage]);

  useEffect(() => {
    const requestsPending = Object.values(state.status).includes(STATUS.PENDING);
    setChartLoading(requestsPending);
  }, [chartLoading, state.status, setChartLoading]);

  return state;
}
export default useGetMeasurementsById;
