import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33331 2H4.53331C4.21505 2 3.90983 2.12643 3.68478 2.35147C3.45974 2.57652 3.33331 2.88174 3.33331 3.2V12.8C3.33331 13.1183 3.45974 13.4235 3.68478 13.6485C3.90983 13.8736 4.21505 14 4.53331 14H11.7333C12.0516 14 12.3568 13.8736 12.5818 13.6485C12.8069 13.4235 12.9333 13.1183 12.9333 12.8V5.6L9.33331 2Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33331 2V5.6H12.9333"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3H6.8C6.32261 3 5.86477 3.18964 5.52721 3.52721C5.18964 3.86477 5 4.32261 5 4.8V19.2C5 19.6774 5.18964 20.1352 5.52721 20.4728C5.86477 20.8104 6.32261 21 6.8 21H17.6C18.0774 21 18.5352 20.8104 18.8728 20.4728C19.2104 20.1352 19.4 19.6774 19.4 19.2V8.4L14 3Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 3V8.4H19.4" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6667 4H9.06669C8.43017 4 7.81972 4.25286 7.36963 4.70294C6.91954 5.15303 6.66669 5.76348 6.66669 6.4V25.6C6.66669 26.2365 6.91954 26.847 7.36963 27.2971C7.81972 27.7471 8.43017 28 9.06669 28H23.4667C24.1032 28 24.7137 27.7471 25.1637 27.2971C25.6138 26.847 25.8667 26.2365 25.8667 25.6V11.2L18.6667 4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6667 4V11.2H25.8667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

function ReportsIcon(props: SvgIconProps) {
  const item = props.fontSize === "large" || props.fontSize === "small" ? size[props.fontSize] : size.default;

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default ReportsIcon;
