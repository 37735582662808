import { MeasureData } from "components/charting/types/MeasureData.types";

export enum ChartDataApiReducerActions {
  SET_STATUS_PENDING = "SET_STATUS_PENDING",
  CLEAR_STATUS = "CLEAR_STATUS",
  ADD_MEASURES = "ADD_MEASURES",
  DELETE_MEASURE = "DELETE_MEASURE",
  RESET = "RESET",
}
export enum ChartDataApiRequestStatus {
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
}
export type ChartDataApiReducerAction =
  | {
      type: ChartDataApiReducerActions.SET_STATUS_PENDING;
      requestId: string;
    }
  | {
      type: ChartDataApiReducerActions.CLEAR_STATUS;
      requestId: string;
    }
  | {
      type: ChartDataApiReducerActions.ADD_MEASURES;
      requestId: string;
      payload: MeasureData;
    }
  | {
      type: ChartDataApiReducerActions.DELETE_MEASURE;
      payload: { id: string };
    }
  | {
      type: ChartDataApiReducerActions.RESET;
    };

export interface ChartDataApiReducerState {
  status: { [requestId: string]: ChartDataApiRequestStatus | undefined };
  measureSets: MeasureData[];
}
