import http from "../../axiosSetup";
import FileService from "../FileService";

class ExportService {
  /**
   * @param {any} options
   */
  static exportAthletes({
    athleteIds,
    startDate,
    endDate,
    splitExercises,
    exerciseIds,
    includeHeight,
    includeWeight,
    includeWellness,
  }) {
    return http
      .post(
        "/export/athletes",
        { athleteIds, startDate, endDate, splitExercises, exerciseIds, includeHeight, includeWeight, includeWellness },
        { responseType: "arraybuffer", responseEncoding: "binary" }
      )
      .then(FileService.downloadResponseAsZip);
  }
}
export default ExportService;
