import { max, mean, min, std } from "mathjs";
import { LineData } from "components/charting/types/Chart.types";
import { useMemo } from "react";

function useChartLineCommonPropertiesCalculator(lineData: LineData[]): LineData[] {
  return useMemo(() => {
    return lineData.map((data): LineData => {
      const values = data.line.map((point) => point.y);
      return {
        ...data,
        common: {
          average: mean(values),
          standardDeviation: std(values) as unknown as number,
          min: min(values),
          max: max(values),
        },
      };
    });
  }, [lineData]);
}

export default useChartLineCommonPropertiesCalculator;
