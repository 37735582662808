import { Theme } from "@mui/material";
import { WorkoutBase, WorkoutTaskType } from "../Workouts.types";

export const defaultInitialValues: WorkoutBase = {
  name: "Workout 1",
  description: "",
  duration: null,
  isPublished: false,
  groupIds: [],
  athleteIds: [],
  labels: [],
  steps: [
    // {
    //   type: WorkoutTaskType.MEASUREMENT,
    //   note: "",
    //   sets: 3,
    //   exercises: [
    //     {
    //       id: "BARBELL_BENCH_PRESS",
    //       reps: 5,
    //       restPeriod: null,
    //       weights: [],
    //     },
    //   ],
    //   id: "STEP_ID_0",
    // },
    // {
    //   type: WorkoutTaskType.MEASUREMENT,
    //   note: "",
    //   sets: null,
    //   exercises: [
    //     {
    //       id: "BILATERAL_CMJ",
    //       reps: null,
    //       restPeriod: null,
    //       weights: [],
    //     },
    //   ],
    //   id: "STEP_ID_1",
    // },
    // {
    //   type: WorkoutTaskType.MEASUREMENT,
    //   note: "",
    //   sets: null,
    //   exercises: [
    //     {
    //       id: "KNEE_TO_WALL",
    //       reps: null,
    //       restPeriod: null,
    //       weights: [],
    //     },
    //   ],
    //   id: "STEP_ID_2",
    // },
  ],
};

export const getWorkoutTaskColor = (type: WorkoutTaskType, theme: Theme) =>
  ({
    [WorkoutTaskType.NOTE]: "#F1C40F",
    [WorkoutTaskType.CUSTOM_EXERCISE]: theme.palette.primary.main,
    [WorkoutTaskType.EXERCISE]: theme.palette.primary.main,
    [WorkoutTaskType.VIDEO]: "#6665DD",
  })[type];
