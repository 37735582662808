import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useLoadingContext } from "hooks";
import * as Skeletons from "./AthleteProfileActivityFeedSkeleton.components";
import Box from "@mui/material/Box";

interface AthleteProfileActivityFeedSkeletonProps {
  message?: string;
}

function AthleteProfileActivityFeedSkeleton(props: AthleteProfileActivityFeedSkeletonProps) {
  const [loading] = useLoadingContext();

  const labelComponent = <Skeletons.Label />;
  const activityCardComponent = <Skeletons.ActivityCard />;
  const workoutCardComponent = <Skeletons.WorkoutCard />;
  const wellnessComponent = <Skeletons.WellnessCircle />;

  return (
    <Skeletons.NoScrollBox height="100%" position="relative">
      {labelComponent}
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        {wellnessComponent}
        {wellnessComponent}
        {wellnessComponent}
        {wellnessComponent}
      </Grid>
      {labelComponent}
      {workoutCardComponent}
      {workoutCardComponent}
      {labelComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {activityCardComponent}
      {props.message && !loading && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          top={0}
          bottom={0}
          left={0}
          right={0}
        >
          <Typography variant="h4" align="center">
            {props.message}
          </Typography>
        </Box>
      )}
    </Skeletons.NoScrollBox>
  );
}

export default AthleteProfileActivityFeedSkeleton;
