import SetSelector from "library/Inputs/SetSelector";

import useDefinedContext from "hooks/useDefinedContext";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";
import { MeasurementSet } from "types";
import { useCallback } from "react";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import { DataVisualizationType } from "components/charting/config/chartTypes";
import { useTranslation } from "react-i18next";

export interface AthleteProfileChartSetSelectorProps {}

function AthleteProfileChartSetSelector(props: AthleteProfileChartSetSelectorProps) {
  const { t } = useTranslation();
  const { value, options, onChange } = useDefinedContext(ChartSetSelectContext);
  const { value: selectedCompareSets } = useDefinedContext(ChartCompareSetSelectContext);
  const visType = useDataVisualizationType();
  const getOptionDisabled = useCallback(
    (option: MeasurementSet) => selectedCompareSets.includes(option),
    [selectedCompareSets]
  );

  const SetSelectorTextFieldProps =
    visType === DataVisualizationType.PROFILING_CHART
      ? { label: t("AthleteProfileChartSetSelector.setSelectLabel") }
      : undefined;

  return (
    <SetSelector
      value={value}
      options={options}
      onChange={onChange}
      AutoCompleteProps={{ getOptionDisabled }}
      TextFieldProps={SetSelectorTextFieldProps}
    />
  );
}

export default AthleteProfileChartSetSelector;
