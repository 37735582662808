import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { Athlete } from "types/athlete";
import useAllAthletes from "hooks/useAllAthletes";
import Collapse from "@mui/material/Collapse";
import { memo, useMemo } from "react";
import * as Components from "./DuplicateAthleteAlert.components";

interface DuplicateAthleteAlertProps {
  submitText: string;
}

const getFullName = (person: { firstName: string; lastName: string }) =>
  [person.firstName.trim(), person.lastName.trim()].filter(Boolean).join(" ");

function DuplicateAthleteAlert(props: DuplicateAthleteAlertProps) {
  const athletes = useAllAthletes();
  const { values } = useFormikContext<Athlete>();
  const newAthleteName = getFullName(values);
  const existingAthlete = useMemo(
    () => athletes.find((athlete) => newAthleteName === getFullName(athlete)),
    [athletes, newAthleteName]
  );
  const matchingAthleteIds = values.id === existingAthlete?.id;
  const showAlert = Boolean(!matchingAthleteIds && newAthleteName.length > 2 && existingAthlete);

  const dob = existingAthlete?.dateOfBirth && (
    <Box component="span" fontWeight="fontWeightBold">
      {" "}
      (Date of Birth: {new Date(existingAthlete?.dateOfBirth).toLocaleDateString()})
    </Box>
  );

  return (
    <Collapse in={showAlert} unmountOnExit>
      <Components.StyledAlert severity="info" variant="filled">
        An {existingAthlete?.isArchived && "archived "}athlete with the name{" '"}
        <Box component="span" fontWeight="fontWeightBold">
          {newAthleteName}
        </Box>
        {"' "}
        already exists in your account{dob}. You can still click{" "}
        <Box component="span" fontWeight="fontWeightBold">
          {props.submitText}
        </Box>{" "}
        below.
      </Components.StyledAlert>
    </Collapse>
  );
}

export default memo(DuplicateAthleteAlert);
