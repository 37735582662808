import Box from "@mui/material/Box";
import useCanDo from "hooks/useCanDo";
import useExercise from "hooks/useExercise";
import keyBy from "lodash/keyBy";
import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";
import { useMemo } from "react";
import { ExerciseType } from "types";

interface WorkoutStepPreviewExerciseTitleProps {
  exerciseId: string | undefined;
}

function WorkoutStepPreviewExerciseTitle(props: WorkoutStepPreviewExerciseTitleProps) {
  const exercise = useExercise(props.exerciseId);
  const archivedExercises = useArchivedCustomExercises();
  const customExercisesEnabled = useCanDo()("library.any");
  const archivedExercisesIdMap = useMemo(() => keyBy(archivedExercises, "id"), [archivedExercises]);
  const archivedExercise = archivedExercisesIdMap[`${props.exerciseId}`];
  const isArchived = Boolean(archivedExercise);

  const exerciseObject = exercise || archivedExercise;

  const isDisabledOrArchived =
    exerciseObject?.type === ExerciseType.Custom && ((customExercisesEnabled && isArchived) || !customExercisesEnabled);

  if (exerciseObject) {
    return (
      <Box
        component="span"
        fontStyle={isDisabledOrArchived ? "italic" : undefined}
        sx={isDisabledOrArchived ? { textDecoration: "line-through" } : undefined}
      >
        {exerciseObject.category}
        {" - "}
        {exerciseObject.name}
      </Box>
    );
  }
  return (
    <Box component="span" color="grey.800">
      {props.exerciseId}
    </Box>
  );
}

export default WorkoutStepPreviewExerciseTitle;
