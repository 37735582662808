import { styled, alpha } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

export const MuiRadioButtonsFormControl = styled(FormControl)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const MuiRadioButtonsFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  color: alpha("#828282", 1),
}));
