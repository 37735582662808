import * as Yup from "yup";
import i18n from "config/i18n";

export const errorMessages = {
  name: i18n.t("Group.All.formNameError"),
  colour: i18n.t("Group.All.formColourError"),
};

export const groupSchema = Yup.object().shape({
  name: Yup.string().min(1).required(errorMessages.name),
  colour: Yup.string().min(7).required(errorMessages.colour),
  athleteIds: Yup.array()
    .min(0)
    .transform((athletes) => athletes.map((athlete) => athlete.id)),
});

export default groupSchema;
