import { useMemo } from "react";
import MeasureHelpers from "../helpers/MeasureHelpers";
import useChartSelections from "../../../components/charting/hooks/useChartSelections";

function useValidChartMeasures() {
  const selections = useChartSelections();

  return useMemo(() => MeasureHelpers.getValidMeasures(selections.measures), [selections.measures]);
}
export default useValidChartMeasures;
