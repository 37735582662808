import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useMemo, useState } from "react";
import useChartPersistAdvancedOptions from "components/charting/hooks/useChartPersistAdvancedOptions";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import ChartingAdvancedOptionsButtons from "components/charting/ChartingAdvancedOptionsButtons";
import * as Components from "./AdvancedOptions.components";
import { useTranslation } from "react-i18next";

interface AdvancedOptionsProps {}

function AdvancedOptions(props: AdvancedOptionsProps) {
  const [open, setOpen] = useState(LocalStorageHelper.get(LocalStorageHelper.keys.advancedOptionsOpen, false));
  const { t } = useTranslation();

  const toggleOpen = useCallback(() => setOpen((prev: boolean) => !prev), []);

  const icon = useMemo(() => (!open ? <AddIcon /> : <RemoveIcon />), [open]);

  useChartPersistAdvancedOptions({ advancedOptionsOpen: open });

  return (
    <div>
      <Components.AdvancedOptionsButtonBase onClick={toggleOpen} aria-label="toggle-chart-advanced-options">
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography component="span" variant="h3">
              {t("Charting.advancedOptionsHeader")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton component="div" color="primary" size="small">
              {icon}
            </IconButton>
          </Grid>
        </Grid>
      </Components.AdvancedOptionsButtonBase>
      <Collapse in={open} unmountOnExit>
        <div>
          <ChartingAdvancedOptionsButtons />
        </div>
      </Collapse>
    </div>
  );
}

export default AdvancedOptions;
