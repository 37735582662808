import { useMemo, useRef } from "react";
import { ChartColorRegistryStoreContextType } from "../../contexts/ChartColorRegistryStoreContext";
import ChartColorRegistryStoreContext from "../../contexts/ChartColorRegistryStoreContext";
import style from "config/style";

interface ChartColorRegistryProviderProps {
  children: React.ReactNode;
  entityIds: string[];
}

type ColorRegistry = ChartColorRegistryStoreContextType;

function ChartColorRegistryProvider(props: ChartColorRegistryProviderProps) {
  const registry = useRef<ColorRegistry>({});

  const registeredEntities = useMemo(() => {
    const newRegistry: ColorRegistry = {};
    const colorUsageCount = style.colors.options.reduce<{ [color: string]: number }>(
      (acc, color) => ({ ...acc, [color]: 0 }),
      {}
    );

    props.entityIds.forEach((entityId) => {
      const color = registry.current[entityId];

      if (typeof color === "undefined") {
        return;
      }

      newRegistry[entityId] = color;
      colorUsageCount[color]++;
    });

    const allColorCount = style.colors.options.length;

    props.entityIds.forEach((entityId) => {
      if (newRegistry[entityId]) {
        return;
      }

      const [nextColor] = Object.entries(colorUsageCount).find(([, colorCount]) => {
        const lowestColorCount = Math.floor(Object.values(newRegistry).length / allColorCount);
        return lowestColorCount >= colorCount;
      }) as [string, number];

      newRegistry[entityId] = nextColor;
      colorUsageCount[nextColor]++;
    });

    registry.current = newRegistry;

    return newRegistry;
  }, [props.entityIds]);

  return (
    <ChartColorRegistryStoreContext.Provider value={registeredEntities}>
      {props.children}
    </ChartColorRegistryStoreContext.Provider>
  );
}

export default ChartColorRegistryProvider;
