import MuiSwitch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material";

const Switch = styled(MuiSwitch)(({ color, theme }) => {
  const switchColor = color && color !== "default" ? theme.palette[color].main : undefined;
  return {
    width: 28,
    height: 16,
    padding: 0,
    // display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: switchColor,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      boxSizing: "border-box",
    },
  };
}) as typeof MuiSwitch;

export default Switch;
