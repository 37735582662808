import http from "../../axiosSetup";
import {
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from "firebase/auth";
import ClaimCheckService from "../ClaimCheckService";
import AnalyticsService from "../AnalyticsService/AnalyticsService";
import { firebaseApp } from "config/firebase";

class UserService {
  static getAuth() {
    return getAuth(firebaseApp);
  }

  static get currentUser() {
    return UserService.getAuth().currentUser;
  }

  static getMe() {
    return http.get("/users/me").then((response) => response.data);
  }

  static setLastSeen() {
    return http.post("/users/me/last-seen").then((response) => response.data);
  }

  /**
   * @param {any} profile
   */
  static updateProfile({ id, name, organisation }) {
    return http.put("/users/me", { id, name, organisation }).then((response) => response.data);
  }

  /**
   * @param {string} email
   * @param {{ password: string; newPassword: string }} param1
   * @returns {Promise<void>}
   */
  static async updatePassword(email, { password, newPassword }) {
    await signInWithEmailAndPassword(UserService.getAuth(), email, password);
    return updatePassword(UserService.getAuth().currentUser, newPassword);
  }

  static signInWithCustomToken(customLoginToken) {
    return signInWithCustomToken(UserService.getAuth(), customLoginToken);
  }

  static onAuthStateChanged(onChange) {
    return UserService.getAuth().onAuthStateChanged((user) => onChange(user));
  }

  /**
   * @param {{ name: string; contentType: string; content: string | ArrayBuffer; }} photo
   */
  static setProfileImage(photo) {
    return http.patch("/users/me/profile-image", photo).then((response) => response.data);
  }

  static deleteProfileImage() {
    return http.delete("/users/me/profile-image").then((response) => response.data);
  }

  static toggleImperialSystem(imperialSystemEnabled) {
    return http
      .patch("/users/me/preferences/imperial-system", { imperialSystemEnabled })
      .then((response) => response.data);
  }

  /**
   * @param {string} timeZone
   * @returns {Promise<import("types").UserProfile["preferences"]>} */
  static updateTimeZone(timeZone) {
    return http.patch("/users/me/preferences/time-zone", { timeZone }).then((response) => response.data);
  }

  static setDefaultRAGTemplate(defaultRagStatusTemplateId) {
    return http
      .patch("/users/me/preferences/default-rag-status-template", { defaultRagStatusTemplateId })
      .then((response) => response.data);
  }
  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  static setDefaultDashboard(id) {
    return http.patch("/users/me/preferences/default-dashboard", { id }).then((response) => response.data);
  }

  static async signIn(email, password) {
    const userCredential = await signInWithEmailAndPassword(UserService.getAuth(), email, password);

    const osRole = await UserService.getRole();

    const userCanDo = [ClaimCheckService.roles.OWNER, ClaimCheckService.roles.COACH].includes(osRole);

    if (!userCanDo) {
      await UserService.signOut();

      throw new Error("Account type not allowed. Contact your organisation owner.");
    }

    return userCredential.user;
  }

  static signOut() {
    AnalyticsService.clear();
    return UserService.getAuth().signOut();
  }

  static sendPasswordResetEmail(email) {
    return sendPasswordResetEmail(UserService.getAuth(), email);
  }

  /**
   * @returns {Promise<import("types/organisation").OrganisationRole>} */
  static async getRole() {
    const claims = await ClaimCheckService.getClaims();

    if (!claims.osRole) throw new Error("Property `osRole` doesn't exist on User claims");

    return claims.osRole;
  }

  static async getOrganisationId() {
    const claims = await ClaimCheckService.getClaims();

    if (!claims.organisationId) throw new Error("Property `organisationId` doesn't exist on User claims");

    return claims.organisationId;
  }

  static async checkHubUser() {
    const claims = await ClaimCheckService.getClaims();

    return claims.hubUser;
  }
}
export default UserService;
