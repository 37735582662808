import useDefinedContext from "hooks/useDefinedContext";
import TableSortContext from "../TableSortContext";

interface useTableSortProps {}

function useTableSort(props?: useTableSortProps) {
  return useDefinedContext(TableSortContext);
}

export default useTableSort;
