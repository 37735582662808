import { PickersDay, PickersDayProps } from "@mui/x-date-pickers-pro";
import Badge from "@mui/material/Badge";
import moment from "moment";

export function HighlightedDay(
  props: PickersDayProps<moment.Moment> & {
    highlightedDays?: {
      [date: string]: object | undefined;
    };
  }
) {
  const { highlightedDays = {}, day, outsideCurrentMonth, ...otherProps } = props;

  const hasActivity = Boolean(!props.outsideCurrentMonth && highlightedDays[moment(props.day).format("YYYY-MM-DD")]);

  return (
    <Badge
      key={props.day.toISOString()}
      overlap="circular"
      color="secondary"
      variant={hasActivity ? "dot" : undefined}
      badgeContent={hasActivity ? <span /> : undefined}
    >
      <PickersDay
        {...otherProps}
        disabled={otherProps.disabled || Boolean(!hasActivity)}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}
