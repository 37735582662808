import { LinePath } from "@visx/shape";
import { ChartLine, ChartPoint } from "components/charting/types/Chart.types";
import { useCallback } from "react";
import useColorRegistry from "../../hooks/useColorRegistry";

function Line(props: ChartLine) {
  const { entityId, line } = props;
  const getEntityColor = useColorRegistry();

  const getX = useCallback((data: ChartPoint) => data.x, []);
  const getY = useCallback((data: ChartPoint) => data.y, []);

  return (
    <LinePath
      data={line}
      x={getX}
      y={getY}
      stroke={getEntityColor(entityId)}
      strokeWidth={3}
      shapeRendering="geometricPrecision"
    />
  );
}

export default Line;
