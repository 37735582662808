import useDefinedContext from "hooks/useDefinedContext";
import SetSearchTextContext from "../SetSearchTextContext";

interface UseSetSearchTextProps {}

function useSetSearchText(props?: UseSetSearchTextProps) {
  return useDefinedContext(SetSearchTextContext);
}

export default useSetSearchText;
