import { MetadataType } from "types/metadata";
import {
  ChartDataApiReducerAction,
  ChartDataApiReducerActions,
  ChartDataApiReducerState,
  ChartDataApiRequestStatus,
} from "./useChartDataApi.types";

export const chartDataApiReducerInitialState: ChartDataApiReducerState = {
  status: {},
  measureSets: [],
};

export function chartDataApiReducer(state: ChartDataApiReducerState, action: ChartDataApiReducerAction) {
  switch (action.type) {
    case ChartDataApiReducerActions.SET_STATUS_PENDING:
      return {
        ...state,
        status: { ...state.status, [action.requestId]: ChartDataApiRequestStatus.PENDING },
      };
    case ChartDataApiReducerActions.CLEAR_STATUS:
      return {
        ...state,
        status: { ...state.status, [action.requestId]: undefined },
      };
    case ChartDataApiReducerActions.ADD_MEASURES:
      return {
        ...state,
        status: { ...state.status, [action.requestId]: ChartDataApiRequestStatus.RESOLVED },
        measureSets: [...state.measureSets, action.payload],
      };
    case ChartDataApiReducerActions.DELETE_MEASURE:
      return {
        ...state,
        measureSets: state.measureSets.map((measureSet) => {
          if (measureSet.metadataType === MetadataType.EXERCISE) {
            return {
              ...measureSet,
              measurements: measureSet.measurements.filter((measurement) => measurement.id !== action.payload.id),
            };
          }
          return measureSet;
        }),
      };
    case ChartDataApiReducerActions.RESET:
    default:
      return chartDataApiReducerInitialState;
  }
}
export default chartDataApiReducer;
