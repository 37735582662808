import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ChartLegendItemColorBlock } from "./ChartLegendItem.components";
import CloseIcon from "@mui/icons-material/Close";
import * as Components from "./ChartLegendItem.components";

interface ChartLegendItemProps {
  color?: string;
  label: string;
  removeItem?(): void;
}

function ChartLegendItem(props: ChartLegendItemProps) {
  return (
    <Components.GridContainer
      container
      borderRadius="8px"
      boxSizing="border-box"
      alignItems="center"
      wrap="nowrap"
      width="100%"
    >
      <Grid item>
        <ChartLegendItemColorBlock color={props.color} />
      </Grid>
      <Grid item xs title={props.label} minWidth={0}>
        <Typography noWrap>{props.label}</Typography>
      </Grid>
      {props.removeItem && (
        <Components.RemoveButtonGrid item>
          <IconButton size="small" onClick={props.removeItem}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Components.RemoveButtonGrid>
      )}
    </Components.GridContainer>
  );
}

export default ChartLegendItem;
