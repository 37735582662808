import Input, { InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "library/Icons/SearchIcon";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

interface IconPopperMenuSearchInputProps extends InputProps {
  onClickClear?: React.MouseEventHandler<HTMLButtonElement>;
}

function IconPopperMenuSearchInput(props: IconPopperMenuSearchInputProps) {
  const { onClickClear, ...restOfProps } = props;

  return (
    <Input
      autoFocus
      color="primary"
      size="medium"
      fullWidth
      disableUnderline
      sx={{ p: 2 }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon color="inherit" />
        </InputAdornment>
      }
      endAdornment={
        Boolean(props.value) && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={onClickClear}>
              <ClearIcon color="inherit" />
            </IconButton>
          </InputAdornment>
        )
      }
      {...restOfProps}
    />
  );
}

export default IconPopperMenuSearchInput;
