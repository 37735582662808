import ReportsContext from "contexts/ReportsContext";
import { ReportsContextActions, ReportsContextType } from "contexts/ReportsContext/ReportsContext";
import useCanDo from "hooks/useCanDo";
import React, { useMemo, useState } from "react";
import ReportService from "services/ReportService";
import { ReportTemplate, ReportTemplateBase } from "types";

interface ReportsProviderProps {
  children?: React.ReactNode;
}

function ReportsProvider(props: ReportsProviderProps) {
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);
  const canViewGroups = useCanDo()("group.view");

  const reportTemplateActions: ReportsContextActions = useMemo(
    () => ({
      generateAthleteReport: ReportService.generateAthleteReport,
      generateGroupReport: ReportService.generateGroupReport,
      getAll: async () => {
        const result = await ReportService.getAllTemplates();
        setReportTemplates(result);

        return result;
      },
      getById: async (id: string, callback?: (template: ReportTemplate) => any) => {
        const result = await ReportService.getTemplateById(id, callback);
        setReportTemplates((prevTemplates) => [...prevTemplates.filter((template) => template.id !== id), result]);

        return result;
      },
      create: async (template: ReportTemplateBase) => {
        const result = await ReportService.createTemplate(template);
        const report = { ...result, template };
        setReportTemplates((prevTemplates) => [...prevTemplates, { ...result, template }]);

        return report;
      },
      delete: async (id: string) => {
        await ReportService.deleteTemplate(id);
        setReportTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== id));
      },
    }),
    []
  );

  const reportsContextValue: ReportsContextType = useMemo(() => {
    const visibleReportTemplates = canViewGroups
      ? reportTemplates
      : reportTemplates.filter((template) => template.reportType === 0);

    return [visibleReportTemplates, reportTemplateActions];
  }, [canViewGroups, reportTemplateActions, reportTemplates]);

  return <ReportsContext.Provider value={reportsContextValue}>{props.children}</ReportsContext.Provider>;
}

export default ReportsProvider;
