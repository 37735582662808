import Constants from "shared/utils/Constants";
import useCanDo from "hooks/useCanDo";
import { useEffect, useMemo, useState } from "react";
import i18n from "config/i18n";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import { useLocation } from "react-router-dom";
import DashboardIcon from "library/Icons/DashboardIcon";
import ActivityIcon from "library/Icons/ActivityIcon";
import AthletesIcon from "library/Icons/AthletesIcon";
import LineChartIcon from "library/Icons/LineChartIcon";
import GroupsIcon from "library/Icons/GroupsIcon";
import LeaderboardsIcon from "library/Icons/LeaderboardsIcon";
import ReportsIcon from "library/Icons/ReportsIcon";
import ExercisesIcon from "library/Icons/ExercisesIcon";
import WorkoutsIcon from "library/Icons/WorkoutsIcon";
import ExportIcon from "library/Icons/ExportIcon";
import SettingsIcon from "library/Icons/SettingsIcon";

export const useDrawerMenuItems = () => {
  const canDo = useCanDo();
  const location = useLocation();

  // TODO: Remove me after 30 days (2024-10-21)
  const [showDashboardSidebarIconIndicator, setShowDashboardSidebarIconIndicator] = useState(
    LocalStorageHelper.get(LocalStorageHelper.keys.showDashboardSidebarIconIndicator, true)
  );

  // Remove the new indicator if the user has visited the dashboard at least once
  useEffect(() => {
    if (LocalStorageHelper.get(LocalStorageHelper.keys.showDashboardSidebarIconIndicator, true)) {
      // if user has visited the dashboard, hide the new indicator
      if (location.pathname.includes("/dashboards")) {
        setShowDashboardSidebarIconIndicator(false);
        LocalStorageHelper.set(LocalStorageHelper.keys.showDashboardSidebarIconIndicator, false);
      }
    }
  }, [location.pathname]);

  return useMemo(
    () =>
      [
        canDo("dashboard.any") && {
          route: "/dashboards",
          title: "Dashboards",
          icon: <DashboardIcon />,
          isNew: showDashboardSidebarIconIndicator,
        },
        {
          route: "/live",
          title: "Live",
          icon: <ActivityIcon />,
        },
        {
          route: Constants.routes.athletes.route,
          title: Constants.routes.athletes.title,
          icon: <AthletesIcon />,
        },
        {
          route: "/charting",
          title: "Charting",
          icon: <LineChartIcon />,
        },
        canDo("group.any") && {
          route: Constants.routes.groups.route,
          title: Constants.routes.groups.title,
          icon: <GroupsIcon />,
        },
        {
          route: Constants.routes.leaderBoard.route,
          title: Constants.routes.leaderBoard.title,
          icon: <LeaderboardsIcon />,
        },
        canDo("report.any") && {
          route: Constants.routes.reportGeneration.route,
          title: Constants.routes.reportGeneration.title,
          icon: <ReportsIcon />,
        },
        canDo("library.any") && {
          route: Constants.routes.exerciseLibrary.route,
          title: i18n.t("Library.mainMenuTitle"),
          icon: <ExercisesIcon />,
        },
        canDo("workout.any") && {
          route: "/workouts",
          title: "Workouts",
          icon: <WorkoutsIcon />,
        },
        canDo("export.any") && {
          route: Constants.routes.exportData.route,
          title: Constants.routes.exportData.title,
          icon: <ExportIcon />,
        },
        {
          route: Constants.routes.settings.route,
          title: Constants.routes.settings.title,
          icon: <SettingsIcon />,
        },
      ].filter(Boolean),
    [canDo, showDashboardSidebarIconIndicator]
  );
};
