import React from "react";
import InfoMessageContext from "../contexts/InfoMessageContext";

function useInfoMessage({ type }) {
  const setInfoMessage = React.useContext(InfoMessageContext);

  const modifiedSetInfoMessage = React.useCallback(
    (info) => {
      setInfoMessage(info, type);
    },
    [setInfoMessage, type]
  );

  return modifiedSetInfoMessage;
}
export default useInfoMessage;
