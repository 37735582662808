import LocalStorageHelper from "helpers/LocalStorageHelper";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { useEffect, useRef } from "react";

function useChartPersistAdvancedOptions(props?: { advancedOptionsOpen: boolean }) {
  const advancedOptionsOpen = props?.advancedOptionsOpen;
  const selections = useChartSelections();
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) return;

    LocalStorageHelper.set(LocalStorageHelper.keys.advancedOptionsOpen, advancedOptionsOpen);
  }, [advancedOptionsOpen]);

  useEffect(() => {
    if (firstRenderRef.current) return;

    LocalStorageHelper.set(LocalStorageHelper.keys.showAverageValue, selections.showAverage);
  }, [selections.showAverage]);

  useEffect(() => {
    if (firstRenderRef.current) return;

    LocalStorageHelper.set(LocalStorageHelper.keys.showStandardDeviation, selections.showStandardDeviation);
  }, [selections.showStandardDeviation]);

  useEffect(() => {
    if (firstRenderRef.current) return;

    LocalStorageHelper.set(LocalStorageHelper.keys.beginYAxesAtZero, selections.showPlotFromZero);
  }, [selections.showPlotFromZero]);

  useEffect(() => {
    if (firstRenderRef.current) return;

    LocalStorageHelper.set(LocalStorageHelper.keys.showPersonalBest, selections.showPersonalBest);
  }, [selections.showPersonalBest]);

  useEffect(() => {
    firstRenderRef.current = false;
  }, []);
}

export default useChartPersistAdvancedOptions;
