import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import { GridBorderBottomGrey } from "../Settings.components";
import SettingsArchivedAthletesList from "./SettingsArchivedAthletesList";

interface SettingsAccountArchiveProps {}

function SettingsAccountArchive(props: SettingsAccountArchiveProps) {
  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <Box pb={4}>
              <Typography variant="h1">
                <Box component="span" fontWeight="fontWeightMedium">
                  Archived Athletes
                </Box>
              </Typography>
            </Box>
            <SettingsArchivedAthletesList />
          </Box>
        </GridBorderBottomGrey>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsAccountArchive;
