import { useInfoMessage } from "hooks";
import useOneRepMaxActions from "providers/OneRepMaxProvider/useOneRepMaxActions";
import { useCallback } from "react";
import { Exercise } from "types";
import { OneRepMaxValue } from "types/onerepmax";
import { FormOneRepMax } from "../useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import OneRepMaxBulkEditFormHelpers from "helpers/OneRepMaxBulkEditFormHelpers";
import usePreferences from "hooks/usePreferences";

interface UseBulkOneRepMaxUpdateProps {
  exercise: Exercise | null;
}

function useBulkOneRepMaxUpdate(props: UseBulkOneRepMaxUpdateProps) {
  const { exercise } = props;
  const oneRepMaxActions = useOneRepMaxActions();
  const successMessage = useInfoMessage({ type: "success" });
  const errorMessage = useInfoMessage({ type: "error" });
  const [{ imperialSystemEnabled }] = usePreferences();

  return useCallback(
    async (values: { athletesOneRepMaxes: FormOneRepMax[] }) => {
      try {
        const updatedOneRepMaxes: {
          athleteId: string;
          oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
        }[] = values.athletesOneRepMaxes
          .map((formOneRepMax) => OneRepMaxBulkEditFormHelpers.fromFormRow(formOneRepMax, { imperialSystemEnabled }))
          .map(({ oneRepMaxes, ...rest }) => ({ ...rest, oneRepMaxes: oneRepMaxes.filter(({ weight }) => weight) }))
          .filter((oneRepMax) => oneRepMax.oneRepMaxes.length);

        if (updatedOneRepMaxes.length) {
          await oneRepMaxActions.updateByAthleteIds(`${exercise?.id}`, updatedOneRepMaxes);
          successMessage({ message: "1RM Values Submitted" });
        }
      } catch (error) {
        errorMessage({ message: "Failed to Submit 1RM Values" });
      }
    },
    [imperialSystemEnabled, oneRepMaxActions, exercise?.id, successMessage, errorMessage]
  );
}

export default useBulkOneRepMaxUpdate;
