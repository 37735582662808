import { DataVisualizationType } from "components/charting/config/chartTypes";
import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useDataVisualizationType from "components/charting/hooks/useDataVisualizationType";
import useMeasurementSetsApi from "hooks/useMeasurementSetsApi";
import { MeasurementSet } from "types";
import { useEffect, useMemo, useState } from "react";
import AnalyticsService from "services/AnalyticsService";
import logEvent from "utils/logEvent";

interface ChartSetSelectProviderProps {
  children?: React.ReactNode;
}

function ChartSetSelectProvider(props: ChartSetSelectProviderProps) {
  const selections = useChartSelections();
  const visType = useDataVisualizationType();
  const [selectedSets, setSelectedSets] = useState<MeasurementSet[]>([]);
  const measure = selections.measures[0];
  const athleteId = selections.sources[0]?.id;
  const exerciseId = measure.id;
  const enableSetsApi = Boolean(
    [DataVisualizationType.SET_COMPARISON_CHART, DataVisualizationType.PROFILING_CHART].includes(visType) &&
      measure.type === "Exercise" &&
      measure.metadata.repetitions.length
  );
  const availableSets = useMeasurementSetsApi({
    athleteId,
    exerciseId,
    enabled: enableSetsApi,
  });

  const exerciseCategory = measure.type === "Exercise" ? measure.metadata.category : undefined;

  useEffect(() => {
    /**
     * Event Log pattern copied from when
     * @function useMeasurementSetsApi makes an api call
     **/

    if (!(enableSetsApi && exerciseId && athleteId)) return;

    logEvent("ATHLETE_PROFILE_VIEW_DATA", {
      [AnalyticsService.PROPERTIES.CHART_TYPE]: visType,
      [AnalyticsService.PROPERTIES.VISUALIZATION_TYPE]: visType,
      [AnalyticsService.PROPERTIES.CHARTING_MEASURE_ID]: exerciseId,
      [AnalyticsService.PROPERTIES.EXERCISE_ID]: measure.type === "Exercise" ? exerciseId : undefined,
      [AnalyticsService.PROPERTIES.CHARTING_MEASURE_TYPE]: measure.type,
      [AnalyticsService.PROPERTIES.EXERCISE_CATEGORY]: exerciseCategory,
    });
  }, [athleteId, enableSetsApi, exerciseId, exerciseCategory, measure.type, visType]);

  const contextValue = useMemo(
    () => ({
      value: selectedSets.filter((set) => availableSets.includes(set)),
      options: availableSets,
      onChange: (sets: MeasurementSet[]) => {
        setSelectedSets(sets);
      },
    }),
    [availableSets, selectedSets]
  );

  return <ChartSetSelectContext.Provider value={contextValue}>{props.children}</ChartSetSelectContext.Provider>;
}

export default ChartSetSelectProvider;
