import { FireStoreMeasurement } from "types";
import moment from "moment";
import groupBy from "lodash/groupBy";

export interface LiveActivityFeedMeasurements {
  id: string;
  athleteId: string;
  completedDate: string;
  measurements: FireStoreMeasurement[];
}

const isDayEqual = (prev: string, cur: string) =>
  moment(prev).format("YYYY-MM-DD") === moment(cur).format("YYYY-MM-DD");

export function processLiveActivityFeedMeasurements(measurements: FireStoreMeasurement[]) {
  const measurementsByDay = groupBy(measurements, (m) => moment(m.completedDate).format("YYYY-MM-DD"));
  const measurementsByAthleteId: LiveActivityFeedMeasurements[] = [];

  Object.values(measurementsByDay).forEach((dayMeasurements) => {
    dayMeasurements.forEach((measurement) => {
      const lastIndex = measurementsByAthleteId.length - 1;
      const nextIndex = measurementsByAthleteId.length;

      if (
        nextIndex > 0 &&
        measurementsByAthleteId[lastIndex].athleteId === measurement.athleteId &&
        isDayEqual(measurementsByAthleteId[lastIndex].completedDate, measurement.completedDate)
      ) {
        measurementsByAthleteId[lastIndex].measurements.push(measurement);
        return;
      }

      measurementsByAthleteId[nextIndex] = {
        id: `${measurement.athleteId}_${measurement.id}`,
        athleteId: measurement.athleteId,
        completedDate: measurement.completedDate,
        measurements: [measurement],
      };
    });
  });

  return measurementsByAthleteId;
}
