import { Athlete } from "types/athlete";
import { Group } from "contexts/GroupsContext/GroupsContext";
import { EntityType } from "types/entity";

class SourceHelper {
  static getLabel(source: ({ type: EntityType.ATHLETE } & Athlete) | ({ type: EntityType.GROUP } & Group)) {
    if (source.type === EntityType.GROUP) {
      return source.name;
    }

    return [source.firstName, source.lastName].filter(Boolean).join(" ");
  }
}
export default SourceHelper;
