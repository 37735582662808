import { useRef, useState, useEffect, useCallback } from "react";

function useGetContainerDimensions() {
  const [dimensions, setDimensions] = useState<ReturnType<HTMLDivElement["getBoundingClientRect"]>>();
  const ref = useRef<HTMLDivElement | null>(null);

  const measureDimensions = useCallback(() => {
    const boxDimensions = ref.current?.getBoundingClientRect();

    setDimensions(boxDimensions);
  }, []);

  useEffect(() => {
    measureDimensions();

    window.addEventListener("resize", measureDimensions);
    return () => window.addEventListener("resize", measureDimensions);
  }, [measureDimensions]);

  return { ref, dimensions };
}
export default useGetContainerDimensions;
