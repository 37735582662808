import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.2002 3.8H9.6002M19.8002 3.8H14.4002M9.6002 3.8V2H14.4002V3.8M9.6002 3.8H14.4002"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M5.3999 6.75195V18.5992C5.3999 20.2561 6.74305 21.5992 8.3999 21.5992H15.5999C17.2568 21.5992 18.5999 20.2561 18.5999 18.5992V6.75195"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M9 8.40039V16.8004"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M12 8.40039V16.8004"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M15 8.40039V16.8004"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </SvgIcon>
  );
}
export default DeleteIcon;
