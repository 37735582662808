import PageContent from "shared/components/PageContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ExportAthleteData from "./ExportAthleteData";
import Constants from "shared/utils/Constants";
import LocalisedLoadingProvider from "../../../components/LocalisedLoadingProvider";
import Paper from "library/mui5/Paper";
import DataExportAthleteInfoCard from "./DataExportAthleteInfoCard";

function DataExportAthlete() {
  return (
    <PageContent pageHeading={Constants.routes.exportData.title} infoCardComponent={DataExportAthleteInfoCard}>
      <Grid container justifyContent="center" mt={2}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Box display="inline-block" alignContent="center">
            <Paper>
              <LocalisedLoadingProvider>
                <Box p={3}>
                  <ExportAthleteData />
                </Box>
              </LocalisedLoadingProvider>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </PageContent>
  );
}
export default DataExportAthlete;
