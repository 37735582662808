import useDefinedContext from "hooks/useDefinedContext";
import OneRepMaxStoreContext from "../OneRepMaxStoreContext";

interface UseOneRepMaxProps {}

function useOneRepMaxStore(props?: UseOneRepMaxProps) {
  return useDefinedContext(OneRepMaxStoreContext);
}

export default useOneRepMaxStore;
