import { useState } from "react";
import TableSortContext from "./TableSortContext";
import SetTableSortContext from "./SetTableSortContext";

interface TableSortProviderProps {
  children?: React.ReactNode;
  initialSortKey: string;
  initialDirection?: "asc" | "desc";
}

function TableSortProvider(props: TableSortProviderProps) {
  const { initialSortKey, initialDirection = "asc" } = props;
  const [sortBy, setSortBy] = useState<{ key: string; direction: "desc" | "asc" }>({
    key: initialSortKey,
    direction: initialDirection,
  });

  return (
    <SetTableSortContext.Provider value={setSortBy}>
      <TableSortContext.Provider value={sortBy}>{props.children}</TableSortContext.Provider>
    </SetTableSortContext.Provider>
  );
}

export default TableSortProvider;
