import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Athlete } from "types/athlete";
import { useMemo } from "react";
import Mui5 from "library/mui5";
import AthleteHelpers from "helpers/AthleteHelpers";
import useMakeProfileImageURL from "hooks/useMakeProfileImageURL";

interface AthleteNameProps extends Partial<Athlete> {
  id: string;
  avatarColor?: string;
  showGroups?: boolean;
}

function AthleteName(props: AthleteNameProps) {
  const { id, firstName, lastName, profilePhotoName, avatarColor } = props;
  const fullName = [firstName, lastName].filter(Boolean).join(" ") || id;
  const initials = [firstName && firstName[0], lastName && lastName[0]].filter(Boolean).join("");

  const avatarCustomColor = useMemo(
    () =>
      avatarColor
        ? (theme: Theme) => ({
            backgroundColor: avatarColor,
            color: theme.palette.getContrastText(avatarColor),
          })
        : undefined,
    [avatarColor]
  );
  const profilePhotoPath = profilePhotoName && AthleteHelpers.getProfileImagePath({ id: props.id, profilePhotoName });
  const profilePhotoUrl = useMakeProfileImageURL(profilePhotoPath);

  return (
    <div>
      <Grid container alignItems="center" p={0} spacing="0px" flexWrap="nowrap">
        <Grid item zeroMinWidth>
          <Mui5.Avatar sx={avatarCustomColor} src={profilePhotoUrl || undefined}>
            {initials}
          </Mui5.Avatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Grid container alignItems="center">
            <Grid item xs zeroMinWidth>
              <Box ml={2} minWidth={0}>
                <Typography color="textPrimary" variant="h4" noWrap pr={1} title={fullName}>
                  {fullName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default AthleteName;
