import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import * as Components from "./ChartLegend.components";

interface ChartLegendItem {
  color: string;
  id: string;
  label: string;
  remove?(): void;
}
interface ChartLegendProps {
  items: ChartLegendItem[];
  disableBorders?: boolean;
}

function ChartLegend(props: ChartLegendProps) {
  return (
    <>
      <Components.GridChartLegendContainer container spacing="8px" width="100%" columns={12}>
        {props.items.map((item: ChartLegendItem, itemIndex) => (
          <Grid key={item.id + itemIndex} item xs={6} lg={4} xl={3} minWidth={240}>
            <Components.GridChartLegendItem
              item
              container
              alignItems="center"
              wrap="nowrap"
              title={item.label}
              spacing={0}
              padding={0}
              disableBorders={props.disableBorders}
              width="100%"
            >
              {item.color ? (
                <Grid item>
                  <Components.ChartLegendColor style={{ background: item.color }} />
                </Grid>
              ) : (
                <Grid item mr={2} />
              )}
              <Grid item xs title={item.label} minWidth={0}>
                <Typography noWrap>{item.label}</Typography>
              </Grid>
              {item.remove && (
                <Components.GridChartLegendRemoveX item>
                  <IconButton size="small" onClick={item.remove}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Components.GridChartLegendRemoveX>
              )}
            </Components.GridChartLegendItem>
          </Grid>
        ))}
      </Components.GridChartLegendContainer>
    </>
  );
}

export default ChartLegend;
