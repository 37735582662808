import { useCallback, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";

interface EditWorkoutIsSharedWarningProps {
  show: boolean;
  message: React.ReactNode;
}

function EditWorkoutIsSharedWarning(props: EditWorkoutIsSharedWarningProps) {
  const [show, setShow] = useState(props.show);

  const handleClose = useCallback(() => setShow(false), []);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  if (!show) return null;

  return (
    <Alert
      square
      severity="warning"
      variant="filled"
      onClose={handleClose}
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        boxShadow: theme.shadows[8],
      })}
    >
      {props.message}
    </Alert>
  );
}

export default EditWorkoutIsSharedWarning;
