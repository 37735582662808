import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material";

const TableCellBase = styled(TableCell)(() => ({
  wordBreak: "break-word",
  maxWidth: 0,
}));

export const WordBreakTableCell = styled(TableCellBase)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

export const WordBreakNoPaddingTableCell = styled(TableCellBase)(() => ({
  padding: 0,
}));

export const HeadTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  "& > *": {
    borderBottom: "unset",
  },
}));

export const RootTableRow = styled(HeadTableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFAFA",
  },
}));
