import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAthleteMonitoringStatusesContext } from "hooks";
import useMonitoringTemplateItems from "hooks/useMonitoringTemplateItems";
import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";

interface MonitoringLabelsRowProps {}

function MonitoringLabelsRow(props: MonitoringLabelsRowProps) {
  const templateItems = useMonitoringTemplateItems();
  const [, { currentRagTemplateId }] = useAthleteMonitoringStatusesContext();
  const archivedCustomExerciseIds = useArchivedCustomExercises(true);

  return (
    <Grid container justifyContent="space-evenly" alignItems="center" height="100%">
      {templateItems
        ?.filter((item) => !archivedCustomExerciseIds.includes(item.metadataId))
        .map((item, itemIndex) => (
          <Grid key={[currentRagTemplateId, item.metadataId, itemIndex].join("_")} item xs={2} textAlign="center">
            <Box color="common.white" fontSize="body2.fontSize" component="span">
              {item.name}
            </Box>
          </Grid>
        ))}
    </Grid>
  );
}

export default MonitoringLabelsRow;
