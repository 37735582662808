import { styled } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";

export const AdvancedOptionsButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  display: "block",
  height: theme.spacing(6),
  padding: theme.spacing(0, 2),
  textAlign: "left",
}));
