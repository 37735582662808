import MessageCard from "components/MessageCard";
import * as Components from "./AthleteProfilingChartNoData.components";

interface AthleteProfilingChartNoDataProps {}

function AthleteProfilingChartNoData(props: AthleteProfilingChartNoDataProps) {
  return (
    <MessageCard title="Profiling">
      No data to display. Please check you have selected a{" "}
      <Components.ImportantText>Rep Metric</Components.ImportantText> and the{" "}
      <Components.ImportantText>Sets</Components.ImportantText> you want to plot.
    </MessageCard>
  );
}

export default AthleteProfilingChartNoData;
