import html2canvas from "html2canvas";

class ImageService {
  static exportAsImage = async (element: HTMLElement, imageFileName: string) => {
    const canvas = await html2canvas(element, {
      height: element.getBoundingClientRect().height + 48,
      width: element.getBoundingClientRect().width + 48,
      onclone: (document) => {
        // @ts-ignore
        document.querySelector(".chartContainer").style.padding = 24 + "px";
        // @ts-ignore
        document.querySelector(".controlsClass").style = "display:none";
        if (document.querySelector(".infoButtonContainer")) {
          // @ts-ignore
          document.querySelector(".infoButtonContainer").style = "display:none";
        }
        // @ts-ignore
        document.querySelector(".logoContainer").style = "display:block";
      },
    });
    const image = canvas.toDataURL("image/png", 1.0);

    ImageService.downloadImage(image, imageFileName);
  };

  private static downloadImage = (blob: any, fileName: string) => {
    const fakeLink = window.document.createElement("a");
    // @ts-ignore
    fakeLink.style = "display:none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };
}
export default ImageService;
