import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { styled } from "@mui/material";

const maxCellWidth = 130;

const CellGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up(1280)]: {
    maxWidth: maxCellWidth,
  },
  [theme.breakpoints.up(1440)]: {
    minWidth: 142,
    maxWidth: 142,
  },
  [theme.breakpoints.up(1600)]: {
    minWidth: 172,
    maxWidth: 172,
  },
}));

export const PrimaryMetricsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(1280)]: {
    maxWidth: maxCellWidth * 5,
  },
}));

export const ExpandIconGrid = styled(Grid)(({ theme }) => ({
  width: theme.spacing(3),
}));

export const AllMetricsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(1280)]: {
    maxWidth: maxCellWidth * 5,
  },
}));

export const GridItem = (props: { className?: string; label?: string; value?: string | number }) => (
  <CellGrid item xs minWidth="20%" maxWidth={100}>
    <Box pt={1} pb={1}>
      <Typography title={props.label} align="center">
        <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
          {props.label}
        </Box>
      </Typography>
      <Typography variant="h4" title={`${props.value}`} align="center">
        {props.value}
      </Typography>
    </Box>
  </CellGrid>
);

export function GridSpacers() {
  return (
    <Fragment>
      {/**
       * @author {Carden}
       * The following are spacers to maintain consistent cell width */}
      <CellGrid item xs minWidth="20%" maxWidth={100} />
      <CellGrid item xs minWidth="20%" maxWidth={100} />
      <CellGrid item xs minWidth="20%" maxWidth={100} />
      <CellGrid item xs minWidth="20%" maxWidth={100} />
    </Fragment>
  );
}
