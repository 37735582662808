import CircularProgress from "@mui/material/CircularProgress";
import { RagStatus } from "contexts/AthleteMonitoringStatusesContext/AthleteMonitoringStatusesContext.types";
import PopoverWrapper from "library/PopoverWrapper";
import { useLoadingContext } from "hooks";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MeasureHelpers from "pages/Charting/helpers/MeasureHelpers";
import RagStatusIndicator from "library/RagStatusIndicator";
import RagStatusSummaryCard from "library/RagStatusSummaryCard";
import EventHelpers from "helpers/EventHelpers/EventHelpers";

interface MonitoringIndicatorProps extends RagStatus {
  athleteId: string;
  hasValues: boolean;
}

function MonitoringIndicator(props: MonitoringIndicatorProps) {
  const { athleteId, hasValues, ...restRagStatusProps } = props;
  const loadingContext = useLoadingContext();
  const navigate = useNavigate();

  const chartUrl = MeasureHelpers.getAthleteProfileChartUrl(props.athleteId, {
    metadataId: props.metadataId,
  });

  const handleIndicatorClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigate(chartUrl);
    },
    [chartUrl, navigate]
  );

  return (
    <>
      {Boolean(!props?.values?.length && loadingContext[0]) && <CircularProgress size={20} color="secondary" />}
      {props.values && (
        <PopoverWrapper id={`rag-popover-${props.athleteId}-${props.metadataId}`}>
          {({ anchorProps, Popover }) => (
            <div onFocus={EventHelpers.stopPropagation}>
              <IconButton {...anchorProps} disabled={!hasValues} onClick={handleIndicatorClick}>
                <RagStatusIndicator values={props.values} />
              </IconButton>
              {hasValues && (
                <Popover>
                  <RagStatusSummaryCard {...restRagStatusProps} />
                </Popover>
              )}
            </div>
          )}
        </PopoverWrapper>
      )}
    </>
  );
}

export default MonitoringIndicator;
