import useDefinedContext from "hooks/useDefinedContext";
import WorkoutsActionsContext from "../WorkoutsActionsContext";

interface UseWorkoutsProps {}

function useWorkoutsActions(props?: UseWorkoutsProps) {
  return useDefinedContext(WorkoutsActionsContext);
}

export default useWorkoutsActions;
