import useDefinedContext from "hooks/useDefinedContext";
import FilteredWorkoutsContext from "../FilteredWorkoutsContext";

interface UseFilteredWorkoutsProps {}

function useFilteredWorkouts(props?: UseFilteredWorkoutsProps) {
  return useDefinedContext(FilteredWorkoutsContext);
}

export default useFilteredWorkouts;
