import { styled } from "@mui/material";
import NoAvatar from "assets/images/no-avatar.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ProfilePhotoDiv = styled("div", {
  shouldForwardProp: (props: string) => props !== "backgroundImage",
})<{ backgroundImage: string | null }>(({ theme, backgroundImage }) => ({
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : `url(${NoAvatar})`,
  marginRight: theme.spacing(2.5),
  height: theme.spacing(7),
  width: theme.spacing(7),
  backgroundPosition: "center",
  backgroundSize: "cover",
  borderRadius: "50px",
  flex: "0 0 65px",
}));

export const EndIconSpan = styled("span")({
  display: "inherit",
  marginRight: -6,
  marginLeft: 4,
});

export const AthleteProfileExpandMoreIcon = styled(ExpandMoreIcon, {
  shouldForwardProp: (props: string) => props !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.short,
  }),
  transform: open ? "rotate(-180deg)" : "rotate(0deg)",
}));
