import { createContext } from "react";

export type MeasurementsContextType = [
  undefined,
  {
    deleteById(id: string): Promise<{ id: string } | undefined>;
  },
];

const MeasurementsContext = createContext<MeasurementsContextType | undefined>(undefined);

export default MeasurementsContext;
