import { useMemo } from "react";
import useChartSelections from "../../../../components/charting/hooks/useChartSelections";
import { useRequest } from "hooks";
import ExportService from "services/ExportService";
import { MetadataType } from "types/metadata";
import MeasureHelpers from "pages/Charting/helpers/MeasureHelpers";

function useChartDataExportButtonProps() {
  const exportData = useRequest(ExportService.exportAthletes);
  const selections = useChartSelections();

  return useMemo(
    () => ({
      onClick: async () => {
        const athleteIds: string[] = [];

        selections.sources.forEach((source) => {
          if (source.type === "ATHLETE") {
            athleteIds.push(source.id);
          } else {
            source.athleteIds.forEach((id) => athleteIds.push(id));
          }
        });

        const options = {
          athleteIds: athleteIds.filter(Boolean),
          startDate: selections.startDate.toISOString(),
          endDate: selections.endDate.toISOString(),
          exerciseIds: selections.measures
            .filter((measure) => measure.type === MetadataType.EXERCISE)
            .map((measure) => measure.id),
          splitExercises: true,
          includeHeight: Boolean(
            selections.measures.filter(
              (measure) => measure.type === MetadataType.ANTHROPOMETRIC && measure.id === "height"
            ).length
          ),
          includeWeight: Boolean(
            selections.measures.filter(
              (measure) => measure.type === MetadataType.ANTHROPOMETRIC && measure.id === "weight"
            ).length
          ),
          includeWellness: Boolean(selections.measures.find((measure) => measure.type === MetadataType.WELLNESS)),
        };
        exportData(options);
      },
      disabled: !Boolean(selections.sources.length && MeasureHelpers.getValidMeasures(selections.measures).length),
    }),
    [exportData, selections.endDate, selections.measures, selections.sources, selections.startDate]
  );
}

export default useChartDataExportButtonProps;
