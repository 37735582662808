import { styled } from "@mui/material";

export interface VirtualTableProps {}

class VirtualTable {
  static Parent = styled("div")({
    height: "100%",
    width: "100%",
    overflow: "auto",
  });

  static Inner = styled("div")({
    width: "100%",
    position: "relative",
  });

  static Row = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  });
}

export default VirtualTable;
