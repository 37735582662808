import { useMemo } from "react";
import AnthropometricMetadataContext from "./contexts/AnthropometricMetadataContext";
import { AnthropometricsHelper } from "components/Chart/helpers";

interface AnthropometricMetadataProviderProps {
  children?: React.ReactNode;
}

function AnthropometricMetadataProvider(props: AnthropometricMetadataProviderProps) {
  const anthropometricMetadata = useMemo(() => AnthropometricsHelper.metadata, []);

  return (
    <AnthropometricMetadataContext.Provider value={anthropometricMetadata}>
      {props.children}
    </AnthropometricMetadataContext.Provider>
  );
}

export default AnthropometricMetadataProvider;
