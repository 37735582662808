/**
 * @Jack Done as workaround due to their being no metadata available on custom exercises.
Must be updated if the shape of the metadata changes on exercises generally.
*/

import { ExerciseMetric } from "types";

export const customExerciseConfigMetadata: ExerciseMetric = {
  isDefault: false,
  isPbEnabled: false,
  displayMetadata: {
    imperial: {
      decimals: null,
      unit: "Pound",
    },
    metric: null,
  },
  name: "Weight",
  field: "weight",
  unitOfMeasure: "Kilogram",
  sortType: 1,
};
