import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function CogIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg fill="none">
        <path
          d="M12 3.75C11.0887 3.75 10.35 4.48873 10.35 5.4V5.4C10.35 6.36537 9.1825 6.84849 8.49988 6.16587V6.16587C7.85552 5.5215 6.81079 5.5215 6.16643 6.16587C5.52206 6.81023 5.52206 7.85496 6.16643 8.49932V8.49932C6.84934 9.18223 6.36578 10.35 5.4 10.35V10.35C4.48873 10.35 3.75 11.0887 3.75 12C3.75 12.9113 4.48873 13.65 5.4 13.65V13.65C6.36581 13.65 6.84921 14.818 6.16627 15.5009V15.5009C5.52191 16.1453 5.52191 17.19 6.16627 17.8344C6.81064 18.4787 7.85536 18.4787 8.49973 17.8344V17.8344C9.18232 17.1518 10.35 17.6347 10.35 18.6V18.6C10.35 19.5113 11.0887 20.25 12 20.25C12.9113 20.25 13.65 19.5113 13.65 18.6V18.6C13.65 17.6344 14.8175 17.1503 15.5002 17.8331V17.8331C16.1446 18.4775 17.1893 18.4775 17.8337 17.8331C18.4781 17.1888 18.4781 16.144 17.8337 15.4997V15.4997C17.1511 14.8171 17.6347 13.65 18.6 13.65V13.65C19.5113 13.65 20.25 12.9113 20.25 12C20.25 11.0887 19.5113 10.35 18.6 10.35V10.35C17.6348 10.35 17.151 9.18306 17.8335 8.50057V8.50057C18.4779 7.8562 18.4779 6.81148 17.8335 6.16712C17.1892 5.52275 16.1444 5.52275 15.5001 6.16712V6.16712C14.8174 6.84984 13.65 6.36552 13.65 5.4V5.4C13.65 4.48873 12.9113 3.75 12 3.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <circle cx="12" cy="12" r="3" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    </SvgIcon>
  );
}
export default CogIcon;
