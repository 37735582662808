import moment from "moment";
import useChartOptions from "pages/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "pages/Charting/hooks/useSetChartSelections";
import { useCallback, useMemo } from "react";
import { ChartingSelections } from "../types";

function useChartTimePeriod() {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();

  const setPeriod = useCallback(
    (event: unknown, timePeriod: ChartingSelections["period"]) => {
      if (timePeriod.value === null) {
        throw new Error("Custom date range not yet implemented");
      }

      const endDate = moment().endOf("day");
      const startDate = moment().endOf("day").clone().subtract(1, timePeriod.value);

      setSelections({
        period: options.period.find((period) => {
          if (period.value === null) return !timePeriod.value;
          return timePeriod.value === period.value;
        }),
        startDate,
        endDate,
      });
    },
    [options.period, setSelections]
  );

  return useMemo(
    () => ({
      options: options.period,
      value: selections.period,
      onChange: setPeriod,
    }),
    [options.period, selections.period, setPeriod]
  );
}
export default useChartTimePeriod;
