import { alpha, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const PublishedStatusIconButton = styled(IconButton, {
  shouldForwardProp: (key: string) => !["published"].includes(key),
})<{
  published: boolean;
}>(({ theme, published }) => ({
  backgroundColor: alpha(published ? theme.palette.success.light : theme.palette.grey[400], 0.25),
  "&:hover": {
    backgroundColor: alpha(published ? theme.palette.success.light : theme.palette.grey[400], 0.5),
  },
}));

export default PublishedStatusIconButton;
