import InputBase, { inputBaseClasses } from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";

export const LeaderboardEditableLabelInputBase = styled(InputBase, {
  shouldForwardProp: (propName: string) => propName !== "dirty" && propName !== "noName",
})<{ dirty: boolean; noName: boolean }>(({ theme, dirty, noName }) => ({
  ...theme.typography.h4,
  fontWeight: noName ? theme.typography.fontWeightLight : theme.typography.fontWeightMedium,
  fontStyle: dirty ? "italic" : undefined,
  "&::placeholder": {
    fontWeight: theme.typography.fontWeightRegular,
    fontStyle: "italic",
  },
  [`& .${inputBaseClasses.input}`]: {
    paddingLeft: theme.spacing(0.5),
  },
}));

export const LowPaddingIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.2),
}));

export const LeaderboardHeading = styled(Typography)(({ theme }) => ({
  /**
   * @author Carden
   * When fullscreen enabled
   */
  fontSize: theme.typography.htmlFontSize === 18 ? theme.typography.h4.fontSize : theme.typography.h1.fontSize,
}));
/**
 * @author Carden
 * When fullscreen enabled
 */
export const LeaderboardSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.htmlFontSize === 18 ? theme.typography.h5.fontSize : theme.typography.subtitle2.fontSize,
}));
