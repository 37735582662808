import useChartSelections from "components/charting/hooks/useChartSelections";
import { ChartLine } from "components/charting/types/Chart.types";
import LineHelpers from "helpers/LineHelpers";
import { useMemo } from "react";
import { Exercise } from "types";

function useAthleteProfilingChartAnnotations(lines: ChartLine[]) {
  const selctions = useChartSelections();

  return useMemo(() => {
    const profilingLine: { x: number; y: number }[] = [];
    let minimumVelocityThreshold: number | null | undefined;

    if (lines.length > 0) {
      lines[0].line.forEach((lineData) => {
        if (lineData.metadataType === "Exercise" && lineData.profiling) {
          minimumVelocityThreshold = (selctions.measures[0]?.metadata as Exercise).minimumVelocityThreshold;
          profilingLine.push(lineData.profiling);
        }
      });
    }

    const line = profilingLine.map((l) => [l.x, l.y]) as [number, number][];

    const estimate = minimumVelocityThreshold && LineHelpers.getXIntersect(line, minimumVelocityThreshold);

    return {
      estimatedOneRepMax: estimate || null,
      minimumVelocityThreshold,
    };
  }, [selctions.measures, lines]);
}

export default useAthleteProfilingChartAnnotations;
