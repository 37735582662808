import { useMemo, useState } from "react";
import OneRepMaxService from "services/OneRepMaxService";
import { OneRepMax, OneRepMaxValue } from "types/onerepmax";
import OneRepMaxActionsContext, { OneRepMaxActionsContextType } from "./OneRepMaxActionsContext";
import OneRepMaxContext, { OneRepMaxStoreContextType } from "./OneRepMaxStoreContext";
import uniq from "lodash/uniq";

interface OneRepMaxProviderProps {
  children?: React.ReactNode;
}

function OneRepMaxProvider(props: OneRepMaxProviderProps) {
  const [oneRepMaxes, setOneRepMaxes] = useState<OneRepMaxStoreContextType>({});

  const oneRepMaxActions: OneRepMaxActionsContextType = useMemo(
    () => ({
      async getByAthleteIds(exerciseId, athleteIds) {
        try {
          const result: OneRepMax[] = await OneRepMaxService.getByAthleteIds(exerciseId, athleteIds, {
            mostRecent: true,
          });
          setOneRepMaxes((prev) => {
            const updatedPrev: OneRepMaxStoreContextType = { ...prev };

            result.forEach((oneRepMax: OneRepMax) => {
              updatedPrev[oneRepMax.athleteId] = {
                ...updatedPrev[oneRepMax.athleteId],
                [oneRepMax.exerciseId]: oneRepMax.oneRepMaxes,
              };
            });

            return updatedPrev;
          });
        } catch (error) {
          throw error;
        }
      },
      async updateByAthleteIds(
        exerciseId: string,
        newOneRepMaxes: {
          athleteId: string;
          oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
        }[]
      ) {
        await OneRepMaxService.updateByAthleteIds(newOneRepMaxes);
        const athleteIds = newOneRepMaxes.map((newOneRepMax) => newOneRepMax.athleteId);
        await oneRepMaxActions.getByAthleteIds(exerciseId, athleteIds);
      },

      async updateByExerciseIds(
        athleteId: string,
        newOneRepMaxes: {
          athleteId: string;
          oneRepMaxes: Pick<OneRepMaxValue, "exerciseId" | "weight" | "variant">[];
        }[]
      ) {
        await OneRepMaxService.updateByAthleteIds(newOneRepMaxes);
        const exerciseIds = uniq(
          newOneRepMaxes.flatMap((newOneRepMax) => newOneRepMax.oneRepMaxes.map((oneRepMax) => oneRepMax.exerciseId))
        );
        await Promise.all(
          exerciseIds.map(async (exerciseId) => await oneRepMaxActions.getByAthleteIds(exerciseId, [athleteId]))
        );
      },
    }),
    []
  );

  return (
    <OneRepMaxActionsContext.Provider value={oneRepMaxActions}>
      <OneRepMaxContext.Provider value={oneRepMaxes}>{props.children}</OneRepMaxContext.Provider>
    </OneRepMaxActionsContext.Provider>
  );
}

export default OneRepMaxProvider;
