import Box from "@mui/material/Box";
import React from "react";
import * as VerticalPageLayoutComponents from "./VerticalPageLayout.components";

interface VerticalPageLayoutProps {
  top: React.ReactNode;
  warning?: React.ReactNode;
  title?: React.ReactNode;
  main: React.ReactNode;
}

function VerticalPageLayout(props: VerticalPageLayoutProps) {
  return (
    <>
      {props.warning}
      <Box display="flex" flexDirection="column" p={2} pb={2} height="100%">
        <Box>{props.top}</Box>
        {Boolean(props.title) && <Box>{props.title}</Box>}
        <VerticalPageLayoutComponents.NoMinHeightStyledBox display="flex" flex={1} position="relative">
          {props.main}
        </VerticalPageLayoutComponents.NoMinHeightStyledBox>
      </Box>
    </>
  );
}
export default VerticalPageLayout;
