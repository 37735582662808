import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function ImageExportIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18 19.5H3.75C3.33579 19.5 3 19.1642 3 18.75V5.25C3 4.83579 3.33579 4.5 3.75 4.5H17.25C17.6642 4.5 18 4.83579 18 5.25V13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3 13.5L7.26369 9.84541C7.82983 9.36014 8.66647 9.36441 9.22763 9.85543L13.3054 13.4234C13.852 13.9018 14.6629 13.9197 15.2302 13.4659L18 11.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <circle cx="13.5" cy="8.25" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="none" />
      <path
        d="M15 16.5H22.5M22.5 16.5L20.25 14.25M22.5 16.5L20.25 18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
export default ImageExportIcon;
