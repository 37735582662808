/**
 * @note
 * Potentially use match-sorter library
 */

export const exerciseAutocompleteFilterOptions = (options, { inputValue }) => {
  const inputKeywords = inputValue.toLowerCase().split(" ");

  if (!inputValue) {
    return options;
  }

  const filteredOptions = options.filter((option) => {
    const candidateKeywordString = [option.name, option.category, option.type, ...(option.searchTerms || [])]
      .filter(Boolean)
      .join(" ")
      .toLowerCase();

    return inputKeywords.every((inputKeyword) => candidateKeywordString.indexOf(inputKeyword) > -1);
  });

  return filteredOptions;
};
export const metadataAutocompleteFilterOptions = (options, { inputValue }) => {
  const inputKeywords = inputValue.toLowerCase().split(" ");

  if (!inputValue) {
    return options;
  }

  const filteredOptions = options.filter((option) => {
    const candidateKeywordString = [
      option?.metadata?.name,
      option?.metadata?.label,
      option?.metadata?.category,
      option?.type,
      option?.metadata?.label,
      ...(option?.metadata?.searchTerms || []),
    ]
      .filter(Boolean)
      .join(" ")
      .toLowerCase();

    return inputKeywords.every((inputKeyword) => candidateKeywordString.indexOf(inputKeyword) > -1);
  });

  return filteredOptions;
};
