import { alpha, styled } from "@mui/material";

export const TableRow = styled("div")<{ selected?: boolean }>((props) => ({
  minHeight: 56,
  borderBottom: `1px solid ${props.theme.palette.grey[400]}`,
  boxSizing: "border-box",
  backgroundColor: props.selected ? alpha(props.theme.palette.primary.main, 0.05) : undefined,
}));

export const ColorBlockDiv = styled("div", {
  shouldForwardProp: (props: string) => props !== "color",
})<{ color: string }>(({ color, theme }) => ({
  backgroundColor: color,
  height: "100%",
  width: theme.spacing(2),
  position: "absolute",
  top: 0,
  bottom: 0,
}));
