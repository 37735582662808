import { useMatch } from "react-router-dom";
import { useField } from "formik";
import AthletesService from "../../../../services/AthleteService";
import { useNotifications } from "../../../../hooks";
import * as Components from "./InviteEmailResendButton.components";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function InviteEmailResendButton() {
  const { t } = useTranslation();

  const notifications = useNotifications({
    success: t("Athlete.formEmailResendSuccessMessage"),
    error: t("Athlete.formEmailResendErrorMessage"),
  });

  const [field, metadata] = useField("email");

  const routeMatch = useMatch("/athlete-hub/:athleteId/edit");
  const athleteId = routeMatch?.params?.athleteId;

  if (!(athleteId && metadata.initialValue)) return null;

  function resendAthleteEmailInvite() {
    AthletesService.resendInvite(athleteId).then(notifications.onSuccess).catch(notifications.onError);
  }

  const disabled = Boolean(field.value !== metadata.initialValue);

  return (
    <Box p={1} mb={2}>
      <Components.EmailResendButton
        onClick={resendAthleteEmailInvite}
        size="medium"
        color="primary"
        variant="contained"
        disabled={disabled}
        disableElevation
      >
        {t("Athlete.formEmailResendButtonText")}
      </Components.EmailResendButton>
    </Box>
  );
}
export default InviteEmailResendButton;
