import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import InfoMessageContext from "../../contexts/InfoMessageContext";
import Snackbar from "@mui/material/Snackbar";
import * as Components from "./InfoMessageProvider.components";

function InfoMessageProvider(props) {
  const [info, setInfo] = useState();

  const sendInfoMessage = useCallback(({ message }, type = "info") => {
    setInfo({ message, type });
  }, []);

  function handleClose() {
    setInfo();
  }

  const snackbarComponent = (
    <Snackbar open={!!info} autoHideDuration={6000} onClose={handleClose} key={info?.message}>
      <Components.InfoMessageAlert elevation={6} variant="filled" onClose={handleClose} severity={info?.type}>
        {info?.message}
      </Components.InfoMessageAlert>
    </Snackbar>
  );

  return (
    <InfoMessageContext.Provider value={sendInfoMessage}>
      {props.children}
      {snackbarComponent}
    </InfoMessageContext.Provider>
  );
}

InfoMessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoMessageProvider;
