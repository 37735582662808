import { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { createContext } from "react";

export type ChartTooltipManagerContextType<T = any> = {
  TooltipInPortal: React.FC<TooltipInPortalProps>;
} & UseTooltipParams<T>;

const ChartTooltipManagerContext = createContext<ChartTooltipManagerContextType | undefined>(undefined);

export default ChartTooltipManagerContext;
