import UnitHelper from "helpers/UnitHelper";
import PolynomialRegression from "js-polynomial-regression";

export class PolynomialRegressionProcessor {
  static degrees = {
    LINEAR: 1 as const,
    QUADRATIC: 2 as const,
  };

  weightInterval = 2.5; // issue on conversion to pounds
  protected poundsInterval = 5;
  protected xOffset = 10;

  line: [number, number][] = [];

  predictY: undefined | ((x: number) => number);

  constructor(
    protected values: [number, number][],
    protected imperial: boolean,
    protected fit: 1 | 2 = 1
  ) {
    if (this.values.filter((v) => v.length !== 2).length) {
      throw new Error("The point needs to have [x, y] values");
    }

    this.setPredictor();
    this.fillAllPoints();
  }

  getXStartAndEnd() {
    let startX = Math.min(...this.values.map((v) => v[0])) - this.xOffset;
    let endX = Math.max(...this.values.map((v) => v[0])) + this.xOffset;

    if (this.imperial) {
      /**
       * 1. Update weight interval to
       * increase KGs (x value) in increments of 5 pounds {this.poundsInterval} */
      this.weightInterval = UnitHelper.convert(this.poundsInterval, UnitHelper.units.Pound, UnitHelper.units.Kilogram);
      /**
       * 1. convert to pounds
       * 2. round to nearest % 5 */
      const kgStart = startX;
      const lbsStart = UnitHelper.convert(kgStart, UnitHelper.units.Kilogram, UnitHelper.units.Pound);
      const remainder = lbsStart % this.poundsInterval;
      const rounded = lbsStart - remainder;
      startX = UnitHelper.convert(rounded, UnitHelper.units.Pound, UnitHelper.units.Kilogram);
    }

    return { startX, endX };
  }

  fillAllPoints() {
    if (!this.predictY) {
      return;
    }

    const { startX, endX } = this.getXStartAndEnd();

    const vals: [number, number][] = [];

    if (this.fit === PolynomialRegressionProcessor.degrees.LINEAR && this.values.length > 1) {
      let x: number = startX;
      let y: number = this.predictY(x);

      while ((x >= 0 && x <= endX) || y >= 0) {
        const nextX = x + this.weightInterval;
        const nextY = this.predictY(nextX);

        if (nextY >= y) {
          break;
        }

        vals.push([x, y]);

        x = nextX;
        y = nextY;
      }
    } else if (this.fit === PolynomialRegressionProcessor.degrees.QUADRATIC) {
      for (let x = startX; x <= endX; x += this.weightInterval) {
        let y = this.predictY(x);

        if (!(x > 0 && y > 0)) break;

        vals.push([x, y]);
      }
    }

    this.line = vals;
  }

  private setPredictor() {
    const data = this.values.map(([x, y]) => ({ x, y }));

    const model = PolynomialRegression.read(data, this.fit);
    const terms = model.getTerms();
    const predictor = (x: number) => model.predictY(terms, x);

    this.predictY = predictor;
  }
}
