import { styled } from "@mui/material";

export const CardWrapper = styled("div")((props) => ({
  overflowY: "hidden",
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

export const ScrollBox = styled("div")((props) => ({
  flex: 1,
  overflowY: "scroll",
  paddingBottom: props.theme.spacing(8),
}));
