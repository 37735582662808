import http from "axiosSetup";
import { Group } from "contexts/GroupsContext/GroupsContext";
import { Coach, CoachBase, CoachDeserialized } from "types/coach";

class CoachService {
  static serialize<T extends PartialWith<CoachDeserialized, "groups">>(
    coach: T
  ): Omit<T, "groups"> & { groupIds: string[] } {
    const { groups, ...restOfCoach } = coach;

    return { ...restOfCoach, groupIds: groups.map((group) => group.id) };
  }

  static deserialize<T extends PartialWith<Coach, "groupIds">>(
    coach: T,
    options: { groups: Group[] }
  ): Omit<T, "groupIds"> & { groups: Group[] } {
    const { groupIds, ...restOfCoach } = coach;

    const groups = options.groups.filter((group) => groupIds.includes(group.id));

    return { ...restOfCoach, groups };
  }

  static async create({ profilePhotoName: profilePhoto, ...coach }: CoachBase) {
    const result = await http.post<Pick<Coach, "id">>("/coaches", coach).then((response) => response.data);

    if (profilePhoto) await CoachService.setProfileImage(result.id, profilePhoto);

    return result;
  }

  static getAll() {
    return http.get<Coach[]>("/coaches").then((response) => response.data);
  }

  static getById(id: string) {
    return http.get<Coach>(`/coaches/${id}`).then((response) => response.data);
  }

  static async update(id: string, { profilePhotoName: profilePhoto, ...coach }: CoachBase) {
    const result = await http.put<Pick<Coach, "id">>(`/coaches/${id}`, coach).then((response) => response.data);
    if (typeof profilePhoto !== "string") {
      // typeof 'string' infers a profile photo url
      // Thus the profile photo has not been updated
      await CoachService.setProfileImage(id, profilePhoto);
    }

    return result;
  }

  static delete(id: string) {
    return http.delete<void>(`/coaches/${id}`).then((response) => response.data);
  }

  private static async setProfileImage(
    id: string,
    profilePhoto: string | null | { content: string | ArrayBuffer; name: string; contentType: string }
  ): Promise<void> {
    if (!profilePhoto) {
      return http.delete<void>(`/coaches/${id}/profile-image`).then((response) => response.data);
    }

    return http.patch<void>(`/coaches/${id}/profile-image`, profilePhoto).then((response) => response.data);
  }
}

export default CoachService;
