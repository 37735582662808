import useDefinedContext from "hooks/useDefinedContext";
import ExercisesContext from "../ExercisesContext";
import ArchivedCustomExercisesContext from "../ArchivedCustomExercisesContext";
import { useMemo } from "react";

function useExercises(withArchived: boolean = false) {
  const enabledExercises = useDefinedContext(ExercisesContext);
  const archivedCustomExercises = useDefinedContext(ArchivedCustomExercisesContext);

  return useMemo(
    () => enabledExercises.concat(withArchived ? archivedCustomExercises : []),
    [archivedCustomExercises, enabledExercises, withArchived]
  );
}

export default useExercises;
