import { useCallback, useEffect, useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { FireStoreMeasurement } from "types";
import { processLiveActivityFeedMeasurements } from "./LiveActivityFeedVirtualTable.utils";
import LiveActivityFeedVirtualTableRow from "../LiveActivityFeedVirtualTableRow";
import useShowMoreActivity from "pages/Live/hooks/useShowMoreActivity";
import Mui5 from "library/mui5";
import Box from "@mui/material/Box";
import VirtualTable from "components/VirtualTable";

interface LiveActivityFeedVirtualTableProps {
  measurements: FireStoreMeasurement[];
}

function LiveActivityFeedVirtualTable(props: LiveActivityFeedVirtualTableProps) {
  const { measurements } = props;
  const measurementsByAthlete = useMemo(() => processLiveActivityFeedMeasurements(measurements), [measurements]);
  const parentRef = useRef<HTMLDivElement>(null);
  const showMore = useShowMoreActivity();

  const rowVirtualizer = useVirtualizer({
    count: measurementsByAthlete.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 56, []),
    overscan: 5,
  });

  const requestProgressRef = useRef<boolean>(false);

  useEffect(() => {
    const virtualTableRef = parentRef.current;
    async function onScroll() {
      const scrollHeight = virtualTableRef?.scrollHeight;
      const scrollPositionBottom = virtualTableRef && virtualTableRef.clientHeight + virtualTableRef.scrollTop;

      if (
        !requestProgressRef.current &&
        typeof scrollHeight === "number" &&
        typeof scrollPositionBottom === "number" &&
        scrollHeight <= scrollPositionBottom
      ) {
        requestProgressRef.current = true;
        await showMore();
        requestProgressRef.current = false;
      }
    }

    virtualTableRef?.addEventListener("scroll", onScroll);

    return () => {
      virtualTableRef?.removeEventListener("scroll", onScroll);
    };
  }, [showMore]);

  const count = rowVirtualizer.getVirtualItems().length;

  return (
    <VirtualTable.Parent ref={parentRef} sx={{ paddingTop: "6px" }}>
      <VirtualTable.Inner sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
        {rowVirtualizer.getVirtualItems().map((virtualRow, virtualRowIndex) => (
          <VirtualTable.Row
            key={virtualRow.index}
            data-index={virtualRow.index}
            ref={rowVirtualizer.measureElement}
            sx={{ pb: 1 }}
            style={{ transform: `translateY(${virtualRow.start}px)` }}
          >
            <LiveActivityFeedVirtualTableRow {...measurementsByAthlete[virtualRow.index]} />
            {Boolean(count - 1 === virtualRowIndex) && (
              <>
                <Box sx={{ height: 16 }} /* spacer */ />
                <Mui5.Button variant="text" onClick={showMore}>
                  Show More
                </Mui5.Button>
              </>
            )}
          </VirtualTable.Row>
        ))}
      </VirtualTable.Inner>
    </VirtualTable.Parent>
  );
}

export default LiveActivityFeedVirtualTable;
