import { useLocation, useNavigate, useParams } from "react-router-dom";

/**
 * @deprecated */
function withNavigate(Component) {
  return (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    return <Component navigate={navigate} match={{ params }} location={location} {...props} />;
  };
}
export default withNavigate;
