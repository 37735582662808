import GroupLeaderboardModuleForm from "./Forms/GroupLeaderboardModuleForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";

function LeaderBoardModule(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title="Add a Leaderboard Module" close={closeModal}>
      <GroupLeaderboardModuleForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}

export default LeaderBoardModule;
