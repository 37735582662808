import { styled } from "@mui/material";

/**
 * @author Carden
 * Material UI Radio buttons have a RHS margin
 * of 16px. This fix counteracts that margin
 * so the RHS of the inputs are touching the
 * edge of the container.
 * {@link https://github.com/mui-org/material-ui/blob/8109f08888d7f8ed506632106e5ee16d1d83202e/packages/material-ui/src/FormControlLabel/FormControlLabel.js#L20}
 */
export default styled("div")({ width: "calc(100% + 16px)" });
