import ChartSetSelectContext from "components/charting/contexts/ChartSetSelectContext";
import { LineData } from "components/charting/types/Chart.types";
import useDefinedContext from "hooks/useDefinedContext";
import { useMemo } from "react";
import useGetMeasurementsById from "hooks/useGetMeasurementsById";
import { ProfilingChartEntityId } from "components/charting/types";
import useProfilingLine from "../hooks/useProfilingLine";
import ChartCompareSetSelectContext from "components/charting/contexts/ChartCompareSetSelectContext";

function useAthleteProfilingChartData(): LineData[] {
  const { value: selectedSets } = useDefinedContext(ChartSetSelectContext);
  const { value: selectedCompareSets } = useDefinedContext(ChartCompareSetSelectContext);

  const selectedSetMeasurementIds = useMemo(() => selectedSets.map((set) => set.measurementId), [selectedSets]);
  const selectedCompareSetMeasurementIds = useMemo(
    () => selectedCompareSets.map((set) => set.measurementId),
    [selectedCompareSets]
  );

  const { measurements } = useGetMeasurementsById({
    measurementIds: [...selectedSetMeasurementIds, ...selectedCompareSetMeasurementIds],
  });

  const primaryMeasurements = useMemo(
    () => measurements.filter(({ id }) => selectedSetMeasurementIds.includes(id)),
    [selectedSetMeasurementIds, measurements]
  );

  const comparativeMeasurements = useMemo(
    () => measurements.filter(({ id }) => selectedCompareSetMeasurementIds.includes(id)),
    [selectedCompareSetMeasurementIds, measurements]
  );

  const primaryLineData = useProfilingLine(primaryMeasurements, ProfilingChartEntityId.PROFILING_LINE);

  const comparativeLineData = useProfilingLine(comparativeMeasurements, ProfilingChartEntityId.COMPARATIVE_LINE);

  return useMemo(() => [...primaryLineData, ...comparativeLineData], [primaryLineData, comparativeLineData]);
}

export default useAthleteProfilingChartData;
