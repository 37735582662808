import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Variants } from "pages/Workouts/WorkoutForm/types/Exercise.types";

function GridRowSpacer() {
  return (
    <Grid item xs={12}>
      <Box pb={0.5} />
    </Grid>
  );
}

function GridDateWrapper(props: { children: React.ReactNode; label: string }) {
  const { children, label } = props;

  return (
    <Grid item xs>
      <Typography variant="body2">
        <Box component="span" fontWeight="fontWeightRegular" aria-label={label}>
          {children}
        </Box>
      </Typography>
    </Grid>
  );
}

function GridDataWrapper(props: { children: React.ReactNode; isDate?: boolean; label: string }) {
  const { children, isDate, label } = props;
  if (isDate) {
    return <GridDateWrapper label={label}>{children}</GridDateWrapper>;
  }
  return (
    <Grid item xs>
      <Typography aria-label={label}>{children}</Typography>
    </Grid>
  );
}

export function RagStatusSummaryCardGridVariantHeaderRow(props: { display?: boolean }) {
  if (!props.display) return null;

  return (
    <>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Typography variant="body2">{Variants.Left}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">{Variants.Right}</Typography>
      </Grid>
      <Grid item xs={12} />
    </>
  );
}

export function RagStatusSummaryCardGridRowDivider() {
  return (
    <Grid item xs={12}>
      <Box pb={1} />
      <Divider />
      <Box pb={1} />
    </Grid>
  );
}

export function RagStatusSummaryCardGridDataRow(props: {
  property: "average" | "latest" | "best" | "standardDeviation" | "lastTested";
  label: string;
  formattedValues: (Record<
    "average" | "latest" | "best" | "standardDeviation" | "lastTested",
    string | null | undefined
  > & {
    variant: Variants | null;
  })[];
}) {
  const leftValue = props.formattedValues.find((value) => value.variant === Variants.Left);
  const rightValue = props.formattedValues.find((value) => value.variant === Variants.Right);

  function renderData() {
    if (leftValue || rightValue) {
      return (
        <>
          <GridDataWrapper isDate={props.property === "lastTested"} label={`${props.label}-${leftValue?.variant}`}>
            {(leftValue && leftValue[props.property]) || "-"}
          </GridDataWrapper>
          <GridDataWrapper isDate={props.property === "lastTested"} label={`${props.label}-${rightValue?.variant}`}>
            {(rightValue && rightValue[props.property]) || "-"}
          </GridDataWrapper>
        </>
      );
    }

    const defaultValue = props.formattedValues[0];

    return <GridDataWrapper label={props.label}>{defaultValue[props.property]}</GridDataWrapper>;
  }

  return (
    <Grid container xs={12} item spacing="4px">
      <Grid item xs>
        <Typography variant="caption" aria-label={props.label}>
          {props.label}
        </Typography>
      </Grid>
      {renderData()}
      <GridRowSpacer />
    </Grid>
  );
}

export const RagStatusSummaryCardPopoverStyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isVariantExercise",
})<{
  isVariantExercise: number;
}>(({ theme, isVariantExercise }) => ({
  maxWidth: isVariantExercise === 1 ? theme.spacing(32) : theme.spacing(48),
}));
