import { styled } from "@mui/material";
import Box from "@mui/material/Box";

export const SideMenuStyledBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(7),
}));

export const BorderLeftBox = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[400]}`,
}));

export const BorderBottomBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));
