import http from "axiosSetup";
import AnalyticsService from "services/AnalyticsService";
import { ReportTemplate, ReportTemplateBase } from "types";
import FileService from "services/FileService";

class ReportService {
  static generateAthleteReport(reportObj: ReportTemplate): Promise<void> {
    AnalyticsService.logEvent(AnalyticsService.EVENTS.ATHLETE_REPORT_GENERATION);

    return http
      .post(`/report-generate/athlete-once`, reportObj, {
        responseType: "arraybuffer",
      })
      .then(FileService.downloadResponseAsZip);
  }

  static generateGroupReport(reportObj: ReportTemplate): Promise<void> {
    AnalyticsService.logEvent(AnalyticsService.EVENTS.GROUP_REPORT_GENERATION);

    return http
      .post(`/report-generate/group-once`, reportObj, {
        responseType: "arraybuffer",
      })
      .then(FileService.downloadResponseAsZip);
  }

  static getTemplateById(id: string, callback?: (template: ReportTemplate) => any): Promise<ReportTemplate> {
    return http.get(`/report-templates/${id}`).then((response) => {
      if (typeof callback === "function") {
        callback(response.data);
      }
      return response.data;
    });
  }

  static getAllTemplates(): Promise<ReportTemplate[]> {
    return http.get(`/report-templates`).then((response) => response.data);
  }

  static createTemplate(template: ReportTemplateBase): Promise<ReportTemplate> {
    return http.post(`/report-templates`, template).then((response) => response.data);
  }

  static deleteTemplate(id: string): Promise<void> {
    return http.delete(`/report-templates/${id}`).then((response) => response.data);
  }
}
export default ReportService;
