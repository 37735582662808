import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { styled, darken } from "@mui/material";

const StyledMuiLink = styled(MuiLink, {
  shouldForwardProp: (propName) => propName !== "inheritColor",
})(({ inheritColor }) => ({
  color: inheritColor ? "inherit" : "#39D0FF",
  textDecoration: "none",
  "&:hover": {
    color: inheritColor ? "inherit" : darken("#39D0FF", 0.2),
    textDecoration: "none",
  },
}));

function Link({ children, inheritColor = false, ...props }) {
  return (
    <StyledMuiLink component={RouterLink} {...props}>
      {children}
    </StyledMuiLink>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
