import { styled } from "@mui/material";
import MuiButton, { buttonClasses } from "@mui/material/Button";

const Button = styled(MuiButton, { shouldForwardProp: (propName) => propName !== "square" })<{ square?: boolean }>(
  ({ theme, square }) => ({
    [`&.${buttonClasses.root}`]: {
      minWidth: 0,
      borderRadius: theme.spacing(square ? 0.5 : 16),
      textTransform: "capitalize",
    },
    [`&.${buttonClasses.text}`]: {
      padding: 0,
      margin: 0,
      borderRadius: theme.spacing(0),
    },
    [`&.${buttonClasses.sizeLarge}`]: {
      padding: theme.spacing(1.5),
    },
  })
);

export default Button;
