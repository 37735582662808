import useDefinedContext from "hooks/useDefinedContext";
import Mui5 from "library/mui5";
import LeaderboardControlsContext from "../contexts/LeaderboardControlsContext";
import useLeaderboardsContext from "../hooks/useLeaderboardsContext";
import { maxLeaderboards } from "../LeaderboardTables/LeaderboardEditable/LeaderboardEditable.config";
import { useTranslation } from "react-i18next";

interface ShowLeaderboardCreateButtonProps {}

function ShowLeaderboardCreateButton(props: ShowLeaderboardCreateButtonProps) {
  const { t } = useTranslation();
  const [leaderboards] = useLeaderboardsContext();
  const [, setShow] = useDefinedContext(LeaderboardControlsContext);

  function toggleShowCreate() {
    setShow((prev) => !prev);
  }

  return (
    <Mui5.Button
      fullWidth
      color="primary"
      disableElevation
      variant="contained"
      onClick={toggleShowCreate}
      disabled={leaderboards.length >= maxLeaderboards}
    >
      {t("Leaderboard.createButtonText")}
    </Mui5.Button>
  );
}

export default ShowLeaderboardCreateButton;
