import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const size = {
  small: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.33342" cy="5.86243" r="2.5291" stroke="currentColor" strokeWidth="1.2" />
      <path
        d="M1.33334 12.8174C2.04368 11.3222 3.56778 10.2884 5.33334 10.2884C7.0989 10.2884 8.62301 11.3222 9.33334 12.8174"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <circle cx="12.0001" cy="7.0194" r="1.68606" stroke="currentColor" strokeWidth="1.2" />
      <path
        d="M9.33334 12.3527C9.8069 11.3559 10.823 10.6667 12 10.6667C13.1771 10.6667 14.1931 11.3559 14.6667 12.3527"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  ),
  default: (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.00013" cy="8.79365" r="3.79365" stroke="currentColor" strokeWidth="1.6" />
      <path
        d="M2 19.2262C3.06551 16.9832 5.35166 15.4325 8 15.4325C10.6483 15.4325 12.9345 16.9832 14 19.2262"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <circle cx="18.0002" cy="10.5291" r="2.5291" stroke="currentColor" strokeWidth="1.6" />
      <path
        d="M14 18.5291C14.7103 17.0338 16.2344 16 18 16C19.7656 16 21.2897 17.0338 22 18.5291"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  ),
  large: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.6668" cy="11.7249" r="5.05819" stroke="currentColor" strokeWidth="1.8" />
      <path
        d="M2.66669 25.6349C4.08736 22.6443 7.13557 20.5767 10.6667 20.5767C14.1978 20.5767 17.246 22.6443 18.6667 25.6349"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
      <circle cx="24.0002" cy="14.0388" r="3.37213" stroke="currentColor" strokeWidth="1.8" />
      <path
        d="M18.6667 24.7055C19.6138 22.7117 21.6459 21.3333 24 21.3333C26.3541 21.3333 28.3862 22.7117 29.3334 24.7055"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  ),
};

function GroupsIcon(props: SvgIconProps) {
  const item = props.fontSize === "large" || props.fontSize === "small" ? size[props.fontSize] : size.default;

  return <SvgIcon {...props}>{item}</SvgIcon>;
}
export default GroupsIcon;
