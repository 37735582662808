import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ragStatusSvgSelector from "./ragStatusSvgSelector";
import { styled } from "@mui/material";

const RAGStatusSvg = styled("img")({ padding: "0 1px" });

const variants = ["Left", "Right"];

function RagStatusIndicator(props) {
  if (!props.values) return null;

  if (props.values.every((value) => variants.includes(value.variant))) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box display="inline-flex">
          {variants.map((variant) => {
            const progressArrow = props.values.find((value) => value.variant === variant)?.progressArrow;
            const { src, alt } = ragStatusSvgSelector({ progressArrow, variant });
            return <RAGStatusSvg key={variant} src={src} alt={alt} />;
          })}
        </Box>
      </Box>
    );
  }

  const { src, alt } = ragStatusSvgSelector(props.values[0]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <RAGStatusSvg src={src} alt={alt} />
    </Box>
  );
}

RagStatusIndicator.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf([null, ...variants]),
      progressArrow: PropTypes.oneOf([-2, -1, 0, 1]),
    }).isRequired
  ).isRequired,
};

export default RagStatusIndicator;
