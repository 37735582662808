import { styled, alpha } from "@mui/material";
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)((props) => {
  const unfocusedLabelColor = alpha(props.theme.palette.common.black, 0.54);
  const unfocusedBorderColor = alpha(props.theme.palette.common.black, 0.23);

  return {
    "& .MuiInputLabel-root": {
      transform: props.variant === "standard" ? "translate(0, 1.5px) scale(0.75)" : undefined,
      "&.Mui-focused": {
        color: unfocusedLabelColor,
      },
    },
    "& .MuiInput-root": {
      "&.MuiInput-underline": {
        "&:before": {
          borderWidth: 1,
          // TODO: not sure why this is not defaulting to this value
          borderColor: unfocusedBorderColor,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderWidth: 2,
          borderColor: unfocusedBorderColor,
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1,
        borderColor: unfocusedBorderColor,
      },
      "&:hover:not(.Mui-disabled) fieldset": {
        borderWidth: 1,
        borderColor: props.color && props.theme.palette[props.color].main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
      },
    },
  };
});
export default TextField as typeof MuiTextField;
