import React, { useContext } from "react";

interface UndefinableContext<T> extends React.Context<T | undefined> {}

function useDefinedContext<T>(Context: UndefinableContext<T>): T {
  const contextValue = useContext(Context);

  if (typeof contextValue === "undefined") throw new Error(`${Context.displayName}.Provider is undefined`);

  return contextValue;
}
export default useDefinedContext;
