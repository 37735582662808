import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */

function SetComparisonChartIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6 13.875L10.5 13.125M17.625 7.125L13.125 11.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M6 15L10.5 17.25M17.25 17.625L13.5 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <circle cx="4.5" cy="14.25" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="transparent" />
      <circle cx="12" cy="12.75" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="transparent" />
      <circle cx="12" cy="18" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="transparent" />
      <circle cx="18.75" cy="6" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="transparent" />
      <circle cx="18.75" cy="17.25" r="1.5" stroke="currentColor" strokeWidth="1.5" fill="transparent" />
    </SvgIcon>
  );
}
export default SetComparisonChartIcon;
