import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LoadingFallback from "pages/Dashboards/DashboardsHome/DashboardWidgetFactory/components/WidgetStatusHandler/LoadingFallback";
import NotFound from "pages/NotFound";
import { useMatch } from "react-router-dom";
import { Dashboard } from "types/Dashboard";

export interface DashboardNoDataWrapperProps {
  children?: React.ReactNode;
  fetching: boolean;
  dashboards: Dashboard[];
}

function DashboardNoDataWrapper(props: DashboardNoDataWrapperProps) {
  const { t } = useTranslation();
  const { children, fetching, dashboards } = props;
  const match = useMatch("/dashboards/:dashboardId");

  const id = match?.params.dashboardId;
  const exists = useMemo(() => Boolean(dashboards.find((d) => d.id === id)), [id, dashboards]);

  if (fetching) {
    return <LoadingFallback animation="pulse" message={t("DashboardNoDataWrapper.loadingMessage")} />;
  } else if (match?.params.dashboardId && !exists) {
    /**
     * If dashboard of `dashboardId` is not found, show NotFound component.
     */
    return (
      <NotFound
        link="/dashboards"
        message={t("DashboardNoDataWrapper.notFoundMessage")}
        buttonText={t("DashboardNoDataWrapper.notFoundButtonText")}
      />
    );
  }

  return <>{children}</>;
}

export default memo(DashboardNoDataWrapper);
