import AnalyticsService from "services/AnalyticsService";

const propertyValues = Object.values(AnalyticsService.PROPERTIES);

function logEvent(
  eventName: keyof typeof AnalyticsService.EVENTS,
  properties?: Partial<{ [key in (typeof propertyValues)[number]]: number | string }>
) {
  if (!eventName) throw new Error("Set eventName when calling logEvent");

  AnalyticsService.logEvent(AnalyticsService.EVENTS[eventName], properties);
}

export default logEvent;
