import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageContent from "shared/components/PageContent";
import Constants from "../../../shared/utils/Constants";
import AthleteForm from "../../../library/Forms/AthleteForm";
import Paper from "library/mui5/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import useInfoMessage from "../../../hooks/useInfoMessage";
import useGroupsContext from "hooks/useGroupsContext";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import useRequest from "hooks/useRequest";
import { useLoadingContext } from "components/LocalisedLoadingProvider";
import Loader from "shared/components/Loader/Loader";
import { style } from "config";
import useCanDo from "hooks/useCanDo";
import useRole from "providers/RoleProvider/useRole";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import { useTranslation } from "react-i18next";
import useAthleteLimits from "hooks/useAthleteLimits";
import logEvent from "utils/logEvent";

function CreateAthlete(props) {
  const canDo = useCanDo();
  const onError = useInfoMessage({ type: "error" });
  const athleteActions = useAthleteActions();
  const [groups, groupActions] = useGroupsContext();
  const handleCreateAthlete = useRequest(athleteActions.create, true);
  const [loading] = useLoadingContext();
  const navigate = useNavigate();
  const role = useRole();
  const { t } = useTranslation();
  const athleteLimits = useAthleteLimits();
  const organisationActions = useOrganisationActions();

  useEffect(() => {
    groupActions.fetchAll();
    organisationActions.get();
  }, [groupActions, organisationActions]);

  async function createAthlete(values) {
    try {
      const existingGroupIds = groups
        .filter((group) =>
          values.groups.find(
            (g) => g.id === group.id || (g?.toLowerCase && g.toLowerCase()) === group.name.toLowerCase()
          )
        )
        .map((group) => group.id);

      const newGroupNames = values.groups
        .filter(
          (g) =>
            typeof g === "string" && Boolean(g) && !groups.find((group) => group.name.toLowerCase() === g.toLowerCase())
        )
        .filter(
          (groupName, index, arr) =>
            !arr.slice(0, index).find((newGroupName) => newGroupName.toLowerCase() === groupName.toLowerCase())
        );

      let newGroups = [];

      if (canDo("group.any")) {
        newGroups = await Promise.all(
          newGroupNames.map((name) => {
            const randomColour = style.colors.options[Math.floor(Math.random() * style.colors.options.length)];
            return groupActions.create({ name, colour: randomColour, athleteIds: [], description: "" });
          })
        );
      }

      const newGroupIds = newGroups.map((group) => group.id);

      const newAthleteId = await handleCreateAthlete({
        firstName: values.firstName,
        lastName: values.lastName,
        externalId: values.externalId,
        email: values.email,
        sex: { Male: "Male", Female: "Female" }[values.sex] || null,
        dateOfBirth: values.dateOfBirth,
        weight: values.weight,
        height: values.height,
        profilePhotoName: values.profilePhotoName,
        groupIds: existingGroupIds.concat(newGroupIds),
      });

      logEvent("ATHLETE_CREATE");

      navigate(`${Constants.routes.athletes.route}/${newAthleteId}`);
    } catch (error) {
      const customErrorMessages = {
        409: {
          message: "Conflict: The email address you have entered is already in use.",
        },
      };

      const errorObject = customErrorMessages[error?.response?.status] || error;

      onError(errorObject);
    }
  }

  function renderHasHitAthleteLimit() {
    const alertMessage = [
      t("Athlete.createAthleteLimitAlertMessage"),
      t("Athlete.createAthleteLimitAlertCallToAction", {
        context: role === "OWNER" ? "OwnerRole" : "OtherRole",
        activeUsage: `${athleteLimits.active.count}/${athleteLimits.active.limit}`,
        archivedUsage: `${athleteLimits.archived.count}/${athleteLimits.archived.limit}`,
      }),
    ].join(" ");

    return (
      athleteLimits.active.isFull && (
        <Box pb={3}>
          <Alert severity="warning" variant="filled">
            {alertMessage}
          </Alert>
        </Box>
      )
    );
  }

  return (
    <PageContent pageHeading={t("Athlete.createAthletePageHeading")} pageHeadingLink={Constants.routes.athletes.route}>
      <Grid container mt={2}>
        <Grid item xs={false} sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Paper>
            <Box p={3}>
              {renderHasHitAthleteLimit()}
              <AthleteForm
                initialValues={{
                  firstName: "",
                  lastName: "",
                  fileToUpload: null,
                  externalId: "",
                  dateOfBirth: null,
                  sex: "Prefer Not Say",
                  height: null,
                  weight: null,
                  groups: [],
                  email: "",
                  profilePhotoName: null,
                }}
                photoChangeButtonText={t("Athlete.createAthleteProfilePhotoText")}
                groupOptions={groups}
                submitText="Add Athlete"
                submitDisabled={athleteLimits.active.isFull}
                onSubmit={createAthlete}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {loading && <Loader />}
    </PageContent>
  );
}

export default CreateAthlete;
