import { useState, useMemo, useCallback } from "react";
import { combineSections } from "./GlobalSearch.utils";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "library/Icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import useGroupsContext from "hooks/useGroupsContext";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import { firstBy } from "thenby";
import * as Components from "./GlobalSearch.components";
import useCanDo from "hooks/useCanDo";
import logEvent from "utils/logEvent";
import naturalCompare from "config/sort/naturalSort";
import AthleteHelpers from "helpers/AthleteHelpers";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

function GlobalSearch() {
  const [inputValue, setInputValue] = useState("");
  const athletes = useAthleteStore();
  const [groups] = useGroupsContext();
  const navigate = useNavigate();
  const canDo = useCanDo();

  const sections = useMemo(
    () =>
      combineSections({
        Athletes: athletes.sort(firstBy("fullName").thenBy("id")),
        Groups: canDo("group.any") ? groups.sort((a, b) => naturalCompare(a.name, b.name)) : [],
      }),
    [athletes, canDo, groups]
  );

  const handleSelection = useCallback(
    (_, option) => {
      if (option?.url) {
        logEvent("HUB_GLOBAL_SEARCH", { section: option.section });
        navigate(option.url);
      }
    },
    [navigate]
  );

  const handleInputChange = useCallback((_, newInputValue, reason) => {
    setInputValue(reason === "reset" ? "" : newInputValue);
  }, []);

  const inputPlaceholder = useMemo(
    () => ["Search for athletes", canDo("group.any") && "or groups"].filter(Boolean).join(" "),
    [canDo]
  );

  const renderSearchInput = useCallback(
    (params) => (
      <Components.InputContainerPaper ref={params.InputProps.ref}>
        <Components.PaddedInputBase
          fullWidth
          inputProps={params.inputProps}
          placeholder={inputPlaceholder}
          endAdornment={<SearchIcon />}
        />
      </Components.InputContainerPaper>
    ),
    [inputPlaceholder]
  );

  const getOptionLabel = useCallback(
    (option) => (option.section === "Groups" ? option.name : AthleteHelpers.getFullName(option)),
    []
  );

  const renderOption = useCallback(
    (props, option) => (
      <AutocompleteOptionColourable
        {...props}
        key={option.id}
        colour={option.section === "Groups" ? option.colour : undefined}
      >
        {getOptionLabel(option)}
      </AutocompleteOptionColourable>
    ),
    [getOptionLabel]
  );

  return (
    <Box>
      <Autocomplete
        id="global-search-athletes-groups"
        options={sections}
        autoHighlight
        getOptionLabel={getOptionLabel}
        groupBy={(option) => option.section}
        value={null}
        onChange={handleSelection}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={renderSearchInput}
        renderOption={renderOption}
      />
    </Box>
  );
}

export default GlobalSearch;
