import Grid from "@mui/material/Grid";
import DependantFormField from "library/Form/DependantFormField";
import FormAutoComplete from "library/Form/FormAutocomplete";
import { Formik, Form, FormikHelpers } from "formik";
import { Exercise } from "types";
import RadioGroup from "library/Form/RadioGroup";
import useExercises from "providers/ExercisesProvider/useExercises";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useCallback, useMemo } from "react";
import CustomDateRange from "library/Form/CustomDateRange";
import CustomDateRangeFullWidthFix from "library/Forms/CustomDateRangeFullWidthFix";
import Button from "library/mui5/Button";
import moment from "moment";
import SetDateRangeHelper from "helpers/SetDateRangeHelper";
import { schema } from "./AthleteTableViewForm.schema";
import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import Mui5 from "library/mui5";

interface TableViewFormInitialValues<T = string, U = string> {
  exerciseId: null | T;
  rangeType: RangeType;
  startDate: undefined | U;
  endDate: undefined | U;
  moduleType: 2;
}

interface AthleteTableViewFormProps {
  onSubmit(values: TableViewFormInitialValues): void;
  onCancel(): void;
  initialValues?: TableViewFormInitialValues;
}

function AthleteTableViewForm(props: AthleteTableViewFormProps) {
  const { onSubmit, initialValues, onCancel } = props;

  const exercises = useExercises();

  const onCustomDateDependancyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("rangeType", RangeType.Other),
    []
  );

  const memoizedInitialValues = useMemo(() => {
    const { startDate, endDate, rangeType, exerciseId } = initialValues || {};

    return {
      exerciseId: exercises.find((exercise) => exercise.id === exerciseId) || null,
      rangeType: rangeType ?? RangeType.Month,
      startDate: startDate ? moment(startDate).startOf("day") : undefined,
      endDate: endDate ? moment(endDate).endOf("day") : undefined,
      moduleType: 2 as const,
    };
  }, [exercises, initialValues]);

  function handleSubmit(values: TableViewFormInitialValues<Exercise, moment.Moment>) {
    const { startDate, endDate, rangeType } = values;

    return onSubmit({
      ...values,
      ...SetDateRangeHelper.calculateDateRange({
        rangeType: rangeType ?? RangeType.Month,
        startDate: startDate,
        endDate: endDate,
      }),
      exerciseId: values.exerciseId?.id || null,
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={memoizedInitialValues} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormAutoComplete
              name="exerciseId"
              options={exercises}
              groupBy={(option: Exercise) => option.category}
              getOptionLabel={(option: Exercise) => option.name || ""}
              renderInput={(params: any) => (
                <Mui5.TextField {...params} label="Exercise" placeholder="Select Exercise" variant="standard" />
              )}
              isOptionEqualToValue={(option: Exercise, value: Exercise | undefined) => option.id === value?.id}
              filterOptions={exerciseAutocompleteFilterOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              label="Date Range"
              name="rangeType"
              color="primary"
              options={[
                { label: "Today", value: RangeType.Day },
                { label: "Week", value: RangeType.Week },
                { label: "Month", value: RangeType.Month },
                { label: "Year", value: RangeType.Year },
                {
                  label: "Custom",
                  value: RangeType.Other,
                  component: (
                    <CustomDateRangeFullWidthFix>
                      <DependantFormField
                        dependancyName="startDate"
                        onDependancyChange={onCustomDateDependancyChange}
                      />
                      <DependantFormField dependancyName="endDate" onDependancyChange={onCustomDateDependancyChange} />
                      <CustomDateRange startInputName="startDate" endInputName="endDate" />
                    </CustomDateRangeFullWidthFix>
                  ),
                },
              ]}
            />
          </Grid>

          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              Confirm
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default AthleteTableViewForm;
