import { DateTime, Exercise, Measurement } from "types";
import { useEffect, useState } from "react";
import MeasurementService from "services/MeasurementService";
import { getTodayDateRangeParams } from "../../AthleteProfileActivityFeed.helpers";
import groupBy from "lodash/groupBy";
import useRequest from "hooks/useRequest";
import useExercises from "providers/ExercisesProvider/useExercises";
import { firstBy } from "thenby";
import useExcludeArchivedExerciseItems from "hooks/useExcludeArchivedExerciseItems";

function useActivityFeedAllActivity(props: { id: string; date: DateTime }) {
  const { id, date } = props;
  const [measurements, setMeasurements] = useState<{ exerciseId: string; measurements: Measurement[] }[]>([]);
  const handleGetMeasurementsByAthleteId = useRequest(MeasurementService.getByAthleteId);
  const exercises = useExercises();

  useEffect(() => {
    const dateParams = getTodayDateRangeParams(date);

    (async () => {
      const result = await handleGetMeasurementsByAthleteId(id, { ...dateParams });

      const grouped = Object.entries(groupBy(result, (measurement) => measurement.exerciseId)).map(([key, value]) => ({
        exerciseId: key,
        exercise: exercises.find((exercise) => exercise.id === key),
        measurements: value,
      }));

      const sorted = grouped
        .sort(
          firstBy<{ exercise?: Exercise }>((a, b) =>
            a.exercise && b.exercise && a.exercise.name > b.exercise.name ? 1 : -1
          ).thenBy((a, b) => (a.exercise && b.exercise && a.exercise.category > b.exercise.category ? 1 : -1))
        )
        .map(({ exercise, ...rest }) => rest);

      setMeasurements(sorted);
    })();

    return () => setMeasurements([]);
  }, [id, date, handleGetMeasurementsByAthleteId, exercises]);

  const excludeArchivedExerciseMeasurements = useExcludeArchivedExerciseItems(measurements);

  return excludeArchivedExerciseMeasurements;
}

export default useActivityFeedAllActivity;
