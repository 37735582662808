import LeaderboardControlsContext from "pages/Leaderboards/contexts/LeaderboardControlsContext";
import { useState } from "react";

interface LeaderboardControlsProviderProps {
  children?: React.ReactNode;
}

function LeaderboardControlsProvider(props: LeaderboardControlsProviderProps) {
  const showCreateState = useState(false);

  return (
    <LeaderboardControlsContext.Provider value={showCreateState}>{props.children}</LeaderboardControlsContext.Provider>
  );
}

export default LeaderboardControlsProvider;
