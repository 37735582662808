import SvgIcon from "@mui/material/SvgIcon";

/**
 * @param {import("@mui/material").SvgIconProps} props
 */
function FullScreenIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask id="path-1-inside-1_11927_45475" fill="white">
        <rect x="1.5" y="3.75" width="21" height="16.5" rx="0.75" />
      </mask>
      <rect
        x="1.5"
        y="3.75"
        width="21"
        height="16.5"
        rx="0.75"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="3"
        mask="url(#path-1-inside-1_11927_45475)"
      />
      <path
        d="M8.25 7.5H5.25V9.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 16.5L18.75 16.5L18.75 14.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 9.75L18.75 7.5L15.75 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 14.25L5.25 16.5L8.25 16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
export default FullScreenIcon;
