import { LinePath } from "@visx/shape";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { ChartLine } from "components/charting/types/Chart.types";
import useUnitFormatter from "hooks/useUnitFormatter";
import useChartingColors from "pages/Charting/hooks/useChartingColors";
import useColorRegistry from "pages/Charting/hooks/useColorRegistry";
import useChartAnnotationLineLabelPosition from "../hooks/useChartAnnotationLineLabelPosition";
import { useCallback, useEffect } from "react";
import useChart1RMApi from "pages/Charting/hooks/useChart1RMApi";
import React from "react";
import logEvent from "utils/logEvent";

function OneRepMaxLine(props: ChartLine) {
  const { entityId } = props;
  const getEntityColor = useColorRegistry();
  const oneRepMaxLineData = useChart1RMApi(props);
  const { axis } = useChartingColors();
  const unitFormatter = useUnitFormatter();
  const selections = useChartSelections();

  const getX = useCallback((data: { x: number; y: number }) => data.x, []);
  const getY = useCallback((data: { x: number; y: number }) => data.y, []);

  const setLineLabelPosition = useChartAnnotationLineLabelPosition();

  useEffect(() => {
    logEvent("CHARTING_V3_VIEW_1RM_LINE");
  }, []);

  return (
    <>
      {oneRepMaxLineData
        .filter((oneRepMax) => (oneRepMax.variant ? oneRepMax.variant === props.variant : oneRepMax))
        .map((lineData) => (
          <React.Fragment key={[props.source.id, entityId, props.variant].filter(Boolean).join("_")}>
            {lineData.oneRepMaxLineCoords.map((lineCoord, index) => (
              <text
                key={["1RM-LABEL", props.source.id, entityId, lineCoord.variant, index].filter(Boolean).join("_")}
                y={getY(lineCoord) + setLineLabelPosition(lineCoord.variant, Boolean(index))}
                x={getX(lineCoord)}
                textAnchor={index ? "end" : undefined}
                fontSize={12}
                fill={axis.line}
              >
                {[
                  "1RM",
                  unitFormatter(lineData.weight, selections.measures.find((m) => m.id === props.measure.id)?.metric),
                  lineCoord.variant && `(${lineCoord.variant.charAt(0)})`,
                ]
                  .filter(Boolean)
                  .join(" ")}
              </text>
            ))}
            <LinePath
              data={lineData.oneRepMaxLineCoords}
              x={getX}
              y={getY}
              stroke={getEntityColor(entityId)}
              strokeWidth={2}
              strokeDasharray="1 3"
              strokeLinecap="round"
            />
          </React.Fragment>
        ))}
    </>
  );
}

export default OneRepMaxLine;
