/* eslint-disable prettier/prettier */
const firebaseConfig = {
  apiKey: "AIzaSyBKr8whXAXRG0QYGsessxefvhvmc35-zk0",
  authDomain: "output-development.firebaseapp.com",
  databaseURL: "https://output-development.firebaseio.com",
  projectId: "output-development",
  storageBucket: "output-development.appspot.com",
  messagingSenderId: "815694947400",
  appId: "1:815694947400:web:376769734aecb4cbc9247d",
  measurementId: "G-89NS96QLNW",
};

export default firebaseConfig;
