import { styled } from "@mui/material";
import MuiMenuItem from "@mui/material/MenuItem";

const AutocompleteOptionColourable = styled(MuiMenuItem, { shouldForwardProp: (key) => key !== "colour" })<{
  colour?: string;
}>((props) => ({
  "&::before": {
    position: "absolute",
    content: `" "`,
    top: 0,
    bottom: 0,
    left: 0,
    width: 8,
    height: "100%",
    backgroundColor: props.colour,
  },
}));

export default AutocompleteOptionColourable;
