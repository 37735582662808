const workoutOverviewConfig = {
  publish: {
    warnings: {
      isShared: "Changing this will affect Workout visibility for Groups or Athletes you cannot access.",
    },
    errors: {
      orgOwnerOnly: "Only the Organisation Owner can Publish or Unpublish this Workout.",
    },
  },
  assignment: {
    groups: {
      accessRemoved: "You will lose access to this Workout when the last Group is unassigned.",
    },
  },
};
export default workoutOverviewConfig;
