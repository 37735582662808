import { useMemo } from "react";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";

interface UseProfileLimitsProps {}

function useAthleteLimits(props?: UseProfileLimitsProps) {
  const organisation = useOrganisation();
  const activeProfileLimit = organisation.accountInfo.athleteLimit;
  const activeProfileCount = organisation.accountInfo.activeAthleteCount;
  const archivedProfileLimit = organisation.accountInfo.athleteLimit;
  const archivedProfileCount = organisation.accountInfo.archivedAthleteCount;

  return useMemo(
    () => ({
      active: {
        count: activeProfileCount,
        limit: activeProfileLimit,
        isFull: activeProfileCount >= activeProfileLimit,
      },
      archived: {
        count: archivedProfileCount,
        limit: archivedProfileLimit,
        isFull: archivedProfileCount >= archivedProfileLimit,
      },
    }),
    [activeProfileCount, activeProfileLimit, archivedProfileCount, archivedProfileLimit]
  );
}

export default useAthleteLimits;
