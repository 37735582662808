import React from "react";

export interface RAGSettings {
  id: string;
  name: string;
  thresholdType: "Percentage" | "StandardDeviation";
  greenThreshold: number;
  amberThreshold: number;
  items: {
    metadataType: string;
    metadataId: string;
  }[];
  default?: boolean;
}

export interface RAGSettingsActions {
  getAll(): Promise<void>;
  create(ragTemplate: RAGSettings): Promise<{ id: string }>;
  delete(id: string): Promise<void>;
  update(id: string, ragTemplate: RAGSettings): Promise<void>;
}

export type RAGSettingsContextType = [RAGSettings[], RAGSettingsActions] | undefined;

const RAGSettingsContext = React.createContext<RAGSettingsContextType>(undefined);

export default RAGSettingsContext;
