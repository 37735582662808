import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { WorkoutTaskType } from "pages/Workouts/Workouts.types";
import { getWorkoutTaskColor } from "pages/Workouts/WorkoutForm/WorkoutForm.config";

export const StepNumberBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "workoutTask",
})<{
  workoutTask: WorkoutTaskType;
}>(({ theme, workoutTask }) => {
  const backgroundColor = getWorkoutTaskColor(workoutTask, theme);

  return {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    borderRadius: theme.spacing(1),
    backgroundColor: backgroundColor,
    color: theme.palette.getContrastText(backgroundColor),
  };
});
