import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function TableData(props: { children: React.ReactNode; title?: string }) {
  return (
    <Box textAlign="center" title={props.title}>
      <Typography variant="h4" component="span">
        {props.children}
      </Typography>
    </Box>
  );
}
export function TableHead(props: { children: React.ReactNode; title?: string }) {
  return (
    <Box textAlign="center" title={props.title}>
      <Typography variant="caption" component="div">
        <Box fontSize={12} fontWeight="fontWeightRegular" component="span">
          {props.children}
        </Box>
      </Typography>
    </Box>
  );
}
