import useDefinedContext from "hooks/useDefinedContext";
import WellnessStoreContext from "../contexts/WellnessMetadataContext";

interface UseWellnessProps {}

function useWellnessMetadata(props?: UseWellnessProps) {
  return useDefinedContext(WellnessStoreContext);
}

export default useWellnessMetadata;
