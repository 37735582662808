import { useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useUserContext from "hooks/useUserContext";
import Mui5 from "library/mui5";
import useRequest from "hooks/useRequest";
import UserService from "services/UserService";
import { schema } from "./SettingsAccountPersonalEdit.schema";
import FormText from "library/Form/FormText";

function SettingsAccountPersonalEdit() {
  const [{ profile }, userActions] = useUserContext();
  const updateProfile = useRequest(UserService.updateProfile, true);
  const handleUpdate = useCallback(
    async ({ name }: { name?: string }) => {
      await updateProfile({ id: profile?.id, name });
      userActions.onUpdate({ name });
    },
    [profile?.id, updateProfile, userActions]
  );

  return (
    <Formik
      initialValues={{ name: profile?.name }}
      enableReinitialize
      onSubmit={handleUpdate}
      validationSchema={schema}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={400}>
            <Grid item xs={12}>
              <Typography variant="h3">
                <Box component="span" fontWeight="fontWeightMedium">
                  Personal Info
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormText name="name" label="Full Name" id="user-name-input" variant="standard" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                disabled={!dirty || isSubmitting}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
                aria-label="save personal info"
              >
                Save Changes
              </Mui5.Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default SettingsAccountPersonalEdit;
