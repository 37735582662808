import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useRequest } from "hooks";
import CoachForm from "library/Forms/CoachForm";
import Mui5 from "library/mui5";
import useCoachesActions from "providers/CoachesProvider/useCoachesActions";
import Typography from "@mui/material/Typography";
import { GridBorderBottomGrey } from "../Settings.components";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import PageContentTitle from "shared/components/PageContent/PageContentTitle";
import { useCallback, useEffect } from "react";
import { CoachBase } from "types/coach";
import { useNavigate } from "react-router-dom";
import HttpHelpers from "helpers/HttpHelpers";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import Alert from "@mui/material/Alert";

interface SettingsCoachCreateProps {}

function SettingsCoachCreate(props: SettingsCoachCreateProps) {
  const { create } = useCoachesActions();
  const createCoach = useRequest(create, true);
  const navigate = useNavigate();
  // TODO: (0S-4710) Replace with AccountInfoProvider
  const organisation = useOrganisation();
  const organisationActions = useOrganisationActions();

  useEffect(() => {
    organisationActions.get();
  }, [organisationActions]);

  const coachLimitHit = Boolean(organisation.accountInfo.coachLimit <= organisation.accountInfo.activeCoachCount);
  // END TODO

  const handleCreate = useCallback(
    async (coach: CoachBase) => {
      await createCoach(coach).catch(HttpHelpers.onError);
      navigate(-1);
    },
    [createCoach, navigate]
  );

  return (
    <LocalisedLoadingProvider>
      <Grid container>
        <GridBorderBottomGrey item xs={12}>
          <Box p={6}>
            <PageContentTitle
              title={
                <Typography component="span" variant="h1">
                  <Box component="span" fontWeight="fontWeightRegular">
                    Create a Coach
                  </Box>
                </Typography>
              }
              titleLink="/settings/coaches"
            />
          </Box>
          <Box pt={0} p={6} maxWidth={720}>
            <Mui5.Paper>
              {coachLimitHit && (
                <Box p={1}>
                  <Alert severity="error">
                    {
                      "Coach limit has been reached. Delete existing Coaches or contact support@outputsports.com to increase your limit."
                    }
                  </Alert>
                </Box>
              )}
              <Box px={8} py={5}>
                <CoachForm
                  onSubmit={handleCreate}
                  submitButtonText={"Create Coach"}
                  photoChangeButtonText={"Add Coach Photo"}
                  submitButtonDisabled={coachLimitHit}
                />
              </Box>
            </Mui5.Paper>
          </Box>
        </GridBorderBottomGrey>
      </Grid>
    </LocalisedLoadingProvider>
  );
}

export default SettingsCoachCreate;
