import useRequest from "hooks/useRequest";
import { useEffect, useState } from "react";
import AthleteService from "services/AthleteService";
import { DateTime, Wellness } from "types";
import { getTodayDateRangeParams } from "../../AthleteProfileActivityFeed.helpers";

interface ActivityFeedHookProps {
  id: string;
  date: DateTime;
}

function useActivityFeedWellness(props: ActivityFeedHookProps) {
  const { id, date } = props;
  const [wellness, setWellness] = useState<Partial<Wellness>>({});
  const handleGetAthleteWellness = useRequest(AthleteService.getAthleteWellness, true);

  useEffect(() => {
    (async () => {
      const dateParams = getTodayDateRangeParams(date);

      const result = await handleGetAthleteWellness(id, {
        ...dateParams,
      });

      setWellness(result?.athleteMeasurements?.measurements[0] || {});
    })();

    return () => setWellness({});
  }, [date, handleGetAthleteWellness, id]);

  return wellness;
}
export default useActivityFeedWellness;
