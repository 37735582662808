import Grid from "@mui/material/Grid";
// import * as Components from "./GroupsTableControls.components";
import { useNavigate } from "react-router-dom";
import { tableCells } from "../GroupsTable.config";
import { Group } from "types/groups";
import Mui5 from "library/mui5";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
// import { useMultiSelectAll } from "providers/MultiSelectProvider";

interface GroupsTableControlsProps {
  groups: Group[];
}

function GroupsTableControls(props: GroupsTableControlsProps) {
  const navigate = useNavigate();
  // const selectAll = useMultiSelectAll(props.groups);

  const handleAddGroupClick = useCallback(() => {
    navigate("/groups/add");
  }, [navigate]);

  const { t } = useTranslation();

  return (
    <Grid container minHeight={64} alignItems="center">
      <Grid {...tableCells.checkbox}>
        {/* <Components.MultiSelectCheckbox
          inputProps={{ "aria-label": `select all groups` }}
          checked={selectAll.value}
          indeterminate={selectAll.indeterminate}
          onChange={selectAll.toggle}
        /> */}
      </Grid>
      <Grid item xs />
      <Grid pr={2}>
        <Mui5.Button color="primary" disableElevation variant="contained" onClick={handleAddGroupClick} size="medium">
          {t("Group.All.addNewGroupButtonText")}
        </Mui5.Button>
      </Grid>
    </Grid>
  );
}

export default GroupsTableControls;
