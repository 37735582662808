import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import InputLabelWithOptional from "../InputLabelWithOptional";
import * as Components from "./MuiRadioButtons.components";

function MuiRadioButtons({ inverse, ...props }) {
  const {
    row = true,
    onBlur = null,
    error = false,
    optional = false,
    disabled = false,
    helperText = null,
    color = "default",
  } = props;
  function renderRadioButtons() {
    const radioIcon = <Radio color={color} />;
    return props.options.map(({ value, label, component }) => (
      <Components.MuiRadioButtonsFormControlLabel
        key={value}
        value={`${value}`}
        control={radioIcon}
        label={component || label}
        labelPlacement="end"
      />
    ));
  }

  return (
    <Components.MuiRadioButtonsFormControl component="fieldset" disabled={disabled}>
      <InputLabel focused={false} shrink error={error}>
        <InputLabelWithOptional label={props.label} optional={optional} />
      </InputLabel>
      <RadioGroup
        row={row}
        aria-label="radio-button-group"
        name={props.name}
        onBlur={onBlur}
        onChange={props.onChange}
        value={`${props.value}`}
      >
        {renderRadioButtons()}
      </RadioGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Components.MuiRadioButtonsFormControl>
  );
}

const ValuePropType = PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired;

MuiRadioButtons.propTypes = {
  value: ValuePropType,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: ValuePropType,
      component: PropTypes.node,
    }).isRequired
  ).isRequired,
  error: PropTypes.bool,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  helperText: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "default"]),
};

export default MuiRadioButtons;
