const style = {
  colors: {
    options: [
      "#0D1724",
      "#C0392B",
      "#2980B9",
      "#E67E22",
      "#7F8C8D",
      "#F1C40F",
      "#000000",
      "#68D5E4",
      "#9C89A0",
      "#DC227B",
      "#1C0E70",
      "#00BFA8",
    ],
    variants: {
      Left: "#2980B9",
      Right: "#C0392B",
    },
  },
};
export default style;
